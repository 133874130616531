import React, { useState, useEffect, useContext } from 'react';
import { AuthUserContext, withAuthorization } from '../Session';
import AdminLayout from './AdminLayout';
import { Drawer, Button, Table, Space, Form, Input, Select, message } from 'antd';
import { data } from '../../const/MockMenus';
import { PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../const/routes';
import { ApiHelpers } from '../../helpers';

const { Column } = Table;
const { Option } = Select;

function Menus() {
  // Get AuthUser ID from Firebase
  const firebaseAuthUser = useContext(AuthUserContext);
  const [form] = Form.useForm();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [companyIdentificationToken, setCompanyIdentificationToken] = useState("");
  const [restaurantData, setRestaurantData] = useState([]);
  const [autoGenId, setAutoGenId] = useState(200);

  const INITIAL_STATE_ELEMENT = {
    menuName: '',
    restaurantIdentificationToken: '',
    startSchedule: '',
    endSchedule: '',
    active: true
  }

  useEffect(() => {

    // Get Company Identifier and afterwards Restaurants
    ApiHelpers.getCompanyDetails(firebaseAuthUser.uid)
      .then((response) => {
        if (!response.data.message) {
          setCompanyIdentificationToken(response.data.uniqueIdentificationToken);
          setNewElement({ ...newElement, companyIdentificationToken: response.data.uniqueIdentificationToken });
          // Get Restaurants from API
          ApiHelpers.getRestaurantsInCompany(response.data.uniqueIdentificationToken)
            .then((response) => {
              setRestaurantData(response.data);
            }, (error) => {
              console.log(error);
            });
        }
      }, (error) => {
        console.log(error);
      });
  }, []);

  // Get Menus


  const [newElement, setNewElement] = useState({ ...INITIAL_STATE_ELEMENT });
  const [menuData, setMenuData] = useState(data);

  const onAddElement = () => {
    setActionType("new");
    setNewElement({ ...INITIAL_STATE_ELEMENT });
    setDrawerVisible(true);
  }
  const onClose = () => {
    setNewElement({ ...INITIAL_STATE_ELEMENT });
    onReset();
    setDrawerVisible(false);
  };

  const onEditElement = record => {
    setActionType("edit");
    const elementsIndex = menuData.findIndex(element => element.id === record.id);

    // Set form fields values
    form.setFieldsValue({
      name: record.name,
      restaurant: record.restaurant,
      id: record.id
    });

    // Update state with the element being edited
    setNewElement({
      name: record.name,
      restaurant: record.restaurant,
      id: record.id
    });
    setDrawerVisible(true);
  };

  const onSave = () => {
    if (actionType === "new") {

      ApiHelpers.postMenu(newElement)
        .then((response) => {

        })

      const newList = menuData.concat(newElement);
      setMenuData(newList);
      setAutoGenId(autoGenId + 1);
      message.success('Element adaugat!');
    }
    else if (actionType === "edit") {
      const elementsIndex = menuData.findIndex(element => element.id === newElement.id);
      let newMenuData = [...menuData];
      newMenuData[elementsIndex] = { ...newElement };
      setMenuData(newMenuData);
      message.success('Element modificat!');
    }
    onReset();
    setDrawerVisible(false);
  };

  const onChange = event => {
    setNewElement({
      ...newElement,
      [event.target.name]: event.target.value
    });
  };

  const onChangeSelect = value => {
    setNewElement({
      ...newElement,
      "restaurantIdentificationToken": value
    });
  };

  const onReset = () => {
    form.resetFields();
  };
  const { name, restaurant } = newElement;
  return (
    <AuthUserContext.Consumer>
      {authUser => (
        <AdminLayout authUser={authUser} pageTitle="Meniuri">
          <div>
            <Button type="primary" onClick={onAddElement} className="btnAdd">
              <PlusOutlined /> Adauga
            </Button>

            <Table dataSource={menuData} pagination={false}>
              <Column title="Nume" dataIndex="name" width="200px"
                render={(text, record) => (
                  <Link to={ROUTES.ADMIN_MENU_DISPLAY + record.id}>{record.name}</Link>
                )} />
              <Column title="Actiuni"
                render={(text, record) => (
                  <Space>
                    <Link to={ROUTES.HOME} target="_blank">Preview</Link>
                    <a onClick={() => onEditElement(record)}>Editează</a>
                  </Space>
                )} />
              <Column title="Restaurant" dataIndex="restaurant" key="restaurant" />
            </Table>

            <Drawer
              title="Adauga meniu"
              width={window.innerWidth > 1000 ? 1000 : window.innerWidth}
              onClose={onClose}
              visible={drawerVisible}
              bodyStyle={{ paddingBottom: 80 }}
              footer={
                <div
                  style={{
                    textAlign: 'right',
                  }}
                >
                  <Button size="large" onClick={onClose} style={{ marginRight: 8 }}>
                    Anuleaza
                      </Button>
                  <Button size="large" onClick={onSave} type="primary">
                    Salveaza
                      </Button>
                </div>
              }
            >
              <Form layout="vertical" form={form} hideRequiredMark>

                <Form.Item
                  name="restaurant"
                  label="Restaurant"
                  rules={[{ required: true, message: 'Camp obligatoriu' }]}
                >
                  <Select size="large" placeholder="Please select restaurant" onChange={onChangeSelect} name="restaurant" value={restaurant} >
                    {restaurantData.map(d => (
                      <Option key={d.uniqueIdentificationToken + "-" + Math.floor(Math.random() * 1111)} value={d.uniqueIdentificationToken}>{d.title}</Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="name"
                  label="Nume"
                  rules={[{ required: true, message: 'Camp obligatoriu' }]}
                >
                  <Input size="large" placeholder="Please enter name" onBlur={onChange} name="name" value={name} />
                </Form.Item>

              </Form>
            </Drawer>
          </div>
        </AdminLayout>
      )}
    </AuthUserContext.Consumer>
  );
}

const condition = authUser => !!authUser;
export default withAuthorization(condition)(Menus);