import React, { useContext, useEffect, useState } from 'react';
import { AuthUserContext, withAuthorization } from '../Session';
import AdminLayout from './AdminLayout';
import Locations from './Locations';
import LocationsDetails from './LocationsDetail';
import Menus from './Menus';
import AdminMenu from './AdminMenu';
import { Row, Col, Card, Skeleton, Spin, Button, Tag, Empty, DatePicker, Space } from 'antd';
import * as ROUTES from '../../const/routes';
import { ApiHelpers } from '../../helpers';

import { Link, useNavigate } from 'react-router-dom';
import RestaurantPH from '../../assets/img/restaurant-ph.jpg';
import Onboarding from './Onboarding';

// Redux
import { connect, useSelector } from 'react-redux';

// Recoil
import { useRecoilState, useSetRecoilState } from 'recoil';
import { activeRestaurantState } from '../../recoil';

import {
    useCompany,
    useMenusByRestaurant,
    useRestaurantDetailsById,
    useMenuItemsByRestaurant,
    useReportFeedbackHistory,
    useReportWaitersInfo,
    useTablesByRestaurant,
    useReportOrderHistory,
    useEventsByRestaurant,
    usePromotionsByRestaurant,
    useReportAnalytics,
} from '../../swr';
import { activeEnvironment } from '../../config';

import { TableIcon, OrderIcon, FeedbackIcon, WaiterIcon } from '../../helpers/customIcons';
import CheckSchedule from '../../helpers/CheckSchedule';
import moment from 'moment';
import { Line } from '@ant-design/charts';

const { RangePicker } = DatePicker;

function Admin(props) {
    // Get AuthUser ID from Firebase
    const firebaseAuthUser = useContext(AuthUserContext);
    const companyState = useSelector((state) => state.companyReducer);
    const activeRestaurantLocalStorage = JSON.parse(localStorage.getItem(activeEnvironment + '_activeRestaurant')) || {};
    const activeRestaurantRecoil = useRecoilState(activeRestaurantState);
    const activeRestaurant = activeRestaurantLocalStorage || activeRestaurantRecoil;
    const isSelfService = activeRestaurant.sysRestaurantType === 'SelfService';
    const [restaurantViews, setRestaurantViews] = useState([]);
    const [inactiveMenuItems, setInactiveMenuItems] = useState([]);
    const [chartConfig, setChartConfig] = useState(null);
    const INITIAL_DATE_RANGE = [moment().startOf('day').subtract(30, 'days').format('YYYY-MM-DD'), moment().subtract(1, 'days').format('YYYY-MM-DD')];
    const INITAL_DATE_RANGE_PICKER = [moment().startOf('day').subtract(30, 'days'), moment().subtract(1, 'days')];

    const [dateRange, setDateRange] = useState(INITIAL_DATE_RANGE);

    const { data: companyData, isLoading: companyIsLoading } = useCompany('', firebaseAuthUser.uid);
    const { data: restaurantDetails, isLoading: isLoadingRestaurantDetails } = useRestaurantDetailsById(activeRestaurant.uniqueIdentificationToken);
    const { data: menusData, isLoading: isLoadingMenusData } = useMenusByRestaurant(activeRestaurant.uniqueIdentificationToken);
    const { data: menuItemsData, isLoading: isLoadingMenuItemsData } = useMenuItemsByRestaurant(activeRestaurant.uniqueIdentificationToken);
    const { data: reportFeedbackHistory, isLoading: isLoadingReportFeedbackHistory } = useReportFeedbackHistory(
        activeRestaurant.uniqueIdentificationToken
    );
    const { data: reportWaitersInfo, isLoading: isLoadingReportWaitersInfo } = useReportWaitersInfo(activeRestaurant.uniqueIdentificationToken);
    const { data: restaurantTables, isLoading: isLoadingRestaurantTables } = useTablesByRestaurant(activeRestaurant.uniqueIdentificationToken);
    const { data: eventsList, isLoading: isLoadingEventsList } = useEventsByRestaurant(activeRestaurant.uniqueIdentificationToken, true);
    const { data: promotionsList, isLoading: isLoadingPromotionsList } = usePromotionsByRestaurant(activeRestaurant.uniqueIdentificationToken, true);
    const { data: orderHistory, isLoading: isLoadingOrderHistory } = useReportOrderHistory(activeRestaurant.uniqueIdentificationToken);
    const { data: orderHistoryMonth, isLoading: isLoadingOrderHistoryMonth } = useReportOrderHistory(
        activeRestaurant.uniqueIdentificationToken,
        ...dateRange
    );
    const { data: restaurantStats, isLoading: isLoadingRestaurantStats } = useReportAnalytics(
        activeRestaurant.uniqueIdentificationToken,
        ...dateRange
    );

    let occupiedRestaurantTables = [];
    const [menuItems, setMenuItems] = useState([]);

    const navigate = useNavigate();

    /*   useEffect(() => {
      if (isLoading !== true)
        // Get Restaurant by Id
        restaurantsListNew.map(d => {
          // Get Menus by RestaurantId
          ApiHelpers.getMenuByRestaurant(d.uniqueIdentificationToken)
            .then((response) => {
              if (response.data.length) {
                setMenusByRestaurant(menusByRestaurant.concat(response.data));
              }
            })
        })
    }, [isLoading]); */

    useEffect(() => {
        const newMenuItems = [];
        if (restaurantDetails && restaurantDetails.menu) {
            restaurantDetails.menu.map((d) => {
                if (d.category) {
                    d.category.map((c) => {
                        if (c.item)
                            c.item.map((i) => {
                                if (i.active === false) newMenuItems.push(i);
                            });
                    });
                }
            });
        }
        setMenuItems([...newMenuItems]);
    }, [restaurantDetails]);

    useEffect(() => {
        if (menuItemsData && menuItemsData.length > 0) setInactiveMenuItems(menuItemsData.filter((d) => d.active === false));
    }, [menuItemsData]);

    const getOccupiedRestaurantTables = (restaurantTables) => {
        if (!isLoadingRestaurantTables && restaurantTables) {
            occupiedRestaurantTables = restaurantTables.filter((d) => {
                return d.occupied === true;
            });
            return occupiedRestaurantTables;
        }
    };

    const getTotalAmountOrders = (orderHistory) => {
        let totalAmountOrders = 0;
        if (orderHistory) {
            orderHistory.map((d) => {
                totalAmountOrders += d.amount;
            });
        }
        return totalAmountOrders.toFixed(2);
    };

    const merge = (a, b, p) => a.filter((aa) => !b.find((bb) => aa[p] === bb[p])).concat(b);

    function aggregate(data, keyFields, accumulator) {
        var createNewObj = (ref, fields) => {
            return fields.reduce((result, key) => {
                return Object.assign(result, { [key]: ref[key] });
            }, {});
        };
        return Object.values(
            data.reduce((result, object, index, ref) => {
                let key = keyFields.map((key) => object[key]).join('');
                let val = result[key] || createNewObj(object, keyFields);
                val[accumulator] = (val[accumulator] || 0) + object[accumulator];
                return Object.assign(result, { [key]: val });
            }, {})
        );
    }

    const enumerateDaysBetweenDates = (startDate, endDate, name, value) => {
        const now = startDate,
            dates = [];

        while (now.isSameOrBefore(endDate)) {
            dates.push({ date: now.format('YYYY.MM.DD'), name: name, value: value });
            now.add(1, 'days');
        }
        return dates;
    };

    useEffect(() => {
        if (!isLoadingRestaurantStats && restaurantStats) {
            setRestaurantViews(restaurantStats.restaurantViews);

            const lastThirtyDays = enumerateDaysBetweenDates(moment(dateRange[0]), moment(dateRange[1]), 'Numar accesari', 0);
            const lastThirtyDays2 = enumerateDaysBetweenDates(moment(dateRange[0]), moment(dateRange[1]), 'Numar vizitatori', 0);
            const lastThirtyDays3 = enumerateDaysBetweenDates(moment(dateRange[0]), moment(dateRange[1]), 'Cheama ospatar', 0);
            const lastThirtyDays4 = enumerateDaysBetweenDates(moment(dateRange[0]), moment(dateRange[1]), 'Cere nota', 0);
            const orderHistoryAmount = restaurantStats.restaurantViews.map((d) => {
                return { date: moment(d.eventDate).format('YYYY.MM.DD'), value: d.viewCount, name: 'Numar accesari' };
            });

            const orderHistoryCounter = restaurantStats.restaurantViews.map((d) => {
                return { date: moment(d.eventDate).format('YYYY.MM.DD'), value: d.usersCount, name: 'Numar vizitatori' };
            });

            const waiterCallsCounter = restaurantStats.waiterCalls.map((d) => {
                return { date: moment(d.eventDate).format('YYYY.MM.DD'), value: d.countOnWeb, name: 'Cheama ospatar' };
            });
            const billsRequestedCounter = restaurantStats.billsRequested.map((d) => {
                return { date: moment(d.eventDate).format('YYYY.MM.DD'), value: d.countOnWeb, name: 'Cere nota' };
            });

            let result = [
                ...merge(lastThirtyDays, aggregate(orderHistoryAmount, ['date', 'name'], 'value'), 'date'),
                ...merge(lastThirtyDays2, aggregate(orderHistoryCounter, ['date', 'name'], 'value'), 'date'),
                ...merge(lastThirtyDays3, aggregate(waiterCallsCounter, ['date', 'name'], 'value'), 'date'),
                ...merge(lastThirtyDays4, aggregate(billsRequestedCounter, ['date', 'name'], 'value'), 'date'),
            ];
            result.sort(function (x, y) {
                let a = x.date.toUpperCase(),
                    b = y.date.toUpperCase();
                return a == b ? 0 : a > b ? 1 : -1;
            });
            setChartConfig({
                data: result,
                xField: 'date',
                yField: 'value',
                seriesField: 'name',
                //  label: {},
                padding: 'auto',

                point: {
                    size: 5,
                    shape: 'circle',
                    style: {
                        fill: 'white',
                        stroke: '#04c6d0',
                        lineWidth: 1,
                    },
                },
                xAxis: {
                    type: 'timeCat',

                    tick: 5,
                    title: {
                        text: '',
                    },
                    label: {
                        // autoRotate: false,
                        offset: 10,
                        style: {
                            fontSize: 14,
                        },
                        formatter: (name) => moment(name, 'YYYY-MM-DD').format('DD.MM.YYYY'),
                    },
                },
                yAxis: {
                    label: {
                        // autoRotate: false,
                        offset: 10,
                        style: {
                            fontSize: 14,
                        },
                        formatter: (name) => name,
                    },
                },
                tooltip: {
                    showMarkers: true,
                    fields: ['date'],
                    showTitle: false,
                    title: 'Test',
                    customContent: (title, data) => {
                        let tooltipTitle =
                            data.length > 0 ? moment(data[0].value).format('dddd') + ' ' + moment(data[0].value).format('DD.MM.YYYY') : 'test';
                        let tooltipContent = "<div class='chartTooltipContent'><h2>" + tooltipTitle + '</h2>';
                        data.map((d) => {
                            tooltipContent +=
                                d.data.name === 'Numar accesari'
                                    ? '<p>Numar accesari: ' + d.data.value + '</p>'
                                    : d.data.name === 'Numar vizitatori'
                                    ? '<p>Numar vizitatori: ' + d.data.value + '</p>'
                                    : d.data.name === 'Cheama ospatar'
                                    ? '<p>Cheamă ospătar: ' + d.data.value + '</p>'
                                    : d.data.name === 'Cere nota'
                                    ? '<p>Cere nota: ' + d.data.value + '</p>'
                                    : '';
                        });
                        tooltipContent += '</div>';
                        return tooltipContent;
                    },
                },
                smooth: true,
                state: {
                    active: {
                        style: {
                            shadowBlur: 4,
                            stroke: '#000',
                            fill: 'red',
                        },
                    },
                },
                interactions: [
                    {
                        type: 'marker-active',
                    },
                ],
            });
        }
    }, [restaurantStats]);

    const handleClickListElement = (uniqueIdentificationToken, type) => {
        type === 'menu'
            ? navigate(ROUTES.ADMIN_LOCATIONS + activeRestaurant.uniqueIdentificationToken + ROUTES.ADMIN_MENU_DISPLAY)
            : type === 'event'
            ? navigate(ROUTES.ADMIN_LOCATIONS + activeRestaurant.uniqueIdentificationToken + ROUTES.ADMIN_RESTAURANT_EVENTS)
            : type === 'stats'
            ? navigate(ROUTES.ADMIN_LOCATIONS + activeRestaurant.uniqueIdentificationToken + ROUTES.ADMIN_RESTAURANT_ANALYTICS)
            : navigate(ROUTES.ADMIN_LOCATIONS + activeRestaurant.uniqueIdentificationToken + ROUTES.ADMIN_RESTAURANT_OFFERS);
    };

    const onChangeRangePicker = (dates, dateStrings) => {
        if (dates && dateStrings) {
            setDateRange([moment(dateStrings[0], 'DD.MM.YYYY').format('YYYY-MM-DD'), moment(dateStrings[1], 'DD.MM.YYYY').format('YYYY-MM-DD')]);
        }
    };

    return (
        <AuthUserContext.Consumer>
            {(authUser) => (
                <AdminLayout authUser={authUser} className="bgColor" breadcrumbVisible={false}>
                    {companyIsLoading ? (
                        <Spin />
                    ) : companyData && !companyData.message ? (
                        Object.keys(activeRestaurant).length !== 0 && isLoadingRestaurantDetails ? (
                            <Spin />
                        ) : !activeRestaurant.uniqueIdentificationToken ? (
                            <Onboarding step={2} />
                        ) : isLoadingRestaurantDetails ? (
                            <Spin />
                        ) : restaurantDetails ? (
                            <div>
                                <Row className="rowGutterCard" gutter={[16, 16]}>
                                    <Col
                                        xs={{ span: 24, offset: 0 }}
                                        md={{ span: 12, offset: 0 }}
                                        lg={{ span: 12, offset: 0 }}
                                        xl={{ span: 6, offset: 0 }}
                                        xxl={{ span: 6, offset: 0 }}>
                                        {isLoadingRestaurantTables ? (
                                            <Skeleton />
                                        ) : (
                                            <Card
                                                hoverable
                                                onClick={() =>
                                                    navigate(
                                                        ROUTES.ADMIN_LOCATIONS +
                                                            activeRestaurant.uniqueIdentificationToken +
                                                            ROUTES.ADMIN_RESTAURANT_TABLES +
                                                            '#active'
                                                    )
                                                }>
                                                <h4 className="widgetTitle">{!isSelfService ? 'Mese' : 'Comenzi'}</h4>
                                                <Row justify="space-between">
                                                    <Col>
                                                        <span className="widgetNumber">{getOccupiedRestaurantTables(restaurantTables).length}</span>
                                                        {!isSelfService ? 'ocupate' : 'in lucru'}
                                                    </Col>
                                                    <Col>
                                                        <TableIcon />
                                                    </Col>
                                                </Row>
                                            </Card>
                                        )}
                                    </Col>
                                    <Col
                                        xs={{ span: 24, offset: 0 }}
                                        md={{ span: 12, offset: 0 }}
                                        lg={{ span: 12, offset: 0 }}
                                        xl={{ span: 6, offset: 0 }}
                                        xxl={{ span: 6, offset: 0 }}>
                                        {isLoadingRestaurantStats ? (
                                            <Skeleton />
                                        ) : (
                                            <Card
                                                hoverable
                                                onClick={() =>
                                                    navigate(
                                                        ROUTES.ADMIN_LOCATIONS +
                                                            activeRestaurant.uniqueIdentificationToken +
                                                            ROUTES.ADMIN_RESTAURANT_ORDERS
                                                    )
                                                }>
                                                <h4 className="widgetTitle">Note de plata</h4>
                                                <Row justify="space-between">
                                                    <Col>
                                                        <span className="widgetNumber">{getTotalAmountOrders(orderHistory)}</span>lei
                                                    </Col>
                                                    <Col>
                                                        <OrderIcon />
                                                    </Col>
                                                </Row>
                                            </Card>
                                        )}
                                    </Col>

                                    <Col
                                        xs={{ span: 24, offset: 0 }}
                                        md={{ span: 12, offset: 0 }}
                                        lg={{ span: 12, offset: 0 }}
                                        xl={{ span: 6, offset: 0 }}
                                        xxl={{ span: 6, offset: 0 }}>
                                        {isLoadingReportFeedbackHistory ? (
                                            <Skeleton />
                                        ) : (
                                            <Card
                                                hoverable
                                                onClick={() =>
                                                    navigate(
                                                        ROUTES.ADMIN_LOCATIONS +
                                                            activeRestaurant.uniqueIdentificationToken +
                                                            ROUTES.ADMIN_RESTAURANT_FEEDBACK
                                                    )
                                                }>
                                                <h4 className="widgetTitle">Feedback</h4>
                                                <Row justify="space-between">
                                                    <Col>
                                                        <span className="widgetNumber">{reportFeedbackHistory.length}</span>
                                                        astazi
                                                    </Col>
                                                    <Col>
                                                        <FeedbackIcon />
                                                    </Col>
                                                </Row>
                                            </Card>
                                        )}
                                    </Col>
                                    <Col
                                        xs={{ span: 24, offset: 0 }}
                                        md={{ span: 12, offset: 0 }}
                                        lg={{ span: 12, offset: 0 }}
                                        xl={{ span: 6, offset: 0 }}
                                        xxl={{ span: 6, offset: 0 }}>
                                        {isLoadingReportWaitersInfo ? (
                                            <Skeleton />
                                        ) : (
                                            <Card
                                                hoverable
                                                onClick={() =>
                                                    navigate(
                                                        ROUTES.ADMIN_LOCATIONS +
                                                            activeRestaurant.uniqueIdentificationToken +
                                                            ROUTES.ADMIN_RESTAURANT_WAITERS
                                                    )
                                                }>
                                                <h4 className="widgetTitle">Staff</h4>
                                                <Row justify="space-between">
                                                    <Col>
                                                        <span className="widgetNumber">
                                                            {reportWaitersInfo.activeShifts.length > 0 ? reportWaitersInfo.activeShifts.length : 0}
                                                        </span>
                                                        activi
                                                    </Col>
                                                    <Col>
                                                        <WaiterIcon />
                                                    </Col>
                                                </Row>
                                            </Card>
                                        )}
                                    </Col>
                                </Row>
                                <Row className="rowGutterCard" gutter={[16, 16]}>
                                    <Col
                                        xs={{ span: 24, offset: 0 }}
                                        md={{ span: 24, offset: 0 }}
                                        lg={{ span: 24, offset: 0 }}
                                        xl={{ span: 16, offset: 0 }}
                                        xxl={{ span: 16, offset: 0 }}>
                                        {activeRestaurant.subscriptionPlan !== 'Free' ? (
                                            <Row className="rowGutterCard" gutter={[16, 16]}>
                                                <Col span={24}>
                                                    <Card
                                                        title="Statistici"
                                                        extra={
                                                            <Space gutter={[0, 20]}>
                                                                <RangePicker
                                                                    defaultValue={[...INITAL_DATE_RANGE_PICKER]}
                                                                    format="DD.MM.YYYY"
                                                                    ranges={{
                                                                        Ieri: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                                                        'Saptamana curenta': [moment().startOf('week'), moment().endOf('week')],
                                                                        'Saptamana trecuta': [
                                                                            moment().subtract(1, 'weeks').startOf('week'),
                                                                            moment().subtract(1, 'weeks').endOf('week'),
                                                                        ],
                                                                        'Luna curenta': [moment().startOf('month'), moment().endOf('month')],
                                                                        'Luna trecuta': [
                                                                            moment().subtract(1, 'month').startOf('month'),
                                                                            moment().subtract(1, 'month').endOf('month'),
                                                                        ],
                                                                    }}
                                                                    onChange={onChangeRangePicker}
                                                                />
                                                                <a onClick={() => handleClickListElement('', 'stats')}>vezi tot</a>
                                                            </Space>
                                                        }>
                                                        {isLoadingRestaurantStats || !chartConfig ? <Skeleton /> : <Line {...chartConfig} />}
                                                    </Card>
                                                </Col>
                                            </Row>
                                        ) : null}
                                        <Row className="rowGutterCard" gutter={[16, 16]}>
                                            <Col
                                                xs={{ span: 24, offset: 0 }}
                                                md={{ span: 12, offset: 0 }}
                                                lg={{ span: 12, offset: 0 }}
                                                xl={{ span: 12, offset: 0 }}
                                                xxl={{ span: 12, offset: 0 }}>
                                                <Card>
                                                    <Row>
                                                        <Col span={18}>
                                                            <h4 className="widgetTitle">Evenimente active</h4>
                                                        </Col>
                                                        <Col span={6} align="right">
                                                            <a onClick={() => handleClickListElement('', 'event')}>vezi tot</a>
                                                        </Col>
                                                    </Row>
                                                    {isLoadingEventsList ? (
                                                        <Skeleton />
                                                    ) : eventsList.length ? (
                                                        eventsList.map((d) => {
                                                            return (
                                                                <Row
                                                                    className="rowGutterCard rowClickable"
                                                                    gutter={[16, 16]}
                                                                    key={d.uniqueIdentificationToken}
                                                                    onClick={() => handleClickListElement(d.uniqueIdentificationToken, 'event')}>
                                                                    <Col>
                                                                        <img
                                                                            className="eventImageSmall"
                                                                            src={ApiHelpers.getImageLink(d.eventImage)}
                                                                            title={d.title}
                                                                            alt={d.title}
                                                                        />
                                                                    </Col>
                                                                    <Col>
                                                                        <h3>{d.title}</h3>
                                                                        {moment(d.dateStart).format('DD.MM.YYYY')} -{' '}
                                                                        {moment(d.dateEnd).format('DD.MM.YYYY')}
                                                                    </Col>
                                                                </Row>
                                                            );
                                                        })
                                                    ) : (
                                                        <Empty
                                                            imageStyle={{
                                                                height: 60,
                                                            }}
                                                            description={<span>Niciun eveniment activ</span>}>
                                                            <Button onClick={() => handleClickListElement('', 'event')}>Adauga</Button>
                                                        </Empty>
                                                    )}
                                                </Card>
                                            </Col>
                                            <Col
                                                xs={{ span: 24, offset: 0 }}
                                                md={{ span: 12, offset: 0 }}
                                                lg={{ span: 12, offset: 0 }}
                                                xl={{ span: 12, offset: 0 }}
                                                xxl={{ span: 12, offset: 0 }}>
                                                <Card>
                                                    <Row>
                                                        <Col span={18}>
                                                            <h4 className="widgetTitle">Oferte active</h4>
                                                        </Col>
                                                        <Col span={6} align="right">
                                                            <a onClick={() => handleClickListElement('', 'promotion')}>vezi tot</a>
                                                        </Col>
                                                    </Row>
                                                    {isLoadingPromotionsList ? (
                                                        <Skeleton />
                                                    ) : promotionsList.length ? (
                                                        promotionsList.map((d) => {
                                                            return (
                                                                <Row
                                                                    className="rowGutterCard rowClickable"
                                                                    gutter={[16, 16]}
                                                                    key={d.uniqueIdentificationToken}
                                                                    onClick={() => handleClickListElement(d.uniqueIdentificationToken, 'promotion')}>
                                                                    <Col>
                                                                        {d.promoImage ? (
                                                                            <img
                                                                                className="eventImageSmall"
                                                                                src={ApiHelpers.getImageLink(d.promoImage)}
                                                                                title={d.title}
                                                                                alt={d.title}
                                                                            />
                                                                        ) : null}
                                                                    </Col>
                                                                    <Col>
                                                                        <h3>{d.title}</h3>
                                                                        {moment(d.dateStart).format('DD.MM.YYYY')} -{' '}
                                                                        {moment(d.dateEnd).format('DD.MM.YYYY')}
                                                                    </Col>
                                                                </Row>
                                                            );
                                                        })
                                                    ) : (
                                                        <Empty
                                                            imageStyle={{
                                                                height: 60,
                                                            }}
                                                            description={<span>Nicio oferta activa</span>}>
                                                            <Button onClick={() => handleClickListElement('', 'promotion')}>Adauga</Button>
                                                        </Empty>
                                                    )}
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col
                                        xs={{ span: 24, offset: 0 }}
                                        md={{ span: 24, offset: 0 }}
                                        lg={{ span: 24, offset: 0 }}
                                        xl={{ span: 8, offset: 0 }}
                                        xxl={{ span: 8, offset: 0 }}>
                                        <Row className="rowGutterCard" gutter={[16, 16]}>
                                            <Col
                                                xs={{ span: 24, offset: 0 }}
                                                md={{ span: 12, offset: 0 }}
                                                lg={{ span: 12, offset: 0 }}
                                                xl={{ span: 24, offset: 0 }}
                                                xxl={{ span: 24, offset: 0 }}>
                                                {restaurantDetails ? (
                                                    <Card
                                                        title={activeRestaurant.title}
                                                        extra={
                                                            <div>
                                                                <a href={ROUTES.ADMIN_LOCATIONS + activeRestaurant.uniqueIdentificationToken}>
                                                                    Editează
                                                                </a>
                                                            </div>
                                                        }>
                                                        <Row>
                                                            <Col
                                                                xs={{ span: 24, offset: 0 }}
                                                                md={{ span: 12, offset: 0 }}
                                                                lg={{ span: 12, offset: 0 }}
                                                                xl={{ span: 24, offset: 0 }}
                                                                xxl={{ span: 24, offset: 0 }}>
                                                                <img
                                                                    alt="example"
                                                                    className="widgetRestaurantCoverimage"
                                                                    src={
                                                                        restaurantDetails.coverImagePath
                                                                            ? ApiHelpers.getImageLink(restaurantDetails.coverImagePath)
                                                                            : RestaurantPH
                                                                    }
                                                                />
                                                            </Col>
                                                            <Col>
                                                                {restaurantDetails.active == true ? (
                                                                    <Tag color="#87d068" className="status statusActive">
                                                                        Activ
                                                                    </Tag>
                                                                ) : (
                                                                    <Tag color="#f50" className="status statusActive">
                                                                        Inactiv
                                                                    </Tag>
                                                                )}
                                                                <CheckSchedule
                                                                    schedule={restaurantDetails.schedule}
                                                                    labelActive="Deschis"
                                                                    labelInactive="Inchis"
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                ) : (
                                                    <Spin />
                                                )}
                                            </Col>

                                            <Col
                                                xs={{ span: 24, offset: 0 }}
                                                md={{ span: 12, offset: 0 }}
                                                lg={{ span: 12, offset: 0 }}
                                                xl={{ span: 24, offset: 0 }}
                                                xxl={{ span: 24, offset: 0 }}>
                                                <Card>
                                                    <Row>
                                                        <Col span={18}>
                                                            <h4 className="widgetTitle">Meniuri</h4>
                                                        </Col>
                                                        <Col span={6} align="right">
                                                            <a onClick={() => handleClickListElement('', 'menu')}>vezi tot</a>
                                                        </Col>
                                                    </Row>
                                                    <div className="widgetContent">
                                                        {menusData && menusData.length > 0 ? (
                                                            menusData.map((d) => {
                                                                return (
                                                                    <Row className="cardRow" gutter={[10, 10]} key={d.uniqueIdentificationToken}>
                                                                        <Col span={12}>
                                                                            <Link
                                                                                to={
                                                                                    ROUTES.ADMIN_LOCATIONS +
                                                                                    activeRestaurant.uniqueIdentificationToken +
                                                                                    '/menus/' +
                                                                                    d.uniqueIdentificationToken
                                                                                }>
                                                                                {d.menuName}
                                                                            </Link>
                                                                        </Col>
                                                                        <Col span={12}>
                                                                            <CheckSchedule schedule={d} labelActive="Activ" labelInactive="Inactiv" />
                                                                        </Col>
                                                                    </Row>
                                                                );
                                                            })
                                                        ) : (
                                                            <Empty
                                                                imageStyle={{
                                                                    height: 60,
                                                                }}
                                                                description={<span>Niciun meniu creat</span>}>
                                                                <Button onClick={() => handleClickListElement('', 'menu')}>Adauga</Button>
                                                            </Empty>
                                                        )}
                                                    </div>
                                                </Card>
                                            </Col>
                                            {inactiveMenuItems && inactiveMenuItems.length > 0 ? (
                                                <Col
                                                    xs={{ span: 24, offset: 0 }}
                                                    md={{ span: 12, offset: 0 }}
                                                    lg={{ span: 12, offset: 0 }}
                                                    xl={{ span: 24, offset: 0 }}
                                                    xxl={{ span: 24, offset: 0 }}>
                                                    <Card className="cardWarning">
                                                        <h4 className="widgetTitle">Preparate inactive</h4>
                                                        <div>
                                                            {inactiveMenuItems.map((d) => {
                                                                return (
                                                                    <Row className="cardRow" gutter={[10, 10]} key={d.uniqueIdentificationToken}>
                                                                        <Col span={12}>
                                                                            <Link
                                                                                to={
                                                                                    ROUTES.ADMIN_LOCATIONS +
                                                                                    activeRestaurant.uniqueIdentificationToken +
                                                                                    '/menus/' +
                                                                                    d.menuIdentificationToken +
                                                                                    '#items/menuitem/' +
                                                                                    d.uniqueIdentificationToken
                                                                                }>
                                                                                {d.title}
                                                                            </Link>
                                                                        </Col>
                                                                        <Col>{d.category}</Col>
                                                                    </Row>
                                                                );
                                                            })}
                                                        </div>
                                                    </Card>
                                                </Col>
                                            ) : null}
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        ) : (
                            <div>
                                <Onboarding step={2} />
                            </div>
                        )
                    ) : (
                        <Onboarding />
                    )}
                </AdminLayout>
            )}
        </AuthUserContext.Consumer>
    );
}

const mapStateToProps = (state) => {
    return { companyReducer: state.companyReducer };
};

const condition = (authUser) => !!authUser;
export default connect(mapStateToProps)(withAuthorization(condition)(Admin));

export { Locations, Menus, AdminMenu, LocationsDetails };
