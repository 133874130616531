export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const SIGN_OUT = '/signout';
export const HOME = '/home';
export const ACCOUNT_SETTINGS = '/account';
export const ACCOUNT_COMPANY = '/company';
export const ACCOUNT_PW_CHANGE = '/account/change-password';
export const ADMIN = '/admin';
export const PASSWORD_FORGET = '/forgot-password';
export const ADMIN_LOCATIONS = '/admin/locations/';
export const ADMIN_LOCATIONS_NEW = '/admin/locations/new';
export const ADMIN_MENUS = '/admin/menus';
export const ADMIN_MENU_DISPLAY = '/menus/';
export const ADMIN_TEST = '/admin/test/';
export const ADMIN_MENUITEMGROUP = '/menuitemgroups/';
export const ADMIN_RESTAURANT_TABLES = '/tables/';
export const ADMIN_RESTAURANT_FEEDBACK = '/feedback/';
export const ADMIN_RESTAURANT_WAITERS = '/waiters/';
export const ADMIN_RESTAURANT_ORDERS = '/orders/';
export const ADMIN_RESTAURANT_EVENTS = '/events/';
export const ADMIN_RESTAURANT_OFFERS = '/offers/';
export const ADMIN_RESTAURANT_ANALYTICS = '/stats/';

export const PUBLIC_RESTAURANT = '/restaurant/';
export const ADMIN_MENU_ITEM = '/menuitem/';
