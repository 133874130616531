import appIconImg from '../assets/img/poftigo-appicon.png';

const ENV = {
    //dev: "https://devpoftigo.azurewebsites.net",
    dev: 'https://poftigodevenv.azurewebsites.net',
    test: 'https://testpoftigo.azurewebsites.net',
    live: 'https://api.poftigo.app',
};

const webGuide = {
    dev: 'https://ghid-dev.poftigo.ro/loc/',
    test: 'https://ghid-test.poftigo.ro/loc/',
    live: 'https://ghid.poftigo.ro/loc/',
};

const webGuideEmbed = {
    dev: 'https://ghid-dev.poftigo.ro/embed/',
    test: 'https://ghid-test.poftigo.ro/embed/',
    live: 'https://ghid.poftigo.ro/embed/',
};

const webGuideLight = {
    dev: 'https://ghid-dev.poftigo.ro/light/',
    test: 'https://ghid-test.poftigo.ro/light/',
    live: 'https://ghid.poftigo.ro/light/',
};

const appIcon = appIconImg;
const appIconStaffFull = 'https://www.poftigo.ro/wp-content/uploads/2021/07/poftigo-staff-qr-logo.png';
const appIconStaff = 'https://www.poftigo.ro/wp-content/uploads/2021/07/poftigo-staff-appicon2-e1626678048259.png';

export { ENV, appIcon, appIconStaff, appIconStaffFull, webGuide, webGuideEmbed, webGuideLight };
