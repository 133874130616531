import React, { useEffect, useState } from "react";
import { Tag } from 'antd';
import moment from "moment";

const CheckSchedule = (props) => {

    const [availableToday, setAvailableToday] = useState(false);
    const { schedule, labelActive, labelInactive } = props;
    const todayWeekDay = moment().locale("en").format("dddd");
    const todayHourMinute = moment().format("HH:mm");

    const isTimeBetween = function (startTime, endTime, serverTime) {
        let start = moment(startTime, "H:mm")
        let end = moment(endTime, "H:mm")
        let server = moment(serverTime, "H:mm")
        if (end < start) {
            return server >= start && server <= moment('23:59:59', "HH:mm:ss") || server >= moment('0:00:00', "HH:mm:ss") && server < end;
        }
        return server >= start && server < end
    }

    useEffect(() => {
        if (schedule){
            schedule['availableOn' + todayWeekDay] === true ?
                isTimeBetween(schedule[todayWeekDay.toLowerCase() + "Start"], schedule[todayWeekDay.toLowerCase() + "End"], todayHourMinute)
                    ? setAvailableToday(true) : setAvailableToday(false)
                : setAvailableToday(false);
            }
    }, [schedule]);

    if (schedule)
        return (
            <>
                {availableToday ? <Tag color="green">{labelActive} azi pana la {schedule[todayWeekDay.toLowerCase() + "End"]}</Tag> : <Tag color="volcano">{labelInactive} acum</Tag>}
            </>
        )
    else
        return ""
}

export default CheckSchedule;