import useSWR from 'swr';
import axios from 'axios';
import { bacchusAPIEndpoint } from '../config';
import moment from 'moment';

const fetcher = (url) => axios.get(url).then((res) => res.data);

export function useReportWaitersInfo(restaurantToken) {
    const { data, error } = useSWR(
        restaurantToken ? bacchusAPIEndpoint + '/api/reports/getWaitersInfoByRestaurant/' + restaurantToken : null,
        fetcher
    );
    return {
        data,
        isLoading: !error && !data,
        isError: error,
    };
}

export function useReportFeedbackHistory(restaurantToken, startDate = moment().format('YYYY-MM-DD'), endDate = moment().format('YYYY-MM-DD')) {
    const { data, error } = useSWR(
        restaurantToken
            ? bacchusAPIEndpoint + '/api/reports/getFeedbackHistoryByRestaurant/' + restaurantToken + '/' + startDate + '/' + endDate
            : null,
        fetcher
    );
    return {
        data,
        isLoading: !error && !data,
        isError: error,
    };
}

export function useReportOrderHistory(restaurantToken, startDate = moment().format('YYYY-MM-DD'), endDate = moment().format('YYYY-MM-DD')) {
    const { data, error } = useSWR(
        restaurantToken ? bacchusAPIEndpoint + '/api/reports/getOrderHistoryByRestaurant/' + restaurantToken + '/' + startDate + '/' + endDate : null,
        fetcher
    );
    return {
        data,
        isLoading: !error && !data,
        isError: error,
    };
}

export function useReportAnalytics(restaurantToken, startDate = moment().format('YYYY-MM-DD'), endDate = moment().format('YYYY-MM-DD')) {
    const { data, error } = useSWR(
        restaurantToken
            ? bacchusAPIEndpoint + '/api/reports/getAnalyticsData/' + restaurantToken + '/' + startDate + '/' + endDate + '/all?pageSize=1000&pageNo=1'
            : null,
        fetcher
    );
    return {
        data,
        isLoading: !error && !data,
        isError: error,
    };
}
