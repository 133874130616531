import React, { useState, useEffect, useContext } from 'react';
import { AuthUserContext, withAuthorization } from '../Session';
import AdminLayout from './AdminLayout';
import { Tabs, Skeleton } from 'antd';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { ApiHelpers } from '../../helpers';
import MenuItemGroupForm from './Menu/MenuItemGroupForm';
import MenuListForm from './Menu/MenuListForm';
import Onboarding from './Onboarding';
import MenuPrint from './Menu/MenuPrint';

import * as ROUTES from '../../const/routes';
import { useRestaurantById, useCompany } from '../../swr';
import RestaurantPrintMenu from './Restaurant/RestaurantPrintMenu';
import RestaurantExportMenu from './Restaurant/RestaurantExportMenu';

const { TabPane } = Tabs;

function MenuHome() {
    // Get AuthUser ID from Firebase
    const firebaseAuthUser = useContext(AuthUserContext);
    let { menuId, restaurantId } = useParams();

    const [menuData, setMenuData] = useState([]);
    const [activeTabKey, setActiveTabKey] = useState();
    const [entryFound, setEntryFound] = useState(false);
    const [menusLoaded, setMenusLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [menusList, setMenusList] = useState([]);

    const { data: restaurantDetailsSWR } = useRestaurantById(restaurantId);
    let restaurantTitle;

    if (restaurantDetailsSWR) {
        restaurantTitle = restaurantDetailsSWR.title;
    }
    const { data: companyDetails, isLoading: isLoadingCompanyDetails } = useCompany('getDetails', firebaseAuthUser.uid);
    let companyIdentificationToken = null;

    if (companyDetails) {
        companyIdentificationToken = companyDetails.uniqueIdentificationToken;
    }

    const navigate = useNavigate();
    const routeLocation = useLocation();

    const callbackTabs = (key) => {
        setActiveTabKey(key);
        navigate(key);
    };

    useEffect(() => {
        // Get Restaurant by Id
        ApiHelpers.getRestaurantsByCode(restaurantId).then(
            (response) => {
                if (response.data.message) {
                    setEntryFound(false);
                    setIsLoading(false);
                } else {
                    setEntryFound(true);

                    // Get Menus by RestaurantId
                    ApiHelpers.getMenuByRestaurant(restaurantId).then((response) => {
                        setMenusList(response.data);
                        setMenusLoaded(true);
                        setIsLoading(false);
                    });
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }, [restaurantId]);

    useEffect(() => {
        if (routeLocation.hash.indexOf('#menuItemGroups') > -1) {
            setActiveTabKey('#menuItemGroups');
        }
    }, [routeLocation]);

    const breadcrumb = [{ label: restaurantTitle, link: ROUTES.ADMIN_LOCATIONS + restaurantId }];

    return (
        <AuthUserContext.Consumer>
            {(authUser) => (
                <AdminLayout breadcrumb={breadcrumb} authUser={authUser} pageTitle={menuData.menuName}>
                    <h2>Meniuri</h2>
                    {isLoadingCompanyDetails ? (
                        <Skeleton active></Skeleton>
                    ) : companyDetails.message ? (
                        <Onboarding />
                    ) : isLoading ? (
                        <Skeleton active></Skeleton>
                    ) : entryFound === true && menusLoaded === true ? (
                        <div>
                            <Tabs activeKey={activeTabKey} defaultActiveKey={routeLocation.hash} onChange={callbackTabs}>
                                <TabPane tab="Meniuri" key="#list">
                                    <MenuListForm
                                        restaurantId={restaurantId}
                                        menusList={menusList}
                                        setMenusList={setMenusList}
                                        restaurantDetails={restaurantDetailsSWR}
                                        view="standard"
                                    />
                                </TabPane>
                                <TabPane tab="Grupuri auxiliare" key="#menuItemGroups">
                                    <MenuItemGroupForm restaurantId={restaurantId} />
                                </TabPane>

                                <TabPane tab="Tipărire" key="#print">
                                    <RestaurantPrintMenu restaurantTitle={restaurantTitle} />
                                </TabPane>
                                <TabPane tab="Export" key="#export">
                                    <RestaurantExportMenu restaurantId={restaurantId} restaurantTitle={restaurantTitle} />
                                </TabPane>
                            </Tabs>
                        </div>
                    ) : entryFound === false ? (
                        <Onboarding step={2} />
                    ) : (
                        <Skeleton active />
                    )}
                </AdminLayout>
            )}
        </AuthUserContext.Consumer>
    );
}

const condition = (authUser) => !!authUser;
export default withAuthorization(condition)(MenuHome);
