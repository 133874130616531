import React, { useRef, useState } from 'react';
import { Button, message, Alert } from 'antd';
import { DeliveredProcedureOutlined } from '@ant-design/icons';
import { ApiHelpers } from '../../../helpers';

const RestaurantExportMenu = (props) => {
    const { restaurantTitle, restaurantId } = props;
    const pdfContent = useRef(null);
    const [isDisabled, setIsDisabeled] = useState(false);

    const exportToGlovo = () => {
        ApiHelpers.exportMenuGlovo(restaurantId).then((response) => {
            message.success('Exportul a fost realizat!');
            setIsDisabeled(true);
        }, (error) => {
            console.log(error);
            message.error('Eroare!');
        });;


    }

    return (
        <>
            <h2>Export pentru Glovo</h2>
            <p>Exportul va genera si trimite automat catre Glovo un fisier Excel cu valorile nutritionale pentru a fi importate in sistemul lor.<br />
                Fisierul contine toate preparatele care au fost marcate ca active pentru Glovo.<br />
                Fisierul contine doar <strong>valorile nutritionale</strong>, NU contine pretul produselor.  </p>
            <Alert
                title=""
                type="warning"
                showIcon
                message="ATENTIE! Importul in sistemul Glovo poate dura pana la 24 ore lucratoare. Dupa finalizarea procesului, veti fi notificat pe email de rezultatul importului."
            />
            <p></p>
            <Button disabled={isDisabled} type="primary" size="large" icon={<DeliveredProcedureOutlined />} onClick={() => exportToGlovo()}>
                Exportă pentru Glovo
            </Button>
        </>
    );
};

export default RestaurantExportMenu;
