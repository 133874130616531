import React from 'react';
import Logo from '../../assets/img/poftigo-admin-logo-white-tr.png';
import * as ROUTES from '../../const/routes'

export default function Header(){
    return(
        <div className="app-logo">
            <a href={ROUTES.LANDING}>
                <img src={Logo} alt="" />
            </a>
        </div>
    )
}