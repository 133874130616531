import React from 'react';
import { Button, Upload, Dropdown } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

function UploadWithOptions(props) {
    return (
        <>
            {props.img ?
                <Dropdown overlay={props.menuImg}>
                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                        <img src={props.getImageLink} alt="" className="uploadedImage" />
                    </a>
                </Dropdown> :
                <Upload {...props.uploadProps}>
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
            }
        </>

    )
}

export default UploadWithOptions;