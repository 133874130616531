import React, { useState, useEffect, useContext } from 'react';
import { AuthUserContext, withAuthorization } from '../Session';
import AdminLayout from './AdminLayout';
import { ApiHelpers } from '../../helpers';
import { Form, Tabs, Spin, Skeleton } from 'antd';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Onboarding from './Onboarding';

import { useCompany } from '../../swr';
import RestaurantTables from './Restaurant/RestaurantTables';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { restaurantDetailsState, menusListState, menuCategoriesState, activeRestaurantState } from '../../recoil';
import RestaurantTablesOccupied from './Restaurant/RestaurantTablesOccupied';
import RestaurantHotspots from './Restaurant/RestaurantHotspots';
import RestaurantQRCode from './Restaurant/RestaurantQRCode';

const { TabPane } = Tabs;

function RestaurantTableHome(props) {
    // Get AuthUser ID from Firebase
    const firebaseAuthUser = useContext(AuthUserContext);
    let { restaurantId } = useParams();
    const [formRestaurant] = Form.useForm();
    const [restaurantDetails, setRestaurantDetails] = useState([]);
    const [entryFound, setEntryFound] = useState(false);
    const [activeTabKey, setActiveTabKey] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const [activeRestaurant, setActiveRestaurant] = useRecoilState(activeRestaurantState);

    const isSelfService = activeRestaurant.sysRestaurantType === 'SelfService';

    const navigate = useNavigate();
    const { data: companyDetails, isLoading: isLoadingCompanyDetails } = useCompany('getDetails', firebaseAuthUser.uid);
    let companyIdentificationToken = null;

    if (companyDetails) {
        companyIdentificationToken = companyDetails.uniqueIdentificationToken;
    }

    const routeLocation = useLocation();

    const callbackTabs = (key) => {
        setActiveTabKey(key);
        navigate(key);
    };

    useEffect(() => {
        // Get Restaurant by Id
        setIsLoading(true);

        ApiHelpers.getRestaurantsByCode(restaurantId).then(
            (response) => {
                if (response.data.message) {
                    setEntryFound(false);
                } else {
                    setRestaurantDetails({
                        ...response.data,
                        companyIdentificationToken: companyIdentificationToken,
                    });
                    setEntryFound(true);
                }
                setIsLoading(false);
            },
            (error) => {
                console.log(error);
            }
        );
    }, [restaurantId]);

    useEffect(() => {
        if (routeLocation.hash) {
            setActiveTabKey(routeLocation.hash);
        } else setActiveTabKey('#list');
    });

    return (
        <AuthUserContext.Consumer>
            {(authUser) => (
                <AdminLayout authUser={authUser} pageTitle={restaurantDetails.title}>
                    <h2>{!isSelfService ? 'Mese / Cod QR' : 'Cod QR'}</h2>
                    {isLoadingCompanyDetails ? (
                        <Skeleton active></Skeleton>
                    ) : companyDetails.message ? (
                        <Onboarding />
                    ) : isLoading ? (
                        <Skeleton active></Skeleton>
                    ) : entryFound === true ? (
                        <Tabs activeKey={activeTabKey} defaultActiveKey={routeLocation.hash} onChange={callbackTabs}>
                            <TabPane tab={!isSelfService ? 'Ocupate' : 'In lucru'} key="#active">
                                <RestaurantTablesOccupied restaurantId={restaurantId} />
                            </TabPane>
                            <TabPane tab={!isSelfService ? 'Toate' : 'Cod QR'} key="#list">
                                <RestaurantTables restaurantId={restaurantId} />
                            </TabPane>
                            <TabPane tab="Personalizare cod QR" key="#qrcode">
                                <RestaurantQRCode restaurantId={restaurantId} />
                            </TabPane>
                            <TabPane tab="Retele Wi-Fi" key="#wifi">
                                <RestaurantHotspots restaurantId={restaurantId} />
                            </TabPane>
                        </Tabs>
                    ) : entryFound === false ? (
                        <Onboarding step={2} />
                    ) : (
                        <Skeleton active />
                    )}
                </AdminLayout>
            )}
        </AuthUserContext.Consumer>
    );
}
const condition = (authUser) => !!authUser;
export default withAuthorization(condition)(RestaurantTableHome);
