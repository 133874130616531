import React, { useEffect, useState } from 'react';
import {
    Form,
    Select,
    Row,
    Col,
    Popconfirm,
    Table,
    Input,
    InputNumber,
    Button,
    Divider,
    Space,
    Tag,
    message,
    Tabs,
    Collapse,
    Popover,
    Tooltip,
} from 'antd';
import { PlusOutlined, DeleteOutlined, CheckOutlined, SearchOutlined, UserOutlined, InfoCircleOutlined } from '@ant-design/icons';
import {
    ApiHelpers,
    nutritionalCalculationFrontend,
    nutritionalCalculation,
    nutritionalCalculationSumIngredients,
    formatFoodAdditives,
    formatNutritionalValues,
    getAllergensInComponents,
    getAdditivesInComponents,
} from '../../../helpers';

const { Option } = Select;
const { TabPane } = Tabs;
const { Panel } = Collapse;
const MenuItemIngredient = (props) => {
    const {
        restaurantId,
        index,
        form,
        listOfIngredients,
        setListOfIngredients,
        sumNutritionalData100g,
        setSumNutritionalData100g,
        menuItem,
        setMenuItem,
        actionType,
    } = props;
    const [data, setData] = useState([]);
    const [nutritionalData, setNutritionalData] = useState([...listOfIngredients]);
    const [selectedElement, setSelectedElement] = useState();
    const [sumNutritionalData, setSumNutritionalData] = useState([]);
    const [editingKey, setEditingKey] = useState([]);
    const [activeTabKeyTable, setActiveTabKeyTable] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const isEditing = (index) => nutritionalData[index].customIngredient === true || nutritionalData[index].review === true
    const isInReview = (index) => nutritionalData[index].review === true;
    const isExternal = (index) => nutritionalData[index].externalSource === true;
    const isModified = (index) => nutritionalData[index].modifiedIngredient === true;
    const [searchKeyword, setSearchKeyword] = useState('');
    const INITIAL_STATE_TAXONOMY = {
        ingredients: [],
        englishIngredients: [],
        allergens: [],
        englishAllergens: [],
        foodAdditives: [],
    };
    const tooltipIngredientOption = {
        title: '',
        text: '',
    };
    const [taxonomyList, setTaxonomyList] = useState({ ...INITIAL_STATE_TAXONOMY });
    const [messageApi, contextHolder] = message.useMessage();
    const edit = (record) => {
        form.setFieldsValue({
            name: '',
            age: '',
            address: '',
            ...record,
        });
        setEditingKey(record.key);
    };

    const callbackTabsTable = (key) => {
        setActiveTabKeyTable(key);
    };

    const fetch = (value, callback, source = 'Poftigo') => {
        if (source === 'InfoCons') {
            const hideMessage = message.loading({
                content: 'Se caută...',
                key: 'externalDB',
            });
            ApiHelpers.getMenuIngredientsFromInfoCons(value)
                .then(
                    (response) => {
                        console.log(response);
                        if (response.data) {
                            const data = response.data
                                .filter((d) => d.energy)
                                .map((d, index) => ({
                                    ...d,
                                    indexKey: index,
                                    externalSource: true,
                                }));
                            callback(data);
                            hideMessage();
                        }
                    },
                    (error) => {
                        hideMessage();
                        if (error.response.data.Message === 'Not found') {
                            message.error('Ingredientul nu a fost gasit');
                        } else message.error('A intervenit o eroare');
                    }
                )
                .catch((error) => {
                    // error is handled in catch block
                    if (error.response) {
                        // status code out of the range of 2xx
                        console.log('Data :', error.response.data);
                        console.log('Status :' + error.response.status);
                    } else if (error.request) {
                        // The request was made but no response was received
                        console.log(error.request);
                    } else {
                        // Error on setting up the request
                        console.log('Error', error.message);
                    }
                });
        } else {
            ApiHelpers.getMenuIngredients(value, restaurantId).then(
                (response) => {
                    if (response.data) {
                        const data = response.data
                            .filter((d) => d.energy)
                            .map((d, index) => ({
                                ...d,
                                indexKey: index,
                            }));
                        callback(data);
                    }
                },
                (error) => {}
            );
        }
    };

    const columnsNutritional = [
        {
            title: 'Name',
            dataIndex: 'nutritionalName',
            key: 'nutritionalName',
            render: (text, record) => <span className={'nutritional-name ' + record.type}>{text}</span>,
        },
        {
            title: 'Value',
            dataIndex: 'nutritionalValue',
            key: 'nutritionalValue',
            render: (text) => <span className={'nutritional-value'}>{text}</span>,
            align: 'right',
        },
    ];

    const columnsNutritional2 = [
        {
            title: 'Nume',
            dataIndex: 'title',
            key: 'title',
            className: 'column-large',
            render: (text, record, index) =>
                (text && !isEditing(index)) || isInReview(index)  ? (
                    <span className={'nutritional-name main'}>{text + (record.brand ? ' (' + record.brand + ')' : '')}</span>
                ) : isEditing(index)? (
                    <Input
                        onChange={(event) => handleCustomInput(event.target.value, 'title', index)}
                        value={nutritionalData[index].title}
                        className="inputTitle"
                        placeholder="Nume"
                        status={!nutritionalData[index].title ? 'error' : ''}
                    />
                ) : null,
        },
        {
            title: 'Cantitate*',
            dataIndex: 'quantity',
            key: 'quantity',
            align: 'right',
            className: 'column-large',
            render: (text, record, index) =>
                (isEditing(index) || isExternal(index)) && !isInReview(index) ? (
                    <Input.Group compact>
                        <InputNumber
                            onChange={(value) => handleCustomInput(value, 'quantity', index)}
                            name={'ingredientQty-' + index}
                            value={nutritionalData[index].quantity}
                            defaultValue={0}
                            min={0}
                            status={nutritionalData[index].quantity === 0 ? 'error' : ''}
                        />
                        <Select
                            defaultValue={nutritionalData[index].unitOfMeasure || 'g'}
                            onChange={(value) => handleCustomInput(value, 'unitOfMeasure', index)}>
                            <Option value="g">g</Option>
                            <Option value="ml">ml</Option>
                        </Select>
                    </Input.Group>
                ) : (
                    <Input.Group compact>
                        <InputNumber
                            placeholder=""
                            name={'ingredientQty-' + index}
                            onChange={(value) => onChange(value, 'quantity', index)}
                            value={nutritionalData[index].quantity}
                            min={0}
                            autoFocus
                            status={!nutritionalData[index].quantity ? 'error' : ''}
                        />
                        <Select
                            defaultValue={nutritionalData[index].unitOfMeasure || 'g'}
                            onChange={(value) => handleCustomInput(value, 'unitOfMeasure', index)}
                            disabled={true}>
                            <Option value="g">g</Option>
                            <Option value="ml">ml</Option>
                        </Select>
                    </Input.Group>
                ),
        },

        {
            title: 'Energie',
            dataIndex: 'energy',
            key: 'energy',
            align: 'right',
            className: 'column-large',
            render: (text, record, index) =>
                isEditing(index) ? (
                    <>
                        <InputNumber
                            onChange={(event) => handleCustomInput(event, 'energyKJ', index)}
                            value={nutritionalData[index].energyKJ}
                            addonAfter="kJ"
                            defaultValue={0}
                            disabled={true}
                            min={0}
                        />

                        <InputNumber
                            onChange={(event) => handleCustomInput(event, 'energyKCal', index)}
                            value={nutritionalData[index].energyKCal}
                            addonAfter="kCal"
                            defaultValue={0}
                            disabled={true}
                            min={0}
                        />
                    </>
                ) : (
                    parseFloat(text.split(' ')[0]).toFixed(2) + ' kJ / ' + parseFloat(text.split(' ')[3]).toFixed(2) + ' kCal'
                ),
        },
        {
            title: 'Grasimi*',
            dataIndex: 'fat',
            key: 'fat',
            align: 'right',
            render: (text, record, index) =>
                isEditing(index) ? (
                    <InputNumber
                        onChange={(event) => handleCustomInput(event, 'fat', index)}
                        value={nutritionalData[index].fat}
                        min={0}
                        defaultValue={0}
                    />
                ) : (
                    parseFloat(text).toFixed(2)
                ),
        },
        {
            title: 'Acizi grasi saturati*',
            dataIndex: 'saturates',
            key: 'saturates',
            align: 'right',
            render: (text, record, index) =>
                isEditing(index) ? (
                    <InputNumber
                        onChange={(event) => handleCustomInput(event, 'saturates', index)}
                        value={nutritionalData[index].saturates}
                        min={0}
                        defaultValue={0}
                    />
                ) : (
                    parseFloat(text).toFixed(2)
                ),
        },
        {
            title: 'Carbohidrati/ Glucide*',
            dataIndex: 'carbohydrate',
            key: 'carbohydrate',
            align: 'right',
            render: (text, record, index) =>
                isEditing(index) ? (
                    <InputNumber
                        onChange={(event) => handleCustomInput(event, 'carbohydrate', index)}
                        value={nutritionalData[index].carbohydrate}
                        defaultValue={0}
                        min={0}
                    />
                ) : (
                    parseFloat(text).toFixed(2)
                ),
        },
        {
            title: 'Zaharuri*',
            dataIndex: 'sugars',
            key: 'sugars',
            align: 'right',
            render: (text, record, index) =>
                isEditing(index) ? (
                    <InputNumber
                        onChange={(event) => handleCustomInput(event, 'sugars', index)}
                        value={nutritionalData[index].sugars}
                        min={0}
                        defaultValue={0}
                    />
                ) : (
                    parseFloat(text).toFixed(2)
                ),
        },
        {
            title: 'Proteine*',
            dataIndex: 'proteins',
            key: 'proteins',
            align: 'right',
            render: (text, record, index) =>
                isEditing(index) ? (
                    <InputNumber
                        onChange={(event) => handleCustomInput(event, 'proteins', index)}
                        value={nutritionalData[index].proteins}
                        min={0}
                        defaultValue={0}
                    />
                ) : (
                    parseFloat(text).toFixed(2)
                ),
        },
        {
            title: 'Sare*',
            dataIndex: 'salt',
            key: 'salt',
            align: 'right',
            render: (text, record, index) =>
                isEditing(index) ? (
                    <InputNumber
                        onChange={(event) => handleCustomInput(event, 'salt', index)}
                        value={nutritionalData[index].salt}
                        min={0}
                        defaultValue={0}
                    />
                ) : (
                    parseFloat(text).toFixed(2)
                ),
        },

        {
            title: 'Aditivi',
            dataIndex: 'listOfAdditives',
            key: 'listOfAdditives',
            className: 'column-large',
            render: (text, record, index) =>
                isEditing(index) || isExternal(index) || isModified(index) ? (
                    <Select
                        mode="multiple"
                        name="foodAdditives"
                        style={{ width: '100%' }}
                        placeholder="Aditivi"
                        defaultValue={nutritionalData[index].foodAdditives ? nutritionalData[index].foodAdditives.split(',') : []}
                        onChange={(value) => handleSelectTaxonomy(index, value, 'foodAdditives')}
                        onFocus={(value) => {}}
                        filterOption={(inputValue, option) =>
                            option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0 ||
                            option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                        }>
                        {taxonomyList.foodAdditives
                            ? taxonomyList.foodAdditives.map((d, index) => {
                                  return (
                                      <Option key={index} value={d.title}>
                                          {d.title + ' - ' + d.eNumber + ' (' + d.category + ')'}
                                      </Option>
                                  );
                              })
                            : null}
                    </Select>
                ) : nutritionalData[index].components && nutritionalData[index].components.length > 0 ? (
                    getAdditivesInComponents(nutritionalData[index])
                ) : (
                    nutritionalData[index].foodAdditives
                ),
        },
        {
            title: 'Alergeni',
            dataIndex: 'listOfAllergens',
            key: 'listOfAllergens',
            className: 'column-large',
            render: (text, record, index) =>
                isEditing(index) || isExternal(index) || isModified(index) ? (
                    <Select
                        mode="multiple"
                        name="allergens"
                        style={{ width: '100%' }}
                        placeholder="Alergeni"
                        onChange={(value) => handleSelectTaxonomy(index, value, 'allergens')}
                        defaultValue={nutritionalData[index].allergens ? nutritionalData[index].allergens.split(',') : []}
                        onFocus={(value) => {}}
                        filterOption={(inputValue, option) =>
                            option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0 ||
                            option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                        }>
                        {taxonomyList.allergens
                            ? taxonomyList.allergens.map((d, index) => {
                                  return (
                                      <Option key={index} value={d.title}>
                                          {d.title}
                                      </Option>
                                  );
                              })
                            : null}
                    </Select>
                ) : nutritionalData[index].components && nutritionalData[index].components.length > 0 ? (
                    getAllergensInComponents(nutritionalData[index])
                ) : (
                    nutritionalData[index].allergens
                ),
        },
        {
            title: 'Actiuni',
            dataIndex: 'actions',
            key: 'actions',
            align: 'center',
            render: (text, record, index) => (
                <Popconfirm title="Sigur vrei sa stergi?" onConfirm={() => handleDeleteRow(index)}>
                    <a title="Sterge ingredient">
                        <DeleteOutlined style={{ fontSize: 20 }} />
                    </a>
                </Popconfirm>
            ),
        },
    ];

    const renderTitle = (title) => <span>{title}</span>;

    const renderItem = (item) => ({
        value: item.title,
        label: (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}>
                {item.title}
                <span>
                    <UserOutlined />
                </span>
            </div>
        ),
    });

    const defaultFooter = () => (
        <Row align="middle" gutter={[15, 15]}>
            <Col>
                <h3 className="addIngredientLabel">Adaugă ingredient</h3>
            </Col>
            <Col>
                <Select
                    style={{ width: '100%' }}
                    showSearch
                    placeholder="din baza de date"
                    onSearch={(val) => handleSearch(val)}
                    onChange={(value, index) => onChangeSelectMenuItem(value, index)}
                    options={[
                        {
                            label: 'Ingrediente folosite recent',
                            className: 'results-group',
                            options: (data || [])
                                .filter((d) => d.recentlyUsed === true)
                                .map((d, index) => ({
                                    value: d.title + 'recent-index-' + d.indexKey,
                                    label: (
                                        <div>
                                            <h4 className="ingredient-select-title">{d.title}</h4>
                                            {d.displayFoodAdditives ? <div className="ingredient-select-text">Aditivi: {formatFoodAdditives(d)}</div> : ''}
                                            {d.brand ? <div className="ingredient-select-text">{d.brand}</div> : ''}
                                        </div>
                                    ),
                                })),
                        },
                        {
                            label: 'Ingrediente compuse proprii',
                            options: (data || [])
                                .filter((d) => d.compositeIngredient === true)
                                .map((d, index) => ({
                                    value: d.title + 'comp-index-' + d.indexKey,
                                    label: (
                                        <div>
                                            <h4 className="ingredient-select-title">{d.title}</h4>
                                            {d.displayFoodAdditives ? <div className="ingredient-select-text">Aditivi: {formatFoodAdditives(d)}</div> : ''}
                                            {d.brand ? <div className="ingredient-select-text">{d.brand}</div> : ''}
                                        </div>
                                    ),
                                })),
                        },
                        {
                            label: 'Ingrediente',
                            options: (data || [])
                                .filter((d) => d.compositeIngredient !== true && d.recentlyUsed !== true)
                                .map((d, index) => ({
                                    value: d.title + '-index-' + d.indexKey,
                                    label: (
                                        <div>
                                            <h4 className="ingredient-select-title">{d.title}</h4>
                                            {d.energy !== '0 kJ / 0 kCal' ? <div className="ingredient-select-text">{formatNutritionalValues(d.energy, true)}</div> : ''}
                                            {d.displayFoodAdditives ? <div className="ingredient-select-text">Aditivi: {formatFoodAdditives(d)}</div> : ''}
                                            {d.brand ? <div className="ingredient-select-text">{d.brand}</div> : ''}
                                        </div>
                                    ),
                                })),
                        },
                    ]}
                    size="large"
                    value={selectedElement}
                    className="ingredient-select"
                    open={searchKeyword.length > 1 ? true : false}
                    dropdownRender={(menu) => (
                        <div>
                            {menu}
                            <Divider style={{ margin: '8px 0' }} />
                            <Space style={{ padding: '0 8px 4px' }}>
                                <Button
                                    type="text"
                                    icon={<SearchOutlined />}
                                    onClick={(val) => {
                                        handleSearch(searchKeyword, 'InfoCons');
                                    }}
                                    className="button-noHover">
                                    Căutare avansată
                                </Button>
                            </Space>
                        </div>
                    )}
                />
            </Col>
            <Col>
                sau{' '}
                <Button type="secondary" onClick={() => handleAddRow()} className="button-icon-left" size="large">
                    <PlusOutlined /> Introdu manual
                </Button>
            </Col>
        </Row>
    );

    const getTaxonomyValues = (value, taxonomy, languageCode = 'ro_RO') => {
        ApiHelpers.getMenuTaxonomy(taxonomy, value, languageCode).then(
            (response) => {
                setTaxonomyList((prevState) => {
                    return { ...prevState, [taxonomy]: response.data };
                });
            },
            (error) => {
                console.log(error);
            }
        );
    };

    const onChangeSelectMenuItem = (value, index) => {
        setSearchKeyword('');
        const tempSelectedElement = data[value.substring(value.indexOf('-index-') + 7)];

        if (tempSelectedElement.energy) {
            tempSelectedElement.energyKJ = parseFloat(tempSelectedElement.energy.split(' ')[0]).toFixed(2);
            tempSelectedElement.energyKCal = parseFloat(tempSelectedElement.energy.split(' ')[3]).toFixed(2);
        }
        if (tempSelectedElement.uniqueIdentificationToken) {
            if (!tempSelectedElement.compositeIngredient) tempSelectedElement.modifiedIngredient = true;
            setNutritionalData([...nutritionalData, tempSelectedElement]);
            setData([]);
            setSelectedElement(null);
        } else {
            ApiHelpers.getUUID().then(
                (response) => {
                    tempSelectedElement.uniqueIdentificationToken = response.data.uuid;
                    tempSelectedElement.newIngredient = true;
                    setNutritionalData([...nutritionalData, tempSelectedElement]);
                    setData([]);
                    setSelectedElement(null);
                },
                (error) => {}
            );
        }
        message.info('Ingredient adăugat. Nu uita să salvezi rețetarul');
    };

    const onChange = (value, field, index) => {
        const tempNutritionalData = nutritionalData;
        tempNutritionalData[index][field] = parseFloat(value);

        setNutritionalData([...tempNutritionalData]);
    };

    const onChangeCheckbox = (event, field, index) => {
        const tempNutritionalData = nutritionalData;
        tempNutritionalData[index] = { ...tempNutritionalData[index], [field]: event.target.checked };

        setNutritionalData([...tempNutritionalData]);
    };

    const handleSearch = (newValue, source = '') => {
        setSearchKeyword(newValue);
        if (newValue && newValue.length > 1) {
            fetch(newValue, setData, source);
        } else {
            setData([]);
        }
    };

    const handleSelectTaxonomy = (index, value, taxonomy) => {
        if (value) {
            const tempNutritionalData = nutritionalData;
            const stringTaxonomy = value.map((d) => {
                return d;
            });
            if (tempNutritionalData[index].modifiedIngredient === true) {
                tempNutritionalData[index].modifiedIngredientInsert = true;
                ApiHelpers.getUUID().then(
                    (response) => {
                        tempNutritionalData[index].uniqueIdentificationToken = response.data.uuid;
                    },
                    (error) => {}
                );
            }
            tempNutritionalData[index][taxonomy] = stringTaxonomy.join();
            if (taxonomy === 'foodAdditives') tempNutritionalData[index].containsAdditives = true;
            setNutritionalData([...tempNutritionalData]);
        }
    };

    const handleDeleteRow = (index) => {
        setEditingKey([...editingKey.filter((item) => item !== index)]);
        const modifiedArray = nutritionalData;
        modifiedArray.splice(index, 1);
        setNutritionalData([...modifiedArray]);
        message.info('Ingredientul a fost sters. Nu uita sa salvezi modificarile');
    };

    const handleAddRow = (index) => {
        message.info('Introdu detaliile ingredientului propriu');
        const currentIndex = nutritionalData.length;
        setEditingKey([...editingKey, nutritionalData.length]);
        // Get generated UUID from API and store it in state
        ApiHelpers.getUUID().then(
            (response) => {
                setNutritionalData([
                    ...nutritionalData,
                    {
                        customIngredient: true,
                        key: 'tableIngredients-' + currentIndex + 1,
                        uniqueIdentificationToken: response.data.uuid,
                        key: 0,
                        energy: '',
                        quantity: 0,
                        fat: 0,
                        saturates: 0,
                        proteins: 0,
                        salt: 0,
                        sugars: 0,
                        carbohydrate: 0,
                        key: 0,
                        energyKJ: 0,
                        energyKCal: 0,
                        monounsaturates: 0,
                        polyunsaturates: 0,
                        polyols: 0,
                        starch: 0,
                        fiber: 0,
                        unitOfMeasure: 'g',
                    },
                ]);
            },
            (error) => {
                console.log(error);
            }
        );
    };

    const handleCustomInput = (value, field, index) => {
        let formatValue = value;
        let calcEnergyKJ = 0;
        let calcEnergyKCal = 0;
        if (field !== 'title' && field !== 'unitOfMeasure') formatValue = parseFloat(value);
        const tempNutritionalData = nutritionalData.map((item) => {
            return { ...item };
        });
        if (field === 'saturates' && value > tempNutritionalData[index].fat) {
        } else if (field === 'sugars' && value > tempNutritionalData[index].carbohydrate) {
        } else {
            tempNutritionalData[index][field] = formatValue;
            calcEnergyKJ =
                17 * tempNutritionalData[index].carbohydrate +
                37 * tempNutritionalData[index].fat +
                10 * tempNutritionalData[index].polyols +
                17 * tempNutritionalData[index].proteins +
                8 * tempNutritionalData[index].fiber;

            calcEnergyKCal =
                4 * tempNutritionalData[index].carbohydrate +
                9 * tempNutritionalData[index].fat +
                2.4 * tempNutritionalData[index].polyols +
                4 * tempNutritionalData[index].proteins +
                2 * tempNutritionalData[index].fiber;
            tempNutritionalData[index] = {
                ...tempNutritionalData[index],
                energy: calcEnergyKJ + ' kJ / ' + calcEnergyKCal + ' kCal',
                energyKJ: calcEnergyKJ,
                energyKCal: calcEnergyKCal,
            };
            setNutritionalData([...tempNutritionalData]);
        }
    };

    const handleCustomInputAdvanced = (value, field, index) => {
        const formatValue = parseFloat(value);
        if (field === 'customQuantityFactor') setMenuItem({ ...menuItem, weightOfEndProduct: formatValue });
        // const sumQuantity = nutritionalCalculationSumIngredients(nutritionalData, false);
        // const tempNutritionalData = nutritionalData.map((item) => {
        //     return { ...item, quantity: parseFloat(((item.quantity * formatValue) / sumQuantity).toFixed(2)) };
        // });
        // setNutritionalData([...tempNutritionalData]);
    };

    useEffect(() => {
        setSumNutritionalData([{ ...nutritionalCalculation(nutritionalData, 'full', menuItem.weightOfEndProduct) }]);
        setSumNutritionalData100g([{ ...nutritionalCalculation(nutritionalData, '100g', menuItem.weightOfEndProduct), quantity: 100 }]);
        if (menuItem.compositeIngredient && actionType !== 'new')
            setMenuItem({
                ...menuItem,
                weightOfEndProduct:
                    menuItem.weightOfEndProduct > 0 ? menuItem.weightOfEndProduct : nutritionalCalculationSumIngredients(nutritionalData, false),
            });
        setListOfIngredients([...nutritionalData]);
    }, [nutritionalData]);

    useEffect(() => {
        getTaxonomyValues('', 'allergens');
        getTaxonomyValues('', 'foodAdditives');
    }, []);

    return (
        <>
            <Row key={'menuItemRow-' + index} gutter={[5, 5]}>
                <Col span={18}>
                    <h3>Lista ingrediente</h3>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Table
                        className="nutritional-table calculation"
                        dataSource={[...nutritionalData]}
                        columns={columnsNutritional2}
                        pagination={false}
                        showHeader={true}
                        rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-light' : 'table-row-dark')}
                        footer={defaultFooter}
                        rowKey={(record, index) => record.uniqueIdentificationToken + '-' + index}
                        scroll={{ x: 240 }}
                    />
                    <small>* valori in grame per 100g</small>
                </Col>
            </Row>
            <div className="widgetContent"></div>
            <Collapse className="subFormCollapse" bordered={false} defaultActiveKey={['results']}>
                {nutritionalData.length > 0 && nutritionalData.filter((d) => d.quantity > 0).length > 0 ? (
                    <Panel header="Rezultate calcul" key="results">
                        <Row gutter={[35, 35]}>
                            <Col
                                xs={{ span: 18, offset: 0 }}
                                md={{ span: 12, offset: 0 }}
                                lg={{ span: 10, offset: 0 }}
                                xl={{ span: 8, offset: 0 }}
                                xxl={{ span: 6, offset: 0 }}>
                                <h3>Valori per 100g</h3>
                                <Table
                                    className="nutritional-table result-100g"
                                    columns={columnsNutritional}
                                    dataSource={nutritionalCalculationFrontend(nutritionalData, '100g', menuItem.weightOfEndProduct)}
                                    pagination={false}
                                    showHeader={false}
                                    loading={isLoading}
                                    rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-light' : 'table-row-dark')}
                                />
                            </Col>
                            <Col
                                xs={{ span: 18, offset: 0 }}
                                md={{ span: 12, offset: 0 }}
                                lg={{ span: 10, offset: 0 }}
                                xl={{ span: 8, offset: 0 }}
                                xxl={{ span: 6, offset: 0 }}>
                                <h3>Valori per porție {nutritionalCalculationSumIngredients(nutritionalData)}</h3>
                                <Table
                                    className="nutritional-table result-total"
                                    columns={columnsNutritional}
                                    dataSource={nutritionalCalculationFrontend(nutritionalData, 'full', menuItem.weightOfEndProduct)}
                                    pagination={false}
                                    showHeader={false}
                                    loading={isLoading}
                                    rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-light' : 'table-row-dark')}
                                />
                            </Col>
                        </Row>
                    </Panel>
                ) : null}
                {menuItem.compositeIngredient && actionType !== 'new' ? (
                    <Panel header="Configurari avansate (opțional)" key="advanced">
                        <div>
                            Cantitate produs finit
                            <InputNumber
                                onChange={(event) => handleCustomInputAdvanced(event, 'customQuantityFactor', index)}
                                value={menuItem.weightOfEndProduct}
                                min={0}
                                defaultValue={0}
                                className="inputAdvanced"
                            />{' '}
                            / 100 = <strong>{menuItem.weightOfEndProduct / 100} </strong>
                            <a
                                title="Reset"
                                onClick={() =>
                                    setMenuItem({
                                        ...menuItem,
                                        weightOfEndProduct: nutritionalCalculationSumIngredients(nutritionalData, false),
                                    })
                                }>
                                reset
                            </a>
                        </div>
                    </Panel>
                ) : null}
            </Collapse>
        </>
    );
};

export default MenuItemIngredient;
