import React, { useState, useEffect, useContext } from 'react';
import { AuthUserContext, withAuthorization } from '../Session';
import AdminLayout from './AdminLayout';
import { ApiHelpers } from '../../helpers';
import { Form, Tabs, Skeleton, Empty, Button, Space } from 'antd';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import { useCompany } from '../../swr';
import { useRecoilState } from 'recoil';
import { restaurantDetailsState, activeRestaurantState } from '../../recoil';
import RestaurantWaiters from './Restaurant/RestaurantWaiters';
import Onboarding from './Onboarding';
import QRCode from 'qrcode.react';
import RestaurantWaitersPending from './Restaurant/RestaurantWaitersPending';

import { appIconStaff } from '../../const';

const { TabPane } = Tabs;

function RestaurantWaitersHome(props) {
    // Get AuthUser ID from Firebase
    const firebaseAuthUser = useContext(AuthUserContext);
    let { restaurantId } = useParams();
    const [restaurantDetails, setRestaurantDetails] = useState([]);
    const [entryFound, setEntryFound] = useState(false);
    const [activeTabKey, setActiveTabKey] = useState();
    const [activeRestaurant, setActiveRestaurant] = useRecoilState(activeRestaurantState);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    const { data: companyDetails, isLoading: isLoadingCompanyDetails } = useCompany('getDetails', firebaseAuthUser.uid);
    let companyIdentificationToken = null;

    if (companyDetails) {
        companyIdentificationToken = companyDetails.uniqueIdentificationToken;
    }

    const routeLocation = useLocation();

    const callbackTabs = (key) => {
        setActiveTabKey(key);
        navigate(key);
    };

    useEffect(() => {
        // Get Restaurant by Id
        setIsLoading(true);
        ApiHelpers.getRestaurantsByCode(restaurantId).then(
            (response) => {
                if (response.data.message) {
                    setEntryFound(false);
                } else {
                    setRestaurantDetails({
                        ...response.data,
                        companyIdentificationToken: companyIdentificationToken,
                    });
                    setEntryFound(true);
                }
                setIsLoading(false);
            },
            (error) => {
                console.log(error);
            }
        );
    }, [restaurantId]);

    useEffect(() => {
        if (routeLocation.hash) {
            setActiveTabKey(routeLocation.hash);
        } else setActiveTabKey('#feedback');
    });

    return (
        <AuthUserContext.Consumer>
            {(authUser) => (
                <AdminLayout authUser={authUser} pageTitle={restaurantDetails.title}>
                    <h2>Staff</h2>
                    {isLoadingCompanyDetails ? (
                        <Skeleton active></Skeleton>
                    ) : companyDetails.message ? (
                        <Onboarding />
                    ) : isLoading ? (
                        <Skeleton active></Skeleton>
                    ) : entryFound === true ? (
                        activeRestaurant.subscriptionPlan !== 'Free' ? (
                            <Tabs activeKey={activeTabKey} defaultActiveKey={routeLocation.hash} onChange={callbackTabs}>
                                <TabPane tab="Activi" key="#feedback">
                                    <RestaurantWaiters restaurantId={restaurantId} />
                                </TabPane>
                                <TabPane tab="Lista" key="#list">
                                    <RestaurantWaitersPending restaurantId={restaurantId} />
                                </TabPane>
                                <TabPane tab="Cod QR inrolare" key="#qrcode">
                                    <p>Acest cod QR trebuie scanat de catre staff-ul restaurantului in procesul de inrolare in Poftigo Staff.</p>
                                    <QRCode
                                        value={restaurantId}
                                        size={250}
                                        level={'Q'}
                                        imageSettings={{
                                            src: appIconStaff,
                                            width: 80,
                                            height: 80,
                                            excavate: true,
                                        }}
                                    />
                                </TabPane>
                            </Tabs>
                        ) : (
                            <Empty description={<span></span>}>
                                <Space size={10} direction="vertical">
                                    <div>Functionalitatile nu sunt incluse in pachetul Free</div>
                                    <Button type="primary">Upgrade</Button>
                                </Space>
                            </Empty>
                        )
                    ) : entryFound === false ? (
                        <Onboarding step={2} />
                    ) : (
                        <Skeleton active />
                    )}
                </AdminLayout>
            )}
        </AuthUserContext.Consumer>
    );
}
const condition = (authUser) => !!authUser;
export default withAuthorization(condition)(RestaurantWaitersHome);
