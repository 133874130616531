import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ApiHelpers } from '../../helpers';
import { useRestaurantDetailsPublicById } from '../../swr';
import { Spin, Layout, Row, Col, Drawer } from 'antd';

const { Footer, Content } = Layout;

const Restaurant = () => {

    let { restaurantId } = useParams();

    const { data: restaurantDetails, isLoading } = useRestaurantDetailsPublicById(restaurantId);
    const [drawerProps, setDrawerProps] = useState({
        visible: true,
        placement: 'bottom'
    });

    const onClose = () => {
        setDrawerProps({ ...drawerProps, visible: false });
    }

    return (
        <Layout>
            <Content className="restaurantContainer">
                <Drawer
                    title="Descarca aplicatia"
                    placement={drawerProps.placement}
                    closable={false}
                    onClose={onClose}
                    visible={drawerProps.visible}
                >
                    <h2>Ai vrea sa comanzi direct la masa?</h2>
                    <p>Descarca aplicatia din AppStore si Google Play</p>
                </Drawer>
                {isLoading !== true ?
                    restaurantDetails.statusCode !== 0 ?
                        (
                            <>
                                <Row>
                                    <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} lg={{ span: 18, offset: 3 }} xl={{ span: 18, offset: 3 }} xxl={{ span: 16, offset: 4 }} className="contentRow">
                                        <div className="coverImagePublicContainer">
                                            {restaurantDetails.coverImagePath ?
                                                <img src={ApiHelpers.getImageLink(restaurantDetails.coverImagePath)} className="coverImagePublic" />
                                                : null}
                                            {restaurantDetails.logoPath ?
                                                <img src={ApiHelpers.getImageLink(restaurantDetails.logoPath)} className="logoImagePublic" />
                                                : null}
                                        </div>
                                        <h1 className="restaurantHeaderTitle">{restaurantDetails.restaurantName}</h1>


                                        <Row>
                                            <Col>
                                                <div className="restaurantSectionContainer">
                                                    <p>{restaurantDetails.cuisineTypes.map((element) => {
                                                        return element.cuisineTypeName;
                                                    }).join(", ")}</p>
                                                    <p>{restaurantDetails.placeAddress}</p>
                                                    <p>Program</p>
                                                </div>
                                            </Col>
                                            <Col>

                                            </Col>
                                        </Row>
                                        <Row className="restaurantMenuContainer" >

                                            {restaurantDetails.menu && restaurantDetails.menu[0].category && restaurantDetails.menu[0].category.map((menuCategory, index) => {
                                                return (<div className="menuCategorySection"><h2>{menuCategory.categoryName}</h2><Row key={"menuCategory" + index}>
                                                    {menuCategory.item && menuCategory.item.map((menuItem, index) => {
                                                        return <Col xs={{ span: 24, offset: 0 }} md={{ span: 12, offset: 0 }} lg={{ span: 12, offset: 0 }} xl={{ span: 11, offset: 0 }} xxl={{ span: 8, offset: 0 }} key={"menuItem" + index}>
                                                            <Row gutter={[8, 20]}>
                                                                <Col span={12}>
                                                                    {menuItem.itemImagePath ? <img src={ApiHelpers.getImageLink(menuItem.itemImagePath)} className="menuItemImage" /> : null}

                                                                </Col>
                                                                <Col span={12}>
                                                                    <h3>{menuItem.title}</h3>
                                                                    <p>{menuItem.itemDescription}</p>
                                                                    <p>{menuItem.price}</p>
                                                                </Col>

                                                            </Row>
                                                        </Col>
                                                    })}
                                                </Row></div>)
                                            })}

                                        </Row>
                                    </Col>
                                </Row>


                            </>

                        )
                        : <div>Not found</div>
                    : <Spin />}
            </Content>
            <Footer>Footer</Footer>
        </Layout>
    )
}

export default Restaurant;