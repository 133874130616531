import React, { useContext, useEffect, useState } from 'react';
import { Form, Input, message, Checkbox, Button, Select, Collapse, Popover } from 'antd';
import { InfoCircleOutlined, DeliveredProcedureOutlined } from '@ant-design/icons';
import { ApiHelpers, MapMarker, FormSchedule, normalizeString } from '../../../helpers';
import { AuthUserContext } from '../../Session';
import UploadLogo from './UploadLogo';
import UploadCoverImage from './UploadCoverImage';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from '../../../const/routes';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useCompany } from '../../../swr';
import FormIntegrationSettings from '../../../helpers/FormIntegrationSettings';
import { activeRestaurantState } from '../../../recoil';
import FormGeneralSettings from '../../../helpers/FormGeneralSettings';

const { Option } = Select;
const { TextArea } = Input;
const { Panel } = Collapse;

function RestaurantForm(props) {
    // Get AuthUser ID from Firebase
    const firebaseAuthUser = useContext(AuthUserContext);
    const navigate = useNavigate();
    const {
        scheduleList,
        setScheduleList,
        restaurantDetails,
        setRestaurantDetails,
        restaurantId,
        formLayout,
        tailLayout,
        drawerVisible,
        actionType,
        integrationSettings,
        setIntegrationSettings,
        setRefreshData,
        generalSettings,
        setGeneralSettings
    } = props;
    let restaurantCuisineTypes = [];
    const [companyIdentificationToken, setCompanyIdentificationToken] = useState();
    //const { data: companyDetails } = useCompany('getDetails', firebaseAuthUser.uid);
    const [activeRestaurant, setActiveRestaurant] = useRecoilState(activeRestaurantState);

    const [citySearch, setCitySearch] = useState(restaurantDetails.cityName ? normalizeString(restaurantDetails.cityName) : null);
    const { data: cityList, isLoading: isLoadingCityList } = useCompany('getCities', citySearch);
    const [cuisineTypeList, setCuisineTypeList] = useState([]);
    const [schedulesNew, setSchedulesNew] = useState({ ...restaurantDetails.schedules });

    const tooltipImplementationMode = {
        title: '',
        text: <div>Bifeaza atunci cand iti configurezi restaurantul si esti in faza de implementare.</div>,
    };

    const tooltipImageLogo = {
        title: '',
        text: <div>Dimensiunea recomandata de minim 400x400px. Imaginea va fi ajustata pentru aspectul patrat.</div>,
    };
    const tooltipImageCover = {
        title: '',
        text: <div>Dimensiunea recomandata de minim 1200x600px. Imaginea va fi ajustata pentru aspectul 2:1</div>,
    };



    const fieldsDictionary = {
        sysRestaurantType: 'Tip restaurant',
        title: 'Titlu',
        cityId: 'Oras',
        placeAddress: 'Adresa',
        appDescription: 'Descriere',
        cuisineType: 'Bucatarie',
        latitude: 'Localizare (lat)',
        longitude: 'Localizare (long)',
        coverImage: 'Imagine cover',
        defaultCategoryType: 'Tip categorie implicita',
    };

    // CuisineType
    useEffect(() => {
        ApiHelpers.getCompanyDetails(firebaseAuthUser.uid).then(
            (response) => {
                if (!response.data.message) {
                    setCompanyIdentificationToken(response.data.uniqueIdentificationToken);
                } else {
                    console.log('Nix');
                }
            },
            (error) => {
                console.log(error);
            }
        );
        ApiHelpers.getCuisineTypes().then(
            (response) => {
                setCuisineTypeList(response.data);
            },
            (error) => {
                console.log(error);
            }
        );
    }, [restaurantId, firebaseAuthUser.uid]);

    useEffect(() => {
        if (restaurantDetails.cuisineTypes)
            restaurantDetails.cuisineTypes.map((element) => {
                restaurantCuisineTypes.push(element.uniqueIdentificationToken);
            });
        // Set form fields values
        formRestaurant.setFieldsValue({
            ...restaurantDetails,
            cuisineType: restaurantCuisineTypes,
        });
    }, [restaurantDetails]);

    const onFocusSelectCuisineType = (value) => {
        if (value != '') {
        }
    };

    const onChangeSelectCuisineType = (value) => {
        const latestValue = value[value.length - 1];
        const stringCuisineType = value.map((d) => {
            return d;
        });

        const arrayCuisineType = value.map((d) => {
            return {
                uniqueIdentificationToken: d,
                active: true,
            };
        });

        // if (ingredientsList.filter(e => e.title === latestValue).length <= 0) {
        //     if (newIngredientsList.indexOf(latestValue) < 0)
        //         setNewIngredientsList([...newIngredientsList, latestValue]);
        // }
        setRestaurantDetails({
            ...restaurantDetails,
            cuisineTypes: arrayCuisineType,
        });
    };

    const onSaveSelectCuisineType = () => { };

    const formRestaurant = formLayout.form;

    // Set form fields values
    formRestaurant.setFieldsValue({
        ...restaurantDetails,
    });
    const onChangeRestaurant = (event) => {
        setRestaurantDetails({
            ...restaurantDetails,
            [event.target.name]: event.target.value,
        });
    };

    const onChangeRestaurantCheckbox = (event, field) => {
        if (field === 'implementationMode' && event.target.checked === true)
            setRestaurantDetails({
                ...restaurantDetails,
                [field]: event.target.checked,
                active: false,
                temporarilyClosed: false,
            });
        else
            setRestaurantDetails({
                ...restaurantDetails,
                [field]: event.target.checked,
            });
    };

    const onChangeRestaurantSelect = (value) => {
        setRestaurantDetails({
            ...restaurantDetails,
            cityId: value,
        });
    };

    const onChangeRestaurantType = (value) => {
        setRestaurantDetails({
            ...restaurantDetails,
            sysRestaurantType: value,
        });
    };

    const onChangeDefaultCategoryType = (value) => {
        setRestaurantDetails({
            ...restaurantDetails,
            defaultCategoryType: value,
        });
    };

    const onSaveRestaurantDetails = (refreshData = false) => {
        message.loading({ content: 'Se incarca...', key: 'statusMessage' });
        formRestaurant
            .validateFields()
            .then(() => {
                if (actionType === 'new') {
                    ApiHelpers.postRestaurant({
                        ...restaurantDetails,
                        schedule: { ...scheduleList },
                        schedules: { ...schedulesNew },
                        companyIdentificationToken: companyIdentificationToken,
                        menu: [],
                    }).then(
                        (response) => {
                            message.success({ content: 'Element adaugat!', key: 'statusMessage', duration: 2 });
                            navigate(ROUTES.ADMIN);
                        },
                        (error) => {
                            console.log(error);
                            message.error('Eroare!');
                        }
                    );
                } else if (actionType === 'edit') {
                    ApiHelpers.updateRestaurant({
                        ...restaurantDetails,
                        schedule: { ...scheduleList },
                        schedules: { ...schedulesNew },
                        integrationSettings: { ...integrationSettings },
                        settings: {...generalSettings},
                        companyIdentificationToken: companyIdentificationToken,
                        menu: [],
                    }).then(
                        (response) => {
                            message.success({ content: 'Element modificat!', key: 'statusMessage', duration: 2 });
                            setRefreshData(refreshData);
                        },
                        (error) => {
                            console.log(error);
                            message.error('Eroare!');
                        }
                    );
                }
            })
            .catch((info) => {
                const errorFields = [];
                if (info && info.errorFields) {
                    info.errorFields.map((d) => {
                        errorFields.push(fieldsDictionary[d.name] || d.name);
                    });
                    //console.log('Exista probleme cu urmatoarele campuri', errorFields.join(','));
                    message.error('Există probleme cu urmatoarele câmpuri: ' + errorFields.join(', '));
                }
            });
    };
    return (
        <>
            <Form {...formLayout}>
                <Collapse className="formCollapse" bordered={false} defaultActiveKey={actionType === 'edit' ? ['1'] : ['0', '1']}>
                    <Panel header="Tip" key="0">
                        <Form.Item
                            name="sysRestaurantType"
                            label={
                                <>
                                    <span className="labelIcon">Tip restaurant</span>
                                </>
                            }
                            rules={[
                                {
                                    required: true,
                                    message: 'Camp obligatoriu',
                                },
                            ]}>
                            <Select
                                size="large"
                                placeholder="Selecteaza tip"
                                disabled={actionType === 'edit' ? true : false}
                                onChange={onChangeRestaurantType}
                                name="sysRestaurantType"
                                value={restaurantDetails.sysRestaurantType}
                                showSearch
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                <Option key="standard" value="Standard">
                                    Standard
                                </Option>
                                <Option key="selfservice" value="SelfService">
                                    Self service
                                </Option>
                            </Select>
                        </Form.Item>
                    </Panel>
                    <Panel header="Informatii" key="1">
                        <Form.Item
                            name="title"
                            label="Titlu"
                            rules={[
                                {
                                    required: true,
                                    message: 'Camp obligatoriu',
                                },
                            ]}>
                            <Input size="large" placeholder="Numele" onBlur={onChangeRestaurant} name="title" value={restaurantDetails.title} />
                        </Form.Item>
                        <Form.Item
                            name="cityId"
                            label="Oras"
                            rules={[
                                {
                                    required: true,
                                    message: 'Camp obligatoriu',
                                },
                            ]}>
                            <Select
                                size="large"
                                placeholder="Caută oras"
                                onSearch={(val) => setCitySearch(val)}
                                onChange={onChangeRestaurantSelect}
                                name="cityId"
                                loading={isLoadingCityList ? true : false}
                                notFoundContent={isLoadingCityList ? 'Căutam...' : 'Nu am gasit niciun rezultat'}
                                value={restaurantDetails.cityId}
                                showSearch
                                showArrow={false}
                                filterOption={(input, option) =>
                                    option.children
                                        .substring(0, option.children.indexOf(','))
                                        .toLowerCase()
                                        .indexOf(input.normalize('NFKD').replace(/[^\w]/g, '').toLowerCase()) >= 0
                                }>
                                {cityList != null
                                    ? cityList.map((d) => (
                                        <Option key={d.cityId} value={d.cityId}>
                                            {d.normalizedTitle + ', ' + d.countyName}
                                        </Option>
                                    ))
                                    : ''}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="placeAddress"
                            label="Adresa"
                            rules={[
                                {
                                    required: true,
                                    message: 'Camp obligatoriu',
                                },
                            ]}>
                            <Input
                                size="large"
                                placeholder="Adresa"
                                onBlur={onChangeRestaurant}
                                name="placeAddress"
                                value={restaurantDetails.placeAddress}
                            />
                        </Form.Item>
                        <Form.Item
                            name="appDescription"
                            label="Descriere"
                            rules={[
                                {
                                    required: true,
                                    message: 'Camp obligatoriu',
                                },
                            ]}>
                            <Input
                                size="large"
                                placeholder="Descrierea"
                                onBlur={onChangeRestaurant}
                                name="appDescription"
                                value={restaurantDetails.appDescription}
                            />
                        </Form.Item>
                        <Form.Item
                            name="bio"
                            label="Bio/Introducere"
                            rules={[
                                {
                                    required: false,
                                    message: 'Camp optional',
                                },
                            ]}>
                            <TextArea rows="5" placeholder="Bio/Introducere" onBlur={onChangeRestaurant} name="bio" value={restaurantDetails.bio} />
                        </Form.Item>
                        <Form.Item
                            name="cuisineType"
                            label="Bucatarie"
                            rules={[
                                {
                                    required: true,
                                    message: 'Camp obligatoriu',
                                },
                            ]}>
                            <Select
                                mode="multiple"
                                name="cuisineType"
                                style={{
                                    width: '100%',
                                }}
                                placeholder="Tipul de bucatarie"
                                onChange={onChangeSelectCuisineType}
                                onFocus={onFocusSelectCuisineType}
                                filterOption={(inputValue, option) =>
                                    option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0 ||
                                    option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                                }>
                                {cuisineTypeList != null
                                    ? cuisineTypeList.map((d, index) => {
                                        return (
                                            <Option key={index} value={d.uniqueIdentificationToken}>
                                                {d.cuisineTypeName}
                                            </Option>
                                        );
                                    })
                                    : null}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="facebook"
                            label="Link Facebook"
                            rules={[
                                {
                                    required: false,
                                    message: 'Camp optional',
                                },
                                {
                                    type: 'url',
                                    message: 'Campul trebuie sa contina un link',
                                },
                            ]}>
                            <Input
                                size="large"
                                placeholder="Facebook"
                                onBlur={onChangeRestaurant}
                                name="facebook"
                                value={restaurantDetails.facebook}
                            />
                        </Form.Item>
                        <Form.Item
                            name="instagram"
                            label="Link Instagram"
                            rules={[
                                {
                                    required: false,
                                    message: 'Camp optional',
                                },
                                {
                                    type: 'url',
                                    message: 'Campul trebuie sa contina un link',
                                },
                            ]}>
                            <Input
                                size="large"
                                placeholder="Instagram"
                                onBlur={onChangeRestaurant}
                                name="instagram"
                                value={restaurantDetails.instagram}
                            />
                        </Form.Item>
                    </Panel>
                    <Panel header="Imagini" key="2" forceRender>
                        <Form.Item
                            name="logo"
                            label="Logo"
                            rules={[
                                {
                                    required: false,
                                    message: '',
                                },
                            ]}>
                            <UploadLogo
                                restaurantId={restaurantId}
                                restaurantDetails={restaurantDetails}
                                setRestaurantDetails={setRestaurantDetails}
                            />
                            <Popover
                                className="iconSpacing"
                                placement="right"
                                title={tooltipImageLogo.title}
                                content={tooltipImageLogo.text}
                                trigger="hover">
                                <InfoCircleOutlined />
                            </Popover>
                        </Form.Item>
                        <Form.Item
                            name="coverImage"
                            label="Imagine cover"
                            rules={[
                                {
                                    required: false,
                                    message: 'Camp obligatoriu',
                                },
                            ]}>
                            <UploadCoverImage
                                restaurantId={restaurantId}
                                restaurantDetails={restaurantDetails}
                                setRestaurantDetails={setRestaurantDetails}
                            />
                            <Popover
                                className="iconSpacing"
                                placement="right"
                                title={tooltipImageCover.title}
                                content={tooltipImageCover.text}
                                trigger="hover">
                                <InfoCircleOutlined />
                            </Popover>
                        </Form.Item>
                    </Panel>
                    <Panel header="Vizibilitate" key="visibility">
                        <Form.Item name="implementationMode">
                            <Checkbox
                                checked={restaurantDetails.implementationMode}
                                disabled={restaurantDetails.active ? true : false}
                                onChange={(event) => onChangeRestaurantCheckbox(event, 'implementationMode')}>
                                In implementare
                            </Checkbox>
                            <Popover
                                className="iconSpacing"
                                placement="right"
                                title={tooltipImplementationMode.title}
                                content={tooltipImplementationMode.text}
                                trigger="hover">
                                <InfoCircleOutlined />
                            </Popover>
                        </Form.Item>

                        <Form.Item name="temporarilyClosed">
                            <Checkbox
                                checked={restaurantDetails.temporarilyClosed}
                                disabled={restaurantDetails.implementationMode ? true : false}
                                onChange={(event) => onChangeRestaurantCheckbox(event, 'temporarilyClosed')}>
                                Inchis temporar
                            </Checkbox>
                        </Form.Item>

                        <Form.Item name="active">
                            <Checkbox
                                disabled={restaurantDetails.implementationMode ? true : false}
                                checked={restaurantDetails.implementationMode ? false : restaurantDetails.active}
                                onChange={(event) => onChangeRestaurantCheckbox(event, 'active')}>
                                Activ
                            </Checkbox>
                        </Form.Item>
                    </Panel>
                    <Panel header="Localizare" key="3" forceRender>
                        <Form.Item name="Map" label="Harta">
                            <MapMarker
                                setRestaurantDetails={setRestaurantDetails}
                                setForm={formRestaurant.setFieldsValue}
                                restaurantDetails={restaurantDetails}
                                cityList={cityList}
                            />
                        </Form.Item>
                        <Form.Item
                            name="latitude"
                            label="Latitudine"
                            hidden
                            rules={[
                                {
                                    required: true,
                                    message: 'Camp obligatoriu',
                                },
                            ]}>
                            <Input
                                size="large"
                                disabled={true}
                                placeholder="Latitudine"
                                onBlur={onChangeRestaurant}
                                name="latitude"
                                value={restaurantDetails.latitude}
                            />
                        </Form.Item>
                        <Form.Item
                            name="longitude"
                            label="Longitudine"
                            hidden
                            rules={[
                                {
                                    required: true,
                                    message: 'Camp obligatoriu',
                                },
                            ]}>
                            <Input
                                size="large"
                                placeholder="Longitudine"
                                disabled={true}
                                onBlur={onChangeRestaurant}
                                name="longitude"
                                value={restaurantDetails.longitude}
                            />
                        </Form.Item>
                    </Panel>
                    <Panel header="Program" key="program">
                        <FormSchedule
                            scheduleList={scheduleList}
                            setScheduleList={setScheduleList}
                            data={restaurantDetails}
                            form={formRestaurant}
                            type="restaurant"
                            drawerVisible={drawerVisible}
                            restaurantId={restaurantId}
                        />
                    </Panel>

                    <Panel header="Afisare meniu" key="afisaremeniu" forceRender>
                        <Form.Item
                            name="defaultCategoryType"
                            label={
                                <>
                                    <span className="labelIcon">Tip categorie implicita</span>
                                </>
                            }
                            rules={[
                                {
                                    required: false,
                                    message: 'Camp obligatoriu',
                                },
                            ]}>
                            <Select
                                size="large"
                                placeholder="Selecteaza tip"
                                onChange={onChangeDefaultCategoryType}
                                name="defaultCategoryType"
                                value={restaurantDetails.defaultCategoryType}
                                defaultValue="food">
                                <Option key="food" value="food">
                                    Mancare
                                </Option>
                                <Option key="beverages" value="beverages">
                                    Bauturi
                                </Option>
                                <Option key="tobacco" value="tobacco">
                                    Tutun
                                </Option>
                            </Select>
                        </Form.Item>
                    </Panel>
                    <Panel header="Integrare livratori" key="delivery">
                        <FormGeneralSettings
                            form={formRestaurant}
                            restaurantId={restaurantId}
                            restaurantDetails={restaurantDetails}
                            setRestaurantDetails={setRestaurantDetails}
                            generalSettings={generalSettings}
                            setGeneralSettings={setGeneralSettings}
                            onSaveRestaurantDetails={onSaveRestaurantDetails}
                            setRefreshData={setRefreshData}
                        />

                    </Panel>
                    <Panel header="Integrare POS" key="integrare">
                        <Form.Item name="activatePosIntegration">
                            <Checkbox
                                checked={restaurantDetails.activatePosIntegration}
                                onChange={(event) => onChangeRestaurantCheckbox(event, 'activatePosIntegration')}>
                                Activ
                            </Checkbox>
                        </Form.Item>
                        {restaurantDetails.activatePosIntegration ? (
                            <FormIntegrationSettings
                                form={formRestaurant}
                                restaurantId={restaurantId}
                                restaurantDetails={restaurantDetails}
                                setRestaurantDetails={setRestaurantDetails}
                                integrationSettings={integrationSettings}
                                setIntegrationSettings={setIntegrationSettings}
                                onSaveRestaurantDetails={onSaveRestaurantDetails}
                                setRefreshData={setRefreshData}
                            />
                        ) : null}
                    </Panel>
                </Collapse>

                {props.isDrawer === false ? (
                    <Button type="primary" className="btnAdd" onClick={() => onSaveRestaurantDetails(false)}>
                        Salveaza
                    </Button>
                ) : (
                    ''
                )}
            </Form>
        </>
    );
}

export default RestaurantForm;
