const columns = [
    {
        title: 'Nume',
        dataIndex: 'name',
        key: 'name'
    },
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'Restaurant',
        dataIndex: 'restaurant',
        key: 'restaurant',
    }

];

const data = [
    {
        name: 'Meniu principal',
        restaurant: 'Soft Bistro',
        id: 1
    }
];

export { columns, data };

