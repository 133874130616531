import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Spin, Button, Row, Col, Popover, Tooltip } from 'antd';
import { DownloadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useRecoilState } from 'recoil';
import { activeRestaurantState } from '../../../../recoil';
import QRCode from 'qrcode.react';
import { appIcon } from '../../../../const';
import * as htmlToImage from 'html-to-image';
import wifiIcon from '../../../../assets/img/wifi-icon.png';
import placecardThumb from '../../../../assets/img/placecard-qr-thumbnail.png';
import placecardThumbEn from '../../../../assets/img/placecard-qr-thumbnail_en.png';
import { ApiHelpers } from '../../../../helpers';

function debugBase64(base64URL) {
    var win = window.open();
    win.document.write(
        '<iframe src="' +
            base64URL +
            '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
    );
}

const TablePlacecard = (props) => {
    const { value, tableIndex, selectedHotspot } = props;
    const [generatedQR, setGeneratedQR] = useState('');
    const [isLoading, setIsloading] = useState(false);
    const [activeRestaurant, setActiveRestaurant] = useRecoilState(activeRestaurantState);
    const isSelfService = activeRestaurant.sysRestaurantType === 'SelfService';
    const ref = useRef(null);
    const refEN = useRef(null);

    const tooltipHotspot = {
        title: '',
        text: <div>Fisierul este pregatit pentru print. Dimensiune A6 cu bleed de 4mm pe toate laturile</div>,
    };

    const QRCodeMarkup = (
        <QRCode
            value={value}
            size={460}
            level="Q"
            fgColor="#292333"
            renderAs="svg"
            imageSettings={{
                //src: activeRestaurant.logoPath ? ApiHelpers.getImageLink(activeRestaurant.logoPath) : appIcon,
                src: appIcon,
                width: 150,
                height: 150,
                excavate: true,
            }}
        />
    );

    // useEffect(() => {

    //     setTimeout(function () {
    //         var node = document.getElementById('qrCodeToImg');
    //         htmlToImage.toPng(node, { height: 1844, width: 1336 })
    //             .then(function (dataUrl) {
    //                 var img = new Image();
    //                 img.src = dataUrl;
    //                 //document.body.appendChild(img);
    //                 setGeneratedQR(dataUrl);
    //                 setIsloading(false);
    //             })
    //             .catch(function (error) {
    //                 console.error('oops, something went wrong!', error);
    //             });
    //     }, 3000);

    // }, [value]);

    const handleDownload = useCallback(
        (ref, language) => {
            if (ref.current === null) {
                return;
            }
            setIsloading(true);
            htmlToImage
                .toPng(ref.current, { cacheBust: true, height: 1844, width: 1336 })
                .then(function (dataUrl) {
                    var img = new Image();
                    img.src = dataUrl;
                    //document.body.appendChild(img);
                    setGeneratedQR(dataUrl);
                    const link = document.createElement('a');
                    link.download = language === 'ro' ? 'placecard-masa-' + tableIndex + '-fata' : 'placecard-masa-' + tableIndex + '-verso';
                    link.href = dataUrl;
                    link.click();
                    setIsloading(false);
                })
                .catch(function (error) {
                    console.error('oops, something went wrong!', error);
                });
        },
        [ref]
    );

    return (
        <div className="qrCodeTemplateContainer">
            <Row>
                <Col span={12}>
                    <h3>
                        Placecard format A6 - fata{' '}
                        <Popover placement="right" title={tooltipHotspot.title} content={tooltipHotspot.text} trigger="hover">
                            <InfoCircleOutlined />
                        </Popover>
                    </h3>
                    <div className="qrCodeToImg" ref={ref}>
                        <div className="qrCodeTemplate">
                            <div className="qrCodeTemplateValue">{QRCodeMarkup}</div>

                            <div className="qrCodeTemplateTableIndex">
                                {activeRestaurant.title} {!isSelfService ? ' - Masa ' + tableIndex : null}
                            </div>
                            <div className="qrCodeTemplateQRImage">
                                <img src={appIcon} />
                            </div>
                            {selectedHotspot ? (
                                <div className="qrCodeTemplateHotspot">
                                    <img src={wifiIcon} className="hotspotIcon" />
                                    <div className="hotspotSsid">
                                        <div className="labelText">Retea Wi-Fi</div>
                                        {selectedHotspot.ssid}
                                    </div>
                                    {selectedHotspot.networkPassword ? (
                                        <div className="hotspotSsid">
                                            <div className="labelText">Parola Wi-Fi</div>
                                            {selectedHotspot.networkPassword}
                                        </div>
                                    ) : null}
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <p>
                        <a href="#" onClick={() => handleDownload(ref, 'ro')}>
                            <img src={placecardThumb} className="placecardThumbnail" title="Placecard fata" />
                        </a>
                    </p>
                    <Button onClick={() => handleDownload(ref, 'ro')} icon={<DownloadOutlined />}>
                        Descarca
                    </Button>
                </Col>
                <Col span={12}>
                    <h3>
                        Placecard format A6 - verso engleza{' '}
                        <Popover placement="right" title={tooltipHotspot.title} content={tooltipHotspot.text} trigger="hover">
                            <InfoCircleOutlined />
                        </Popover>
                    </h3>
                    <div className="qrCodeToImg" ref={refEN}>
                        <div className="qrCodeTemplate qrCodeTemplateEN">
                            <div className="qrCodeTemplateValue">{QRCodeMarkup}</div>
                            <div className="qrCodeTemplateTableIndex">
                                {activeRestaurant.title} {!isSelfService ? ' - Table ' + tableIndex : null}
                            </div>
                            <div className="qrCodeTemplateQRImage">
                                <img src={appIcon} />
                            </div>
                            {selectedHotspot ? (
                                <div className="qrCodeTemplateHotspot">
                                    <img src={wifiIcon} className="hotspotIcon" />
                                    <div className="hotspotSsid">
                                        <div className="labelText">Wi-Fi network</div>
                                        {selectedHotspot.ssid}
                                    </div>
                                    {selectedHotspot.networkPassword ? (
                                        <div className="hotspotSsid">
                                            <div className="labelText">Wi-Fi password</div>
                                            {selectedHotspot.networkPassword}
                                        </div>
                                    ) : null}
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <p>
                        <a href="#" onClick={() => handleDownload(refEN, 'en')}>
                            <img src={placecardThumbEn} className="placecardThumbnail" title="Placecard verso" />
                        </a>
                    </p>
                    <Button onClick={() => handleDownload(refEN, 'en')} icon={<DownloadOutlined />}>
                        Descarca
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default TablePlacecard;
