import useSWR from 'swr';
import axios from 'axios';
import { bacchusAPIEndpoint } from '../config';

const fetcher = (url) => axios.get(url).then((res) => res.data);

function useRestaurantById(id) {
    const { data, error } = useSWR(bacchusAPIEndpoint + '/admin/restaurant/' + id, fetcher);
    return {
        data,
        isLoading: !error && !data,
        isError: error,
    };
}

function useRestaurantDetailsById(id) {
    const { data, error } = useSWR(id ? bacchusAPIEndpoint + '/admin/restaurant/' + id : null, fetcher, {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
    });
    return {
        data,
        isLoading: !error && !data,
        isError: error,
    };
}

function useRestaurantDetailsPublicById(id) {
    const { data, error } = useSWR(bacchusAPIEndpoint + '/api/menu/' + id, fetcher);
    return {
        data,
        isLoading: !error && !data,
        isError: error,
    };
}

function useRestaurant(id) {
    const { data: company } = useSWR(id ? bacchusAPIEndpoint + '/admin/company/getByUuid/' + id : null, fetcher, {
        onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
            // Only retry up to 10 times.
            if (retryCount >= 3) return;

            // Retry after 5 seconds.
            setTimeout(() => revalidate({ retryCount }), 5000);
        },
        shouldRetryOnError: false,
    });
    const { data, error } = useSWR(() => bacchusAPIEndpoint + '/admin/restaurant/getByCompany/' + company.uniqueIdentificationToken, fetcher);
    return {
        data,
        isLoading: !error && !data,
        isError: error,
    };
}

function useMenusByRestaurant(uniqueIdentificationToken) {
    const { data, error } = useSWR(
        uniqueIdentificationToken ? bacchusAPIEndpoint + '/admin/menu/getByRestaurant/' + uniqueIdentificationToken : null,
        fetcher
    );
    return {
        data: data,
        isLoading: !error && !data,
        isError: error,
    };
}

function useMenuById(restaurantIdentificationToken, uniqueIdentificationToken) {
    const { data, error } = useSWR(
        uniqueIdentificationToken
            ? bacchusAPIEndpoint + '/admin/menu/getByUniqueCode/' + restaurantIdentificationToken + '/' + uniqueIdentificationToken
            : null,
        fetcher
    );
    return {
        data,
        isLoading: !error && !data,
        isError: error,
    };
}

// Restaurant Menu items
function useMenuItemsByRestaurant(uniqueIdentificationToken) {
    const { data, error } = useSWR(
        uniqueIdentificationToken ? bacchusAPIEndpoint + '/admin/menu/getMenuItemsByRestaurant/' + uniqueIdentificationToken : null,
        fetcher
    );
    return {
        data,
        isLoading: !error && !data,
        isError: error,
    };
}

// Restaurant Tables
function useTablesByRestaurant(uniqueIdentificationToken) {
    const { data, error } = useSWR(
        uniqueIdentificationToken ? bacchusAPIEndpoint + '/admin/restaurant/table/getRestaurantTables/' + uniqueIdentificationToken : null,
        fetcher
    );
    return {
        data,
        isLoading: !error && !data,
        isError: error,
    };
}

// Restaurant events
function useEventsByRestaurant(uniqueIdentificationToken, activeOnly = false) {
    const { data, error } = useSWR(bacchusAPIEndpoint + '/admin/restaurant/getEvents/' + uniqueIdentificationToken + '/' + activeOnly, fetcher);
    return {
        data,
        isLoading: !error && !data,
        isError: error,
    };
}

// Restaurant offers / promotions
function usePromotionsByRestaurant(uniqueIdentificationToken, activeOnly = false) {
    const { data, error } = useSWR(bacchusAPIEndpoint + '/admin/restaurant/getPromotions/' + uniqueIdentificationToken + '/' + activeOnly, fetcher);
    return {
        data,
        isLoading: !error && !data,
        isError: error,
    };
}

export {
    useRestaurant,
    useRestaurantById,
    useMenusByRestaurant,
    useRestaurantDetailsPublicById,
    useRestaurantDetailsById,
    useMenuItemsByRestaurant,
    useTablesByRestaurant,
    useEventsByRestaurant,
    usePromotionsByRestaurant,
    useMenuById,
};
