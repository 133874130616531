import React, { useState, useEffect } from 'react';
import { Table, Input, Form, Button, Alert, Collapse, Skeleton, Row, Col, Switch, Space, message } from 'antd';
import { ImportOutlined } from '@ant-design/icons';
import { useRecoilState } from 'recoil';
import { ApiHelpers } from '../../../helpers';
import { useNavigate } from 'react-router-dom';
import { appIcon } from '../../../const';
import { activeRestaurantState } from '../../../recoil';
import UploadCustomizationImageQR from './UploadCustomizationImageQR';
import QRCodeCustomPreview from './Tables/QRCodeCustomPreview';

const { Column } = Table;
const { Panel } = Collapse;
const Search = Input.Search;


const RestaurantQRCode = (props) => {
    const [form] = Form.useForm();
    const [newElement, setNewElement] = useState([]);
    const [actionType, setActionType] = useState(props.actionType);
    const [activeRestaurant, setActiveRestaurant] = useRecoilState(activeRestaurantState);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [customization, setCustomization] = useState({
        uniqueIdentificationToken: '',
        backgroundColour: '#C32E32',
        qrImagePath: null,
        showAllActions: activeRestaurant.subscriptionPlan === 'Free' ? false : true,
        textColour: '',
        textColourQR: '',
        primaryColour: '',
        primaryColourQR: '',
        accentColour: '',
        backgroundImage: false,
        backgroundImageQR: false,
        restaurantToken: props.restaurantId,
    });

    const INITIAL_STATE_ELEMENT = {
        uniqueIdentificationToken: null,
        ssid: null,
        networkPassword: null,
        restaurantToken: props.restaurantId,
    };

    useEffect(() => {
        // Get Restaurant by Id
        if (activeRestaurant && activeRestaurant.uniqueIdentificationToken) {
            setIsLoading(true);
            ApiHelpers.getRestaurantCustomization(activeRestaurant.uniqueIdentificationToken).then(
                (response) => {
                    if (!response.data.message) {
                        setActionType('edit');
                        setCustomization({ ...customization, ...response.data });
                        setIsLoading(false);
                    } else {
                        setActionType('new');
                        setIsLoading(false);
                    }
                },
                (error) => {
                    console.log(error);
                    setActionType('new');
                    setIsLoading(false);
                }
            );
        }
    }, [activeRestaurant]);

    const onSave = () => {
        // Validate form
        form.validateFields()
            .then(() => {
                if (actionType === 'new') {
                    ApiHelpers.postRestaurantCustomization({
                        ...customization,
                    }).then(
                        (response) => {
                            message.success({ content: 'Modificări salvate!', key: 'statusMessage', duration: 2 });
                            navigate('#list');
                        },
                        (error) => {
                            message.error('Eroare!');
                        }
                    );
                } else if (actionType === 'edit') {
                    ApiHelpers.updateRestaurantCustomization(customization.uniqueIdentificationToken, {
                        ...customization,
                    }).then(
                        (response) => {
                            message.success({ content: 'Modificări salvate!', key: 'statusMessage', duration: 2 });
                            navigate('#list');
                        },
                        (error) => {
                            message.error('Eroare!');
                        }
                    );
                }
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    };

    const onChangeCheckbox = (field) => {
        setCustomization({
            ...customization,
            qrImagePath: activeRestaurant.coverImagePath ? activeRestaurant.coverImagePath : null,
        });
    };

    const onChange = (event) => {
        setNewElement({
            ...newElement,
            [event.target.name]: event.target.value,
        });
    };

    const onClickColor = (color) => {
        setCustomization({ ...customization, backgroundColour: color });
    };
    const onChangeSwitch = (checked, field) => {
        if (field === 'text') setCustomization({ ...customization, showAllActions: checked });
        if (field === 'imgBg')
            setCustomization({
                ...customization,
                qrImagePath: checked === true ? (activeRestaurant.coverImagePath ? activeRestaurant.coverImagePath : null) : null,
            });
    };

    return (
        <>
            {' '}
            {isLoading || !activeRestaurant.title ? (
                <Skeleton active></Skeleton>
            ) : (
                <>
                    <Row gutter={[25, 25]}>
                        <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                            <h3>Opțiuni</h3>
                            <Alert
                                showIcon
                                description={
                                    <>
                                        Codurile QR sunt disponibile în secțiunea <a href="#list">'Toate'</a>, de unde vor putea fi descărcate
                                        individual pentru fiecare masă. În exemplul prezentat în previzualizare, 10 este numărul mesei.
                                    </>
                                }
                                type="info"
                                className="spacingBottom"
                            />
                            <Form layout="vertical" form={form} size="large">
                                <Form.Item name="active" rules={[{ required: false, message: '' }]}>
                                    <h4>Culori</h4>

                                    <div className="customize-QR-group">
                                        <span
                                            className={
                                                'color-picker-QR accent-white' +
                                                (customization.backgroundColour === '#FFFFFF' ? ' accent-active' : '')
                                            }
                                            onClick={() => onClickColor('#FFFFFF')}></span>
                                        <span
                                            className={
                                                'color-picker-QR accent-beige' +
                                                (customization.backgroundColour === '#F6EFD6' ? ' accent-active' : '')
                                            }
                                            onClick={() => onClickColor('#F6EFD6')}></span>
                                        <span
                                            className={
                                                'color-picker-QR accent-gray' + (customization.backgroundColour === '#7f7f7f' ? ' accent-active' : '')
                                            }
                                            onClick={() => onClickColor('#7f7f7f')}></span>
                                        <span
                                            className={
                                                'color-picker-QR accent-yellow' +
                                                (customization.backgroundColour === '#F3D11B' ? ' accent-active' : '')
                                            }
                                            onClick={() => onClickColor('#F3D11B')}></span>
                                        <span
                                            className={
                                                'color-picker-QR accent-red' + (customization.backgroundColour === '#C32E32' ? ' accent-active' : '')
                                            }
                                            onClick={() => onClickColor('#C32E32')}></span>
                                        <span
                                            className={
                                                'color-picker-QR accent-green' +
                                                (customization.backgroundColour === '#125E39' ? ' accent-active' : '')
                                            }
                                            onClick={() => onClickColor('#125E39')}></span>
                                        <span
                                            className={
                                                'color-picker-QR accent-blue' + (customization.backgroundColour === '#0F4C85' ? ' accent-active' : '')
                                            }
                                            onClick={() => onClickColor('#0F4C85')}></span>
                                        <span
                                            className={
                                                'color-picker-QR accent-magenta' +
                                                (customization.backgroundColour === '#9010A5' ? ' accent-active' : '')
                                            }
                                            onClick={() => onClickColor('#9010A5')}></span>
                                        <span
                                            className={
                                                'color-picker-QR accent-black' +
                                                (customization.backgroundColour === '#000000' ? ' accent-active' : '')
                                            }
                                            onClick={() => onClickColor('#000000')}></span>
                                    </div>
                                </Form.Item>
                                {activeRestaurant.subscriptionPlan !== 'Free' ? (
                                    <Space direction="vertical" size={[15, 15]}>
                                        <div>
                                            <h4>Imagine fundal</h4>
                                            <div>
                                                <Space direction="horizontal" align="center">
                                                    <UploadCustomizationImageQR
                                                        restaurantId={props.restaurantId}
                                                        customization={customization}
                                                        setCustomization={setCustomization}
                                                    />
                                                    sau
                                                    <a onClick={() => onChangeCheckbox('active')}>
                                                        <ImportOutlined /> Preia imagine cover ca fundal
                                                    </a>
                                                </Space>
                                            </div>
                                        </div>
                                        <div>
                                            <Switch
                                                defaultChecked
                                                onChange={(checked) => onChangeSwitch(checked, 'text')}
                                                checked={customization.showAllActions}
                                            />
                                            Afișează toate acțiunile
                                        </div>
                                    </Space>
                                ) : null}
                            </Form>
                            <div className="spacingTop"></div>
                            <Button size="large" onClick={onSave} type="primary">
                                Salvează
                            </Button>
                        </Col>
                        {}
                        <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                            <h3>Previzualizare</h3>
                            <QRCodeCustomPreview customization={customization} isStaticPreview={true} />
                        </Col>
                    </Row>
                </>
            )}
        </>
    );
};

export default RestaurantQRCode;
