import React, { useState, useEffect, useRef } from 'react';
import { Table, Input, Form, Drawer, Button, message, Checkbox, Select, Popconfirm, Alert } from 'antd';
import { PlusOutlined, EditOutlined, CheckOutlined } from '@ant-design/icons';
import { ApiHelpers } from '../../../helpers';
import MenuItemGroupItem from './MenuItemGroupItem';
import { useNavigate, useLocation } from 'react-router-dom';

const { Column } = Table;
const Search = Input.Search;
const { Option } = Select;

const MenuItemGroupForm = (props) => {
    const [form] = Form.useForm();
    const [formItems] = Form.useForm();
    const [menuItemGroups, setMenuItemGroups] = useState([]);
    const [menuItemGroupsFilter, setMenuItemGroupsFilter] = useState([]);
    const [newElement, setNewElement] = useState([]);
    const [actionType, setActionType] = useState(props.actionType);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [menuItems, setMenuItems] = useState([]);
    const [menuItemFormList, setMenuItemFormList] = useState([]);
    const [menuItemMarkupList, setMenuItemMarkupList] = useState([]);
    const routeLocation = useLocation();

    const navigate = useNavigate();
    const ref = useRef(menuItemFormList);

    const INITIAL_STATE_ELEMENT = {
        uniqueIdentificationToken: null,
        groupName: '',
        displayGroupName: '',
        selectionRequired: false,
        restaurantToken: props.restaurantId,
    };

    const getMenuAssets = () => {
        // Get Menu item groups by restaurant
        ApiHelpers.getMenuItemGroup(props.restaurantId).then(
            (response) => {
                setMenuItemGroups(response.data);
                setMenuItemGroupsFilter(response.data);
            },
            (error) => {
                console.log(error);
            }
        );

        // Get Menu Items by restaurant
        ApiHelpers.getMenuItemsByRestaurant(props.restaurantId).then(
            (response) => {
                setMenuItems(response.data);
            },
            (error) => {
                console.log(error);
            }
        );
    };

    useEffect(() => {
        getMenuAssets();
    }, []);

    useEffect(() => {
        if (routeLocation.hash.indexOf('/menuItemGroup/') > -1) {
            const hashMenuItem = routeLocation.hash.substring(routeLocation.hash.indexOf('menuItemGroup') + 29);
            const foundMenuItem = menuItemGroups.find((d) => d.uniqueIdentificationToken === hashMenuItem);
            if (foundMenuItem) {
                onEditElement(foundMenuItem);
            }
        }
    }, [routeLocation, menuItemGroups]);

    const onClose = () => {
        setDrawerVisible(false);
        onReset();
    };

    const onReset = () => {
        form.resetFields();
        navigate('#menuItemGroups');
        setMenuItemFormList([]);
        setMenuItemMarkupList([]);
        setNewElement([]);
    };

    const onSave = () => {
        // Validate form
        form.validateFields()
            .then(() => {
                formItems
                    .validateFields()
                    .then(() => {
                        if (actionType === 'new') {
                            ApiHelpers.postMenuItemGroup({
                                ...newElement,
                                items: [...menuItemFormList],
                            }).then(
                                (response) => {
                                    const newList = menuItemGroups.concat(response.data.menuItemGroup);

                                    setMenuItemGroups(newList);
                                    setMenuItemGroupsFilter(newList);
                                    message.success('Element adaugat!');
                                },
                                (error) => {
                                    console.log(error);
                                    message.error('Eroare!');
                                }
                            );
                        } else if (actionType === 'edit') {
                            ApiHelpers.updateMenuItemGroup({
                                ...newElement,
                                items: [...menuItemFormList],
                            }).then(
                                (response) => {
                                    if (response.data.message) {
                                        message.error('Eroare!');
                                    } else {
                                        const elementsIndex = menuItemGroups.findIndex(
                                            (element) => element.uniqueIdentificationToken === newElement.uniqueIdentificationToken
                                        );
                                        let newData = [...menuItemGroups];
                                        newData[elementsIndex] = { ...newElement, items: [...menuItemFormList] };
                                        setMenuItemGroups(newData);
                                        setMenuItemGroupsFilter(newData);
                                        message.success('Element modificat!');
                                    }
                                },
                                (error) => {
                                    console.log(error);
                                    message.error('Eroare!');
                                }
                            );
                        }
                        onReset();
                        setDrawerVisible(false);
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    };
    const onChange = (event) => {
        setNewElement({
            ...newElement,
            [event.target.name]: event.target.value,
        });
    };

    const onChangeCheckbox = (event, field) => {
        setNewElement({
            ...newElement,
            [field]: event.target.checked,
        });
    };

    const refreshForm = (data) => {
        data.map((d, index) => {
            const priceName = 'price-' + index;
            const menuItem = 'menuItem-' + index;
            formItems.setFieldsValue({
                [menuItem]: d.menuItemToken,
                [priceName]: d.price,
            });
        });
    };

    const resetForm = (data) => {
        formItems.resetFields();
    };

    const onAddElement = () => {
        setActionType('new');
        setNewElement({
            ...INITIAL_STATE_ELEMENT,
        });
        setDrawerVisible(true);
    };

    const onEditElement = (record) => {
        setActionType('edit');

        if (routeLocation.hash.indexOf('#menuItemGroups/menuItemGroup/') === -1) {
            navigate('#menuItemGroups/menuItemGroup/' + record.uniqueIdentificationToken);
        }

        // Set form fields values
        const editObject = {
            uniqueIdentificationToken: record.uniqueIdentificationToken,
            restaurantToken: record.restaurantToken,
            groupName: record.groupName,
            displayGroupName: record.displayGroupName,
            selectionRequired: record.selectionRequired,
            items: record.items,
            menuCategoryToken: record.menuCategoryToken,
        };

        form.setFieldsValue({
            ...editObject,
        });

        // Update state with the element being edited
        setNewElement({ ...editObject });

        // Populate list of menu items
        if (record.items) {
            setMenuItemFormList([...record.items]);
            refreshForm(record.items);
        }

        setDrawerVisible(true);
    };

    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        if (value) {
            setMenuItemGroups(
                menuItemGroupsFilter.filter((item) => {
                    if (item.groupName.toLowerCase().indexOf(value) > -1 || item.displayGroupName.toLowerCase().indexOf(value) > -1) return true;
                })
            );
        } else setMenuItemGroups(menuItemGroupsFilter);
    };

    const addMenuItemFormCombo = () => {
        ref.current = [
            ...menuItemFormList,
            {
                menuItemToken: null,
                price: null,
                restaurantToken: props.restaurantId,
            },
        ];
        setMenuItemFormList((menuItemFormList) => [
            ...menuItemFormList,
            {
                menuItemToken: null,
                price: null,
                restaurantToken: props.restaurantId,
            },
        ]);
    };

    const onDelete = () => {
        ApiHelpers.deleteMenuItemGroup(newElement.uniqueIdentificationToken, props.restaurantId).then(
            (response) => {
                if (response.data.statusCode.statusCode === 200) {
                    const newList = menuItemGroups.filter(function (obj) {
                        return obj.uniqueIdentificationToken !== newElement.uniqueIdentificationToken;
                    });
                    setMenuItemGroups(newList);
                    setMenuItemGroupsFilter(newList);
                    message.success('Elementul a fost sters!');
                }
            },
            (error) => {
                console.log(error);
                message.error('Eroare!');
            }
        );
        onReset();
        setDrawerVisible(false);
    };

    const refreshCategoryAsMenuItemGroup = () => {
        ApiHelpers.putMenuCategoryAsMenuItemGroup(props.restaurantId, newElement.menuCategoryToken, true).then(
            (response) => {
                if (response.data.message) {
                    message.error('Eroare sursa de date.');
                } else {
                    message.success('Date actualizate!');
                    getMenuAssets();
                    setDrawerVisible(false);
                }
            },
            (error) => {
                console.log(error);
                message.error('Eroare!');
            }
        );
    };

    return (
        <>
            <Button type="primary" onClick={() => onAddElement()} className="btnAdd">
                <PlusOutlined /> Adauga grup
            </Button>
            <Search placeholder="Cauta" onChange={handleSearch} className="tableSearchField" />
            <Table dataSource={menuItemGroups} pagination={false} rowKey="uniqueIdentificationToken" scroll={{ x: 240 }}>
                <Column title="Nume intern" width="250px" dataIndex="groupName" />
                <Column
                    title="Actiuni"
                    key="actions"
                    render={(text, record) => (
                        <Button type="default" className="tableAction" icon={<EditOutlined />} onClick={() => onEditElement(record)}>
                            Editeaza
                        </Button>
                    )}
                />

                <Column title="Nume afisat" dataIndex="displayGroupName" />
                <Column title="Numar preparate" render={(text, record) => record.items.length} />

                <Column
                    title="Selectie obligatorie"
                    dataIndex="selectionRequired"
                    render={(text, record) => (record.selectionRequired === true ? <CheckOutlined /> : '')}
                />
                <Column title="Categorie" render={(text, record) => (record.menuCategoryToken ? <CheckOutlined /> : '')} />
            </Table>
            <Drawer
                title="Grup auxiliare"
                width={window.innerWidth > 1000 ? 1000 : window.innerWidth}
                onClose={onClose}
                visible={drawerVisible}
                bodyStyle={{ paddingBottom: 80 }}
                footer={
                    <div className="modalFooter">
                        <div
                            style={{
                                textAlign: 'left',
                            }}>
                            {actionType !== 'new' ? (
                                <Popconfirm title="Sigur vrei sa stergi?" onConfirm={onDelete}>
                                    <Button size="large" danger style={{ marginRight: 8 }}>
                                        Sterge
                                    </Button>
                                </Popconfirm>
                            ) : null}
                        </div>
                        <div
                            style={{
                                textAlign: 'right',
                            }}>
                            <Button size="large" onClick={onClose} style={{ marginRight: 8 }}>
                                Anuleaza
                            </Button>
                            <Button size="large" onClick={onSave} type="primary">
                                Salveaza
                            </Button>
                        </div>
                    </div>
                }>
                {newElement.menuCategoryToken ? (
                    <Alert
                        showIcon
                        className="spacingBottom"
                        message={
                            <>
                                Acest grup este legat de o categorie de meniu.{' '}
                                <a href="#" title="" onClick={refreshCategoryAsMenuItemGroup}>
                                    Preia datele si sincronizeaza
                                </a>
                            </>
                        }
                        type="warning"
                    />
                ) : null}
                <Form layout="vertical" form={form} size="large" scroll={{ x: 240 }}>
                    <Form.Item name="groupName" label="Nume intern" rules={[{ required: true, message: 'Camp obligatoriu' }]}>
                        <Input size="large" placeholder="Nume grup" onBlur={onChange} name="groupName" value={newElement.groupName} />
                    </Form.Item>
                    <Form.Item name="displayGroupName" label="Nume afisat" rules={[{ required: false }]}>
                        <Input size="large" placeholder="Nume afisat" onBlur={onChange} name="displayGroupName" value={newElement.displayGroupName} />
                    </Form.Item>
                    <Checkbox checked={newElement.selectionRequired} onChange={(event) => onChangeCheckbox(event, 'selectionRequired')}>
                        Selectie obligatorie
                    </Checkbox>
                    <h3 className="spacingTop">Continut</h3>
                    <Button type="secondary" onClick={() => addMenuItemFormCombo()} className="btnAdd">
                        <PlusOutlined /> Adauga preparat
                    </Button>
                </Form>
                <Form layout="vertical" form={formItems} size="large" scroll={{ x: 240 }}>
                    {menuItemFormList.map((item, index) => {
                        return (
                            <MenuItemGroupItem
                                key={'menuItemGroupItem' + index}
                                item={item}
                                index={index}
                                restaurantId={props.restaurantId}
                                menuItems={menuItems}
                                menuItemFormList={menuItemFormList}
                                setMenuItemFormList={setMenuItemFormList}
                                form={formItems}
                                refreshForm={refreshForm}
                                resetForm={resetForm}
                            />
                        );
                    })}
                </Form>
            </Drawer>
        </>
    );
};

export default MenuItemGroupForm;
