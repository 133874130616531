import React, { useRef } from 'react';
import MenuPrint from '../Menu/MenuPrint';

const RestaurantPrintMenu = (props) => {
    const { restaurantTitle } = props;
    const pdfContent = useRef(null);

    return <MenuPrint restaurantTitle={restaurantTitle} />;
};

export default RestaurantPrintMenu;
