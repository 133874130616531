import React, { useState } from 'react';
import { message, Button, Upload, Menu, Dropdown, Modal } from 'antd';
import { ApiHelpers } from '../../../helpers';
import { UploadOutlined } from '@ant-design/icons';

import ImgCrop from 'antd-img-crop';

function UploadCoverImage(props) {
    const { restaurantId, restaurantDetails, setRestaurantDetails } = props;
    const [modalVisible, setModalVisible] = useState(false);
    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };

    const uploadPropsCoverImage = {
        name: 'file',
        action: ApiHelpers.uploadRestaurantCover(restaurantId),
        method: 'PUT',
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                //console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
                setRestaurantDetails({
                    ...restaurantDetails,
                    coverImagePath: info.file.response.coverImagePath,
                });
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onPreview: onPreview,
    };
    const onDeleteRestaurantCoverImage = () => {
        ApiHelpers.deleteRestaurantCoverImage(restaurantId).then(
            (response) => {
                setRestaurantDetails({
                    ...restaurantDetails,
                    coverImagePath: null,
                });
                message.success('Imagine stearsa!');
            },
            (error) => {
                console.log(error);
                message.error('Eroare!');
            }
        );
    };
    const menuCoverImage = (
        <Menu>
            <Menu.Item>
                <a onClick={onDeleteRestaurantCoverImage}>Sterge</a>
            </Menu.Item>
            <Menu.Item>
                <ImgCrop rotate aspect={4 / 2} minZoom={0.2} cropperProps={{ restrictPosition: false }}>
                    <Upload {...uploadPropsCoverImage}>
                        <a onClick={(e) => e.preventDefault()}>Modifica</a>
                    </Upload>
                </ImgCrop>
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            {restaurantDetails.coverImagePath ? (
                <Dropdown overlay={menuCoverImage}>
                    <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                        <img src={ApiHelpers.getImageLink(restaurantDetails.coverImagePath)} className="uploadedImage" />
                    </a>
                </Dropdown>
            ) : (
                <ImgCrop rotate aspect={4 / 2} modalTitle="Previzualizare">
                    <Upload {...uploadPropsCoverImage}>
                        <Button icon={<UploadOutlined />}>Upload</Button>
                    </Upload>
                </ImgCrop>
            )}
        </>
    );
}

export default UploadCoverImage;
