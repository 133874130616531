import React, { useState, useEffect, useContext } from 'react';
import { Table, Input, Form, Button, message, Tag, Drawer, Collapse, Select, Popconfirm } from 'antd';
import { CheckOutlined, ClockCircleOutlined, EditOutlined } from '@ant-design/icons';
import { ApiHelpers } from '../../../helpers';
import { useRecoilState } from 'recoil';
import { AuthUserContext, withAuthorization } from '../../Session';
import { activeRestaurantState } from '../../../recoil';
import moment from 'moment';

const { Column } = Table;
const { Panel } = Collapse;
const { Option } = Select;
const Search = Input.Search;

const RestaurantWaitersPending = (props) => {
    // Get AuthUser ID from Firebase
    const firebaseAuthUser = useContext(AuthUserContext);
    const [form] = Form.useForm();
    const [restaurantWaiters, setRestaurantWaiters] = useState([]);
    const [restaurantWaitersFilter, setRestaurantWaitersFilter] = useState([]);
    const [newElement, setNewElement] = useState([]);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [integrationOperators, setIntegrationOperators] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [actionType, setActionType] = useState('edit');

    const [activeRestaurant, setActiveRestaurant] = useRecoilState(activeRestaurantState);
    const INITIAL_STATE_ELEMENT = {
        tableIndex: null,
        uniqueIdentificationToken: null,
        restaurantToken: props.restaurantId,
    };

    const refreshData = () => {
        // Get Restaurant by Id
        ApiHelpers.getPendingValidationWaiters(props.restaurantId).then(
            (response) => {
                if (!response.data.message) {
                    setRestaurantWaiters(response.data);
                    setRestaurantWaitersFilter(response.data);
                }
            },
            (error) => {
                console.log(error);
            }
        );
    };

    useEffect(() => {
        ApiHelpers.getIntegrationOperators(props.restaurantId).then(
            (response) => {
                setIntegrationOperators(response.data);
                setIsLoading(false);
            },
            (error) => {
                console.log(error);
            }
        );
    }, [props.restaurantId]);

    useEffect(() => {
        refreshData();
    }, []);

    const onReset = () => {
        form.resetFields();
        setNewElement([]);
    };

    const onDelete = () => {
        ApiHelpers.deleteWaiter(props.restaurantId, newElement).then(
            (response) => {
                message.success('Elementul a fost sters!');
                refreshData();
            },
            (error) => {
                console.log(error);
                message.error('Eroare!');
            }
        );
        onReset();
        setDrawerVisible(false);
    };

    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        if (value) {
            setRestaurantWaiters(
                restaurantWaitersFilter.filter((item) => {
                    if (item.name.toLowerCase().indexOf(value) > -1) return true;
                })
            );
        } else setRestaurantWaiters(restaurantWaitersFilter);
    };

    const onValidateWaiter = (record) => {
        const validationDetails = {
            uniqueIdentificationToken: record.uniqueIdentificationToken,
            uuid: record.uuid,
            validationUserToken: firebaseAuthUser.uid,
            restaurantToken: props.restaurantId,
        };
        ApiHelpers.validateWaiter(record.uniqueIdentificationToken, validationDetails).then(
            (response) => {
                if (response.data.message) message.error('Eroare! ' + response.data.message);
                else message.success('Validat cu succes!');
                refreshData();
            },
            (error) => {
                console.log(error);
                message.error('Eroare!');
            }
        );
    };

    const onEditElement = (record) => {
        setActionType('edit');
        // Set form fields values
        const editObject = {
            ...record,
        };

        form.setFieldsValue({
            ...editObject,
        });

        // Update state with the element being edited
        setNewElement({ ...editObject });
        setDrawerVisible(true);
    };

    const onClose = () => {
        setDrawerVisible(false);
        onReset();
    };

    const onChangeSelect = (value) => {
        setNewElement({
            ...newElement,
            integrationCode: value,
        });
    };

    const onSave = () => {
        // Validate form
        form.validateFields()
            .then(() => {
                if (actionType === 'edit') {
                    ApiHelpers.updateWaiter(newElement).then(
                        (response) => {
                            message.success('Element modificat!');
                        },
                        (error) => {
                            console.log(error);
                            message.error('Eroare!');
                        }
                    );
                    onClose();
                }
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    };

    return (
        <AuthUserContext.Consumer>
            {(authUser) => (
                <>
                    <Search placeholder="Dupa nume" onChange={handleSearch} className="tableSearchField" />
                    <Table
                        dataSource={restaurantWaiters}
                        pagination={false}
                        rowKey={(record) => record.uniqueIdentificationToken}
                        scroll={{ x: 240 }}>
                        <Column title="Nume" dataIndex="name" />
                        <Column
                            title="Actiuni"
                            render={(text, record) => (
                                <>
                                    <Button type="default" className="tableAction" icon={<EditOutlined />} onClick={() => onEditElement(record)}>
                                        Editează
                                    </Button>
                                    {record.validationPending ? (
                                        <Button
                                            type="default"
                                            className="tableAction"
                                            icon={<CheckOutlined />}
                                            onClick={() => onValidateWaiter(record)}>
                                            Valideaza
                                        </Button>
                                    ) : null}
                                </>
                            )}
                        />

                        <Column
                            title="Status validare"
                            render={(record) =>
                                !record.validationPending ? (
                                    <CheckOutlined />
                                ) : (
                                    <Tag icon={<ClockCircleOutlined />} color="orange">
                                        In asteptare
                                    </Tag>
                                )
                            }
                        />
                        <Column
                            title="Data validare"
                            render={(record) => (record.validationDateTime ? moment(record.validationDateTime).format('DD.MM.YYYY HH:mm:ss') : null)}
                        />
                        <Column title="Medie scor" align="right" dataIndex="score" />
                    </Table>
                    <Drawer
                        title="Staff"
                        width={window.innerWidth > 1000 ? 1000 : window.innerWidth}
                        onClose={onClose}
                        visible={drawerVisible}
                        bodyStyle={{ paddingBottom: 80 }}
                        footer={
                            <div className="modalFooter">
                                <div
                                    style={{
                                        textAlign: 'left',
                                    }}>
                                    {actionType !== 'new' ? (
                                        <Popconfirm title="Sigur vrei sa stergi?" onConfirm={onDelete}>
                                            <Button size="large" danger style={{ marginRight: 8 }}>
                                                Sterge
                                            </Button>
                                        </Popconfirm>
                                    ) : null}
                                </div>
                                <div
                                    style={{
                                        textAlign: 'right',
                                    }}>
                                    <Button size="large" onClick={onClose} style={{ marginRight: 8 }}>
                                        Anuleaza
                                    </Button>
                                    <Button size="large" onClick={onSave} type="primary">
                                        Salveaza
                                    </Button>
                                </div>
                            </div>
                        }>
                        <Form layout="vertical" form={form} size="large">
                            <p>{newElement.name}</p>
                            <Collapse className="formCollapse formDrawer" bordered={false} defaultActiveKey={['0']}>
                                <Panel header="Integrare POS" key="0">
                                    <Form.Item
                                        name="integrationCode"
                                        label={
                                            <>
                                                <span className="labelIcon">Asociere operator</span>
                                            </>
                                        }
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Camp optional',
                                            },
                                        ]}>
                                        <Select
                                            size="large"
                                            disabled={activeRestaurant.activatePosIntegration === true ? false : true}
                                            placeholder="Alege"
                                            onChange={onChangeSelect}
                                            name="integrationCode"
                                            value={newElement.integrationCode}
                                            showSearch
                                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            {integrationOperators.map((d) => {
                                                return (
                                                    <Option key={'integrationCode-' + d.code} value={d.code}>
                                                        {d.name}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Panel>
                            </Collapse>
                        </Form>
                    </Drawer>
                </>
            )}
        </AuthUserContext.Consumer>
    );
};

const condition = (authUser) => !!authUser;
export default withAuthorization(condition)(RestaurantWaitersPending);
