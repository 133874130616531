import React, { useState, useEffect } from 'react';
import { Table, Input, Form, Drawer, Button, message, Checkbox, Collapse, Select } from 'antd';
import { ApiHelpers, FormSchedule } from '../../../helpers';
import FormSchedules from '../../../helpers/FormSchedules';
import { useRecoilState, useRecoilValue } from 'recoil';
import { restaurantDetailsState } from '../../../recoil';

const { Column } = Table;
const { Panel } = Collapse;
const { Option } = Select;

function MenuForm(props) {
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [actionType, setActionType] = useState(props.actionType);
    const [menusList, setMenusList] = useState(props.menusList);
    const [menuTypes, setMenuTypes] = useState([]);
    const restaurantDetails = props.restaurantDetails;
    const { menuData } = props;
    const restaurantDetailsStateLocal = useRecoilValue(restaurantDetailsState);
    const restaurantId = props.restaurantId;
    const [formMenu] = Form.useForm();
    const [scheduleList, setScheduleList] = useState([]);
    const menuView = props.view;
    const [schedulesNew, setSchedulesNew] = useState();

    const timePickerFormat = 'HH:mm';

    const formLayout = {
        layout: 'horizontal',
        labelCol: { span: 3 },
        wrapperCol: { span: 9 },
        size: 'large',
        labelAlign: 'left',
    };

    const INITIAL_STATE_MENU = {
        restaurantIdentificationToken: restaurantId,
        uniqueIdentificationToken: null,
        startSchedule: '',
        endSchedule: '',
        menuName: '',
        active: false,
        note: null,
        availableOnMonday: false,
        availableOnTuesday: false,
        availableOnWednesday: false,
        availableOnThursday: false,
        availableOnFriday: false,
        availableOnSaturday: false,
        availableOnSunday: false,
        mondayStart: null,
        mondayEnd: null,
        tuesdayStart: null,
        tuesdayEnd: null,
        wednesdayStart: null,
        wednesdayEnd: null,
        thursdayStart: null,
        thursdayEnd: null,
        fridayStart: null,
        fridayEnd: null,
        saturdayStart: null,
        saturdayEnd: null,
        sundayStart: null,
        sundayEnd: null,
        menuIndex: 0,
        menuType: '',
    };
    const [newElementMenu, setNewElementMenu] = useState({ ...INITIAL_STATE_MENU });

    useEffect(() => {
        if (props.menuData) {
            console.log(props.menuData);
            setNewElementMenu({ ...props.menuData });

            setScheduleList({
                availableOnMonday: props.menuData.availableOnMonday,
                availableOnTuesday: props.menuData.availableOnTuesday,
                availableOnWednesday: props.menuData.availableOnWednesday,
                availableOnThursday: props.menuData.availableOnThursday,
                availableOnFriday: props.menuData.availableOnFriday,
                availableOnSaturday: props.menuData.availableOnSaturday,
                availableOnSunday: props.menuData.availableOnSunday,
                mondayStart: props.menuData.mondayStart,
                mondayEnd: props.menuData.mondayEnd,
                tuesdayStart: props.menuData.tuesdayStart,
                tuesdayEnd: props.menuData.tuesdayEnd,
                wednesdayStart: props.menuData.wednesdayStart,
                wednesdayEnd: props.menuData.wednesdayEnd,
                thursdayStart: props.menuData.thursdayStart,
                thursdayEnd: props.menuData.thursdayEnd,
                fridayStart: props.menuData.fridayStart,
                fridayEnd: props.menuData.fridayEnd,
                saturdayStart: props.menuData.saturdayStart,
                saturdayEnd: props.menuData.saturdayEnd,
                sundayStart: props.menuData.sundayStart,
                sundayEnd: props.menuData.sundayEnd,
                restaurantToken: restaurantId,
            });

            setSchedulesNew({ ...props.menuData.schedules });

            // Set form fields values
            formMenu.setFieldsValue({
                ...props.menuData,
            });

            ApiHelpers.getMenuTypes().then(
                (response) => {
                    if (!response.data.message) {
                        setMenuTypes(response.data);
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        }
    }, [props.menuData]);

    const onChangeMenu = (event) => {
        setNewElementMenu({
            ...newElementMenu,
            [event.target.name]: event.target.value,
        });
    };

    const onChangeMenuCheckbox = (event) => {
        setNewElementMenu({
            ...newElementMenu,
            active: event.target.checked,
        });
    };

    const resetNewElement = () => {
        setNewElementMenu({
            ...INITIAL_STATE_MENU,
        });
    };
    const onClose = () => {
        resetNewElement();
        onReset(formMenu);
        setDrawerVisible(false);
    };

    const onReset = (form) => {
        form.resetFields();
    };

    const onSaveMenu = () => {
        formMenu
            .validateFields()
            .then(() => {
                if (actionType === 'new') {
                    ApiHelpers.postMenu({ ...newElementMenu, ...scheduleList, schedules: { ...schedulesNew } }).then(
                        (response) => {
                            const newList = menusList.concat(response.data.menu);
                            setMenusList(newList);
                            message.success('Element adaugat!');
                        },
                        (error) => {
                            console.log(error);
                            message.error('Eroare!');
                        }
                    );
                } else if (actionType === 'edit') {
                    ApiHelpers.updateMenu({ ...newElementMenu, ...scheduleList, schedules: { ...schedulesNew } }).then(
                        (response) => {
                            message.success('Element modificat!');
                        },
                        (error) => {
                            console.log(error);
                            message.error('Eroare!');
                        }
                    );
                }
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    };

    const onDelete = () => {
        ApiHelpers.deleteMenu(newElementMenu.uniqueIdentificationToken).then(
            (response) => {
                if (response.data.statusCode.statusCode === 200) {
                    const newList = menusList.filter(function (obj) {
                        return obj.uniqueIdentificationToken !== newElementMenu.uniqueIdentificationToken;
                    });
                    setMenusList(newList);
                    message.success('Elementul a fost sters!');
                }
            },
            (error) => {
                console.log(error);
                message.error('Eroare!');
            }
        );
        onReset();
        setDrawerVisible(false);
    };

    const onChangeSelect = (value) => {
        setNewElementMenu({
            ...newElementMenu,
            menuType: value,
        });
    };

    let totalMenuItems = 0;
    totalMenuItems = restaurantDetailsStateLocal.menu
        ? restaurantDetailsStateLocal.menu.map((menu) => {
              let totalPerMenu = 0;
              if (menu.category)
                  menu.category.map((menuCat) => {
                      if (menuCat.item) totalPerMenu += menuCat.item.length;
                  });
              return totalPerMenu;
          })
        : 0;
    return (
        <>
            <Form form={formMenu} {...formLayout}>
                <Collapse className="formCollapse" bordered={false} defaultActiveKey={['info', 'schedule']}>
                    <Panel header="Informatii" key="info">
                        <Form.Item name="menuType" label="Tip meniu" rules={[{ required: true, message: 'Camp obligatoriu' }]}>
                            <Select
                                size="large"
                                placeholder="Selecteaza categorie"
                                onChange={onChangeSelect}
                                name="menuType"
                                value={newElementMenu.menuType}>
                                {menuTypes.map((d, index) => (
                                    <Option key={index + '-mt-' + Math.floor(Math.random() * 1111)} value={d.title}>
                                        {d.title}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name="menuName" label="Nume meniu" rules={[{ required: true, message: 'Camp obligatoriu' }]}>
                            <Input size="large" placeholder="" onBlur={onChangeMenu} name="menuName" value={newElementMenu.menuName} />
                        </Form.Item>
                        <Checkbox checked={newElementMenu.active} onChange={onChangeMenuCheckbox}>
                            Activ
                        </Checkbox>
                    </Panel>
                    <Panel header="Program" key="schedule">
                        <FormSchedule
                            scheduleList={scheduleList}
                            setScheduleList={setScheduleList}
                            data={newElementMenu}
                            form={formMenu}
                            type="menu"
                            restaurantId={restaurantId}
                        />
                    </Panel>
                </Collapse>
                <Button type="primary" className="btnAdd" onClick={onSaveMenu}>
                    Salveaza
                </Button>
            </Form>
        </>
    );
}

export default MenuForm;
