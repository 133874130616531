import React, { useState, useEffect } from 'react';
import { Table, Input, Form, Skeleton, DatePicker, Space, Button, Drawer, Collapse, Row, Col } from 'antd';
import { useReportAnalytics } from '../../../swr';
import { UnorderedListOutlined } from '@ant-design/icons';
import { sortByKey, aggregate, merge, enumerateDaysBetweenDates, mergeArrayObjects } from '../../../helpers';
import moment from 'moment';
import { Line } from '@ant-design/charts';
const { Column } = Table;
const Search = Input.Search;
const { Panel } = Collapse;

const RestaurantStats = (props) => {
    const [form] = Form.useForm();
    const [restaurantViews, setRestaurantViews] = useState([]);
    const [menuCategoryViews, setMenuCategoryViews] = useState([]);
    const [menuItemViews, setMenuItemViews] = useState([]);
    const [restaurantOrdersFilter, setRestaurantOrdersFilter] = useState([]);
    const [newElement, setNewElement] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [chartConfig, setChartConfig] = useState(null);
    const INITIAL_DATE_RANGE = [moment().subtract(30, 'days').format('YYYY-MM-DD'), moment().subtract(1, 'days').format('YYYY-MM-DD')];
    const INITAL_DATE_RANGE_PICKER = [moment().subtract(30, 'days'), moment().subtract(1, 'days')];

    const [dateRange, setDateRange] = useState(INITIAL_DATE_RANGE);
    const { data: orderHistory, isLoading: isLoadingOrderHistory } = useReportAnalytics(props.restaurantId, ...dateRange);
    const { RangePicker } = DatePicker;

    useEffect(() => {
        if (!isLoadingOrderHistory && orderHistory) {
            setRestaurantViews(orderHistory.restaurantViews);
            setRestaurantOrdersFilter(orderHistory.restaurantViews);
            let resultMenuCategoryViews = [...orderHistory.menuCategoryViews];
            let resultMenuItemViews = [...orderHistory.menuItemViews];

            setMenuCategoryViews(resultMenuCategoryViews);
            setMenuItemViews(resultMenuItemViews);
            setIsLoading(false);
            const lastThirtyDays = enumerateDaysBetweenDates(moment(dateRange[0]), moment(dateRange[1]), 'Numar accesari', 0);
            const lastThirtyDays2 = enumerateDaysBetweenDates(moment(dateRange[0]), moment(dateRange[1]), 'Numar vizitatori', 0);
            const lastThirtyDays3 = enumerateDaysBetweenDates(moment(dateRange[0]), moment(dateRange[1]), 'Cheama ospatar', 0);
            const lastThirtyDays4 = enumerateDaysBetweenDates(moment(dateRange[0]), moment(dateRange[1]), 'Cere nota', 0);
            const orderHistoryAmount = orderHistory.restaurantViews.map((d) => {
                return { date: moment(d.eventDate).format('YYYY.MM.DD'), value: d.viewCount, name: 'Numar accesari' };
            });

            const orderHistoryCounter = orderHistory.restaurantViews.map((d) => {
                return { date: moment(d.eventDate).format('YYYY.MM.DD'), value: d.usersCount, name: 'Numar vizitatori' };
            });

            const waiterCallsCounter = orderHistory.waiterCalls.map((d) => {
                return { date: moment(d.eventDate).format('YYYY.MM.DD'), value: d.countOnWeb, name: 'Cheama ospatar' };
            });
            const billsRequestedCounter = orderHistory.billsRequested.map((d) => {
                return { date: moment(d.eventDate).format('YYYY.MM.DD'), value: d.countOnWeb, name: 'Cere nota' };
            });

            let result = [
                ...merge(lastThirtyDays, aggregate(orderHistoryAmount, ['date', 'name'], 'value'), 'date'),
                ...merge(lastThirtyDays2, aggregate(orderHistoryCounter, ['date', 'name'], 'value'), 'date'),
                ...merge(lastThirtyDays3, aggregate(waiterCallsCounter, ['date', 'name'], 'value'), 'date'),
                ...merge(lastThirtyDays4, aggregate(billsRequestedCounter, ['date', 'name'], 'value'), 'date'),
            ];
            result.sort(function (x, y) {
                let a = x.date.toUpperCase(),
                    b = y.date.toUpperCase();
                return a == b ? 0 : a > b ? 1 : -1;
            });
            setChartConfig({
                data: result,
                xField: 'date',
                yField: 'value',
                seriesField: 'name',
                //  label: {},
                padding: 'auto',

                point: {
                    size: 5,
                    shape: 'circle',
                    style: {
                        fill: 'white',
                        stroke: '#04c6d0',
                        lineWidth: 1,
                    },
                },
                xAxis: {
                    type: 'timeCat',
                    tick: 5,
                    title: {
                        text: '',
                    },
                    label: {
                        // autoRotate: false,
                        offset: 10,
                        style: {
                            fontSize: 14,
                        },
                        formatter: (name) => moment(name, 'YYYY-MM-DD').format('DD.MM.YYYY'),
                    },
                },
                yAxis: {
                    label: {
                        // autoRotate: false,
                        offset: 10,
                        style: {
                            fontSize: 14,
                        },
                        formatter: (name) => name,
                    },
                },
                tooltip: {
                    showMarkers: true,
                    fields: ['date'],
                    showTitle: false,
                    title: 'Test',
                    customContent: (title, data) => {
                        let tooltipTitle =
                            data.length > 0 ? moment(data[0].value).format('dddd') + ' ' + moment(data[0].value).format('DD.MM.YYYY') : 'test';
                        let tooltipContent = "<div class='chartTooltipContent'><h2>" + tooltipTitle + '</h2>';
                        data.map((d) => {
                            tooltipContent +=
                                d.data.name === 'Numar accesari'
                                    ? '<p>Numar accesari: ' + d.data.value + '</p>'
                                    : d.data.name === 'Numar vizitatori'
                                    ? '<p>Numar vizitatori: ' + d.data.value + '</p>'
                                    : d.data.name === 'Cheama ospatar'
                                    ? '<p>Cheamă ospătar: ' + d.data.value + '</p>'
                                    : d.data.name === 'Cere nota'
                                    ? '<p>Cere nota: ' + d.data.value + '</p>'
                                    : '';
                        });
                        tooltipContent += '</div>';
                        return tooltipContent;
                    },
                },
                smooth: true,
                state: {
                    active: {
                        style: {
                            shadowBlur: 4,
                            stroke: '#000',
                            fill: 'red',
                        },
                    },
                },
                interactions: [
                    {
                        type: 'marker-active',
                    },
                ],
            });
        }
    }, [orderHistory]);

    const onClose = () => {
        setDrawerVisible(false);
    };

    const onReset = () => {
        form.resetFields();
        setNewElement([]);
    };

    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        if (value) {
            setRestaurantViews(
                restaurantOrdersFilter.filter((item) => {
                    if (
                        item.waiter.toLowerCase().indexOf(value) > -1 ||
                        item.sessionStartMoment.toLowerCase().indexOf(value) > -1 ||
                        item.items.toLowerCase().indexOf(value) > -1
                    )
                        return true;
                })
            );
        } else setRestaurantViews(restaurantOrdersFilter);
    };

    const onChange = (dates, dateStrings) => {
        if (dates && dateStrings) {
            setDateRange([moment(dateStrings[0], 'DD.MM.YYYY').format('YYYY-MM-DD'), moment(dateStrings[1], 'DD.MM.YYYY').format('YYYY-MM-DD')]);
        }
    };

    const handleFilter = () => {};

    const onOrderDetails = (record) => {
        setDrawerVisible(true);
        // Update state with the element being edited
        setNewElement({ ...record });
    };

    return isLoading ? (
        <Skeleton active></Skeleton>
    ) : (
        <>
            <Space direction="horizontal" size={12}>
                Perioada:
                <RangePicker
                    defaultValue={[...INITAL_DATE_RANGE_PICKER]}
                    format="DD.MM.YYYY"
                    ranges={{
                        Ieri: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                        'Saptamana curenta': [moment().startOf('week'), moment().endOf('week')],
                        'Saptamana trecuta': [moment().subtract(1, 'weeks').startOf('week'), moment().subtract(1, 'weeks').endOf('week')],
                        'Luna curenta': [moment().startOf('month'), moment().endOf('month')],
                        'Luna trecuta': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                    }}
                    onChange={onChange}
                />
                <Button onClick={handleFilter} type="primary">
                    Filtreaza
                </Button>
            </Space>

            <Row gutter={[20, 20]}>
                <Col
                    xs={{ span: 24, offset: 0 }}
                    md={{ span: 24, offset: 0 }}
                    lg={{ span: 21, offset: 0 }}
                    xl={{ span: 21, offset: 0 }}
                    xxl={{ span: 21, offset: 0 }}>
                    <div className="spacingVertical">
                        <Line {...chartConfig} />
                    </div>
                </Col>
                <Col
                    xs={{ span: 24, offset: 0 }}
                    md={{ span: 24, offset: 0 }}
                    lg={{ span: 3, offset: 0 }}
                    xl={{ span: 3, offset: 0 }}
                    xxl={{ span: 3, offset: 0 }}>
                    {orderHistory ? (
                        <Row gutter={[30, 30]} justify="space-between" align="middle">
                            <Col>
                                <h4 className="widgetTitle">Total accesari</h4>
                                <span className="widgetNumber">
                                    {orderHistory.restaurantViews.reduce((accumulator, object) => {
                                        return accumulator + object.viewCount;
                                    }, 0)}
                                </span>
                            </Col>
                            <Col>
                                <h4 className="widgetTitle">Total vizitatori</h4>
                                <span className="widgetNumber">
                                    {orderHistory.restaurantViews.reduce((accumulator, object) => {
                                        return accumulator + object.usersCount;
                                    }, 0)}
                                </span>
                            </Col>
                            <Col>
                                <h4 className="widgetTitle">Cheamă ospătar</h4>
                                <span className="widgetNumber">
                                    {orderHistory.waiterCalls.reduce((accumulator, object) => {
                                        return accumulator + object.countOnWeb + object.countOnMobile;
                                    }, 0)}
                                </span>
                            </Col>
                            <Col>
                                <h4 className="widgetTitle">Cere nota</h4>
                                <span className="widgetNumber">
                                    {orderHistory.billsRequested.reduce((accumulator, object) => {
                                        return accumulator + object.countOnWeb + object.countOnMobile;
                                    }, 0)}
                                </span>
                            </Col>
                        </Row>
                    ) : null}
                </Col>
            </Row>
            <div className="spacingTop"></div>
            <Row gutter={[24, 24]}>
                <Col
                    xs={{ span: 24, offset: 0 }}
                    md={{ span: 24, offset: 0 }}
                    lg={{ span: 12, offset: 0 }}
                    xl={{ span: 12, offset: 0 }}
                    xxl={{ span: 12, offset: 0 }}>
                    <h3 className="ant-collapse-header">Categorii</h3>
                    <Table
                        dataSource={sortByKey(menuCategoryViews, 'viewCountOnWeb', 0).slice(0, 5)}
                        pagination={false}
                        rowKey={(record) => record.categoryTitle}
                        scroll={{ x: 240 }}>
                        <Column title="Titlu" dataIndex="categoryTitle" />
                        <Column title="Vizualizari" render={(text, record) => record.viewCountOnWeb + record.viewCountOnMobile} />
                    </Table>
                </Col>
                <Col
                    xs={{ span: 24, offset: 0 }}
                    md={{ span: 12, offset: 0 }}
                    lg={{ span: 12, offset: 0 }}
                    xl={{ span: 12, offset: 0 }}
                    xxl={{ span: 12, offset: 0 }}>
                    <h3 className="ant-collapse-header">Preparate</h3>
                    <Table
                        dataSource={sortByKey(menuItemViews, 'viewCountOnWeb', 0).slice(0, 5)}
                        pagination={false}
                        rowKey={(record) => record.menuItemTitle}
                        scroll={{ x: 240 }}>
                        <Column title="Titlu" dataIndex="menuItemTitle" />
                        <Column title="Vizualizari" render={(text, record) => record.viewCountOnWeb + record.viewCountOnMobile} />
                    </Table>
                </Col>
            </Row>
        </>
    );
};

export default RestaurantStats;
