import React, { useState, useEffect } from 'react';
import { Table, Input, Form, Drawer, Button, message, InputNumber, Collapse, Skeleton, Popconfirm } from 'antd';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import { ApiHelpers } from '../../../helpers';

import QRCode from 'qrcode.react';
import { appIcon } from '../../../const';

import { webGuideENV } from '../../../config';

const { Column } = Table;
const { Panel } = Collapse;
const Search = Input.Search;

const RestaurantHotspots = (props) => {

    const [form] = Form.useForm();
    const [restaurantHotspots, setRestaurantHotspots] = useState([]);
    const [restaurantHotspotsFilter, setRestaurantHotspotsFilter] = useState([]);
    const [newElement, setNewElement] = useState([]);
    const [actionType, setActionType] = useState(props.actionType);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const INITIAL_STATE_ELEMENT = {
        uniqueIdentificationToken: null,
        ssid: null,
        networkPassword: null,
        restaurantToken: props.restaurantId
    }

    useEffect(() => {
        // Get Restaurant by Id
        ApiHelpers.getRestaurantHotspots(props.restaurantId)
            .then((response) => {
                setRestaurantHotspots(response.data);
                setRestaurantHotspotsFilter(response.data);
                setIsLoading(false);
            }, (error) => {
                console.log(error);
            });

    }, []);

    const onClose = () => {
        setDrawerVisible(false);
        onReset();
    };

    const onReset = () => {
        form.resetFields();
        setNewElement([]);
    };

    const onSave = () => {
        // Validate form
        form.validateFields()
            .then(() => {
                if (actionType === "new") {
                    ApiHelpers.postRestaurantHotspot(props.restaurantId, newElement)
                        .then((response) => {
                            const newList = restaurantHotspots.concat(response.data.hotspot);
                            setRestaurantHotspots(newList);
                            setRestaurantHotspotsFilter(newList);
                            message.success('Element adaugat!');
                        }, (error) => {
                            console.log(error);
                            message.error("Eroare!")
                        })
                }
                else if (actionType === "edit") {
                    ApiHelpers.updateRestaurantHotspot(props.restaurantId, newElement)
                        .then((response) => {
                            const elementsIndex = restaurantHotspots.findIndex(element => element.uniqueIdentificationToken === newElement.uniqueIdentificationToken);
                            let newMenuData = [...restaurantHotspots];
                            newMenuData[elementsIndex] = { ...newElement };
                            setRestaurantHotspots(newMenuData);
                            setRestaurantHotspotsFilter(newMenuData)
                            message.success('Element modificat!');
                        }, (error) => {
                            console.log(error);
                            message.error('Eroare!');
                        });
                }
                onReset();
                setDrawerVisible(false);
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    }

    const onDelete = () => {
        ApiHelpers.deleteRestaurantHotspot(props.restaurantId, newElement.uniqueIdentificationToken)
            .then((response) => {
                if (response.data.statusCode.statusCode === 200) {
                    const newList = restaurantHotspots.filter(function (obj) {
                        return obj.uniqueIdentificationToken !== newElement.uniqueIdentificationToken;
                    });
                    setRestaurantHotspots(newList);
                    setRestaurantHotspotsFilter(newList);
                    message.success("Elementul a fost sters!");
                }
            }, (error) => {
                console.log(error);
                message.error("Eroare!")
            });
        onReset();
        setDrawerVisible(false);
    }

    const onChange = event => {
        setNewElement({
            ...newElement,
            [event.target.name]: event.target.value
        });
    };

    const onAddElement = () => {
        setActionType("new");
        setNewElement({
            ...INITIAL_STATE_ELEMENT
        });
        setDrawerVisible(true);
    }

    const onEditElement = (record) => {
        setActionType("edit");

        // Set form fields values
        const editObject = {
            uniqueIdentificationToken: record.uniqueIdentificationToken,
            restaurantToken: record.restaurantToken,
            ssid: record.ssid,
            networkPassword: record.networkPassword,
        }

        form.setFieldsValue({
            ...editObject
        });

        // Update state with the element being edited
        setNewElement({ ...editObject });
        setDrawerVisible(true);
    };

    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        if (value) {
            setRestaurantHotspots(restaurantHotspotsFilter.filter(item => {
                if (item.uniqueIdentificationToken.toLowerCase().indexOf(value) > -1)
                    return true
            }));
        }
        else
            setRestaurantHotspots(restaurantHotspotsFilter);

    }

    const generateQRCodeValue = (qrCodeContent) => {
        if (qrCodeContent) {
            return webGuideENV + qrCodeContent;
        }
    }

    return (
        <> {isLoading ? <Skeleton active></Skeleton> :
            <>
                <Button type="primary" onClick={() => onAddElement()} className="btnAdd">
                    <PlusOutlined /> Adauga retea
                </Button>
                <Table
                    dataSource={restaurantHotspots}
                    pagination={false}
                    rowKey="uniqueIdentificationToken"
                    scroll={{ x: 240 }}
                >
                    <Column title="Nume retea" width="200px"
                        dataIndex="ssid" />
                    <Column title="Actiuni" key="actions" width="200px" render={(text, record) => (
                        <Button type="default" className="tableAction" icon={<EditOutlined />} onClick={() => onEditElement(record)}>
                            Editează
                        </Button>
                    )} />
                   <Column title="Parola" 
                        dataIndex="networkPassword" />
                </Table>
                <Drawer
                    title="Retea"
                    width={window.innerWidth > 1000 ? 1000 : window.innerWidth}
                    onClose={onClose}
                    visible={drawerVisible}
                    bodyStyle={{ paddingBottom: 80 }}
                    footer={
                        <div className="modalFooter">
                            <div
                                style={{
                                    textAlign: 'left',
                                }}
                            >{actionType !== 'new' ?
                                <Popconfirm title="Sigur vrei sa stergi?" onConfirm={onDelete}>
                                    <Button size="large" danger style={{ marginRight: 8 }}>
                                        Sterge
                                    </Button>
                                </Popconfirm>

                                : null}
                            </div>
                            <div
                                style={{
                                    textAlign: 'right',
                                }}
                            >
                                <Button size="large" onClick={onClose} style={{ marginRight: 8 }}>
                                    Anuleaza
                                </Button>
                                <Button size="large" onClick={onSave} type="primary">
                                    Salveaza
                                </Button>
                            </div>
                        </div>
                    }
                >
                    <Form layout="vertical" form={form} size="large">
                        <Collapse className="formCollapse" bordered={false} defaultActiveKey={['info', 'download']}>
                            <Panel header="Informatii" key="info">
                                <Form.Item
                                    name="ssid"
                                    label="Nume retea"
                                    rules={[{ required: true, message: 'Camp obligatoriu' }]}
                                >
                                    <Input onBlur={onChange} name="ssid" value={newElement.ssid} />
                                </Form.Item>
                                <Form.Item
                                    name="networkPassword"
                                    label="Parola"
                                    rules={[{ required: false, message: 'Camp obligatoriu' }]}
                                >
                                    <Input onBlur={onChange} name="networkPassword" value={newElement.networkPassword} />
                                </Form.Item>
                            </Panel>

                        </Collapse>
                    </Form>
                </Drawer>
            </>}
        </>
    );
}

export default RestaurantHotspots;