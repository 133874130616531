import React from 'react';
import { message, Button, Upload, Menu, Dropdown } from 'antd';
import { ApiHelpers } from '../../../helpers';
import { UploadOutlined } from '@ant-design/icons';

import ImgCrop from 'antd-img-crop';

function UploadLogo(props) {
    const { restaurantId, restaurantDetails, setRestaurantDetails } = props;

    const uploadPropsLogo = {
        name: 'file',
        method: 'PUT',
        action: ApiHelpers.uploadRestaurantLogo(restaurantId),
        
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                //console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);

                setRestaurantDetails({
                    ...restaurantDetails,
                    logoPath: info.file.response.logoPath,
                });
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    const onDeleteRestaurantLogo = () => {
        ApiHelpers.deleteRestaurantLogo(restaurantId).then(
            (response) => {
                setRestaurantDetails({
                    ...restaurantDetails,
                    logoPath: null,
                });
                message.success('Imagine stearsa!');
            },
            (error) => {
                console.log(error);
                message.error('Eroare!');
            }
        );
    };

    const menuLogo = (
        <Menu>
            <Menu.Item>
                <a onClick={onDeleteRestaurantLogo}>Sterge</a>
            </Menu.Item>
            <Menu.Item>
                <ImgCrop rotate aspect={2 / 2} minZoom={0.2} cropperProps={{ restrictPosition: false }}>
                    <Upload {...uploadPropsLogo}>
                        <a onClick={(e) => e.preventDefault()}>Modifica</a>
                    </Upload>
                </ImgCrop>
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            {restaurantDetails.logoPath ? (
                <Dropdown overlay={menuLogo}>
                    <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                        <img src={ApiHelpers.getImageLink(restaurantDetails.logoPath)} className="uploadedImage" />
                    </a>
                </Dropdown>
            ) : (
                <ImgCrop rotate aspect={2 / 2} minZoom={0.2} cropperProps={{ restrictPosition: false }}>
                    <Upload {...uploadPropsLogo}>
                        <Button icon={<UploadOutlined />}>Upload</Button>
                    </Upload>
                </ImgCrop>
            )}
        </>
    );
}

export default UploadLogo;
