import React, { useEffect, useState } from 'react';
import { Table, Input, Form, Drawer, Button, message, Checkbox, Collapse, Skeleton, Select, Popconfirm, Switch } from 'antd';
import { PlusOutlined, CheckOutlined, EditOutlined, UnorderedListOutlined, MenuOutlined } from '@ant-design/icons';
import { ApiHelpers, FormSchedule } from '../../../helpers';
import { Link, useMatch, useLocation, useNavigate } from 'react-router-dom';
import * as ROUTES from '../../../const/routes';

import { useRecoilState, useRecoilValue } from 'recoil';
import { restaurantDetailsState, menusListState, menuCategoriesState } from '../../../recoil';

import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

const { Column } = Table;
const { Panel } = Collapse;
const { Option } = Select;

function MenuListForm(props) {
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [actionType, setActionType] = useState(props.actionType);
    const [menusList, setMenusList] = useState(props.menusList);
    const [menuTypes, setMenuTypes] = useState([]);
    const restaurantDetails = props.restaurantDetails;
    const navigate = useNavigate();

    const restaurantDetailsStateLocal = useRecoilValue(restaurantDetailsState);
    const restaurantId = props.restaurantId;
    const [formMenu] = Form.useForm();
    const [scheduleList, setScheduleList] = useState([]);
    const menuView = props.view;

    const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

    const SortableItem = sortableElement((props) => <tr {...props} className="ant-table-row" />);
    const SortableContainer = sortableContainer((props) => <tbody {...props} />);
    let location = useLocation();
    let url = location.pathname;
    const timePickerFormat = 'HH:mm';

    const INITIAL_STATE_MENU = {
        restaurantIdentificationToken: restaurantId,
        uniqueIdentificationToken: null,
        startSchedule: '',
        endSchedule: '',
        menuName: '',
        active: false,
        note: null,
        availableOnMonday: false,
        availableOnTuesday: false,
        availableOnWednesday: false,
        availableOnThursday: false,
        availableOnFriday: false,
        availableOnSaturday: false,
        availableOnSunday: false,
        mondayStart: null,
        mondayEnd: null,
        tuesdayStart: null,
        tuesdayEnd: null,
        wednesdayStart: null,
        wednesdayEnd: null,
        thursdayStart: null,
        thursdayEnd: null,
        fridayStart: null,
        fridayEnd: null,
        saturdayStart: null,
        saturdayEnd: null,
        sundayStart: null,
        sundayEnd: null,
        menuIndex: 0,
        menuType: '',
    };
    const [newElementMenu, setNewElementMenu] = useState({ ...INITIAL_STATE_MENU });

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable([].concat(menusList), oldIndex, newIndex).filter((el) => !!el);
            newData.map((d, index) => (d.menuIndex = index));

            ApiHelpers.updateMenus(newData, restaurantId).then(
                (response) => {
                    message.success('Modificari efectuate!');
                    setMenusList(newData);
                },
                (error) => {
                    console.log(error);
                }
            );
        }
    };

    const DraggableContainer = (props) => (
        <SortableContainer useDragHandle disableAutoscroll helperClass="rowDragging" onSortEnd={onSortEnd} {...props} />
    );

    const DraggableBodyRow = ({ className, style, ...restProps }) => {
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = menusList.findIndex((x) => x.uniqueIdentificationToken === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };

    useEffect(() => {
        ApiHelpers.getMenuTypes().then(
            (response) => {
                if (!response.data.message) {
                    setMenuTypes(response.data);
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }, []);

    const getMenusList = () => {
        // Get Menus by RestaurantId
        ApiHelpers.getMenuByRestaurant(restaurantId).then((response) => {
            setMenusList(response.data);
        });
    };

    const onChangeMenu = (event) => {
        setNewElementMenu({
            ...newElementMenu,
            [event.target.name]: event.target.value,
        });
    };

    const onChangeMenuCheckbox = (event) => {
        setNewElementMenu({
            ...newElementMenu,
            active: event.target.checked,
        });
    };

    const resetNewElement = () => {
        setNewElementMenu({
            ...INITIAL_STATE_MENU,
        });
    };
    const onClose = () => {
        onReset(formMenu);
        setDrawerVisible(false);
    };

    const onReset = (form) => {
        form.resetFields();
        resetNewElement();
        setScheduleList([]);
    };

    const onSaveMenu = () => {
        formMenu
            .validateFields()
            .then(() => {
                if (actionType === 'new') {
                    ApiHelpers.postMenu({ ...newElementMenu, ...scheduleList }).then(
                        (response) => {
                            if (response.data.message) {
                                message.error('Eroare sursa de date.');
                            } else {
                                const newList = menusList.concat(response.data.menu);
                                setMenusList(newList);
                                message.success('Element adaugat!');
                            }
                        },
                        (error) => {
                            console.log(error);
                            message.error('Eroare!');
                        }
                    );
                } else if (actionType === 'edit') {
                    ApiHelpers.updateMenu({ ...newElementMenu, ...scheduleList }).then(
                        (response) => {
                            if (response.data.message) {
                                message.error('Eroare sursa de date.');
                            } else {
                                const elementsIndex = menusList.findIndex(
                                    (element) => element.uniqueIdentificationToken === newElementMenu.uniqueIdentificationToken
                                );
                                let newMenusList = [...menusList];
                                newMenusList[elementsIndex] = { ...newElementMenu, ...scheduleList };
                                setMenusList(newMenusList);
                                message.success('Element modificat!');
                            }
                        },
                        (error) => {
                            console.log(error);
                            message.error('Eroare!');
                        }
                    );
                }
                setNewElementMenu({ ...INITIAL_STATE_MENU });
                setDrawerVisible(false);
                onReset(formMenu);
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    };

    const onDelete = () => {
        ApiHelpers.deleteMenu(newElementMenu.uniqueIdentificationToken).then(
            (response) => {
                if (response.data.statusCode.statusCode === 200) {
                    const newList = menusList.filter(function (obj) {
                        return obj.uniqueIdentificationToken !== newElementMenu.uniqueIdentificationToken;
                    });
                    setMenusList(newList);
                    message.success('Elementul a fost sters!');
                }
            },
            (error) => {
                console.log(error);
                message.error('Eroare!');
            }
        );
        onReset(formMenu);
        setDrawerVisible(false);
    };

    const onAddMenu = () => {
        setActionType('new');
        setDrawerVisible(true);
    };

    const onEditElement = (record) => {
        setActionType('edit');
        const elementsIndex = menusList.findIndex((element) => element.uniqueIdentificationToken === record.uniqueIdentificationToken);

        // Set form fields values
        formMenu.setFieldsValue({
            ...record,
        });

        // Update state with the element being edited
        setNewElementMenu({ ...record });
        setScheduleList({
            availableOnMonday: record.availableOnMonday,
            availableOnTuesday: record.availableOnTuesday,
            availableOnWednesday: record.availableOnWednesday,
            availableOnThursday: record.availableOnThursday,
            availableOnFriday: record.availableOnFriday,
            availableOnSaturday: record.availableOnSaturday,
            availableOnSunday: record.availableOnSunday,
            mondayStart: record.mondayStart,
            mondayEnd: record.mondayEnd,
            tuesdayStart: record.tuesdayStart,
            tuesdayEnd: record.tuesdayEnd,
            wednesdayStart: record.wednesdayStart,
            wednesdayEnd: record.wednesdayEnd,
            thursdayStart: record.thursdayStart,
            thursdayEnd: record.thursdayEnd,
            fridayStart: record.fridayStart,
            fridayEnd: record.fridayEnd,
            saturdayStart: record.saturdayStart,
            saturdayEnd: record.saturdayEnd,
            sundayStart: record.sundayStart,
            sundayEnd: record.sundayEnd,
            restaurantToken: restaurantId,
        });
        setDrawerVisible(true);
    };
    let totalMenuItems = 0;
    totalMenuItems = restaurantDetailsStateLocal.menu
        ? restaurantDetailsStateLocal.menu.map((menu) => {
              let totalPerMenu = 0;
              if (menu.category)
                  menu.category.map((menuCat) => {
                      if (menuCat.item) totalPerMenu += menuCat.item.length;
                  });
              return totalPerMenu;
          })
        : 0;

    const onChangeSelect = (value) => {
        setNewElementMenu({
            ...newElementMenu,
            menuType: value,
        });
    };

    const onChangeSwitchActive = (checked, record) => {
        ApiHelpers.changeMenuState(record.restaurantIdentificationToken, record.uniqueIdentificationToken, checked).then(
            (response) => {
                if (response.data.message) {
                    message.error('Eroare sursa de date.');
                } else {
                    message.success('Element modificat!');
                    getMenusList();
                }
            },
            (error) => {
                console.log(error);
                message.error('Eroare!');
            }
        );
    };

    const columns = [
        {
            title: 'Sortare',
            dataIndex: 'sort',
            width: 30,
            className: 'drag-visible',
            render: () => <DragHandle />,
        },
        {
            title: 'Nume',
            dataIndex: 'menuName',
            className: 'drag-visible',
            render: (text, record, index) => (
                <div>
                    {record.menuName}
                    {menuView === 'extended' ? (
                        <ul>
                            {restaurantDetails.menu[index] &&
                                restaurantDetails.menu[index].category &&
                                restaurantDetails.menu[index].category.map((d) => {
                                    return (
                                        <li>
                                            {d.categoryName}
                                            <ul>
                                                {d.item &&
                                                    d.item.map((d) => {
                                                        return <li>{d.title}</li>;
                                                    })}
                                            </ul>
                                        </li>
                                    );
                                })}
                        </ul>
                    ) : null}
                </div>
            ),
        },
        {
            title: 'Actiuni',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record, index) => (
                <div>
                    <Button type="default" className="tableAction" icon={<EditOutlined />} onClick={() => onEditElement(record)}>
                        Editează
                    </Button>
                    <Button
                        type="default"
                        className="tableAction"
                        icon={<UnorderedListOutlined />}
                        onClick={() => navigate(url.replace(/\/$/, '') + '/' + record.uniqueIdentificationToken + '#categories')}>
                        Vezi{' '}
                        {restaurantDetailsStateLocal.menu
                            ? restaurantDetailsStateLocal.menu[index]
                                ? restaurantDetailsStateLocal.menu[index].category
                                    ? restaurantDetailsStateLocal.menu[index].category.length
                                    : '0'
                                : ' '
                            : ' '}{' '}
                        categorii
                    </Button>
                    <Button
                        type="default"
                        className="tableAction"
                        icon={<UnorderedListOutlined />}
                        onClick={() => navigate(url.replace(/\/$/, '') + '/' + record.uniqueIdentificationToken + '#items')}>
                        Vezi {totalMenuItems[index] || ' '} preparate
                    </Button>
                </div>
            ),
        },
        {
            title: 'Tip meniu',
            dataIndex: 'menuType',
        },
        {
            title: 'Activ',
            dataIndex: 'active',
            render: (text, record) => <Switch defaultChecked={record.active} onChange={(checked) => onChangeSwitchActive(checked, record)} />,
        },
    ];

    return !menusList ? (
        <Skeleton active></Skeleton>
    ) : (
        <>
            <Button type="primary" onClick={onAddMenu} className="btnAdd">
                <PlusOutlined /> Adauga meniu
            </Button>
            <>
                <Table
                    dataSource={menusList}
                    pagination={false}
                    rowKey="uniqueIdentificationToken"
                    columns={columns}
                    scroll={{ x: 240 }}
                    components={{
                        body: {
                            wrapper: DraggableContainer,
                            row: DraggableBodyRow,
                        },
                    }}
                />
            </>
            <Drawer
                title="Meniu"
                width={window.innerWidth > 1000 ? 1000 : window.innerWidth}
                onClose={onClose}
                visible={drawerVisible}
                bodyStyle={{ paddingBottom: 80 }}
                footer={
                    <div className="modalFooter">
                        <div
                            style={{
                                textAlign: 'left',
                            }}>
                            {actionType !== 'new' ? (
                                <Popconfirm title="Sigur vrei sa stergi?" onConfirm={onDelete}>
                                    <Button size="large" danger style={{ marginRight: 8 }}>
                                        Sterge
                                    </Button>
                                </Popconfirm>
                            ) : null}
                        </div>
                        <div
                            style={{
                                textAlign: 'right',
                            }}>
                            <Button size="large" onClick={onClose} style={{ marginRight: 8 }}>
                                Anuleaza
                            </Button>
                            <Button size="large" onClick={onSaveMenu} type="primary">
                                Salveaza
                            </Button>
                        </div>
                    </div>
                }>
                <Form layout="vertical" form={formMenu}>
                    <Collapse className="formCollapse" bordered={false} defaultActiveKey={['info', 'schedule']}>
                        <Panel header="Informatii" key="info">
                            <Form.Item name="menuType" label="Tip meniu" rules={[{ required: true, message: 'Camp obligatoriu' }]}>
                                <Select
                                    size="large"
                                    placeholder="Selecteaza categorie"
                                    onChange={onChangeSelect}
                                    name="menuType"
                                    value={newElementMenu.menuType}>
                                    {menuTypes.map((d, index) => (
                                        <Option key={index + '-mt-' + Math.floor(Math.random() * 1111)} value={d.title}>
                                            {d.title}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item name="menuName" label="Nume meniu" rules={[{ required: true, message: 'Camp obligatoriu' }]}>
                                <Input size="large" placeholder="" onBlur={onChangeMenu} name="menuName" value={newElementMenu.menuName} />
                            </Form.Item>
                            <Checkbox checked={newElementMenu.active} onChange={onChangeMenuCheckbox}>
                                Activ
                            </Checkbox>
                        </Panel>
                        <Panel header="Program" key="schedule">
                            <FormSchedule
                                scheduleList={scheduleList}
                                setScheduleList={setScheduleList}
                                data={newElementMenu}
                                form={formMenu}
                                type="menu"
                                drawerVisible={drawerVisible}
                                restaurantId={restaurantId}
                            />
                        </Panel>
                    </Collapse>
                </Form>
            </Drawer>
        </>
    );
}

export default MenuListForm;
