import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Button, Row, Col, Space } from 'antd';
import { DownloadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useRecoilState } from 'recoil';
import { activeRestaurantState } from '../../../../recoil';
import QRCode from 'qrcode.react';
import { appIcon } from '../../../../const';
import * as htmlToImage from 'html-to-image';
import downloadThumb from '../../../../assets/img/qrcode-tableIndex-thumbnail.png';
import { ApiHelpers } from '../../../../helpers';
import QRCodeCustomPreview from './QRCodeCustomPreview';

function debugBase64(base64URL) {
    var win = window.open();
    win.document.write(
        '<iframe src="' +
            base64URL +
            '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
    );
}

const QRCodeCustom = (props) => {
    const { value, tableIndex } = props;
    const [generatedQR, setGeneratedQR] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [activeRestaurant, setActiveRestaurant] = useRecoilState(activeRestaurantState);
    const isSelfService = activeRestaurant.sysRestaurantType === 'SelfService';
    const [customization, setCustomization] = useState({});
    const ref = useRef(null);

    const QRCodeMarkup = (
        <QRCode
            value={value}
            size={500}
            level="Q"
            fgColor="#292333"
            renderAs="svg"
            imageSettings={{
                //src: activeRestaurant.logoPath ? ApiHelpers.getImageLink(activeRestaurant.logoPath) : appIcon,
                src: appIcon,
                width: 160,
                height: 160,
                excavate: true,
            }}
        />
    );

    const handleDownload = useCallback(
        (ref) => {
            if (ref.current === null) {
                return;
            }
            setIsLoading(true);
            htmlToImage
                .toPng(ref.current, { cacheBust: true, height: 2390, width: 1690 })
                .then(function (dataUrl) {
                    var img = new Image();
                    img.src = dataUrl;
                    //document.body.appendChild(img);
                    setGeneratedQR(dataUrl);
                    const link = document.createElement('a');
                    link.download = 'qrcode-masa-' + tableIndex;
                    link.href = dataUrl;
                    link.click();
                    setIsLoading(false);
                })
                .catch(function (error) {
                    console.error('oops, something went wrong!', error);
                });
        },
        [ref]
    );
    useEffect(() => {
        if (props.autoDownload) {
            setTimeout(() => {
                if (ref) {
                    console.log('sasa');
                    handleDownload(ref);
                }
            }, 2000);
        }
    }, [ref]);

    useEffect(() => {
        // Get Restaurant by Id
        if (activeRestaurant && activeRestaurant.uniqueIdentificationToken) {
            setIsLoading(true);
            ApiHelpers.getRestaurantCustomization(activeRestaurant.uniqueIdentificationToken).then(
                (response) => {
                    if (!response.data.message) {
                        setCustomization({ ...customization, ...response.data });
                        setIsLoading(false);
                    } else {
                        setIsLoading(false);
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        }
    }, [activeRestaurant]);

    return (
        <div className="qrCodeTemplateContainer">
            <Row>
                <Col span={24}>
                    <Row gutter={[80,80]} align="middle">
                        <Col>
                            <h3>Cod QR cu grafică</h3>
                        </Col>
                        <Col>
                
                        </Col>
                    </Row>
                    <div className="qrCodeTableIndex" ref={ref}>
                        <QRCodeCustomPreview
                            customization={customization}
                            tableIndex={tableIndex}
                            value={value}
                            isStaticPreview={false}
                            size="large"
                        />
                    </div>
                    <Space size={20} direction="vertical">
                        <QRCodeCustomPreview
                            customization={customization}
                            tableIndex={tableIndex}
                            value={value}
                            isStaticPreview={true}
                            size="small"
                        />

                        <Button onClick={() => handleDownload(ref)} icon={<DownloadOutlined />}>
                            Descarca
                        </Button>
                    </Space>
                </Col>
            </Row>
        </div>
    );
};

export default QRCodeCustom;
