import React from 'react';
import { Row, Col } from 'antd';
import food from '../../assets/food.jpg';
import MenuThemeContext from '../context.js';



export default function MenuItem(props) {
    const menuTemplate = React.useContext(MenuThemeContext);
    return (
        menuTemplate === 3 ?
            <Col span={24} className={props.content.chefSelection === true ? "chefSelection-item" : null}>
                <Row gutter={{ xs: 12, sm: 16, md: 15, lg: 15 }}>
                    <Col xs={6} sm={5} md={5} lg={4} xl={4}>
                        <img src={food} width="100%" alt="" className="menuItem-img" />
                    </Col>
                    <Col xs={18} sm={19} md={19} lg={20} xl={20}>
                        <div className="menuItem-title">
                            <h3>{props.content.name}</h3>
                            <span></span>
                            <span className="menuItem-price">{props.content.price} lei</span>
                        </div>
                        <Row>
                            <Col xs={18} sm={16} md={14} lg={16} xl={12}>
                                {props.content.description}
                                <span>{props.content.size}</span>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
            : (menuTemplate === 2 ?
                <Col span={12} className={props.content.chefSelection === true ? "chefSelection-item" : null}>
                    <Row>
                        <Col span={22}>
                            <div className="menuItem-title">
                                <h3>{props.content.name}</h3>
                                <span></span>
                                <span className="menuItem-price">{props.content.price} lei</span>
                            </div>
                            <Row>
                                <Col xs={22} sm={20} md={20} lg={18} xl={20}>
                                    <p className="menuItem-description truncate-overflow">
                                        {props.content.description}
                                        <span>{props.content.size}</span>
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                : (menuTemplate === 1 ?
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} className={props.content.chefSelection === true ? "mt3 chefSelection-item" : "mt3"}>
                        <Row>
                            <Col span={23}>
                                <Row gutter={10}>
                                    <Col xs={16} sm={16} md={14} lg={14} xl={16}>
                                        <h3 className="menuItem-name">{props.content.name}</h3>
                                        <p className="menuItem-description truncate-overflow">
                                            {props.content.description}
                                            <span>{props.content.size}</span>
                                        </p>

                                        <div className="menuItem-price">{props.content.price} lei</div>
                                    </Col>
                                    <Col xs={8} sm={8} md={10} lg={10} xl={8}>
                                        <img src={food} width="100%" alt="" className="menuItem-img" />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    :
                    <Col span={24} className={props.content.chefSelection === true ? "chefSelection-item" : null}>
                        <Row>
                            <Col span={24}>
                                <div className="menuItem-title">
                                    <h3>{props.content.name}</h3>
                                    <span></span>
                                    <span className="menuItem-price">{props.content.price} lei</span>
                                </div>
                                <Row>
                                    <Col xs={18} sm={16} md={14} lg={16} xl={12}>
                                        {props.content.description}
                                        <span>{props.content.size}</span>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                )
            )
    );
}