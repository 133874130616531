import React from 'react';
import { withFirebase } from './Firebase';
import SignOutButton from './SignOut';

import { AuthUserContext } from './Session';

const Test = () => (
    <div>
      <AuthUserContext.Consumer>
        {authUser =>
          authUser ?  
          <div>
          Hello {authUser.email}
          <SignOutButton />
          
          </div>
          : 
          <div>
          Not logged in 
          <a href="http://localhost:3000/signin">Sign in</a>
          </div>
        }
       
      </AuthUserContext.Consumer>
     
    </div>
  );


export default withFirebase(Test);