import React from 'react';
import { Input, Form, Select, Row, Col, Popconfirm } from 'antd';

const { Option } = Select;

const MenuItemGroupItem = (props) => {

    const { index, menuItems, menuItemFormList, item, form, setMenuItemFormList, refreshForm, resetForm, restaurantId } = props;

    const onChangeSelectMenuItem = (value, index) => {
        const elementsIndex = menuItems.findIndex(element => element.uniqueIdentificationToken === value);
        const priceName = "price-" + index;
        if (elementsIndex > -1)
            form.setFieldsValue({ [priceName]: menuItems[elementsIndex].price });
        const newMenuItemFormList = menuItemFormList;
        newMenuItemFormList[index]={};
        newMenuItemFormList[index].restaurantToken = restaurantId;
        newMenuItemFormList[index].menuItemToken = value;
        newMenuItemFormList[index].price = menuItems[elementsIndex].price;
        //console.log(newMenuItemFormList);
        setMenuItemFormList([
            ...newMenuItemFormList
        ]);
    }

    const onChange = (event, index) => {
        const newMenuItemFormList = menuItemFormList;
        newMenuItemFormList[index].price = event.target.value;
        setMenuItemFormList([
            ...menuItemFormList
        ]);
    };

    const deleteMenuItem = (index) => {
        resetForm(menuItemFormList);
        let newMenuItemFormList = [...menuItemFormList];
        newMenuItemFormList.splice(index,1);
        setMenuItemFormList([
            ...newMenuItemFormList
        ]);
        refreshForm(newMenuItemFormList);
    }

    return (
        <Row key={"menuItemRow-" + index} gutter={[5, 5]}>
            <Col span={18}>
                <Form.Item
                    name={"menuItem-" + index}
                    rules={[{ required: true, message: 'Camp obligatoriu' }]}
                >
                    <Select style={{ width: '100%' }}
                        showSearch
                        placeholder="Preparat"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value) => onChangeSelectMenuItem(value, index)}
                    >
                        {menuItems && menuItems.map((d, index2) => {
                            return <Option key={"menuItemOption-" + index + "-" + index2} value={d.uniqueIdentificationToken}>{d.title}</Option>
                        })}
                    </Select>
                </Form.Item>
            </Col>
            <Col span={3}>
                <Form.Item
                    name={"price-" + index}
                    rules={[{ required: true, message: 'Camp obligatoriu' }]}
                >
                    <Input size="large" placeholder="Pret" onBlur={(event) => onChange(event, index)} name={"price-" + index} value={item.price} />
                </Form.Item>
            </Col>
            <Col span={2} offset={1}>
                <Popconfirm title="Sigur vrei sa stergi?" onConfirm={() => deleteMenuItem(index)}>
                    <a>Sterge</a>
                </Popconfirm>
            </Col>
        </Row>
    )
}

export default MenuItemGroupItem;