import React from 'react';
import {
    RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
  } from 'recoil';

  export const savedMenuCategory = atom({
    key: 'savedMenuCategory', // unique ID (with respect to other atoms/selectors)
    default: '', // default value (aka initial value)
  });

  export const restaurantDetailsState = atom({
    key: 'restaurantDetailsState', // unique ID (with respect to other atoms/selectors)
    default: '', // default value (aka initial value)
  });

  export const menusListState = atom({
    key: 'menusListState', // unique ID (with respect to other atoms/selectors)
    default: '', // default value (aka initial value)
  });

  export const menuCategoriesState = atom({
    key: 'menuCategoriesState', // unique ID (with respect to other atoms/selectors)
    default: '', // default value (aka initial value)
  });

  export const activeRestaurantState = atom({
    key: 'activeRestaurant', // unique ID (with respect to other atoms/selectors)
    default: {
      title: "",
      uniqueIdentificationToken: "",
      coverImagePath: "",
      logoPath: "",
      subscriptionPlan: "",
      sysRestaurantType: "",
      activatePosIntegration: false
    }, // default value (aka initial value)
  });

  export const companyDetailsState = atom({
    key: 'companyDetailsState', // unique ID (with respect to other atoms/selectors)
    default: '', // default value (aka initial value)
  });