import React from 'react';
import MenuList from './MenuList';
import { Row, Col, Tabs, } from 'antd';
import { StickyContainer, Sticky } from 'react-sticky';
import MainLayout from '../Layout/MainLayout';

const { TabPane } = Tabs;



function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}

const renderTabBar = (props, DefaultTabBar) => (
    <Sticky bottomOffset={80}>
      {({ style }) => (
        <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
      )}
    </Sticky>
  );

export default function Menu(props){

    const menuContent = props.menuContent;
    const menuDayContent = props.menuDayContent;
    const menuCategories = [...new Set(menuContent.map(item => item.category))];
    const menuGroupedbyCategory = groupBy(menuContent, item => item.category);
    
    return(
        <MainLayout>
            <Row>
                <Col xs={{span: 22, offset:1}} md={{span: 20, offset:2}} lg={{span: 18, offset:3}} xl={{span: 18, offset:3}} xxl={{span: 16, offset:4}} className="container-menu">
                    <h1 className="page-title restaurant-title ms-fontSize-42">Soft Bistro</h1>
                    <StickyContainer>                 
                        <Tabs defaultActiveKey="0" renderTabBar={renderTabBar}>
                            <TabPane tab="Toate" key="0">
                                <MenuList menuContent={menuContent} menuGrouped={[...menuGroupedbyCategory]}></MenuList> 
                            </TabPane>
                            <TabPane tab="Meniul zilei" key="1">
                                <MenuList menuContent={menuDayContent}></MenuList> 
                            </TabPane>
                            {menuCategories.map( (item, index) =>  {
                            return <TabPane tab={item} key={index+2}>
                                        <MenuList menuContent={menuGroupedbyCategory.get(item)} menuCategory={item}></MenuList> 
                                   </TabPane>
                            })}
                        </Tabs> 
                    </StickyContainer>   
                </Col>
            </Row>
        </MainLayout>
    );
}