import React, { useState, useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';
import {
    Input,
    Select,
    Form,
    Button,
    message,
    Checkbox,
    Collapse,
    Tabs,
    Popover,
    Row,
    Col,
    Skeleton,
    Drawer,
    Popconfirm,
    Table,
    Tag,
    Space,
    Alert,
} from 'antd';
import { PlusOutlined, InfoCircleOutlined, ControlOutlined, FilePdfOutlined, CopyOutlined, ArrowLeftOutlined, LockOutlined } from '@ant-design/icons';
import {
    ApiHelpers,
    nutritionalCalculation,
    nutritionalCalculationFrontend,
    nutritionalMenuFull,
    nutritionalMenuShort,
    nutritionalMenuShort2,
    nutritionalCalculationSumIngredients,
} from '../../../helpers';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { AuthUserContext } from '../../Session';
import AdminLayout from '../AdminLayout';
import UploadMenuItemImage from '../Menu/UploadMenuItemImage';
import MenuItem from '../Menu/MenuItem';
import * as ROUTES from '../../../const/routes';
import { activeRestaurantState, savedMenuCategory } from '../../../recoil';
import MenuItemIngredient from './MenuItemIngredient';
import MenuItemPrint from './Print/MenuItemPrint';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
Bugsnag.start({
    apiKey: '57938f7702519d85f3895fe7b59f54e5',
    plugins: [new BugsnagPluginReact()],
    logger: null,
    autoDetectErrors: false,
});
const { TextArea } = Input;
const { Option } = Select;
const Search = Input.Search;
const { Panel } = Collapse;
const { TabPane } = Tabs;

const tooltipImage = {
    title: '',
    text: <div>Dimensiunea recomandata de minim 1200x800px. Imaginea va fi ajustata pentru aspectul 3:2.</div>,
};

function MenuItemFormNew(props) {
    let { menuId, restaurantId, menuItemId } = useParams();
    const routeLocation = useLocation();
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [activeRestaurant, setActiveRestaurant] = useRecoilState(activeRestaurantState);
    const [menuItemData, setMenuItemData] = useState({});
    const [menuCategoriesData, setMenuCategoriesData] = useState([]);
    const [form] = Form.useForm();
    const [newElement, setNewElement] = useState([]);
    const [actionType, setActionType] = useState('');
    const [menuItemsData, setMenuItemsData] = useState([]);
    const [activeTabKey, setActiveTabKey] = useState();
    const [activeTabKeyPreview, setActiveTabKeyPreview] = useState();
    const [activeTabKeyTable, setActiveTabKeyTable] = useState();
    const [menuItemFormList, setMenuItemFormList] = useState([]);
    const [listOfIngredients, setListOfIngredients] = useState([]);
    const [copyListOfIngredients, setCopyListOfIngredients] = useState([]);
    const [selectedIngredient, setSelectedIngredient] = useState();
    const [menuItemGroups, setMenuItemGroups] = useState([]);
    const [integrationMenuItems, setIntegrationMenuItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [sumNutritionalData100g, setSumNutritionalData100g] = useState([]);
    const [menuName, setMenuName] = useState('');
    const isCalculationAllowed = activeRestaurant && activeRestaurant.modules
        ? activeRestaurant.modules.indexOf('vn') > -1 || activeRestaurant.modules.indexOf('one-time-vn') > -1
            ? true
            : false
        : false;

    const previewShort = useRef(null);
    const previewFull = useRef(null);
    const breadcrumb =
        menuItemId !== 'new'
            ? [
                { label: activeRestaurant ? activeRestaurant.title : null, link: ROUTES.ADMIN_LOCATIONS + restaurantId },
                {
                    label: menuItemData ? menuName : null,
                    link: ROUTES.ADMIN_LOCATIONS + restaurantId + '/menus/' + menuItemData.menuIdentificationToken,
                },
                {
                    label: menuItemData ? menuItemData.category : null,
                    link:
                        ROUTES.ADMIN_LOCATIONS +
                        restaurantId +
                        '/menus/' +
                        menuItemData.menuIdentificationToken +
                        '#items?category=' +
                        menuItemData.menuCategoryIdentificationToken,
                },
            ]
            : [
                { label: activeRestaurant ? activeRestaurant.title : null, link: ROUTES.ADMIN_LOCATIONS + restaurantId },
                {
                    label: menuName ? menuName : null,
                    link: ROUTES.ADMIN_LOCATIONS + restaurantId + '/menus/' + routeLocation.state.menuId,
                },
            ];
    const INITIAL_STATE_TAXONOMY = {
        ingredients: [],
        englishIngredients: [],
        allergens: [],
        englishAllergens: [],
        foodAdditives: [],
    };
    const [taxonomyList, setTaxonomyList] = useState({ ...INITIAL_STATE_TAXONOMY });
    const [newTaxonomyList, setNewTaxonomyList] = useState({
        ingredients: [],
        englishIngredients: [],
        allergens: [],
        englishAllergens: [],
        foodAdditives: [],
    });
    let taxonomyContainer = {};
    const [lastMenuCategory, setLastMenuCategory] = useRecoilState(savedMenuCategory);

    const callbackTabs = (key) => {
        setActiveTabKey(key);
    };
    const callbackTabsPreview = (key) => {
        setActiveTabKeyPreview(key);
    };
    const callbackTabsTable = (key) => {
        setActiveTabKeyTable(key);
    };

    const fieldsDictionary = {
        menuCategoryIdentificationToken: 'Categorie',
        price: 'Pret',
        weight: 'Gramaj',
        title: 'Denumire',
    };

    const INITIAL_STATE_ELEMENT = {
        title: '',
        uniqueIdentificationToken: null,
        menuCategoryIdentificationToken: '',
        menuIdentificationToken: props.menuId,
        description: '',
        itemImagePath: null,
        preparationTime: '',
        price: 0,
        ingredients: '',
        allergens: '',
        tags: '',
        suggestion: '',
        active: true,
        category: '',
        weight: 0,
        vegan: false,
        diet: false,
        suitableForFasting: false,
        freezedIngredientsUsed: false,
        spicy: false,
        groups: [],
        menuItemIndex: 0,
        englishTitle: null,
        englishDescription: null,
        englishIngredients: null,
        englishAllergens: null,
        englishSuggestion: null,
        calculatePriceByWeight: false,
        weightMultiplicationFactor: 0,
        energy: null,
        fat: 0,
        saturates: 0,
        carbohydrate: 0,
        sugars: 0,
        proteins: 0,
        salt: 0,
        foodAdditives: null,
        displayFoodAdditives: null,
        isPrepackedProduct: false,
        containsAdditives: false,
        listOfIngredients: [],
        prepackedProduct: {},
        compositeIngredient: 0,
        unitOfMeasure: 'g',
        weightOfEndProduct: 0,
        lockNutritionDeclaration: false,
        glovoPrice: 0,
        availableForGlovo: false
    };

    const tooltipCalculatePriceByWeight = {
        title: '',
        text: <div>Pretul se va calcula in functie de gramaj. Formula de calcul este gramaj * pretul introdus mai sus.</div>,
    };
    const tooltipCompositeIngredient = {
        title: '',
        text: <div>Preparatul poate fi folosit in alte retete ca ingredient compus</div>,
    };
    const tooltipWeight = {
        title: '',
        text: <div>Introduceți inclusiv unitatea de măsura, ex.: 350g sau 400ml</div>,
    };
    const columnsNutritional = [
        {
            title: 'Name',
            dataIndex: 'nutritionalName',
            key: 'nutritionalName',
            render: (text, record) => <span className={'nutritional-name ' + record.type}>{text}</span>,
        },
        {
            title: 'Value',
            dataIndex: 'nutritionalValue',
            key: 'nutritionalValue',
            render: (text) => <span className={'nutritional-value'}>{text}</span>,
            align: 'right',
        },
    ];
    useEffect(() => {
        window.scrollTo(0, 0);
        const isDuplicateElement = routeLocation.pathname.split('/')[6] === 'copy';
        if (restaurantId) {
            if (menuItemId) {
                if (menuItemId === 'new') {
                    onReset();
                    setActionType('new');
                    ApiHelpers.getUUID().then(
                        (response) => {
                            setNewElement({
                                ...INITIAL_STATE_ELEMENT,
                                uniqueIdentificationToken: response.data.uuid,
                                menuCategoryIdentificationToken: lastMenuCategory !== '' ? lastMenuCategory : '',
                            });
                            if (lastMenuCategory !== '') {
                                form.setFieldsValue({
                                    menuCategoryIdentificationToken: lastMenuCategory,
                                });
                            }
                            // Get menu categories
                            ApiHelpers.getMenuCategories(routeLocation.state.menuId).then(
                                (response) => {
                                    setMenuCategoriesData(response.data);
                                },
                                (error) => {
                                    console.log(error);
                                }
                            );
                            //Get menu name
                            ApiHelpers.getMenuByUniqueCode(restaurantId, routeLocation.state.menuId).then(
                                (response) => {
                                    setMenuName(response.data[0].menuName);
                                },
                                (error) => {
                                    console.log(error);
                                }
                            );
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                } else {
                    setActionType('edit');
                    // Get Menu Item details
                    ApiHelpers.getMenuItem(menuItemId, restaurantId).then(
                        (response) => {
                            let recordData = !isDuplicateElement
                                ? response.data
                                : { ...response.data, title: response.data.title + ' [COPIE]', active: false, integrationCode: null };
                            if (isDuplicateElement) {
                                setActionType('copy');
                                ApiHelpers.getUUID().then(
                                    (response) => {
                                        recordData = { ...recordData, uniqueIdentificationToken: response.data.uuid };
                                        setNewElement(recordData);
                                        setMenuItemData(recordData);
                                        onEditElement(recordData);
                                    },
                                    (error) => {
                                        console.log(error);
                                    }
                                );
                            } else {
                                setNewElement(recordData);
                                setMenuItemData(recordData);
                                onEditElement(recordData);
                            }

                            setListOfIngredients(response.data.listOfIngredients);
                            // Get menu categories
                            ApiHelpers.getMenuCategories(response.data.menuIdentificationToken).then(
                                (response) => {
                                    setMenuCategoriesData(response.data);
                                },
                                (error) => {
                                    console.log(error);
                                }
                            );

                            //Get menu name
                            ApiHelpers.getMenuByUniqueCode(restaurantId, response.data.menuIdentificationToken).then(
                                (response) => {
                                    setMenuName(response.data[0].menuName);
                                },
                                (error) => {
                                    console.log(error);
                                }
                            );
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                }
            }

            // Get Menu Item Groups by restaurant
            ApiHelpers.getMenuItemGroup(restaurantId).then(
                (response) => {
                    setMenuItemGroups(response.data);
                },
                (error) => {
                    console.log(error);
                }
            );

            ApiHelpers.getIntegrationProducts(restaurantId).then(
                (response) => {
                    setIntegrationMenuItems(response.data);
                    setIsLoading(false);
                },
                (error) => {
                    console.log(error);
                }
            );
        }
    }, [restaurantId, menuItemId]);

    const onClose = () => {
        setDrawerVisible(false);
        setListOfIngredients([...copyListOfIngredients]);
        // setActiveTabKey('ro');
    };
    const onFocusSelectTaxonomy = (value, taxonomy, languageCode = 'ro_RO') => {
        ApiHelpers.getMenuTaxonomy(taxonomy, value, languageCode).then(
            (response) => {
                setTaxonomyList({ ...taxonomyList, [taxonomy]: response.data });
            },
            (error) => {
                console.log(error);
            }
        );
    };

    const onChangeSelectTaxonomy = (value, taxonomy) => {
        if (value) {
            const latestValue = value[value.length - 1];
            const stringTaxonomy = value.map((d) => {
                return d;
            });
            if (taxonomyList[taxonomy].length > 0 && taxonomyList[taxonomy].filter((e) => e.title === latestValue).length <= 0) {
                if (newTaxonomyList[taxonomy].indexOf(latestValue) < 0)
                    setNewTaxonomyList({ ...newTaxonomyList, [taxonomy]: [...newTaxonomyList[taxonomy], latestValue] });
            }
            setNewElement({
                ...newElement,
                [taxonomy]: stringTaxonomy.join(),
            });
        }
    };

    const onEditElement = (record, contentType, drawerVisible = true) => {
        // Set form fields values
        const editObject = {
            ...record,
        };

        if (record.groups && record.groups.length > 0) {
            const newArr = record.groups.map((d) => {
                return { uniqueIdentificationToken: d };
            });
            setMenuItemFormList([...newArr]);
            refreshForm(newArr);
        }

        taxonomyContainer.ingredients = record.ingredients !== '' && record.ingredients !== null ? record.ingredients.split(',') : [];
        taxonomyContainer.allergens = record.allergens !== '' && record.allergens !== null ? record.allergens.split(',') : [];
        taxonomyContainer.englishIngredients =
            record.englishIngredients !== '' && record.englishIngredients !== null ? record.englishIngredients.split(',') : [];
        taxonomyContainer.englishAllergens =
            record.englishAllergens !== '' && record.englishAllergens !== null ? record.englishAllergens.split(',') : [];
        taxonomyContainer.foodAdditives = record.foodAdditives !== '' && record.foodAdditives !== null ? record.foodAdditives.split(',') : [];

        form.setFieldsValue({
            ...editObject,
            ingredients: taxonomyContainer.ingredients,
            allergens: taxonomyContainer.allergens,
            englishIngredients: taxonomyContainer.englishIngredients,
            englishAllergens: taxonomyContainer.englishAllergens,
            foodAdditives: taxonomyContainer.foodAdditives,
        });
        // Update state with the element being edited
        setNewElement({ ...editObject });
    };

    const onSaveIngredient = () => {
        let validation = true;
        listOfIngredients.map((d) => {
            if (!d.quantity) validation = false;
        });
        if (validation) {
            setNewElement({ ...newElement, ...nutritionalCalculation(listOfIngredients, '100g', newElement.weightOfEndProduct) });
            setDrawerVisible(false);
            setSelectedIngredient();
            onSave();
        } else {
            message.error('Eroare! Campurile obligatorii trebuie completate');
        }
    };

    const onSave = (redirect = false) => {
        // Validate form
        form.validateFields()
            .then(async () => {
                let groupsArray = [];
                const hideMessage = message.loading({
                    content: 'Se salvează modificările...',
                    key: 'menuItemUpdate',
                });
                if (menuItemFormList.length > 0)
                    // Collect groups from state and convert to array of strings
                    groupsArray = menuItemFormList.map((d) => {
                        return d.uniqueIdentificationToken;
                    });
                if (actionType === 'new' || actionType === 'copy') {
                    // onSaveSelectTaxonomy('ingredients', 'ro_RO');
                    // onSaveSelectTaxonomy('englishIngredients', 'en_US');
                    // post ingredients
                    await Promise.all(
                        listOfIngredients.map(async (d) => {
                            if (d.customIngredient === true || d.newIngredient === true || d.modifiedIngredientInsert === true)
                                ApiHelpers.postMenuIngredient(d).then(
                                    (response) => { },
                                    (error) => { }
                                );
                        })
                    );
                    ApiHelpers.postMenuItem({
                        ...newElement,
                        groups: [...groupsArray],
                        weightMultiplicationFactor: newElement.weight ? newElement.weight.replace(/[^0-9\.]/g, '') : 0,
                        ...nutritionalCalculation(listOfIngredients, '100g', newElement.weightOfEndProduct),
                        listOfIngredients: [...listOfIngredients],
                    }).then(
                        (response) => {
                            if (response.data.message) {
                                if (response.data.message.indexOf('Title') > -1) {
                                    message.error('Eroare sursa de data. Un preparat cu aceeasi denumire exista deja.');
                                } else message.error('Eroare sursa de date.');
                            } else {
                                hideMessage();
                                message.success('Element adaugat!');
                                setLastMenuCategory(newElement.menuCategoryIdentificationToken);
                                //onReset();
                                //refreshData();
                                setDrawerVisible(false);
                                if (redirect) navigate(-1);
                                else
                                    navigate(ROUTES.ADMIN_LOCATIONS + restaurantId + ROUTES.ADMIN_MENU_ITEM + newElement.uniqueIdentificationToken, {
                                        replace: true,
                                    });
                            }
                        },
                        (error) => {
                            //console.log(error, error.toJSON().config.data);
                            //Bugsnag.notify(new Error(error));
                            Bugsnag.notify(new Error(error.toJSON().config.data));
                            message.error('Eroare!');
                        }
                    );
                } else if (actionType === 'edit') {
                    // post ingredients
                    await Promise.all(
                        listOfIngredients.map(async (d) => {
                            if (d.customIngredient === true || d.newIngredient === true || d.modifiedIngredientInsert === true)
                                ApiHelpers.postMenuIngredient(d).then(
                                    (response) => { },
                                    (error) => { }
                                );
                        })
                    );
                    // update ingredients when review=true
                    await Promise.all(
                        listOfIngredients.map(async (d) => {
                            if (d.review === true)
                                ApiHelpers.updateMenuIngredient({ ...d, review: false }).then(
                                    (response) => { },
                                    (error) => { }
                                );
                        })
                    );
                    ApiHelpers.updateMenuItem({
                        ...newElement,
                        groups: [...groupsArray],
                        weightMultiplicationFactor: newElement.weight ? newElement.weight.replace(/[^0-9\.]/g, '') : 0,
                        ...nutritionalCalculation(listOfIngredients, '100g', newElement.weightOfEndProduct),
                        listOfIngredients: [...listOfIngredients],
                    }).then(
                        (response) => {
                            if (response.data.message) {
                                message.error('Eroare sursa de date.');
                            } else {
                                hideMessage();
                                message.success('Element modificat cu succes!');
                                onReset();
                                refreshData();
                                if (redirect) navigate(-1);
                            }
                        },
                        (error) => {
                            //console.log(error, error.toJSON().config.data);
                            //Bugsnag.notify(new Error(error));
                            Bugsnag.notify(new Error(error.toJSON().config.data));
                            message.error('Eroare!');
                        }
                    );
                }
            })
            .catch((info) => {
                const errorFields = [];
                if (info && info.errorFields) {
                    info.errorFields.map((d) => {
                        errorFields.push(fieldsDictionary[d.name] || d.name);
                    });
                    //console.log('Exista probleme cu urmatoarele campuri', errorFields.join(','));
                    message.error('Există probleme cu urmatoarele câmpuri: ' + errorFields.join(', '));
                }
            });
    };

    const onChange = (event, numberValidation = false) => {
        let value = event.target.value;
        if (numberValidation === true) {
            value = value.replace(/[^0-9\.]/g, '');
        }
        setNewElement({
            ...newElement,
            [event.target.name]: value,
        });
    };

    const onChangeCheckbox = (event, field) => {
        setNewElement({
            ...newElement,
            [field]: event.target.checked,
        });
    };

    const onChangeSelect = (value) => {
        setNewElement({
            ...newElement,
            menuCategoryIdentificationToken: value,
        });
    };

    const onChangeSelectIntegration = (value) => {
        setNewElement({
            ...newElement,
            integrationCode: value,
        });
    };

    const onDelete = () => {
        ApiHelpers.deleteMenuItem(newElement.uniqueIdentificationToken, restaurantId).then(
            (response) => {
                if (response.data.statusCode.statusCode === 200) {
                    message.success('Elementul a fost sters!');
                    navigate(-1);
                }
            },
            (error) => {
                console.log(error);
                Bugsnag.notify(new Error(error));
                message.error('Eroare!');
            }
        );
    };

    const onSaveSelectTaxonomy = (taxonomy, languageCode) => {
        if (newElement[taxonomy]) {
            const taxonomyArray = newElement[taxonomy].split(',');
            taxonomyArray.forEach(function (element) {
                ApiHelpers.postMenuTaxonomy(taxonomy, element, languageCode).then(
                    (response) => { },
                    (error) => {
                        console.log(error);
                    }
                );
            });
        }
    };

    const onReset = () => {
        form.resetFields();
        setTaxonomyList({ ...INITIAL_STATE_TAXONOMY });
        setNewTaxonomyList({ ...INITIAL_STATE_TAXONOMY });
        setMenuItemFormList([]);
        setListOfIngredients([]);
        setNewElement({ ...INITIAL_STATE_ELEMENT });
    };

    const refreshData = () => {
        ApiHelpers.getMenuItem(menuItemId, restaurantId).then(
            (response) => {
                setMenuItemData(response.data);
                setNewElement(response.data);
                setListOfIngredients(response.data.listOfIngredients);
                // Get menu categories
                ApiHelpers.getMenuCategories(response.data.menuIdentificationToken).then(
                    (response) => {
                        setMenuCategoriesData(response.data);
                    },
                    (error) => {
                        console.log(error);
                    }
                );
                onEditElement(response.data);

                //Get menu name
                ApiHelpers.getMenuByUniqueCode(restaurantId, response.data.menuIdentificationToken).then(
                    (response) => {
                        setMenuName(response.data[0].menuName);
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            },
            (error) => {
                console.log(error);
            }
        );
    };

    const menuCategoryList = menuCategoriesData.map((d) => ({
        text: d.title,
        value: d.uniqueIdentificationToken,
    }));

    const navigate = useNavigate();

    useEffect(() => {
        if (routeLocation.hash.indexOf('category=') > -1) {
            const menuCategory = routeLocation.hash.substring(routeLocation.hash.indexOf('category=') + 9);
            setLastMenuCategory(menuCategory);
        }
        if (routeLocation.hash.indexOf('/menuitem/') > -1) {
            const hashMenuItem = routeLocation.hash.substring(routeLocation.hash.indexOf('category=') + 17);
            const foundMenuItem = menuItemsData.find((d) => d.uniqueIdentificationToken === hashMenuItem);
            if (foundMenuItem) {
                onEditElement(foundMenuItem, 'menuCategory');
            }
        }
    }, [routeLocation, menuItemsData]);

    const addMenuItemFormCombo = () => {
        setMenuItemFormList((menuItemFormList) => [
            ...menuItemFormList,
            {
                uniqueIdentificationToken: null,
            },
        ]);
    };

    const addIngredientFormCombo = () => {
        const copy = listOfIngredients.map((item) => {
            return { ...item };
        });
        setCopyListOfIngredients([...copy]);
        setDrawerVisible(true);
    };

    const refreshForm = (data) => {
        data.map((d, index) => {
            const menuItem = 'menuItem-' + index;
            form.setFieldsValue({
                [menuItem]: d.uniqueIdentificationToken,
            });
        });
    };

    return (
        <AuthUserContext.Consumer>
            {(authUser) => (
                <AdminLayout breadcrumb={breadcrumb} authUser={authUser} pageTitle={menuItemData.title}>
                    {(actionType !== 'new' && Object.keys(menuItemData).length > 0) || (actionType === 'new' && menuItemData) ? (
                        <div>
                            <h2>
                                <a onClick={() => navigate(-1)}>
                                    <ArrowLeftOutlined />
                                </a>{' '}
                                {menuItemId !== 'new' ? menuItemData.title : 'Adauga preparat'}
                            </h2>
                            <Row>
                                <Col
                                    xs={{ span: 24, offset: 0 }}
                                    md={{ span: 24, offset: 0 }}
                                    lg={{ span: 24, offset: 0 }}
                                    xl={{ span: 24, offset: 0 }}
                                    xxl={{ span: 18, offset: 0 }}>
                                    {actionType === 'copy' ? (
                                        <Alert
                                            showIcon
                                            description="Acest element este o copie, denumirea trebuie modificată și setată vizibilitatea. Nu uitați să salvați!"
                                            message="Copie nesalvată"
                                            type="info"
                                            className="spacingBottom"
                                        />
                                    ) : null}
                                    <Form layout="vertical" form={form} size="large">
                                        <Tabs defaultActiveKey="ro" activeKey={activeTabKey} onChange={callbackTabs} type="card">
                                            <TabPane tab="RO" key="ro">
                                                <Collapse
                                                    className="formCollapse"
                                                    bordered={false}
                                                    defaultActiveKey={['info', 'delivery', 'nutritional', 'tags', 'media']}>
                                                    <Panel header="Informații" key="info">
                                                        <Row gutter={[15, 15]}>
                                                            <Col
                                                                xs={{ span: 24, offset: 0 }}
                                                                md={{ span: 18, offset: 0 }}
                                                                lg={{ span: 18, offset: 0 }}
                                                                xl={{ span: 18, offset: 0 }}
                                                                xxl={{ span: 18, offset: 0 }}>
                                                                <Form.Item
                                                                    name="menuCategoryIdentificationToken"
                                                                    label="Categorie"
                                                                    rules={[{ required: true, message: 'Camp obligatoriu' }]}>
                                                                    <Select
                                                                        size="large"
                                                                        placeholder="Selecteaza categorie"
                                                                        onChange={onChangeSelect}
                                                                        name="menuCategoryIdentificationToken"
                                                                        value={newElement.menuCategoryIdentificationToken}>
                                                                        {menuCategoriesData.map((d) => (
                                                                            <Option
                                                                                key={d.uniqueIdentificationToken}
                                                                                value={d.uniqueIdentificationToken}>
                                                                                {d.title}
                                                                            </Option>
                                                                        ))}
                                                                    </Select>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col
                                                                xs={{ span: 24, offset: 0 }}
                                                                md={{ span: 6, offset: 0 }}
                                                                lg={{ span: 6, offset: 0 }}
                                                                xl={{ span: 6, offset: 0 }}
                                                                xxl={{ span: 6, offset: 0 }}>
                                                                <Form.Item
                                                                    name="compositeIngredient"
                                                                    label="Retatar"
                                                                    rules={[{ required: false, message: '' }]}>
                                                                    <Checkbox
                                                                        checked={newElement.compositeIngredient}
                                                                        onChange={(event) => onChangeCheckbox(event, 'compositeIngredient')}>
                                                                        Ingredient compus
                                                                        <Popover
                                                                            className="iconSpacing"
                                                                            placement="right"
                                                                            title={tooltipCompositeIngredient.title}
                                                                            content={tooltipCompositeIngredient.text}
                                                                            trigger="hover">
                                                                            <InfoCircleOutlined />
                                                                        </Popover>
                                                                    </Checkbox>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>

                                                        <Row gutter={[15, 15]}>
                                                            <Col
                                                                xs={{ span: 24, offset: 0 }}
                                                                md={{ span: 18, offset: 0 }}
                                                                lg={{ span: 18, offset: 0 }}
                                                                xl={{ span: 18, offset: 0 }}
                                                                xxl={{ span: 18, offset: 0 }}>
                                                                <Form.Item
                                                                    name="title"
                                                                    label="Denumire"
                                                                    rules={[
                                                                        {
                                                                            validator: (_, value) =>
                                                                                !value
                                                                                    ? Promise.reject(new Error('Campul este obligatoriu'))
                                                                                    : value && value.indexOf('[COPIE]') === -1
                                                                                        ? Promise.resolve()
                                                                                        : Promise.reject(new Error('Denumirea trebuie modificată')),
                                                                        },
                                                                    ]}>
                                                                    <Input
                                                                        placeholder="Please enter name"
                                                                        onBlur={onChange}
                                                                        name="title"
                                                                        value={newElement.title}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col
                                                                xs={{ span: 24, offset: 0 }}
                                                                md={{ span: 6, offset: 0 }}
                                                                lg={{ span: 6, offset: 0 }}
                                                                xl={{ span: 6, offset: 0 }}
                                                                xxl={{ span: 6, offset: 0 }}>
                                                                <Form.Item
                                                                    name="price"
                                                                    label="Preț (lei)"
                                                                    rules={[
                                                                        {
                                                                            required: !newElement.compositeIngredient ? true : false,
                                                                            message: 'Camp obligatoriu',
                                                                        },
                                                                        ({ getFieldValue }) => ({
                                                                            validator(_, value) {
                                                                                if (newElement.compositeIngredient || value > 0) {
                                                                                    return Promise.resolve();
                                                                                }
                                                                                return Promise.reject('Pretul trebuie sa fie  > 0!');
                                                                            },
                                                                        }),
                                                                    ]}>
                                                                    <Input
                                                                        placeholder="Pret"
                                                                        onBlur={onChange}
                                                                        name="price"
                                                                        value={newElement.price}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Row gutter={[15, 15]}>
                                                            <Col
                                                                xs={{ span: 24, offset: 0 }}
                                                                md={{ span: 24, offset: 0 }}
                                                                lg={{ span: 18, offset: 0 }}
                                                                xl={{ span: 18, offset: 0 }}
                                                                xxl={{ span: 18, offset: 0 }}>
                                                                <Form.Item name="description" label="Descriere" rules={[{ required: false }]}>
                                                                    <TextArea
                                                                        rows="5"
                                                                        placeholder="Please enter name"
                                                                        onBlur={onChange}
                                                                        name="description"
                                                                        value={newElement.description}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col
                                                                xs={{ span: 24, offset: 0 }}
                                                                md={{ span: 6, offset: 0 }}
                                                                lg={{ span: 6, offset: 0 }}
                                                                xl={{ span: 6, offset: 0 }}
                                                                xxl={{ span: 6, offset: 0 }}>
                                                                <Form.Item
                                                                    name="weight"
                                                                    label={
                                                                        <>
                                                                            Gramaj
                                                                            <Popover
                                                                                className="iconSpacing"
                                                                                placement="right"
                                                                                title={tooltipWeight.title}
                                                                                content={tooltipWeight.text}
                                                                                trigger="hover">
                                                                                <InfoCircleOutlined />
                                                                            </Popover>
                                                                        </>
                                                                    }
                                                                    rules={[
                                                                        {
                                                                            required: !newElement.compositeIngredient ? true : false,
                                                                            message: 'Camp obligatoriu',
                                                                        },
                                                                    ]}>
                                                                    <Input
                                                                        placeholder="Gramaj"
                                                                        onBlur={(event) => onChange(event)}
                                                                        name="weight"
                                                                        value={newElement.weight}
                                                                    />
                                                                </Form.Item>
                                                                <Checkbox
                                                                    checked={newElement.freezedIngredientsUsed}
                                                                    onChange={(event) =>
                                                                        onChangeCheckbox(event, 'freezedIngredientsUsed')
                                                                    }>
                                                                    Poate proveni din produs congelat
                                                                </Checkbox>
                                                            </Col>
                                                        </Row>

                                                        <Form.Item name="active" label="Vizibilitate" rules={[{ required: false, message: '' }]}>
                                                            <Checkbox
                                                                checked={newElement.active}
                                                                onChange={(event) => onChangeCheckbox(event, 'active')}>
                                                                Activ
                                                            </Checkbox>
                                                        </Form.Item>
                                                    </Panel>

                                                    <Panel header={
                                                        <>
                                                            Livratori
                                                            <Tag color="#f50" className="sidebar-menu-tag">
                                                                nou
                                                            </Tag>
                                                        </>
                                                    }
                                                        key="delivery">
                                                        <Row>
                                                            <Col
                                                                xs={{ span: 24, offset: 0 }}
                                                                md={{ span: 6, offset: 0 }}
                                                                lg={{ span: 6, offset: 0 }}
                                                                xl={{ span: 6, offset: 0 }}
                                                                xxl={{ span: 6, offset: 0 }}
                                                            >
                                                                <Form.Item name="availableForGlovo" label="Vizibilitate Glovo" rules={[{ required: false, message: '' }]}>
                                                                    <Checkbox
                                                                        checked={newElement.availableForGlovo}
                                                                        onChange={(event) => onChangeCheckbox(event, 'availableForGlovo')}>
                                                                        Activ
                                                                    </Checkbox>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col
                                                                xs={{ span: 24, offset: 0 }}
                                                                md={{ span: 6, offset: 0 }}
                                                                lg={{ span: 6, offset: 0 }}
                                                                xl={{ span: 6, offset: 0 }}
                                                                xxl={{ span: 6, offset: 0 }}>
                                                                <Form.Item
                                                                    name="glovoPrice"
                                                                    label={"Preț Glovo (lei)"}
                                                                    rules={[
                                                                        {
                                                                            required: false,
                                                                            message: 'Camp obligatoriu',
                                                                        },
                                                                        ({ getFieldValue }) => ({
                                                                            validator(_, value) {
                                                                                if (value > 0) {
                                                                                    return Promise.resolve();
                                                                                }
                                                                                return Promise.reject('Pretul trebuie sa fie  > 0!');
                                                                            },
                                                                        }),
                                                                    ]}
                                                                >
                                                                    <Input
                                                                        placeholder={Math.round(newElement.price * 1.3)}
                                                                        onBlur={onChange}
                                                                        name="glovoPrice"
                                                                        value={Math.floor(newElement.glovoPrice) != 0 ? newElement.glovoPrice : undefined}
                                                                        
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Panel>
                                                    <Panel
                                                        header={
                                                            <>
                                                                Rețetar și declarație nutriționala

                                                            </>
                                                        }
                                                        key="nutritional">
                                                        {newElement.lockNutritionDeclaration !== false ? (
                                                            <Alert
                                                                title=""
                                                                type="warning"
                                                                message={
                                                                    <>
                                                                        <LockOutlined /> Calculul valorilor nutriționale este blocat. Valorile
                                                                        nutriționale nu pot fi calculate pentru acest preparat.
                                                                    </>
                                                                }
                                                            />
                                                        ) : null}
                                                        {newElement.lockNutritionDeclaration === false ? (
                                                            <Button type="secondary" onClick={() => addIngredientFormCombo()} className="btnAdd">
                                                                <ControlOutlined /> Configurează declarația
                                                            </Button>
                                                        ) : null}
                                                        <Row span={24} gutter={[15, 15]}>
                                                            <Col
                                                                xs={{ span: 24, offset: 0 }}
                                                                md={{ span: 12, offset: 0 }}
                                                                lg={{ span: 12, offset: 0 }}
                                                                xl={{ span: 12, offset: 0 }}
                                                                xxl={{ span: 12, offset: 0 }}>
                                                                {listOfIngredients ? (
                                                                    <Row className="nutritional-table-container">
                                                                        <Col
                                                                            xs={{ span: 24, offset: 0 }}
                                                                            md={{ span: 18, offset: 0 }}
                                                                            lg={{ span: 16, offset: 0 }}
                                                                            xl={{ span: 16, offset: 0 }}
                                                                            xxl={{ span: 16, offset: 0 }}
                                                                            onClick={() =>
                                                                                newElement.lockNutritionDeclaration === false
                                                                                    ? addIngredientFormCombo()
                                                                                    : null
                                                                            }>
                                                                            <Tabs
                                                                                activeKey={activeTabKeyTable}
                                                                                defaultActiveKey="100g"
                                                                                onChange={callbackTabsTable}
                                                                                className="nutritional-preview-container">
                                                                                <TabPane tab="Valori per 100g" key="#100g">
                                                                                    <Table
                                                                                        className="nutritional-table result-100g"
                                                                                        dataSource={nutritionalCalculationFrontend(
                                                                                            listOfIngredients,
                                                                                            '100g',
                                                                                            newElement.weightOfEndProduct
                                                                                        )}
                                                                                        columns={columnsNutritional}
                                                                                        pagination={false}
                                                                                        showHeader={false}
                                                                                        rowClassName={(record, index) =>
                                                                                            index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
                                                                                        }
                                                                                    />
                                                                                </TabPane>
                                                                                <TabPane
                                                                                    tab={
                                                                                        'Valori per porție ' +
                                                                                        nutritionalCalculationSumIngredients(listOfIngredients)
                                                                                    }
                                                                                    key="#full">
                                                                                    <Table
                                                                                        className="nutritional-table result-100g"
                                                                                        dataSource={nutritionalCalculationFrontend(
                                                                                            listOfIngredients,
                                                                                            'full',
                                                                                            newElement.weightOfEndProduct
                                                                                        )}
                                                                                        columns={columnsNutritional}
                                                                                        pagination={false}
                                                                                        showHeader={false}
                                                                                        rowClassName={(record, index) =>
                                                                                            index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
                                                                                        }
                                                                                    />
                                                                                </TabPane>
                                                                            </Tabs>
                                                                        </Col>
                                                                    </Row>
                                                                ) : null}
                                                            </Col>
                                                            <Col
                                                                xs={{ span: 24, offset: 0 }}
                                                                md={{ span: 12, offset: 0 }}
                                                                lg={{ span: 12, offset: 0 }}
                                                                xl={{ span: 12, offset: 0 }}
                                                                xxl={{ span: 12, offset: 0 }}>
                                                                <Row>
                                                                    <Col>
                                                                        <Tabs
                                                                            activeKey={activeTabKeyPreview}
                                                                            defaultActiveKey={routeLocation.hash}
                                                                            onChange={callbackTabsPreview}
                                                                            className="nutritional-preview-container">
                                                                            <TabPane tab="Afișare rezumat" key="#short">
                                                                                <div className="nutritional-preview short">
                                                                                    {listOfIngredients.length > 0 ? (
                                                                                        <>
                                                                                            <div
                                                                                                className="inner"
                                                                                                ref={previewShort}
                                                                                                onClick={() =>
                                                                                                    newElement.lockNutritionDeclaration === false
                                                                                                        ? addIngredientFormCombo()
                                                                                                        : null
                                                                                                }>
                                                                                                <div>
                                                                                                    {nutritionalMenuShort2(
                                                                                                        listOfIngredients,
                                                                                                        newElement
                                                                                                    )}
                                                                                                </div>{' '}
                                                                                            </div>
                                                                                            <Space>
                                                                                                <Button
                                                                                                    icon={<CopyOutlined />}
                                                                                                    onClick={() => {
                                                                                                        navigator.clipboard.writeText(
                                                                                                            previewShort.current.innerText
                                                                                                        );
                                                                                                        message.success(
                                                                                                            'Conținutul a fost copiat in clipboard'
                                                                                                        );
                                                                                                    }}
                                                                                                    size="middle">
                                                                                                    Copiază
                                                                                                </Button>
                                                                                                <MenuItemPrint
                                                                                                    title={newElement.title}
                                                                                                    nutritionalData={listOfIngredients}
                                                                                                    record={newElement}
                                                                                                    type="short"
                                                                                                />
                                                                                            </Space>
                                                                                        </>
                                                                                    ) : (
                                                                                        <a
                                                                                            onClick={() => addIngredientFormCombo()}
                                                                                            className="nutritional-table-action">
                                                                                            <ControlOutlined /> Configurează declarația
                                                                                        </a>
                                                                                    )}
                                                                                </div>
                                                                            </TabPane>
                                                                            <TabPane tab="Afișare completă" key="#full">
                                                                                <>
                                                                                    <div className="nutritional-preview">
                                                                                        {listOfIngredients.length > 0 ? (
                                                                                            <div
                                                                                                className="inner"
                                                                                                ref={previewFull}
                                                                                                onClick={() => addIngredientFormCombo()}>
                                                                                                <div>
                                                                                                    {nutritionalMenuFull(
                                                                                                        listOfIngredients,
                                                                                                        newElement
                                                                                                    )}
                                                                                                </div>{' '}
                                                                                            </div>
                                                                                        ) : (
                                                                                            <a
                                                                                                onClick={() => addIngredientFormCombo()}
                                                                                                className="nutritional-table-action">
                                                                                                <ControlOutlined /> Configurează declarația
                                                                                            </a>
                                                                                        )}
                                                                                    </div>
                                                                                    <Space>
                                                                                        <Button
                                                                                            icon={<CopyOutlined />}
                                                                                            onClick={() => {
                                                                                                navigator.clipboard.writeText(
                                                                                                    previewFull.current.innerText
                                                                                                );
                                                                                                message.success(
                                                                                                    'Conținutul a fost copiat in clipboard'
                                                                                                );
                                                                                            }}
                                                                                            size="middle">
                                                                                            Copiază
                                                                                        </Button>
                                                                                        <MenuItemPrint
                                                                                            title={newElement.title}
                                                                                            nutritionalData={listOfIngredients}
                                                                                            record={newElement}
                                                                                            type="full"
                                                                                        />
                                                                                    </Space>
                                                                                </>
                                                                            </TabPane>
                                                                        </Tabs>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Panel>
                                                    <Panel header={
                                                        <>
                                                            Etichete

                                                        </>
                                                    }
                                                        key="tags">
                                                        <Row gutter={[15, 15]}>
                                                            <Col>
                                                                <Checkbox
                                                                    checked={newElement.vegan}
                                                                    onChange={(event) => onChangeCheckbox(event, 'vegan')}>
                                                                    Vegan
                                                                </Checkbox>
                                                            </Col>
                                                            <Col>
                                                                <Checkbox
                                                                    checked={newElement.diet}
                                                                    onChange={(event) => onChangeCheckbox(event, 'diet')}>
                                                                    De dieta
                                                                </Checkbox>
                                                            </Col>
                                                            <Col>
                                                                <Checkbox
                                                                    checked={newElement.suitableForFasting}
                                                                    onChange={(event) => onChangeCheckbox(event, 'suitableForFasting')}>
                                                                    De post
                                                                </Checkbox>
                                                            </Col>

                                                            <Col>
                                                                <Checkbox
                                                                    checked={newElement.spicy}
                                                                    onChange={(event) => onChangeCheckbox(event, 'spicy')}>
                                                                    Picant
                                                                </Checkbox>
                                                            </Col>
                                                            <Col>
                                                                <Checkbox
                                                                    checked={newElement.containsAdditives}
                                                                    onChange={(event) => onChangeCheckbox(event, 'containsAdditives')}>
                                                                    Contine aditivi
                                                                </Checkbox>
                                                            </Col>
                                                            <Col>
                                                                <Checkbox
                                                                    checked={newElement.calculatePriceByWeight}
                                                                    onChange={(event) =>
                                                                        onChangeCheckbox(event, 'calculatePriceByWeight')
                                                                    }>
                                                                    Pretul se calculeaza per gramaj
                                                                    <Popover
                                                                        className="iconSpacing"
                                                                        placement="right"
                                                                        title={tooltipCalculatePriceByWeight.title}
                                                                        content={tooltipCalculatePriceByWeight.text}
                                                                        trigger="hover">
                                                                        <InfoCircleOutlined />
                                                                    </Popover>
                                                                </Checkbox>
                                                            </Col>
                                                        </Row>
                                                    </Panel>
                                                    {newElement.ingredients || newElement.allergens ? (
                                                        <Panel
                                                            header={
                                                                <>
                                                                    Ingrediente și alergeni
                                                                    <Tag color="warning" className="sidebar-menu-tag">
                                                                        înlocuit
                                                                    </Tag>
                                                                </>
                                                            }
                                                            key="ingredients">
                                                            <Alert
                                                                title=""
                                                                type="warning"
                                                                showIcon
                                                                message="În cazul în care ați completat rețetarul de mai sus, aceste valori nu trebuie completate si sunt ignorate."
                                                            />
                                                            <Form.Item
                                                                name="ingredients"
                                                                label="Ingrediente"
                                                                rules={[
                                                                    {
                                                                        required: menuItemFormList.length > 0 ? false : true,
                                                                        message: 'Camp obligatoriu',
                                                                    },
                                                                ]}>
                                                                <Select
                                                                    mode="tags"
                                                                    name="ingredients"
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Ingrediente"
                                                                    onChange={(value) => onChangeSelectTaxonomy(value, 'ingredients')}
                                                                    onFocus={(value) => onFocusSelectTaxonomy(value, 'ingredients')}>
                                                                    {taxonomyList.ingredients && taxonomyList.ingredients.length > 0
                                                                        ? taxonomyList.ingredients.map((d, index) => {
                                                                            return (
                                                                                <Option key={'ingredient-' + index} value={d.title}>
                                                                                    {d.title}
                                                                                </Option>
                                                                            );
                                                                        })
                                                                        : null}
                                                                </Select>
                                                            </Form.Item>
                                                            <Form.Item name="allergens" label="Alergeni" rules={[{ required: false }]}>
                                                                <Select
                                                                    mode="multiple"
                                                                    name="allergens"
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Alergeni"
                                                                    onChange={(value) => onChangeSelectTaxonomy(value, 'allergens')}
                                                                    onFocus={(value) => onFocusSelectTaxonomy(value, 'allergens')}
                                                                    filterOption={(inputValue, option) =>
                                                                        option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0 ||
                                                                        option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                                                                    }>
                                                                    {taxonomyList.allergens && taxonomyList.allergens.length > 0
                                                                        ? taxonomyList.allergens.map((d, index) => {
                                                                            return (
                                                                                <Option key={'allergen-' + index} value={d.title}>
                                                                                    {d.title}
                                                                                </Option>
                                                                            );
                                                                        })
                                                                        : null}
                                                                </Select>
                                                            </Form.Item>
                                                        </Panel>
                                                    ) : null}
                                                    <Panel header="Imagini" key="media">
                                                        <Form.Item
                                                            name="itemImagePath"
                                                            label={
                                                                <>
                                                                    Imagine
                                                                    <Popover
                                                                        className="iconSpacing"
                                                                        placement="right"
                                                                        title={tooltipImage.title}
                                                                        content={tooltipImage.text}
                                                                        trigger="hover">
                                                                        <InfoCircleOutlined />
                                                                    </Popover>
                                                                </>
                                                            }
                                                            rules={[{ required: false, message: 'Camp obligatoriu' }]}>
                                                            <UploadMenuItemImage
                                                                menuItemsData={menuItemsData}
                                                                setMenuItemsData={setMenuItemsData}
                                                                getMenuItems={() => { }}
                                                                menuItemId={newElement.uniqueIdentificationToken}
                                                                newElement={newElement}
                                                                setNewElement={setNewElement}
                                                                restaurantIdentificationToken={restaurantId}
                                                                menuId={props.menuId}
                                                            />
                                                        </Form.Item>
                                                    </Panel>

                                                    {!newElement.calculatePriceByWeight ? (
                                                        <Panel header="Grupuri auxiliare" key="menuItemGroups">
                                                            <Button type="secondary" onClick={() => addMenuItemFormCombo()} className="btnAdd">
                                                                <PlusOutlined /> Adauga grup
                                                            </Button>
                                                            {menuItemFormList.map((item, index) => {
                                                                return (
                                                                    <MenuItem
                                                                        key={'menuItem' + index}
                                                                        item={item}
                                                                        index={index}
                                                                        menuItems={menuItemGroups}
                                                                        menuItemFormList={menuItemFormList}
                                                                        setMenuItemFormList={setMenuItemFormList}
                                                                        form={form}
                                                                        refreshForm={refreshForm}
                                                                    />
                                                                );
                                                            })}
                                                        </Panel>
                                                    ) : null}

                                                    {activeRestaurant.activatePosIntegration === true ? (
                                                        <Panel header="Integrare POS" key="integrare">
                                                            <Form.Item
                                                                name="integrationCode"
                                                                label={
                                                                    <>
                                                                        <span className="labelIcon">Asociere preparat</span>
                                                                    </>
                                                                }
                                                                rules={[
                                                                    {
                                                                        required: false,
                                                                        message: 'Camp optional',
                                                                    },
                                                                ]}>
                                                                <Select
                                                                    size="large"
                                                                    placeholder="Alege"
                                                                    onChange={onChangeSelectIntegration}
                                                                    name="integrationCode"
                                                                    value={newElement.integrationCode}
                                                                    showSearch
                                                                    filterOption={(input, option) =>
                                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                    }>
                                                                    {integrationMenuItems.map((d) => {
                                                                        return (
                                                                            <Option key={'integrationCode-' + d.code} value={d.code}>
                                                                                {d.name + ' (' + d.unitPriceWithVat + ' lei)'}
                                                                            </Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                            </Form.Item>
                                                        </Panel>
                                                    ) : null}
                                                </Collapse>
                                            </TabPane>
                                            <TabPane tab="EN" key="en">
                                                <Form.Item
                                                    name="englishTitle"
                                                    label="Denumire"
                                                    rules={[{ required: false, message: 'Camp obligatoriu' }]}>
                                                    <Input
                                                        placeholder="Please enter name"
                                                        onBlur={onChange}
                                                        name="englishTitle"
                                                        value={newElement.englishTitle}
                                                    />
                                                </Form.Item>
                                                <Form.Item name="englishDescription" label="Descriere" rules={[{ required: false }]}>
                                                    <TextArea
                                                        rows="5"
                                                        placeholder="Please enter name"
                                                        onBlur={onChange}
                                                        name="englishDescription"
                                                        value={newElement.englishDescription}
                                                    />
                                                </Form.Item>
                                            </TabPane>
                                        </Tabs>
                                    </Form>
                                </Col>
                            </Row>
                            <Drawer
                                title="Rețetar și declarație nutriționala"
                                width={window.innerWidth > 1200 ? window.innerWidth - 100 : window.innerWidth}
                                onClose={onClose}
                                visible={drawerVisible}
                                bodyStyle={{ paddingBottom: 80 }}
                                destroyOnClose={true}
                                footer={
                                    <div className="modalFooter">
                                        <div
                                            style={{
                                                textAlign: 'left',
                                            }}></div>
                                        <div
                                            style={{
                                                textAlign: 'right',
                                            }}>
                                            <Button size="large" onClick={onClose} style={{ marginRight: 8 }}>
                                                Anulează
                                            </Button>
                                            <Button size="large" onClick={onSaveIngredient} type="primary">
                                                Salvează
                                            </Button>
                                        </div>
                                    </div>
                                }>
                                <MenuItemIngredient
                                    restaurantId={restaurantId}
                                    menuItem={newElement}
                                    setMenuItem={setNewElement}
                                    form={form}
                                    listOfIngredients={listOfIngredients}
                                    setListOfIngredients={setListOfIngredients}
                                    sumNutritionalData100g={sumNutritionalData100g}
                                    setSumNutritionalData100g={setSumNutritionalData100g}
                                    actionType={actionType}
                                />
                            </Drawer>
                            <Button type="primary" size="large" className="btnAdd" onClick={() => onSave(true)} style={{ marginRight: 8 }}>
                                Salvează
                            </Button>
                            {actionType !== 'new' && actionType !== 'copy' ? (
                                <Popconfirm title="Sigur vrei sa stergi?" onConfirm={onDelete}>
                                    <Button size="large" danger style={{ marginRight: 8 }}>
                                        Sterge
                                    </Button>
                                </Popconfirm>
                            ) : null}
                        </div>
                    ) : (
                        <Skeleton active />
                    )}
                </AdminLayout>
            )}
        </AuthUserContext.Consumer>
    );
}

export default MenuItemFormNew;
