import React, { useState, useEffect } from 'react';
import { Table, Input, Form, Skeleton, DatePicker, Space, Button, Drawer, Collapse } from 'antd';
import { useReportOrderHistory } from '../../../swr';
import { UnorderedListOutlined } from '@ant-design/icons';
import { sortByKey, splitAndIterate } from '../../../helpers';
import moment from 'moment';
const { Column } = Table;
const Search = Input.Search;
const { Panel } = Collapse;

const RestaurantOrders = (props) => {
    const [form] = Form.useForm();
    const [restaurantOrders, setRestaurantOrders] = useState([]);
    const [restaurantOrdersFilter, setRestaurantOrdersFilter] = useState([]);
    const [newElement, setNewElement] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [drawerVisible, setDrawerVisible] = useState(false);

    const INITIAL_DATE_RANGE = [moment().subtract(1, 'weeks').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
    const INITAL_DATE_RANGE_PICKER = [moment().subtract(1, 'weeks'), moment()];

    const [dateRange, setDateRange] = useState(INITIAL_DATE_RANGE);
    const { data: orderHistory, isLoading: isLoadingOrderHistory } = useReportOrderHistory(props.restaurantId, ...dateRange);
    const { RangePicker } = DatePicker;

    useEffect(() => {
        if (!isLoadingOrderHistory && orderHistory) {
            setRestaurantOrders(orderHistory);
            setRestaurantOrdersFilter(orderHistory);
            setIsLoading(false);
        }
    }, [orderHistory]);

    const onClose = () => {
        setDrawerVisible(false);
    };

    const onReset = () => {
        form.resetFields();
        setNewElement([]);
    };

    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        if (value) {
            setRestaurantOrders(
                restaurantOrdersFilter.filter((item) => {
                    if (
                        item.waiter.toLowerCase().indexOf(value) > -1 ||
                        item.sessionStartMoment.toLowerCase().indexOf(value) > -1 ||
                        item.items.toLowerCase().indexOf(value) > -1
                    )
                        return true;
                })
            );
        } else setRestaurantOrders(restaurantOrdersFilter);
    };

    const onChange = (dates, dateStrings) => {
        if (dates && dateStrings) {
            setDateRange([moment(dateStrings[0], 'DD.MM.YYYY').format('YYYY-MM-DD'), moment(dateStrings[1], 'DD.MM.YYYY').format('YYYY-MM-DD')]);
        }
    };

    const handleFilter = () => {};

    const onOrderDetails = (record) => {
        setDrawerVisible(true);
        // Update state with the element being edited
        setNewElement({ ...record });
        let recordItems = '<ul>';
        let recordItemsLevel = '';
    };

    return isLoading ? (
        <Skeleton active></Skeleton>
    ) : (
        <>
            <Space direction="horizontal" size={12}>
                Perioada:
                <RangePicker
                    defaultValue={[...INITAL_DATE_RANGE_PICKER]}
                    format="DD.MM.YYYY"
                    ranges={{
                        Astazi: [moment(), moment()],
                        Ieri: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                        'Saptamana curenta': [moment().startOf('week'), moment().endOf('week')],
                        'Saptamana trecuta': [moment().subtract(1, 'weeks').startOf('week'), moment().subtract(1, 'weeks').endOf('week')],
                        'Luna curenta': [moment().startOf('month'), moment().endOf('month')],
                        'Luna trecuta': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                    }}
                    onChange={onChange}
                />
                <Button onClick={handleFilter} type="primary">
                    Filtreaza
                </Button>
            </Space>

            <Search placeholder="Cauta" onChange={handleSearch} className="tableSearchField" />

            <Table
                dataSource={sortByKey(restaurantOrders, 'sessionStartMoment', 0)}
                pagination={false}
                rowKey={(record) => record.tableSessionToken}
                scroll={{ x: 240 }}
                summary={(pageData) => {
                    if (pageData.length > 0) {
                        let total = 0;
                        let totalNo = 0;
                        pageData.forEach(({ amount }) => {
                            total += amount;
                        });
                        return (
                            <Table.Summary fixed>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                                    <Table.Summary.Cell index={1}>{pageData.length}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={3}></Table.Summary.Cell>
                                    <Table.Summary.Cell align="right" index={4}>
                                        {total.toFixed(2)}
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={3}></Table.Summary.Cell>
                                </Table.Summary.Row>
                            </Table.Summary>
                        );
                    }
                }}>
                <Column
                    title="Data"
                    dataIndex="sessionStartMoment"
                    render={(text, record) =>
                        moment(record.sessionStartMoment).format('DD.MM.YYYY HH:mm') +
                        ' -> ' +
                        moment(record.clearTableMoment).format('DD.MM.YYYY HH:mm')
                    }
                />
                <Column
                    title="Actiuni"
                    key="actions"
                    render={(text, record) => (
                        <Button type="default" className="tableAction" icon={<UnorderedListOutlined />} onClick={() => onOrderDetails(record)}>
                            Vezi detalii
                        </Button>
                    )}
                />
                <Column title="Masa" align="right" dataIndex="tableIndex" />
                <Column title="Ospatar" dataIndex="waiterName" />
                <Column title="Total nota" align="right" render={(record) => record.amount.toFixed(2)} />
                <Column title="Numar utilizatori" align="right" dataIndex="peopleCount" />
            </Table>
            <Drawer
                title="Detalii nota de plata"
                width={window.innerWidth > 1000 ? 1000 : window.innerWidth}
                onClose={onClose}
                visible={drawerVisible}
                bodyStyle={{ paddingBottom: 80 }}
                footer={
                    <div className="modalFooter">
                        <div
                            style={{
                                textAlign: 'left',
                            }}></div>
                        <div
                            style={{
                                textAlign: 'right',
                            }}>
                            <Button size="large" onClick={onClose} style={{ marginRight: 8 }}>
                                Inchide
                            </Button>
                        </div>
                    </div>
                }>
                {newElement ? (
                    <Collapse className="formCollapse" bordered={false} defaultActiveKey={['info', 'orderItems']}>
                        <Panel header="Informatii" key="info">
                            <p>
                                <strong>Data:</strong>{' '}
                                {moment(newElement.sessionStartMoment).format('DD.MM.YYYY HH:mm') +
                                    ' -> ' +
                                    moment(newElement.clearTableMoment).format('DD.MM.YYYY HH:mm')}
                            </p>
                            <p>
                                <strong>Masa:</strong> {newElement.tableIndex}
                            </p>
                            <p>
                                <strong>Ospatar:</strong> {newElement.waiterName}
                            </p>
                            <p>
                                <strong>Total nota:</strong> {newElement.amount ? newElement.amount.toFixed(2) : null}{' '}
                            </p>
                            <p>
                                <strong>Numar utilizatori:</strong> {newElement.peopleCount}
                            </p>
                        </Panel>
                        <Panel header="Continut" key="orderItems">
                            {newElement.items ? (
                                <div>
                                    {splitAndIterate(newElement.items).map((d) => {
                                        return <div className={'orderContent orderContentDepth-' + d.depth}>{d.token}</div>;
                                    })}
                                </div>
                            ) : null}
                        </Panel>
                    </Collapse>
                ) : null}
            </Drawer>
        </>
    );
};

export default RestaurantOrders;
