import React, { useEffect } from 'react';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../const/routes';
import {
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";

const withAuthorization = (condition) => (Component) => {
    const WithAuthorization = (props) => {
        let navigate = useNavigate();
        useEffect(() => {
            const listener = props.firebase.auth.onAuthStateChanged((authUser) => {
                if (!condition(authUser)) {
                    navigate(ROUTES.SIGN_IN);
                }
            });
            return listener; // unsubscribe on unmount
        }, []);

        return <AuthUserContext.Consumer>{(authUser) => (condition(authUser) ? <Component {...props} /> : null)}</AuthUserContext.Consumer>;
    };

    return withFirebase(WithAuthorization);
};

export default withAuthorization;
