import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { PushpinOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { normalizeString } from '../helpers';

const mapStyles = {};

const containerStyle = {
    position: 'relative',
    width: '430px',
    height: '400px',
};

const onInfoWindowClose = () => {};
let Geocoder = null;
const initGeocoder = ({ maps }) => {
    Geocoder = new maps.Geocoder();
};

function MapMarker(props) {
    const location = {
        lat: props.restaurantDetails.latitude ? props.restaurantDetails.latitude : 44.435508,
        lng: props.restaurantDetails.longitude ? props.restaurantDetails.longitude : 26.102548,
    };
    const [markerPosition, setMarkerPosition] = useState({ ...location });
    const [draggable, setDraggable] = useState(true);

    const updateLatLng = (lat, lng) => {
        setMarkerPosition({
            ...markerPosition,
            lat: lat,
            lng: lng,
        });
        props.setRestaurantDetails({
            ...props.restaurantDetails,
            latitude: lat,
            longitude: lng,
        });
        props.setForm({
            ...props.restaurantDetails,
            latitude: lat,
            longitude: lng,
        });
    };

    const onClickMap = ({ lat, lng, event }) => {
        updateLatLng(lat, lng);
    };

    const onMarkerClick = (marker) => {};

    const onDragMarker = (childKey, childProps, mouse) => {
        setDraggable(false);
        updateLatLng(mouse.lat, mouse.lng);
    };

    const onDragMarkerEnd = (childKey, childProps, mouse) => {
        setDraggable(true);
    };

    const geocode = (request) => {
        Geocoder.geocode(request)
            .then((result) => {
                const { results } = result;
                updateLatLng(results[0].geometry.location.lat(), results[0].geometry.location.lng());
                return results;
            })
            .catch((e) => {
                alert('Geocode was not successful for the following reason: ' + e);
            });
    };

    const triggerGeocoding = (e) => {
        e.preventDefault();
        const cityName = props.cityList.find(({ cityId }) => cityId === props.restaurantDetails.cityId).cityName;
        geocode({ address: normalizeString(cityName) + ',' + props.restaurantDetails.placeAddress });
    };

    const MarkerComponent = ({ text }) => <div className="mapMarker">{text}</div>;

    return (
        <div>
            <Button type="secondary" onClick={triggerGeocoding} className="btnAdd">
                <PushpinOutlined /> Preia adresa introdusa
            </Button>

            <div style={{ height: 400, maxWidth: 500 }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyCLgAD-kgS0kEBBxEAUkRtsqGeiv7wb4f8' }}
                    center={[parseFloat(markerPosition.lat), parseFloat(markerPosition.lng)]}
                    defaultZoom={14}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={initGeocoder}
                    onChildMouseDown={onDragMarker}
                    onChildMouseMove={onDragMarker}
                    onChildMouseUp={onDragMarkerEnd}
                    onClick={onClickMap}
                    draggable={draggable}>
                    <MarkerComponent
                        onDragMarker={onDragMarker}
                        onDragend={onDragMarker}
                        lat={parseFloat(markerPosition.lat)}
                        lng={parseFloat(markerPosition.lng)}
                        text=" "
                    />
                </GoogleMapReact>
            </div>
        </div>
    );
}

export default MapMarker;
