import React, { useState, useEffect } from 'react';
import { Table, Input, Form, Skeleton } from 'antd';
import { ApiHelpers } from '../../../helpers';

import moment from "moment";

const { Column } = Table;
const Search = Input.Search;
const RestaurantWaiters = (props) => {

    const [form] = Form.useForm();
    const [restaurantWaiters, setrRestaurantWaiters] = useState([]);
    const [restaurantWaitersFilter, setRestaurantWaitersFilter] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Get Restaurant by Id
        ApiHelpers.getWaitersInfoByRestaurant(props.restaurantId)
            .then((response) => {
                if (!response.data.message) {
                    setrRestaurantWaiters(response.data.activeShifts);
                    setRestaurantWaitersFilter(response.data.activeShifts);
                    setIsLoading(false);
                }
                else {
                    setIsLoading(false);
                }

            }, (error) => {
                console.log(error);
            });

    }, []);

    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        if (value) {
            setrRestaurantWaiters(restaurantWaitersFilter.filter(item => {
                if (item.name.toLowerCase().indexOf(value) > -1)
                    return true
            }));
        }
        else
            setrRestaurantWaiters(restaurantWaitersFilter);
    }

    return (
        isLoading ? <Skeleton active></Skeleton> :
            <>

                <Search placeholder="Dupa nume" onChange={handleSearch} className="tableSearchField" />
                <Table
                    dataSource={restaurantWaiters}
                    pagination={false}
                    rowKey={(record) => record.uniqueIdentificationToken}
                    scroll={{ x: 240 }}
                >

                    <Column title="Nume"
                        dataIndex="name" />
                    <Column title="Medie scor"
                        align="right"
                        dataIndex="score" />
                    <Column title="Incepere tura"
                        align="right"
                        render={record => (
                            moment(record.startShiftDate).format("DD.MM.YYYY HH:mm")
                        )} />


                </Table>


            </>
    );
}

export default RestaurantWaiters;