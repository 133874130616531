import React, { useState, useEffect } from 'react';
import { Table, Input, Form, Drawer, Button, message, Popconfirm, DatePicker, Checkbox, Skeleton } from 'antd';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import { ApiHelpers } from '../../../helpers';

import moment from "moment";
import { usePromotionsByRestaurant } from '../../../swr';
import UploadOfferImage from './UploadOfferImage';
const { Column } = Table;
const Search = Input.Search;
const { RangePicker } = DatePicker;
const { TextArea } = Input;

const RestaurantOffers = (props) => {

    const [form] = Form.useForm();
    const [restaurantEvents, setRestaurantEvents] = useState([]);
    const [promotionsListFilter, setPromotionsListFilter] = useState([]);
    const [newElement, setNewElement] = useState([]);
    const [actionType, setActionType] = useState(props.actionType);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const { data: promotionsList, isLoading: isLoadingPromotionsList } = usePromotionsByRestaurant(props.restaurantId);
    const [isLoading, setIsLoading] = useState(true);

    const INITIAL_STATE_ELEMENT = {

        uniqueIdentificationToken: null,
        title: "",
        restaurantToken: props.restaurantId,
        dateStart: "",
        dateEnd: "",
        hourStart: "",
        hourEnd: "",
        promoDescription: "",
        promoImage: "",
        recurring: false,
        promoWeekdays: ""
    }

    const optionsWeekdays = [
        { label: 'Luni', value: '1' },
        { label: 'Marti', value: '2' },
        { label: 'Miercuri', value: '3' },
        { label: 'Joi', value: '4' },
        { label: 'Vineri', value: '5' },
        { label: 'Sambata', value: '6' },
        { label: 'Duminica', value: '7' },
    ];

    useEffect(() => {
        if (!isLoadingPromotionsList && promotionsList) {
            setPromotionsListFilter(promotionsList);
            setIsLoading(false);
        }
    }, [promotionsList, isLoadingPromotionsList]);


    const onClose = () => {
        setDrawerVisible(false);
        onReset();
    };

    const onReset = () => {
        form.resetFields();
        setNewElement([]);
    };

    const onDelete = () => {
        ApiHelpers.deleteRestaurantPromotion(newElement.uniqueIdentificationToken, newElement.restaurantToken)
            .then((response) => {
                if (response.data.statusCode === 200) {
                    const newList = promotionsList.filter(function (obj) {
                        return obj.uniqueIdentificationToken !== newElement.uniqueIdentificationToken;
                    });
                    setPromotionsListFilter(newList);
                    message.success("Elementul a fost sters!");
                }
            }, (error) => {
                console.log(error);
                message.error("Eroare!")
            });
        onReset();
        setDrawerVisible(false);
    }

    const onSave = () => {
        // Validate form
        form.validateFields()
            .then(() => {
                if (actionType === "new") {
                    ApiHelpers.postRestaurantPromotion(newElement)
                        .then((response) => {
                            const newList = promotionsList.concat(response.data.restaurantEvent);
                            setPromotionsListFilter(newList);
                            message.success('Element adaugat!');
                        }, (error) => {
                            console.log(error);
                            message.error("Eroare!")
                        })
                }
                else if (actionType === "edit") {
                    ApiHelpers.updateRestaurantPromotion(newElement)
                        .then((response) => {
                            const elementsIndex = promotionsList.findIndex(element => element.uniqueIdentificationToken === newElement.uniqueIdentificationToken);
                            let newMenuData = [...promotionsList];
                            newMenuData[elementsIndex] = { ...newElement };

                            setPromotionsListFilter(newMenuData)
                            message.success('Element modificat!');
                        }, (error) => {
                            console.log(error);
                            message.error('Eroare!');
                        });
                }
                onReset();
                setDrawerVisible(false);
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    }
    const onChange = event => {
        setNewElement({
            ...newElement,
            [event.target.name]: event.target.value
        });
    };

    const onAddElement = () => {
        setActionType("new");

        ApiHelpers.getUUID()
            .then((response) => {
                setNewElement({
                    ...INITIAL_STATE_ELEMENT,
                    uniqueIdentificationToken: response.data.uuid,
                });

                setDrawerVisible(true);
            }, (error) => {
                console.log(error);
            });
    }

    const onChangeCheckbox = (event, field) => {
        setNewElement({
            ...newElement,
            [field]: event.target.checked
        });
    };

    const onChangeCheckboxGroup = (checkedValues) => {

        let stringWeekdays = "";
        checkedValues.map((d) => {
            stringWeekdays += d + "|";
        })
        //    console.log('checked = ', checkedValues, stringWeekdays);
        setNewElement({
            ...newElement,
            promoWeekdays: stringWeekdays
        });
    }

    const onChangeRangePicker = (dates, dateStrings) => {

        setNewElement({
            ...newElement,
            dateStart: moment(dates[0]).format("YYYY-MM-DD"),
            hourStart: moment(dates[0]).format("HH:mm"),
            dateEnd: moment(dates[1]).format("YYYY-MM-DD"),
            hourEnd: moment(dates[1]).format("HH:mm"),
        });
    }

    const onEditElement = (record) => {
        setActionType("edit");

        // Set form fields values
        const editObject = {
            ...record
        }
        const timeRangeStart = record.dateStart.substring(0, record.dateStart.indexOf('T') + 1) + record.hourStart;
        const timeRangeEnd = record.dateEnd.substring(0, record.dateEnd.indexOf('T') + 1) + record.hourEnd;
        const timeRangeValues = [moment(timeRangeStart), moment(timeRangeEnd)];

        form.setFieldsValue({
            ...editObject,
            promotDate: timeRangeValues
        });

        // Update state with the element being edited
        setNewElement({ ...editObject });
        setDrawerVisible(true);
    };
    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        if (value) {
            setPromotionsListFilter(promotionsList.filter(item => {
                if (item.waiter.toLowerCase().indexOf(value) > -1 || item.sessionStartMoment.toLowerCase().indexOf(value) > -1 || item.items.toLowerCase().indexOf(value) > -1)
                    return true
            }));
        }
        else {

        }

    }

    return (

        <> {isLoadingPromotionsList || isLoading ? <Skeleton active></Skeleton> :
            <>
                <Button type="primary" onClick={() => onAddElement()} className="btnAdd">
                    <PlusOutlined /> Adauga oferta
                </Button>
                <Search placeholder="Cauta" onChange={handleSearch} className="tableSearchField" />
                <Table
                    dataSource={promotionsListFilter}
                    pagination={false}
                    rowKey="uniqueIdentificationToken"
                    scroll={{ x: 240 }}
                >
                    <Column
                        title="Imagine"
                        render={(text, record) => (
                            record.promoImage !== "" && record.promoImage !== null ? <img src={ApiHelpers.getImageLink(record.promoImage)} className="thumbnailList" /> : ""
                        )}
                    />
                    <Column
                        title="Titlu"
                        dataIndex="title"
                    />
                    <Column title="Actiuni"
                        render={(text, record) => (
                            <Button type="default" className="tableAction" icon={<EditOutlined />} onClick={() => onEditElement(record)}>
                                Editează
                            </Button>)}
                    />
                    <Column title="Descriere"
                        dataIndex="promoDescription" />
                    <Column title="Start"
                        render={record => (
                            moment(record.dateStart).format("DD.MM.YYYY") + " " + record.hourStart
                        )} />
                    <Column title="Sfarsit"
                        render={record => (
                            moment(record.dateEnd).format("DD.MM.YYYY") + " " + record.hourEnd
                        )} />

                    <Column title="Recurent"
                        render={record => (
                            record.recurring ? "Da" : "Nu"
                        )}
                    />


                </Table>
                <Drawer
                    title="Oferta"
                    width={window.innerWidth > 1000 ? 1000 : window.innerWidth}
                    onClose={onClose}
                    visible={drawerVisible}
                    bodyStyle={{ paddingBottom: 80 }}
                    footer={
                        <div className="modalFooter">
                            <div
                                style={{
                                    textAlign: 'left',
                                }}
                            >{actionType !== 'new' ?
                                <Popconfirm title="Sigur vrei sa stergi?" onConfirm={onDelete}>
                                    <Button size="large" danger style={{ marginRight: 8 }}>
                                        Sterge
                                    </Button>
                                </Popconfirm>

                                : null}
                            </div>
                            <div
                                style={{
                                    textAlign: 'right',

                                }}
                            >
                                <Button size="large" onClick={onClose} style={{ marginRight: 8 }}>
                                    Anuleaza
                                </Button>
                                <Button size="large" onClick={onSave} type="primary">
                                    Salveaza
                                </Button>
                            </div>
                        </div>
                    }
                >
                    <Form layout="vertical" form={form} size="large">
                        <Form.Item
                            name="title"
                            label="Titlu"
                            rules={[{ required: true, message: 'Camp obligatoriu' }]}
                        >
                            <Input placeholder="Please enter name" onBlur={onChange} name="title" value={newElement.title} />
                        </Form.Item>
                        <Form.Item
                            name="promoDescription"
                            label="Descriere"
                            rules={[{ required: true }]}
                        >
                            <TextArea rows="5" placeholder="Adauga o descriere" onBlur={onChange} name="promoDescription" value={newElement.promoDescription} />
                        </Form.Item>
                        <Form.Item
                            name="promoImage"
                            label="Imagine"
                            rules={[{ required: false, message: 'Camp obligatoriu' }]}
                        >
                            <UploadOfferImage
                                menuItemId={newElement.uniqueIdentificationToken}
                                newElement={newElement}
                                setNewElement={setNewElement}
                                restaurantIdentificationToken={props.restaurantId}
                            />
                        </Form.Item>
                        <Form.Item
                            name="promotDate"
                            label="Data start si data final"
                            rules={[{ required: true }]}>
                            <RangePicker
                                showTime={{ format: 'HH:mm' }}
                                format="DD-MM-YYYY HH:mm"
                                onChange={onChangeRangePicker}
                            //    onChange={onChange}

                            />
                        </Form.Item>
                        <Form.Item
                            name="recurring"

                        >
                            <Checkbox checked={newElement.recurring} onChange={(event) => onChangeCheckbox(event, "recurring")}>Oferta recurenta</Checkbox>
                        </Form.Item>

                        {newElement.recurring ?
                            <Form.Item
                                name="promoWeekdays"
                                label="Zile"
                            >
                                <Checkbox.Group options={optionsWeekdays} onChange={onChangeCheckboxGroup} />
                            </Form.Item>
                            : null}
                    </Form>

                </Drawer>

            </>}
        </>
    );
}

export default RestaurantOffers;