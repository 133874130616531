import useSWR from 'swr';
import axios from 'axios'
import { bacchusAPIEndpoint } from '../config';

const fetcher = url => axios.get(url).then(res => res.data)

function useUser (id) {
    const { data, error } = useSWR(bacchusAPIEndpoint + '/api/user/' + id, fetcher)
    return {
      data,
      isLoading: !error && !data,
      isError: error
    }
  }

  export default useUser;