import React from 'react';
import MenuItem from './MenuItem';
import { Row } from 'antd';

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}


export default function MenuList(props) {

    return (
        props.menuGrouped == null ?
            <>
                <h5 className="menu-category">{props.menuCategory}</h5>
                <Row gutter={[8, 8]} className="menu-list">
                    {props.menuContent.map((menuItem, index) => {
                        return <MenuItem content={menuItem} key={index} />
                    })
                    }
                </Row>
            </>
            :
            <>
                {props.menuGrouped.map((menuCategory, index) => {
                    return (<>
                        <h5 className="menu-category">{menuCategory[0]}</h5>
                        <Row gutter={[8, 8]} className="menu-list">
                            {menuCategory[1].map((menuItem, index) => {
                                return <MenuItem content={menuItem} key={index} />
                            })}
                        </Row></>)
                })
                }

            </>
    );
}