import React, { useState, useEffect, useContext } from 'react';
import { AuthUserContext, withAuthorization } from '../Session';
import AdminLayout from './AdminLayout';
import { ApiHelpers } from '../../helpers';
import { Form, Tabs, Spin, Skeleton } from 'antd';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import { useCompany } from '../../swr';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { restaurantDetailsState, menusListState, menuCategoriesState, activeRestaurantState } from '../../recoil';
import RestaurantOrders from './Restaurant/RestaurantOrders';
import RestaurantEvents from './Restaurant/RestaurantEvents';
import Onboarding from './Onboarding';

const { TabPane } = Tabs;

function RestaurantEventsHome(props) {
    // Get AuthUser ID from Firebase
    const firebaseAuthUser = useContext(AuthUserContext);
    let { restaurantId } = useParams();
    const [formRestaurant] = Form.useForm();
    const [restaurantDetails, setRestaurantDetails] = useState([]);
    const [restaurantDetailsStateLocal, setRestaurantDetailsStateLocal] = useRecoilState(restaurantDetailsState);
    const [entryFound, setEntryFound] = useState(false);
    const [activeTabKey, setActiveTabKey] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const [activeRestaurant, setActiveRestaurant] = useRecoilState(activeRestaurantState);

    const navigate = useNavigate();
    const { data: companyDetails, isLoading: isLoadingCompanyDetails } = useCompany('getDetails', firebaseAuthUser.uid);
    let companyIdentificationToken = null;

    if (companyDetails) {
        companyIdentificationToken = companyDetails.uniqueIdentificationToken;
    }

    const routeLocation = useLocation();

    const locationFormLayout = {
        layout: 'horizontal',
        form: formRestaurant,
        labelCol: { span: 3 },
        wrapperCol: { span: 9 },
        size: 'large',
        labelAlign: 'left',
    };
    const tailLayout = {
        wrapperCol: { offset: 3, span: 9 },
    };

    useEffect(() => {
        setIsLoading(true);
        // Get Restaurant by Id
        ApiHelpers.getRestaurantsByCode(restaurantId).then(
            (response) => {
                if (response.data.message) {
                    setEntryFound(false);
                } else {
                    setRestaurantDetails({
                        ...response.data,
                        companyIdentificationToken: companyIdentificationToken,
                    });
                    setEntryFound(true);
                }
                setIsLoading(false);
            },
            (error) => {
                console.log(error);
            }
        );
    }, [restaurantId]);

    useEffect(() => {
        if (routeLocation.hash) {
            setActiveTabKey(routeLocation.hash);
        } else setActiveTabKey('#list');
    });

    return (
        <AuthUserContext.Consumer>
            {(authUser) => (
                <AdminLayout authUser={authUser} pageTitle={restaurantDetails.title}>
                    <h2>Evenimente</h2>
                    {isLoadingCompanyDetails ? (
                        <Skeleton active></Skeleton>
                    ) : companyDetails.message ? (
                        <Onboarding />
                    ) : isLoading ? (
                        <Skeleton active></Skeleton>
                    ) : entryFound === true ? (
                        <>
                            <RestaurantEvents restaurantId={restaurantId} />
                        </>
                    ) : entryFound === false ? (
                        <Onboarding step={2} />
                    ) : (
                        <Skeleton active />
                    )}
                </AdminLayout>
            )}
        </AuthUserContext.Consumer>
    );
}
const condition = (authUser) => !!authUser;
export default withAuthorization(condition)(RestaurantEventsHome);
