import * as ApiHelpers from './ApiHelpers';
import UploadWithOptions from './UploadWithOptions';
import MapMarker from './MapMarker';
import { INITAL_STATE_SCHEDULEHELPER, INITAL_STATE_SCHEDULE_NEW_HELPER, INITIAL_SLOTS } from './scheduleHelper';
import FormSchedule from './FormSchedule';
import { WarningOutlined } from '@ant-design/icons';

function sortByKey(array, key, asc = 1) {
    return array.sort(function (a, b) {
        var x = asc == 1 ? a[key] : b[key];
        var y = asc == 1 ? b[key] : a[key];
        return x < y ? -1 : x > y ? 1 : 0;
    });
}

function sortByKeyMixed(array, key, asc = 1) {
    let numArr = array.filter((el) => isNumeric(el[key])).sort((a, b) => a[key] - b[key]);
    let strArr = sortByKey(
        array.filter((el) => !isNumeric(el[key])),
        key
    );
    return numArr.concat(strArr);
}

export const normalizeString = (string) => {
    return string
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();
};

function getSelectedMenuKey(location) {
    const { pathname, hash } = location;
    const result = {
        key: '',
        subMenuKey: '',
    };
    if (pathname.indexOf('feedback') > -1) result.key = 'feedback';
    else if (pathname.indexOf('offers') > -1) result.key = 'offers';
    else if (pathname.indexOf('stats') > -1) result.key = 'stats';
    else if (pathname.indexOf('events') > -1) result.key = 'events';
    else if (pathname.indexOf('orders') > -1) result.key = 'orders';
    else if (pathname.indexOf('waiters') > -1) result.key = 'waiters';
    else if (pathname.indexOf('tables') > -1) result.key = 'tables';
    else if (hash.indexOf('menuItemGroups') > -1) {
        result.key = 'menuItemGroups';
        result.subMenuKey = 'menus';
    } else if (pathname.indexOf('menus') > -1 && hash.indexOf('list') > -1) {
        result.key = 'menus#list';
        result.subMenuKey = 'menus';
    } else if (pathname.indexOf('menus') > -1) {
        var menuID = /[^/]*$/.exec(pathname)[0];
        result.key = 'menus/' + menuID;
        result.subMenuKey = 'menus';
    } else if (pathname.indexOf('/admin/locations/') > -1) result.key = 'restaurant';
    else if (pathname.indexOf('/admin') > -1) result.key = 'dashboard';

    return result;
}

function aggregate(data, keyFields, accumulator) {
    var createNewObj = (ref, fields) => {
        return fields.reduce((result, key) => {
            return Object.assign(result, { [key]: ref[key] });
        }, {});
    };
    return Object.values(
        data.reduce((result, object, index, ref) => {
            let key = keyFields.map((key) => object[key]).join('');
            let val = result[key] || createNewObj(object, keyFields);
            val[accumulator] = (val[accumulator] || 0) + object[accumulator];
            return Object.assign(result, { [key]: val });
        }, {})
    );
}

const merge = (a, b, p) => a.filter((aa) => !b.find((bb) => aa[p] === bb[p])).concat(b);
const mergeArrayObjects = (arr1, arr2, key) => {
    return arr1.map((item, i) => {
        if (item[key] === arr2[i][key]) {
            //merging two objects
            return Object.assign({}, item, arr2[i]);
        }
    });
};
const enumerateDaysBetweenDates = (startDate, endDate, name, value) => {
    const now = startDate,
        dates = [];

    while (now.isSameOrBefore(endDate)) {
        dates.push({ date: now.format('YYYY.MM.DD'), name: name, value: value });
        now.add(1, 'days');
    }
    return dates;
};

const splitAndIterate = (string) => {
    //const string = '3 x Sandvis Corny|1 x Sandvis Beef;1 x Meniul zilei|1 x Supă de roșii^1 x Sandvis Corny^1 x Lava cake v3';
    var tokens = string.split(/([\;\|\^])/),
        result = [],
        depth = 1;

    tokens.forEach(function scan(token) {
        if (!token) return;
        if (token === ';') {
            depth = 1;
            return;
        }
        if (token === '|') {
            depth = 2;
            return;
        }
        if (token === '^') {
            depth = 3;
            return;
        }
        result.push({ depth: depth, token: token });
    });
    return result;
};

function isNumeric(str) {
    if (typeof str != 'string') return false;
    return !isNaN(str) && !isNaN(parseFloat(str));
}

export const uniqueValuesinArray = (array) => {
    return array.filter((item, pos) => array.indexOf(item) === pos);
};

export const nutritionalCalculation = (nutritionalData, type = '100g', customQuantityFactor = null) => {
    let totalQuantity = 0;
    const calculation = {
        energy: null,
        quantity: null,
        fat: null,
        saturates: null,
        proteins: null,
        salt: null,
        sugars: null,
        carbohydrate: null,
        key: null,
        energyKJ: null,
        energyKCal: null,
        monounsaturates: 0,
        polyunsaturates: 0,
        polyols: 0,
        starch: 0,
        fiber: 0,
    };
    const calculation100g = {
        energy: null,
        quantity: null,
        fat: null,
        saturates: null,
        proteins: null,
        salt: null,
        sugars: null,
        carbohydrate: null,
        key: null,
        energyKJ: null,
        energyKCal: null,
        monounsaturates: 0,
        polyunsaturates: 0,
        polyols: 0,
        starch: 0,
        fiber: 0,
    };
    if (nutritionalData.length > 0) {
        nutritionalData.map((d) => {
            totalQuantity += d.quantity;
        });
        const quantityFactor =
            customQuantityFactor > 0 ? parseFloat((customQuantityFactor / 100).toFixed(2)) : parseFloat((totalQuantity / 100).toFixed(2));
        nutritionalData.map((d) => {
            calculation.quantity += d.quantity;
            ['fat', 'saturates', 'proteins', 'salt', 'sugars', 'carbohydrate'].map((field) => {
                calculation[field] += (parseFloat(d[field]).toFixed(2) * d.quantity) / 100;
            });
        });
        // Calculate energy values based on sum
        calculation.energyKJ =
            17 * calculation.carbohydrate + 37 * calculation.fat + 10 * calculation.polyols + 17 * calculation.proteins + 8 * calculation.fiber;
        calculation.energyKCal =
            4 * calculation.carbohydrate + 9 * calculation.fat + 2.4 * calculation.polyols + 4 * calculation.proteins + 2 * calculation.fiber;
        calculation.energy = calculation.energyKJ + ' kJ / ' + calculation.energyKCal + ' kCal';
        ['fat', 'saturates', 'proteins', 'salt', 'sugars', 'carbohydrate'].map(
            (field) => (calculation100g[field] += parseFloat(calculation[field]) / quantityFactor)
        );
        // Calculate energy values based on sum
        calculation100g.energyKJ =
            17 * calculation100g.carbohydrate +
            37 * calculation100g.fat +
            10 * calculation100g.polyols +
            17 * calculation100g.proteins +
            8 * calculation100g.fiber;
        calculation100g.energyKCal =
            4 * calculation100g.carbohydrate +
            9 * calculation100g.fat +
            2.4 * calculation100g.polyols +
            4 * calculation100g.proteins +
            2 * calculation100g.fiber;
        calculation100g.energy = calculation100g.energyKJ + ' kJ / ' + calculation100g.energyKCal + ' kCal';
    }
    return type === '100g' ? calculation100g : calculation;
};

export const nutritionalCalculationFrontend = (nutritionalData, type = '100g', customQuantityFactor = null) => {
    const calculation = nutritionalCalculation(nutritionalData, type === '100g' ? '100g' : 'full', customQuantityFactor);
    const dataSourceNutritional = [
        {
            key: type + '1',
            nutritionalName: 'Energie (kCal)',
            nutritionalValue: calculation.energy ? (
                parseFloat(calculation.energy.split(' ')[3]).toFixed(2)
            ) : (
                <WarningOutlined style={{ color: '#f5222d' }} />
            ),
            type: 'main',
        },
        {
            key: type + '2',
            nutritionalName: 'Energie (kJ)',
            nutritionalValue: calculation.energy ? (
                parseFloat(calculation.energy.split(' ')[0]).toFixed(2)
            ) : (
                <WarningOutlined style={{ color: '#f5222d' }} />
            ),
            type: 'main',
        },
        {
            key: type + '3',
            nutritionalName: 'Grasimi (grame)',
            nutritionalValue: calculation.fat !== null ? calculation.fat.toFixed(2) : <WarningOutlined style={{ color: '#f5222d' }} />,
            type: 'main',
        },
        {
            key: type + '4',
            nutritionalName: 'din care saturate/ acizi grasi saturati',
            nutritionalValue: calculation.saturates !== null ? calculation.saturates.toFixed(2) : <WarningOutlined style={{ color: '#f5222d' }} />,
            type: 'secondary',
        },
        {
            key: type + '5',
            nutritionalName: 'Carbohidrati/ Glucide (grame)',
            nutritionalValue:
                calculation.carbohydrate !== null ? calculation.carbohydrate.toFixed(2) : <WarningOutlined style={{ color: '#f5222d' }} />,
            type: 'main',
        },
        {
            key: type + '6',
            nutritionalName: 'din care zaharuri',
            nutritionalValue: calculation.sugars !== null ? calculation.sugars.toFixed(2) : <WarningOutlined style={{ color: '#f5222d' }} />,
            type: 'secondary',
        },
        {
            key: type + '7',
            nutritionalName: 'Proteine',
            nutritionalValue: calculation.proteins !== null ? calculation.proteins.toFixed(2) : <WarningOutlined style={{ color: '#f5222d' }} />,
            type: 'main',
        },
        {
            key: type + '8',
            nutritionalName: 'Sare',
            nutritionalValue: calculation.salt !== null ? calculation.salt.toFixed(2) : <WarningOutlined style={{ color: '#f5222d' }} />,
            type: 'main',
        },
    ];
    return dataSourceNutritional;
};

export const nutritionalRenderIngredient = (item) => {
    const renderTitle = item.allergens ? <strong>{item.title}</strong> : item.title;
    const unitOfMeasure = item.unitOfMeasure || 'g';
    return renderTitle + (' ' + item.quantity + ' ' + unitOfMeasure + ', ');
};

export const formatNutritionalValues = (value, isString = false) => {
    if (isString && value) {
        return parseFloat(value.split(' ')[3]).toFixed(2) + ' kCal / ' + parseFloat(value.split(' ')[0]).toFixed(2) + ' kJ';
    } else {
        return parseFloat(value).toFixed(2);
    }
};

export const formatFoodAdditives = (item) => {
    let result = [];
    if (item.displayFoodAdditives) {
        result = item.displayFoodAdditives.split(',');
        result = result.map((d) => d.slice(d.indexOf('/') + 1));
    } else {
        result = item.foodAdditives.split(',');
    }
    return result.join(", ");
};

export const formatAllergensAndAdditives = (item) => {
    return item ? item.split(',').join('; ') : null;
};

export const nutritionalMenuShort = (nutritionalData, record) => {
    const nutritionalDataSorted = sortByKey(nutritionalData, 'quantity', 0);

    let listOfIngredientsSorted = [];
    let nutritionalValues = [];
    let noOfAdditives = 0;
    let uniqueAdditives = [];
    let noOfUniqueAdditives = 0;
    let allergensInComponents = [];
    let foodAdditivesExtractE = [];
    nutritionalDataSorted.map((item, index) => {
        if (item.displayFoodAdditives) {
            uniqueAdditives = [...uniqueAdditives, ...formatFoodAdditives(item)];
            noOfAdditives += item.foodAdditives.split(',').length;
        }
        if (item.components && item.components.length > 0) {
            item.components.map((item2) => {
                if (item2.displayFoodAdditives) {
                    uniqueAdditives = [...uniqueAdditives, ...formatFoodAdditives(item2)];
                    noOfAdditives += item2.foodAdditives.split(',').length;
                }
            });
        }
    });
    uniqueAdditives = uniqueValuesinArray(uniqueAdditives);
    noOfUniqueAdditives = uniqueAdditives.filter(function (v, i) {
        return i == uniqueAdditives.lastIndexOf(v);
    }).length;
    nutritionalDataSorted.map((item, index) => {
        const unitOfMeasure = item.unitOfMeasure || 'g';
        listOfIngredientsSorted.push(
            <span key={'ingredient-' + item.uniqueIdentificationToken + '-' + index}>
                {index > 0 && index < nutritionalDataSorted.length ? ', ' : ''}
                {item.allergens ? <strong>{item.title}</strong> : item.title}
                <span>{' ' + item.quantity + ' ' + unitOfMeasure}</span>
                {item.components && item.components.length > 0
                    ? item.components.filter((d) => d.allergens).length > 0
                        ? (item.components.map((d) => (d.allergens ? allergensInComponents.push(d.allergens) : null)),
                          (
                              <span>
                                  {' '}
                                  (alergeni: <strong>{uniqueValuesinArray(allergensInComponents).join(',')})</strong>
                              </span>
                          ))
                        : null
                    : ''}
            </span>
        );
    });
    nutritionalValues.push(
        <div>
            <h5>Valori nutritionale / 100g</h5>
            Valoare energetica: {formatNutritionalValues(record.energy, true)} | Grasimi: {formatNutritionalValues(record.fat)} g | acizi grasi
            saturati: {formatNutritionalValues(record.saturates)} g | Glucide: {formatNutritionalValues(record.carbohydrate)} g | Zaharuri:{' '}
            {formatNutritionalValues(record.sugars)} g | Proteine: {formatNutritionalValues(record.proteins)} g | Sare:{' '}
            {formatNutritionalValues(record.salt)} g
        </div>
    );
    return (
        <div>
            <h3>
                {record.title} {noOfUniqueAdditives > 0 ? ' - ' + noOfUniqueAdditives + ' aditivi / ' + uniqueAdditives.join(',') : null}
            </h3>
            {listOfIngredientsSorted}
            {nutritionalValues}
            <small>Ingredientele ingrosate contin sau pot contine alergeni. </small>
        </div>
    );
};

export const getAllergensInComponents = (item) => {
    let allergensInComponents = [];

    if (item.components && item.components.length > 0)
        if (item.components.filter((d) => d.allergens).length > 0)
            item.components.map((d) => (d.allergens ? allergensInComponents.push(d.allergens) : null));

    return uniqueValuesinArray(allergensInComponents).join(',');
};

export const getAdditivesInComponents = (item) => {
    let additivesInComponents = [];

    if (item.components && item.components.length > 0)
        if (item.components.filter((d) => d.foodAdditives).length > 0)
            item.components.map((d) => (d.foodAdditives ? additivesInComponents.push(d.foodAdditives) : null));

    return uniqueValuesinArray(additivesInComponents).join(',');
};

export const nutritionalMenuShort2 = (nutritionalData, record) => {
    const nutritionalDataSorted = sortByKey(nutritionalData, 'quantity', 0);

    let listOfIngredientsSorted = [];
    let nutritionalValues = [];
    let noOfAdditives = 0;
    let uniqueAdditives = [];
    let noOfUniqueAdditives = 0;
    let allergensInComponents = [];
    let foodAdditivesExtractE = [];
    nutritionalDataSorted.map((item, index) => {
        if (item.displayFoodAdditives) {
            uniqueAdditives = [...uniqueAdditives, ...formatFoodAdditives(item)];
            noOfAdditives += item.foodAdditives.split(',').length;
        }
        if (item.components && item.components.length > 0) {
            item.components.map((item2) => {
                if (item2.displayFoodAdditives) {
                    uniqueAdditives = [...uniqueAdditives, ...formatFoodAdditives(item2)];
                    noOfAdditives += item2.foodAdditives.split(',').length;
                }
            });
        }
        const unitOfMeasure = item.unitOfMeasure || 'g';
        listOfIngredientsSorted.push(renderMenuItemIngredientShort(item, index, nutritionalDataSorted.length, unitOfMeasure));
    });
    uniqueAdditives = uniqueValuesinArray(uniqueAdditives);
    noOfUniqueAdditives = uniqueAdditives.filter(function (v, i) {
        return i == uniqueAdditives.lastIndexOf(v);
    }).length;

    nutritionalValues.push(
        <div>
            <h5>Valori nutritionale / 100g</h5>
            Valoare energetica: {formatNutritionalValues(record.energy, true)} | Grasimi: {formatNutritionalValues(record.fat)} g | acizi grasi
            saturati: {formatNutritionalValues(record.saturates)} g | Glucide: {formatNutritionalValues(record.carbohydrate)} g | Zaharuri:{' '}
            {formatNutritionalValues(record.sugars)} g | Proteine: {formatNutritionalValues(record.proteins)} g | Sare:{' '}
            {formatNutritionalValues(record.salt)} g
        </div>
    );
    return (
        <div>
            <h3>{record.title}</h3>
            {listOfIngredientsSorted} {noOfUniqueAdditives ? ' - ' + noOfUniqueAdditives + 'E' : null}
            {nutritionalValues}
            {record.freezedIngredientsUsed ? (
                <div>
                    <small>Poate proveni din produs congelat</small>
                </div>
            ) : null}
            <small>Ingredientele îngroșate conțin sau pot conține alergeni. </small>
        </div>
    );
};

const renderMenuItemIngredient = (item, index, length, unitOfMeasure, isChild = 0, parentQuantity = null, parentSum) => {
    let componentsSumQuantity = 0;
    const hasAllergens = item.allergens || (item.components && item.components.filter((d) => d.allergens).length > 0);
    if (item.components && item.components.length > 0) item.components.map((d) => (componentsSumQuantity += d.quantity));
    return (
        <span key={'ingredient-' + item.uniqueIdentificationToken}>
            {index > 0 && index < length && !isChild ? ', ' : isChild && index > 0 ? (hasAllergens ? '; ' : ', ') : ''}

            {item.allergens ? <strong>{item.title}</strong> : item.title}
            {item.allergens ? <span> | alergen: {formatAllergensAndAdditives(item.allergens)}</span> : null}
            {item.foodAdditives ? <span> | aditivi: {formatAllergensAndAdditives(item.displayFoodAdditives)}</span> : null}

            {item.components && item.components.length > 0 ? ' (' : ''}

            {item.components && item.components.length > 0
                ? sortByKey(item.components, 'quantity', 0).map((d, index) => {
                      return renderMenuItemIngredient(d, index, item.components.length, unitOfMeasure, 1, item.quantity, componentsSumQuantity);
                  })
                : null}
            {item.components && item.components.length > 0 ? ')' : ''}
        </span>
    );
};

const renderMenuItemIngredientShort = (item, index, length, unitOfMeasure, isChild = 0, parentQuantity = null, parentSum) => {
    let componentsSumQuantity = 0;
    const hasAllergensOrAdditives = item.foodAdditives !== null || item.allergens ? true : false;
    const hasAllergens = item.allergens || (item.components && item.components.filter((d) => d.allergens).length > 0);
    if (item.components && item.components.length > 0) item.components.map((d) => (componentsSumQuantity += d.quantity));

    return (
        <span key={'ingredient-' + item.uniqueIdentificationToken}>
            {index > 0 && index < length && !isChild ? ', ' : isChild && index > 0 && hasAllergens ? '; ' : ''}

            {!isChild ? hasAllergens ? <strong>{item.title}</strong> : item.title : null}

            {item.allergens ? (
                <span>
                    {!isChild || (isChild && index === 0) ? ' | alergen:' : ''} {formatAllergensAndAdditives(item.allergens)}
                </span>
            ) : null}

            {item.components && item.components.length > 0
                ? sortByKey(item.components, 'quantity', 0).map((d, index) => {
                      return renderMenuItemIngredientShort(d, index, item.components.length, unitOfMeasure, 1, item.quantity, componentsSumQuantity);
                  })
                : null}
        </span>
    );
};

export const nutritionalMenuFull = (nutritionalData, record) => {
    const nutritionalDataSorted = sortByKey(nutritionalData, 'quantity', 0);

    let listOfIngredientsSorted = [];
    let nutritionalValues = [];
    let nutritionalCalculationSum = nutritionalCalculation(nutritionalData, 'full');
    nutritionalDataSorted.map((item, index) => {
        const unitOfMeasure = item.unitOfMeasure || 'g';
        listOfIngredientsSorted.push(renderMenuItemIngredient(item, index, nutritionalDataSorted.length, unitOfMeasure));
    });
    nutritionalValues.push(
        <div>
            <h5>Valori nutritionale / 100g</h5>
            Valoare energetica: {formatNutritionalValues(record.energy, true)} | Grasimi: {formatNutritionalValues(record.fat)} g | acizi grasi
            saturati: {formatNutritionalValues(record.saturates)} g | Glucide: {formatNutritionalValues(record.carbohydrate)} g | Zaharuri:{' '}
            {formatNutritionalValues(record.sugars)} g | Proteine: {formatNutritionalValues(record.proteins)} g | Sare:{' '}
            {formatNutritionalValues(record.salt)} g
        </div>
    );

    return (
        <div>
            <h3>{record.title}</h3>
            {listOfIngredientsSorted}
            {nutritionalValues}
            {record.freezedIngredientsUsed ? (
                <div>
                    <small>Poate proveni din produs congelat</small>
                </div>
            ) : null}
            <small>Ingredientele ingrosate contin sau pot contine alergeni. </small>
        </div>
    );
};

export const nutritionalCalculationSumIngredients = (nutritionalData, format = true) => {
    let totalQuantity = 0;
    if (nutritionalData.length > 0)
        nutritionalData.map((d) => {
            totalQuantity += d.quantity;
        });
    return totalQuantity > 0 ? (format === true ? '(' + totalQuantity + ' g)' : totalQuantity) : '';
};

export {
    ApiHelpers,
    UploadWithOptions,
    MapMarker,
    INITAL_STATE_SCHEDULEHELPER,
    INITAL_STATE_SCHEDULE_NEW_HELPER,
    INITIAL_SLOTS,
    FormSchedule,
    sortByKey,
    getSelectedMenuKey,
    aggregate,
    merge,
    enumerateDaysBetweenDates,
    mergeArrayObjects,
    splitAndIterate,
    isNumeric,
    sortByKeyMixed,
};
