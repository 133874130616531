import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Menu } from './components/Menu/';
import './App.css';
import { mockMenu, mockMenuDay } from './const/MockData';
import { ConfigProvider } from 'antd';
import roRO from 'antd/lib/locale/ro_RO';
import SignUpPage from './components/Auth/SignUp';
import SignInPage from './components/Auth/SignIn';
import SignOutPage from './components/SignOut';
import PasswordForgetPage from './components/Auth/PasswordForgot';
import Test from './components/Test';
import Admin, { Locations, Menus, AdminMenu, LocationsDetails } from './components/Admin';
import MenuHome from './components/Admin/MenuHome';
import AdminTest from './components/Admin/Test';

import * as ROUTES from './const/routes';
import MenuThemeContext from './components/context.js';
import Settings from './components/Account/Settings';
import Company from './components/Account/Company.js';

import { withAuthentication } from './components/Session';
import ChangePassword from './components/Account/ChangePassword';

import Restaurant from './components/Public/Restaurant';
import MenuItemGroupHome from './components/Admin/MenuItemGroupHome';
import RestaurantTableHome from './components/Admin/RestaurantTableHome';
import RestaurantFeedbackHome from './components/Admin/RestaurantFeedbackHome';
import RestaurantWaitersHome from './components/Admin/RestaurantWaitersHome';
import RestaurantOrdersHome from './components/Admin/RestaurantOrdersHome';
import RestaurantOffersHome from './components/Admin/RestaurantOffersHome';
import RestaurantEventsHome from './components/Admin/RestaurantEventsHome';
import RestaurantStatsHome from './components/Admin/RestaurantStatsHome';
import MenuItemForm from './components/Admin/Menu/MenuItemForm';
import MenuItemFormNew from './components/Admin/Menu/MenuItemFormNew';

function App() {
    return (
        <ConfigProvider locale={roRO}>
            <Router>
                <Routes>
                    <Route path="/test" element={<Test />} />
                    <Route path={ROUTES.SIGN_UP} element={<SignUpPage />} />
                    <Route path={ROUTES.SIGN_IN} element={<SignInPage />} />
                    <Route path={ROUTES.SIGN_OUT} element={<SignOutPage />} />
                    <Route path={ROUTES.ADMIN_LOCATIONS + ':restaurantId' + ROUTES.ADMIN_MENU_DISPLAY + ':menuId'} element={<AdminMenu />} />
                    <Route path={ROUTES.ADMIN_LOCATIONS + ':restaurantId' + ROUTES.ADMIN_MENU_ITEM + ':menuItemId' + '/copy'} element={<MenuItemFormNew />} />
                    <Route path={ROUTES.ADMIN_LOCATIONS + ':restaurantId' + ROUTES.ADMIN_MENU_ITEM + ':menuItemId'} element={<MenuItemFormNew />} />
                    <Route path={ROUTES.ADMIN_LOCATIONS + ':restaurantId' + ROUTES.ADMIN_MENU_DISPLAY} element={<MenuHome />} />
                    <Route path={ROUTES.ADMIN_LOCATIONS + ':restaurantId' + ROUTES.ADMIN_MENUITEMGROUP} element={<MenuItemGroupHome />} />
                    <Route path={ROUTES.ADMIN_LOCATIONS + ':restaurantId' + ROUTES.ADMIN_RESTAURANT_TABLES} element={<RestaurantTableHome />} />
                    <Route path={ROUTES.ADMIN_LOCATIONS + ':restaurantId' + ROUTES.ADMIN_RESTAURANT_FEEDBACK} element={<RestaurantFeedbackHome />} />
                    <Route path={ROUTES.ADMIN_LOCATIONS + ':restaurantId' + ROUTES.ADMIN_RESTAURANT_WAITERS} element={<RestaurantWaitersHome />} />
                    <Route path={ROUTES.ADMIN_LOCATIONS + ':restaurantId' + ROUTES.ADMIN_RESTAURANT_ORDERS} element={<RestaurantOrdersHome />} />
                    <Route path={ROUTES.ADMIN_LOCATIONS + ':restaurantId' + ROUTES.ADMIN_RESTAURANT_ANALYTICS} element={<RestaurantStatsHome />} />
                    <Route
                        key="X"
                        path={ROUTES.ADMIN_LOCATIONS + ':restaurantId' + ROUTES.ADMIN_RESTAURANT_EVENTS}
                        element={<RestaurantEventsHome />}
                    />
                    <Route path={ROUTES.ADMIN_LOCATIONS + ':restaurantId' + ROUTES.ADMIN_RESTAURANT_OFFERS} element={<RestaurantOffersHome />} />
                    <Route path={ROUTES.ADMIN_LOCATIONS + ':restaurantId'} element={<LocationsDetails />} />
                    <Route path={ROUTES.ADMIN_LOCATIONS} element={<Locations />} />
                    <Route path={ROUTES.ADMIN_MENUS} element={<Menus />} />
                    <Route path={ROUTES.ADMIN_TEST} element={<AdminTest />} />
                    <Route path={ROUTES.ADMIN} element={<Admin />} />
                    <Route path={ROUTES.ACCOUNT_PW_CHANGE} element={<ChangePassword />} />
                    <Route path={ROUTES.ACCOUNT_SETTINGS} element={<Settings />} />
                    <Route path={ROUTES.ACCOUNT_COMPANY} element={<Company />} />
                    <Route path={ROUTES.PASSWORD_FORGET} element={<PasswordForgetPage />} />
                    <Route
                        path="/1"
                        element={
                            <MenuThemeContext.Provider value="1">
                                <Menu menuContent={mockMenu} menuDayContent={mockMenuDay} menuTemplate="1" />
                            </MenuThemeContext.Provider>
                        }
                    />
                    <Route
                        path="/2"
                        element={
                            <MenuThemeContext.Provider value="2">
                                <Menu menuContent={mockMenu} menuDayContent={mockMenuDay} menuTemplate="2" />
                            </MenuThemeContext.Provider>
                        }
                    />
                    <Route
                        path="/3"
                        element={
                            <MenuThemeContext.Provider value="3">
                                <Menu menuContent={mockMenu} menuDayContent={mockMenuDay} menuTemplate="3" />
                            </MenuThemeContext.Provider>
                        }
                    />
                    <Route
                        path="/0"
                        element={
                            <MenuThemeContext.Provider value="0">
                                <Menu menuContent={mockMenu} menuDayContent={mockMenuDay} />
                            </MenuThemeContext.Provider>
                        }
                    />
                    <Route path={ROUTES.PUBLIC_RESTAURANT + ':restaurantId'} element={<Restaurant />} />
                    <Route path="/" element={<Navigate replace to="/admin" />} />
                </Routes>
            </Router>
        </ConfigProvider>
    );
}

export default withAuthentication(App);
