import React, { useEffect, useState, useRef } from 'react';
import { Form, Select, Input, Spin, message, Space, Popover, Button, InputNumber, Alert } from 'antd';
import { DeliveredProcedureOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { ApiHelpers } from './';

const { Option } = Select;

const FormGeneralSettings = (props) => {
    const {
        restaurantId,
        generalSettings,
        setGeneralSettings,

    } = props;
    const [isLoading, setIsLoading] = useState(false);


    const tooltipDelivery = {
        title: 'Cum functioneaza? ',
        text: <div>La introducerea sau modificarea unui produs, daca pretul Glovo nu este actualizat, Poftigo propune automat in campul pret Glovo un pret majorat cu procentul introdus in acest camp.
            <br />Va trebui sa ajustezi sau sa confirmi pretul inainte de salvarea modificarilor.</div>,
    };

    const onChangeSettings = (value) => {
        setGeneralSettings({
            ...generalSettings,
            glovoPriceMultiplier: value,
        });
    };

    const triggerIntegrationSync = () => {
        message.loading({ content: 'Se incarca...', key: 'statusMessage' });
        ApiHelpers.syncMenuGlovo(restaurantId).then(
            (response) => {
                message.success({ content: 'Sincronizare finalizata cu succes', key: 'statusMessage', duration: 2 });
            },
            (error) => {
                message.error({ content: 'Eroare sincronizare!', key: 'statusMessage', duration: 2 });
                console.log(error);
            }
        );
    };

    return isLoading ? (
        <Spin />
    ) : (
        <div className="integration-selector">
            <h4><strong>Glovo</strong></h4>
            <Space size={10} direction="vertical"> 
                <Button type="secondary" className="btnAdd" onClick={triggerIntegrationSync}>
                    <DeliveredProcedureOutlined /> Trimite datele în sistemul de livrări
                </Button>
            </Space>
        </div>
    );
};

export default FormGeneralSettings;
