import React, { useEffect, useState } from "react";
import { Form, TimePicker, Checkbox, Tooltip, Spin } from 'antd';
import moment from "moment";
import { INITAL_STATE_SCHEDULEHELPER } from './';
import { DeliveredProcedureOutlined } from '@ant-design/icons';
import { ApiHelpers } from './';

const FormSchedule = (props) => {

    const { scheduleList, setScheduleList, restaurantId, form, data, type, drawerVisible } = props;

    const [scheduledListFormatted, setScheduleListFormatted] = useState();
    const [scheduleHelper, setScheduleHelper] = useState(INITAL_STATE_SCHEDULEHELPER);
    const [isLoading, setIsLoading] = useState(false);

    const INITIAL_STATE_SCHEDULE = {
        "mondayStart": null,
        "mondayEnd": null,
        "tuesdayStart": null,
        "tuesdayEnd": null,
        "wednesdayStart": null,
        "wednesdayEnd": null,
        "thursdayStart": null,
        "thursdayEnd": null,
        "fridayStart": null,
        "fridayEnd": null,
        "saturdayStart": null,
        "saturdayEnd": null,
        "sundayStart": null,
        "sundayEnd": null,
        "restaurantToken": restaurantId
    }

    const formatScheduleList = (schedule) => {
        if (!schedule) {
            setScheduleList(INITIAL_STATE_SCHEDULE)
        }
        else {
            const dateFormat = "HH:mm";
            const newScheduleListFormatted = {
                scheduleMonday: schedule.mondayStart ? [moment(schedule.mondayStart, dateFormat), moment(schedule.mondayEnd, dateFormat)] : null,
                scheduleTuesday: schedule.tuesdayStart ? [moment(schedule.tuesdayStart, dateFormat), moment(schedule.tuesdayEnd, dateFormat)] : null,
                scheduleWednesday: schedule.wednesdayStart ? [moment(schedule.wednesdayStart, dateFormat), moment(schedule.wednesdayEnd, dateFormat)] : null,
                scheduleThursday: schedule.thursdayStart ? [moment(schedule.thursdayStart, dateFormat), moment(schedule.thursdayEnd, dateFormat)] : null,
                scheduleFriday: schedule.fridayStart ? [moment(schedule.fridayStart, dateFormat), moment(schedule.fridayEnd, dateFormat)] : null,
                scheduleSaturday: schedule.saturdayStart ? [moment(schedule.saturdayStart, dateFormat), moment(schedule.saturdayEnd, dateFormat)] : null,
                scheduleSunday: schedule.sundayStart ? [moment(schedule.sundayStart, dateFormat), moment(schedule.sundayEnd, dateFormat)] : null,
            };
            setScheduleListFormatted(newScheduleListFormatted);
            form.setFieldsValue(newScheduleListFormatted);
        }
    }

    useEffect(() => {
        // Get Schedule   
        formatScheduleList(scheduleList);
    }, [data, drawerVisible]);

    const onChangeSchedule = (key, time, timeString) => {
        const day = key.substring(8).toLowerCase();
        const dayStart = day + "Start";
        const dayEnd = day + "End";
        setScheduleList({ ...scheduleList, [dayStart]: timeString[0], [dayEnd]: timeString[1] });
    }

    const handleDisableTimePicker = (available) => {
        setScheduleList({ ...scheduleList, [available]: !scheduleList[available] })
    }

    const triggerImportSchedule = (e) => {
        setIsLoading(true);
        e.preventDefault();
        ApiHelpers.getRestaurantSchedule(restaurantId)
            .then((response) => {
                if (!response.data.message) {
                    const { uniqueIdentificationToken, ...newSchedule } = response.data;
                    formatScheduleList({ ...newSchedule });
                    setScheduleList({ ...newSchedule });
                }
                setIsLoading(false);
            }, (error) => {
                console.log(error);
                setIsLoading(false);
            });
    }

    return (
        <>
            {type === 'menu' ?
                <div className="actionLinkIcon">
                    <DeliveredProcedureOutlined />
                    <Tooltip title="Apasa pentru a prelua programul restaurantului">
                        <a href="#" onClick={triggerImportSchedule}>Preia programul restaurantului</a>
                    </Tooltip>
                </div>

                : null}
            {isLoading ? <Spin /> :
                scheduleList && scheduleHelper.map((d, index) => {
                    const { start, end, label, name, available } = d;
                    return <Form.Item
                        key={name + '' + index}
                        name={name}
                        label={<Checkbox name={available} checked={scheduleList[available]} onChange={() => handleDisableTimePicker(available)}>{label}</Checkbox>}
                        rules={[{ required: false }]}
                    >

                        <TimePicker.RangePicker
                            format="HH:mm"
                            disabled={!scheduleList[available]}
                            placeholder={["Ora start", "Ora final"]}
                            onChange={(time, timeString) => onChangeSchedule(name, time, timeString)}
                            name={name} />

                    </Form.Item>
                })}

        </>
    )
}

export default FormSchedule;