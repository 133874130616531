import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MainLayout from '../Layout/MainLayout';
import { Row, Col, Form, Input, Button, Checkbox } from 'antd';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../const/routes';

import { UserOutlined, LockOutlined } from '@ant-design/icons';

const PasswordForgetPage = () => (
  <MainLayout>
    <Row>
      <Col xs={{ span: 22, offset: 1 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 7 }} lg={{ span: 12, offset: 6 }} xl={{ span: 8, offset: 8 }}>
        <h1 className="page-title">Ai uitat parola?</h1>
        <PasswordForgetForm />
      </Col>
    </Row>
  </MainLayout>

);

const INITIAL_STATE = {
  email: '',
  error: null,
  success: null
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email } = this.state;
    this.setState({ success: null });
    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.state = { ...INITIAL_STATE };
        this.setState({ success: "Am trimis un email de resetare a parolei la adresa mentionata" });
      })
      .catch(error => {
        this.setState({ error });
      });

  
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error, success} = this.state;
    
    const isInvalid = email === '';

    return (
      <div>
        {success && <p>{success}</p>}
        {error && <p>{error.message}</p>}
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={this.onSubmit}
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: 'Adresa de email trebuie introdusa',
              }
            ]}
          >
            <Input size="large" name="email" type="email" prefix={<UserOutlined className="site-form-item-icon" />} onChange={this.onChange} value={email} placeholder="Email" />
          </Form.Item>
         
         
        <Button size="large" type="primary" htmlType="submit" disabled={isInvalid} className="login-form-button">
              Reseteaza parola
        </Button>
        </Form>
      </div>
    );
  }
}

const PasswordForgetLink = () => (
  <p>
    <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
  </p>
);

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };