import React from 'react';
import Header from './Header';
import { Layout, Row, Col } from 'antd';
import Footer from './Footer';

export default function AuthLayout(props) {
    return (
        <Layout className="authLayout">
            <Row>
                <Col span={24}>
                    {props.children}
                </Col>
            </Row>
            <Footer />
        </Layout>
    );
}