import React from 'react';
import ImgCrop from 'antd-img-crop';
import { Button, message, Upload, Menu, Dropdown } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { ApiHelpers } from '../../../helpers';

export default function UploadMenuItemImage(props) {
    const { menuItemsData, setMenuItemsData, getMenuItems, newElement, setNewElement, restaurantIdentificationToken, menuId } = props;

    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };

    const uploadPropsImage = {
        name: 'file',
        action: ApiHelpers.uploadMenuItemImage(newElement.uniqueIdentificationToken, restaurantIdentificationToken),
        method: 'PUT',
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                // console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
                setNewElement({
                    ...newElement,
                    itemImagePath: info.file.response.itemImagePath,
                });
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onPreview: onPreview,
    };

    const menuImg = (uniqueIdentificationToken = newElement.uniqueIdentificationToken) => {
        return (
            <Menu>
                <Menu.Item>
                    <a onClick={() => onDeleteMenuItemImage(uniqueIdentificationToken, restaurantIdentificationToken)} href="#">
                        Sterge
                    </a>
                </Menu.Item>
                <Menu.Item>
                    <ImgCrop rotate aspect={3 / 2} minZoom={0.2} cropperProps={{ restrictPosition: false }}>
                        <Upload {...uploadPropsImage}>
                            <a href="#">Modifica</a>
                        </Upload>
                    </ImgCrop>
                </Menu.Item>
            </Menu>
        );
    };

    const onDeleteMenuItemImage = (uniqueIdentificationToken, restaurantIdentificationToken) => {
        ApiHelpers.deleteMenuItemImage(uniqueIdentificationToken, restaurantIdentificationToken).then(
            (response) => {
                setNewElement({
                    ...newElement,
                    itemImagePath: null,
                });
                message.success('Imagine stearsa!');
            },
            (error) => {
                console.log(error);
                message.error('Eroare!');
            }
        );
    };

    return (
        <>
            {newElement.itemImagePath ? (
                <Dropdown overlay={menuImg}>
                    <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                        <img src={ApiHelpers.getImageLink(newElement.itemImagePath)} className="uploadedImage" />
                    </a>
                </Dropdown>
            ) : (
                <ImgCrop rotate aspect={3 / 2} minZoom={0.2} cropperProps={{ restrictPosition: false }}>
                    <Upload {...uploadPropsImage}>
                        <Button icon={<UploadOutlined />}>Încarcă imagine</Button>
                    </Upload>
                </ImgCrop>
            )}
        </>
    );
}
