import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { activeEnvironment } from '../../../../config';
import {
    ApiHelpers,
    nutritionalMenuFullListOfIngredients,
    nutritionalMenuFullValues,
    sortByKey,
    nutritionalCalculation,
    formatFoodAdditives,
    uniqueValuesinArray,
    formatNutritionalValues,
    formatAllergensAndAdditives,
} from '../../../../helpers';
import { Buffer } from 'buffer';
import { Button } from 'antd';
import { DeliveredProcedureOutlined } from '@ant-design/icons';
import moment from 'moment';
import {
    Document,
    HeadingLevel,
    Packer,
    Paragraph,
    Table,
    TableCell,
    TableRow,
    VerticalAlign,
    TextDirection,
    BorderStyle,
    WidthType,
    AlignmentType,
    TextRun,
    ImageRun,
    convertInchesToTwip,
} from 'docx';
import { saveAs } from 'file-saver';
import { P } from '@antv/g2plot';
const proxyURL = 'https://proxy-vhillsioda-uc.a.run.app/';
async function getBase64(url) {
    let image = await axios.get(url, { responseType: 'arraybuffer' });
    let raw = Buffer.from(image.data).toString('base64');

    return raw;
    //return 'data:' + image.headers['content-type'] + ';base64,' + raw;
}

const MenuPrintDocx = (props) => {
    const { restaurantDetails, selectedMenuCategories, itemsInMenuCategoryTag, restaurantTitle } = props;
    const [isLoading, setIsLoading] = useState(false);

    const nutritionalMenuShort = (nutritionalData, record, embed = false) => {
        const nutritionalDataSorted = sortByKey(nutritionalData, 'quantity', 0);

        let listOfIngredientsSorted = [];
        let nutritionalValues = [];
        let noOfAdditives = 0;
        let uniqueAdditives = [];
        let noOfUniqueAdditives = 0;
        let allergensInComponents = [];
        let foodAdditivesExtractE = [];
        nutritionalDataSorted.map((item, index) => {
            if (item.displayFoodAdditives) {
                uniqueAdditives = [...uniqueAdditives, ...formatFoodAdditives(item)];
                noOfAdditives += item.foodAdditives.split(',').length;
            }
            if (item.components && item.components.length > 0) {
                item.components.map((item2) => {
                    if (item2.displayFoodAdditives) {
                        uniqueAdditives = [...uniqueAdditives, ...formatFoodAdditives(item2)];
                        noOfAdditives += item2.foodAdditives.split(',').length;
                    }
                });
            }
        });
        uniqueAdditives = uniqueValuesinArray(uniqueAdditives);
        noOfUniqueAdditives = uniqueAdditives.filter(function (v, i) {
            return i == uniqueAdditives.lastIndexOf(v);
        }).length;

        nutritionalDataSorted.map((item, index) => {
            allergensInComponents = [];
            const unitOfMeasure = item.unitOfMeasure || 'g';
            if (item.components && item.components.length > 0)
                if (item.components.filter((d) => d.allergens).length > 0)
                    item.components.map((d) => {
                        if (d.allergens) allergensInComponents.push(d.allergens);
                    });
            listOfIngredientsSorted.push(
                new TextRun({
                    text: index > 0 && index < nutritionalDataSorted.length ? ', ' : '',
                }),
                new TextRun({
                    text: item.allergens ? item.title : item.title,
                    bold: item.allergens ? true : false,
                }),
                new TextRun({
                    text: ' ' + item.quantity + ' ' + unitOfMeasure,
                }),
                new TextRun({
                    text: allergensInComponents.length > 0 ? ' (alergeni: ' + uniqueValuesinArray(allergensInComponents).join('; ') + ')' : '',
                })
            );
        });
        nutritionalValues.push(
            'Valoare energetica: ' +
                formatNutritionalValues(record.energy, true) +
                ' | Grasimi: ' +
                formatNutritionalValues(record.fat) +
                ' g |  acizi grasi saturati: ' +
                formatNutritionalValues(record.saturates) +
                ' g | Glucide: ' +
                formatNutritionalValues(record.carbohydrate) +
                ' g | Zaharuri: ' +
                formatNutritionalValues(record.sugars) +
                ' g | Proteine: ' +
                formatNutritionalValues(record.proteins) +
                ' g | Sare: ' +
                formatNutritionalValues(record.salt) +
                ' g'
        );

        return [
            new Paragraph({
                children: [
                    new TextRun({
                        text: record.title,
                        style: 'menuItemTitle',
                    }),
                    new TextRun({
                        text: noOfUniqueAdditives > 0 ? ' - ' + noOfUniqueAdditives + ' aditivi / ' + uniqueAdditives.join(',') : '',
                        style: 'menuItemTitleExtra',
                    }),
                ],
            }),
            new Paragraph({
                children: [
                    record.weight && record.weight != 0 ? new TextRun(' ' + record.weight + ' | ') : new TextRun(''),
                    record.itemDescription ? new TextRun(record.itemDescription) : new TextRun(''),
                ],
                style: 'standard',
            }),
            new Paragraph({
                children: [...listOfIngredientsSorted],
                style: 'standard',
            }),
            new Paragraph({
                text: 'Valori nutritionale / 100g',
                style: 'standard',
            }),
            new Paragraph({
                children: [new TextRun(nutritionalValues.join(' '))],
                style: 'standard',
            }),
        ];
    };

    const renderMenuItemIngredientShort = (item, index, length, unitOfMeasure, isChild = 0, parentQuantity = null, parentSum) => {
        let componentsSumQuantity = 0;
        const hasAllergensOrAdditives = item.foodAdditives !== null || item.allergens ? true : false;
        if (item.components && item.components.length > 0) item.components.map((d) => (componentsSumQuantity += d.quantity));
        const array = [];
        array.push(
            new TextRun({
                text: (index > 0 && index < length && !isChild) || (isChild && index > 0 && hasAllergensOrAdditives) ? ', ' : '',
            }),
            new TextRun({
                text: !isChild ? (item.allergens ? item.title : item.title) : '',
                bold: item.allergens ? true : false,
            }),
            new TextRun({
                text: !isChild ? ' ' + item.quantity + ' ' + unitOfMeasure : '',
            })
        );
        return [...array];
        return (
            new TextRun({
                text: (index > 0 && index < length && !isChild) || (isChild && index > 0 && hasAllergensOrAdditives) ? ', ' : '',
            }),
            new TextRun({
                text: !isChild ? (item.allergens ? item.title : item.title) : '',
                bold: item.allergens ? true : false,
            }),
            new TextRun({
                text: !isChild ? ' ' + item.quantity + ' ' + unitOfMeasure : '',
            }),
            new TextRun({
                text: item.foodAdditives ? (!isChild ? ' | ' : '') : ' aditivi:' + item.displayFoodAdditives,
            }),
            new TextRun({
                text: item.allergens ? ' | alergen: ' + item.allergens : '',
            }),
            new TextRun({
                text: item.components && item.components.length > 0 ? ' (' : '',
            }),
            item.components && item.components.length > 0
                ? sortByKey(item.components, 'quantity', 0).map((d, index) => {
                      return renderMenuItemIngredientShort(d, index, item.components.length, unitOfMeasure, 1, item.quantity, componentsSumQuantity);
                  })
                : new TextRun({
                      text: '',
                  }),
            new TextRun({
                text: item.components && item.components.length > 0 ? ')' : '',
            })
        );
    };

    const nutritionalMenuShort2 = (nutritionalData, record, embed = false) => {
        const nutritionalDataSorted = sortByKey(nutritionalData, 'quantity', 0);

        let listOfIngredientsSorted = [];
        let nutritionalValues = [];
        let noOfAdditives = 0;
        let uniqueAdditives = [];
        let noOfUniqueAdditives = 0;
        let allergensInComponents = [];
        let foodAdditivesExtractE = [];
        nutritionalDataSorted.map((item, index) => {
            if (item.displayFoodAdditives) {
                uniqueAdditives = [...uniqueAdditives, ...formatFoodAdditives(item)];
                noOfAdditives += item.foodAdditives.split(',').length;
            }
            if (item.components && item.components.length > 0) {
                item.components.map((item2) => {
                    if (item2.displayFoodAdditives) {
                        uniqueAdditives = [...uniqueAdditives, ...formatFoodAdditives(item2)];
                        noOfAdditives += item2.foodAdditives.split(',').length;
                    }
                });
            }
        });
        uniqueAdditives = uniqueValuesinArray(uniqueAdditives);
        noOfUniqueAdditives = uniqueAdditives.filter(function (v, i) {
            return i == uniqueAdditives.lastIndexOf(v);
        }).length;

        nutritionalDataSorted.map((item, index) => {
            allergensInComponents = [];
            const unitOfMeasure = item.unitOfMeasure || 'g';
            if (item.components && item.components.length > 0)
                if (item.components.filter((d) => d.allergens).length > 0)
                    item.components.map((d) => {
                        if (d.allergens) allergensInComponents.push(d.allergens);
                    });
            let isChild = 1;
            const hasAllergensOrAdditives = item.foodAdditives !== null || item.allergens ? true : false;
            let hasAllergens = item.allergens || (item.components && item.components.filter((d) => d.allergens).length > 0);
            const childrenArray = [];
            item.components && item.components.length > 0
                ? sortByKey(item.components, 'quantity', 0).map((d, index) => {
                    hasAllergens = d.allergens ? true : false;
                      childrenArray.push(
                          new TextRun({
                              text: index > 0 && index < item.components.length && !isChild ? ', ' : isChild && index > 0 && hasAllergens ? '; ' : '',
                          }),
                          new TextRun({
                              text: !isChild ? (item.allergens ? d.title : d.title) : '',
                              bold: d.allergens ? true : false,
                          }),
                          new TextRun({
                              text: !isChild ? ' ' + d.quantity + ' ' + unitOfMeasure : '',
                          }),
                          new TextRun({
                              text: d.allergens ? ' | alergen: ' + formatAllergensAndAdditives(d.allergens) : '',
                          }),
                          new TextRun({
                              text: d.components && d.components.length > 0 ? ' (' : '',
                          })
                      );
                  })
                : new TextRun({
                      text: '',
                  });
            isChild = 0;
            listOfIngredientsSorted.push(
                new TextRun({
                    text:
                        (index > 0 && index < nutritionalDataSorted.length && !isChild) || (isChild && index > 0 && hasAllergensOrAdditives)
                            ? ', '
                            : '',
                }),
                new TextRun({
                    text: !isChild ? (item.allergens ? item.title : item.title) : '',
                    bold: item.allergens || hasAllergens ? true : false,
                }),
                new TextRun({
                    text: item.allergens ? ' | alergen: ' +  formatAllergensAndAdditives(item.allergens) : '',
                }),
                new TextRun({
                    text: item.components && item.components.length > 0 ? ' (' : '',
                }),
                ...childrenArray,
                new TextRun({
                    text: item.components && item.components.length > 0 ? ')' : '',
                })
            );
        });
        listOfIngredientsSorted.push(
            new TextRun({
                text: noOfUniqueAdditives ? ' - ' + noOfUniqueAdditives + 'E' : '',
            })
        );
        nutritionalValues.push(
            'Valoare energetica: ' +
                formatNutritionalValues(record.energy, true) +
                ' | Grasimi: ' +
                formatNutritionalValues(record.fat) +
                ' g |  acizi grasi saturati: ' +
                formatNutritionalValues(record.saturates) +
                ' g | Glucide: ' +
                formatNutritionalValues(record.carbohydrate) +
                ' g | Zaharuri: ' +
                formatNutritionalValues(record.sugars) +
                ' g | Proteine: ' +
                formatNutritionalValues(record.proteins) +
                ' g | Sare: ' +
                formatNutritionalValues(record.salt) +
                ' g'
        );

        return [
            new Paragraph({
                children: [
                    new TextRun({
                        text: record.title,
                        style: 'menuItemTitle',
                    }),
                ],
            }),
            new Paragraph({
                children: [
                    record.weight && record.weight != 0 ? new TextRun(record.weight) : new TextRun(''),
                    record.itemDescription ? new TextRun(' | ' + record.itemDescription) : new TextRun(''),
                ],
                style: 'standard',
            }),
            new Paragraph({
                children: [...listOfIngredientsSorted],
                style: 'standard',
            }),
            new Paragraph({
                text: 'Valori nutritionale / 100g',
                style: 'standard',
            }),
            new Paragraph({
                children: [new TextRun(nutritionalValues.join(' '))],
                style: 'standard',
            }),
        ];
    };

    const generateDocx = async (imgArray) => {
        const createTableRow = (menuItem, index) => {
            const hasListOfIngredients = (menuItem) => menuItem.listOfIngredients && menuItem.listOfIngredients.length > 0;
            let imgBase64 = null;
            // if (menuItem.itemImagePath) {
            //     imgBase64 = await getBase64(
            //         activeEnvironment === 'live'
            //             ? proxyURL + ApiHelpers.getImageLinkSize(menuItem.itemImagePath, 'small')
            //             : ApiHelpers.getImageLinkSize(menuItem.itemImagePath, 'small')
            //     ).catch(() => {
            //         return null;
            //     });
            // }
            return new TableRow({
                children: [
                    imgArray[index]
                        ? new TableCell({
                              children: [
                                  new Paragraph({
                                      children: [
                                          imgArray[index]
                                              ? new ImageRun({
                                                    data: Buffer.from(imgArray[index], 'base64'),
                                                    transformation: {
                                                        width: 130,
                                                        height: 80,
                                                    },
                                                })
                                              : new TextRun(''),
                                      ],
                                  }),
                              ],
                              margins: {
                                  top: convertInchesToTwip(0.03),
                                  bottom: convertInchesToTwip(0.05),
                                  left: convertInchesToTwip(0.03),
                                  right: convertInchesToTwip(0.05),
                              },
                              borders,
                          })
                        : [],
                    new TableCell({
                        children: [
                            ...(hasListOfIngredients(menuItem)
                                ? nutritionalMenuShort2(menuItem.listOfIngredients, menuItem)
                                : [
                                      new Paragraph({
                                          text: menuItem.title,
                                          heading: HeadingLevel.HEADING_3,
                                      }),
                                      new Paragraph({
                                          children: [
                                              menuItem.weight && menuItem.weight != 0 ? new TextRun(' ' + menuItem.weight + ' | ') : new TextRun(''),
                                              menuItem.itemDescription ? new TextRun(menuItem.itemDescription) : new TextRun(''),
                                              new TextRun(' ' + menuItem.ingredients),
                                          ],
                                          style: 'standard',
                                      }),
                                  ]),
                        ],
                        verticalAlign: VerticalAlign.TOP,
                        margins: {
                            top: convertInchesToTwip(0.03),
                            bottom: convertInchesToTwip(0.15),
                            left: convertInchesToTwip(0.03),
                            right: convertInchesToTwip(0.05),
                        },
                        borders,
                        columnSpan: imgArray[index] ? 1 : 2,
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                text: `${menuItem.price.toFixed(2)} lei`,
                                alignment: AlignmentType.RIGHT,
                                heading: HeadingLevel.HEADING_4,
                                margins: {
                                    left: convertInchesToTwip(0.03),
                                },
                            }),
                        ],
                        verticalAlign: VerticalAlign.TOP,
                        margins: {
                            top: convertInchesToTwip(0.03),
                            bottom: convertInchesToTwip(0.05),
                            left: convertInchesToTwip(0.05),
                            right: convertInchesToTwip(0.03),
                        },
                        borders,
                    }),
                ],
            });
        };

        const createTableRowTitle = (menuCategory) => {
            return new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({ text: menuCategory.categoryName, alignment: AlignmentType.CENTER, heading: HeadingLevel.HEADING_2 }),
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                        borders,
                        columnSpan: 3,
                    }),
                ],
            });
        };

        restaurantDetails.menu &&
            restaurantDetails.menu[0].category &&
            restaurantDetails.menu[0].category.map((menuCategory, index) => {
                if (
                    selectedMenuCategories !== null
                        ? selectedMenuCategories.checkedNodes.filter((d) => d.key === menuCategory.menuCategoryToken).length > 0
                        : true
                )
                    if (menuCategory.item && menuCategory.item.length > 0) {
                        menuCategory.item.map((menuCategoryItem, index) => {
                            const arr = [];
                            return arr;
                        });
                    }
            });

        const borders = {
            top: {
                style: BorderStyle.NONE,
                size: 0,
                color: 'FFFFFF',
            },
            bottom: {
                style: BorderStyle.NONE,
                size: 0,
                color: 'FFFFFF',
            },
            left: {
                style: BorderStyle.NONE,
                size: 0,
                color: 'FFFFFF',
            },
            right: {
                style: BorderStyle.NONE,
                size: 0,
                color: 'FFFFFF',
            },
        };
        let ctr = 0;
        const doc = new Document({
            creator: 'Poftigo',
            title: 'Meniu',
            styles: {
                default: {
                    heading1: {
                        run: {
                            font: 'Arial',
                            size: 34,
                            bold: true,
                        },
                        paragraph: {
                            spacing: {
                                after: 450,
                            },
                        },
                    },
                    heading2: {
                        run: {
                            font: 'Arial',
                            size: 22,
                            bold: true,
                        },
                        paragraph: {
                            spacing: {
                                before: 150,
                                after: 80,
                            },
                        },
                    },
                    heading3: {
                        run: {
                            font: 'Arial',
                            size: 26,
                            bold: true,
                            color: '#292333',
                        },
                        paragraph: {
                            spacing: {
                                after: 60,
                            },
                        },
                    },
                    heading4: {
                        run: {
                            font: 'Arial',
                            size: 20,
                            bold: true,
                            color: '#292333',
                        },
                        paragraph: {},
                    },
                    listParagraph: {
                        run: {
                            font: 'Arial',
                            color: '#FF0000',
                        },
                    },
                },
                paragraphStyles: [
                    {
                        id: 'standard',
                        name: 'Standard',
                        basedOn: 'Normal',
                        next: 'Normal',
                        run: {
                            font: 'Arial',
                            size: 20,
                        },
                        paragraph: {
                            indent: {},
                            spacing: {},
                        },
                    },
                ],
                characterStyles: [
                    {
                        id: 'menuItemTitle',
                        name: 'menuItemTitle',
                        basedOn: 'Normal',
                        next: 'Normal',
                        run: {
                            font: 'Arial',
                            size: 26,
                            bold: true,
                        },
                        paragraph: {
                            indent: {},
                            spacing: {},
                        },
                    },
                    {
                        id: 'menuItemTitleExtra',
                        name: 'menuItemTitleExtra',
                        basedOn: 'Normal',
                        next: 'Normal',
                        run: {
                            font: 'Arial',
                            size: 22,
                            bold: false,
                        },
                        paragraph: {
                            indent: {},
                            spacing: {},
                        },
                    },
                ],
            },
            sections: [
                {
                    children: [
                        new Paragraph({
                            text: restaurantTitle,
                            heading: HeadingLevel.HEADING_1,
                            alignment: AlignmentType.CENTER,
                        }),
                        new Table({
                            rows: [
                                ...restaurantDetails.menu[0].category
                                    .filter((d) => d.englishTag === 'food')
                                    .map((menuCategory, index) => {
                                        let arr = [];
                                        if (
                                            selectedMenuCategories !== null
                                                ? selectedMenuCategories.checkedNodes.filter((d) => d.key === menuCategory.menuCategoryToken).length >
                                                  0
                                                : true
                                        )
                                            if (menuCategory.item && menuCategory.item.length > 0) {
                                                arr.push(createTableRowTitle(menuCategory));

                                                menuCategory.item.map((menuCategoryItem) => {
                                                    arr.push(createTableRow(menuCategoryItem, ctr));
                                                    ctr++;
                                                });
                                            }
                                        return arr;
                                    })
                                    .reduce((prev, curr) => prev.concat(curr), []),
                                ...restaurantDetails.menu[0].category
                                    .filter((d) => d.englishTag === 'beverages')
                                    .map((menuCategory, index) => {
                                        let arr = [];
                                        if (
                                            selectedMenuCategories !== null
                                                ? selectedMenuCategories.checkedNodes.filter((d) => d.key === menuCategory.menuCategoryToken).length >
                                                  0
                                                : true
                                        )
                                            if (menuCategory.item && menuCategory.item.length > 0) {
                                                arr.push(createTableRowTitle(menuCategory));

                                                menuCategory.item.map((menuCategoryItem) => {
                                                    arr.push(createTableRow(menuCategoryItem, ctr));
                                                    ctr++;
                                                });
                                            }
                                        return arr;
                                    })
                                    .reduce((prev, curr) => prev.concat(curr), []),
                                ...restaurantDetails.menu[0].category
                                    .filter((d) => d.englishTag === 'tobacco')
                                    .map((menuCategory, index) => {
                                        let arr = [];
                                        if (
                                            selectedMenuCategories !== null
                                                ? selectedMenuCategories.checkedNodes.filter((d) => d.key === menuCategory.menuCategoryToken).length >
                                                  0
                                                : true
                                        )
                                            if (menuCategory.item && menuCategory.item.length > 0) {
                                                arr.push(createTableRowTitle(menuCategory));

                                                menuCategory.item.map((menuCategoryItem) => {
                                                    arr.push(createTableRow(menuCategoryItem, ctr));
                                                    ctr++;
                                                });
                                            }
                                        return arr;
                                    })
                                    .reduce((prev, curr) => prev.concat(curr), []),
                            ],
                            width: {
                                size: 100,
                                type: WidthType.PERCENTAGE,
                            },
                        }),

                        new Paragraph({
                            text: 'Ingredientele ingrosate contin sau pot contine alergeni.',
                            spacing: {
                                before: 340,
                            },
                        }),
                    ],
                },
            ],
        });

        Packer.toBlob(doc).then((blob) => {
            // saveAs from FileSaver will download the file
            saveAs(blob, 'menu-' + restaurantTitle + '-' + moment().format('YYYY-MM-DD'));
            setIsLoading(false);
        });
    };

    const getAllMenuItemImages = async () => {
        let arr = [];
        restaurantDetails.menu[0].category.map(async (menuCategory, index) => {
            if (
                selectedMenuCategories !== null
                    ? selectedMenuCategories.checkedNodes.filter((d) => d.key === menuCategory.menuCategoryToken).length > 0
                    : true
            )
                if (menuCategory.item && menuCategory.item.length > 0) {
                    menuCategory.item.map((menuItem, index) => {
                        if (menuItem.itemImagePath) {
                            const imgBase64 = getBase64(
                                activeEnvironment === 'live'
                                    ? proxyURL + ApiHelpers.getImageLinkSize(menuItem.itemImagePath, 'small')
                                    : ApiHelpers.getImageLinkSize(menuItem.itemImagePath, 'small')
                            ).catch(() => {
                                return null;
                            });
                            arr.push(imgBase64);
                        }
                    });
                }
        });
        const results = await Promise.all(arr);
        return results;
    };

    const triggerDownload = async () => {
        setIsLoading(true);
        const imgArray = await getAllMenuItemImages();
        generateDocx(imgArray);
    };

    return !isLoading ? (
        <Button type="primary" size="large" icon={<DeliveredProcedureOutlined />} onClick={triggerDownload}>
            Descarca DOCX
        </Button>
    ) : (
        <Button size="large" loading={true}>
            Se incarca
        </Button>
    );
};

export default MenuPrintDocx;
