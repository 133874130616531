import React, { Component } from 'react';
import { Link, Navigate } from 'react-router-dom';

import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';
import * as ROUTES from '../../const/routes';
import { Row, Col, Form, Input, Button, Checkbox, Alert } from 'antd';
import { UserOutlined, LockOutlined, EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import AuthLayout from '../Layout/AuthLayout';
import logo from '../../assets/img/poftigo-admin-logo-tr.png';
import withRouter from '../Session/withRouter';

const SignInPage = () => (
    <AuthUserContext.Consumer>
        {(authUser) =>
            !authUser ? (
                <AuthLayout>
                    <Row>
                        <Col
                            xs={{ span: 22, offset: 1 }}
                            sm={{ span: 16, offset: 4 }}
                            md={{ span: 12, offset: 6 }}
                            lg={{ span: 10, offset: 7 }}
                            xl={{ span: 8, offset: 8 }}
                            xxl={{ span: 6, offset: 9 }}>
                            <div className="authFormContainer">
                                <div className="authLogo">
                                    <img src={logo} title="Pofitgo Admin" alt="Poftigo Admin" />
                                </div>
                                <h1 className="page-title">Intră în contul de administrator</h1>
                                <SignInForm />
                            </div>
                        </Col>
                    </Row>
                </AuthLayout>
            ) : (
              <Navigate to={ROUTES.ADMIN} replace={true} />
            )
        }
    </AuthUserContext.Consumer>
);

const INITIAL_STATE = {
    email: '',
    password: '',
    error: null,
};

class SignInFormBase extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    onSubmit = (values) => {
        const { email, password } = values;
        this.props.firebase
            .doSignInWithEmailAndPassword(email, password)
            .then(() => {
                this.setState({ ...INITIAL_STATE });
                this.props.router.navigate(ROUTES.ADMIN);
            })
            .catch((error) => {
                this.setState({ error });
            });
    };

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { email, password, error } = this.state;

        const isInvalid = password === '' || email === '';

        return (
            <div>
                {error && <Alert type="error" message={error.message} />}
                <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={this.onSubmit}>
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Adresa de email trebuie introdusa',
                            },
                        ]}>
                        <Input
                            size="large"
                            prefix={<UserOutlined className="site-form-item-icon" />}
                            name="email"
                            onChange={this.onChange}
                            type="email"
                            value={email}
                            placeholder="Email"
                        />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Parola trebuie introdusa',
                            },
                        ]}>
                        <Input.Password
                            size="large"
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            onChange={this.onChange}
                            value={password}
                            name="password"
                            placeholder="Parola"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Form.Item name="remember" valuePropName="checked" noStyle>
                            <Checkbox>Reține</Checkbox>
                        </Form.Item>
                        <Link className="login-form-forgot" to={ROUTES.PASSWORD_FORGET}>
                            Am uitat parola
                        </Link>
                    </Form.Item>
                    <Form.Item>
                        <Button size="large" type="primary" htmlType="submit" className="login-form-button">
                            Intră în cont
                        </Button>
                    </Form.Item>
                    Sau <Link to={ROUTES.SIGN_UP}>fă-ți cont acum!</Link>
                </Form>
            </div>
        );
    }
}

const SignInForm = withFirebase(withRouter(SignInFormBase));

export default SignInPage;

export { SignInForm };
