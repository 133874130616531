import React from 'react';
import { Form, Select, Row, Col, Popconfirm } from 'antd';

const { Option } = Select;

const MenuItem = (props) => {
    const { index, menuItems, menuItemFormList, item, form, setMenuItemFormList, refreshForm } = props;

    const onChangeSelectMenuItem = (value, index) => {
        const newMenuItemFormList = menuItemFormList;
        newMenuItemFormList[index].uniqueIdentificationToken = value;

        setMenuItemFormList([...newMenuItemFormList]);
    };

    const deleteMenuItem = (index) => {
        let newMenuItemFormList = [...menuItemFormList];
        newMenuItemFormList.splice(index, 1);
        setMenuItemFormList([...newMenuItemFormList]);
        refreshForm(newMenuItemFormList);
    };

    return (
        <Row key={'menuItemRow-' + index} gutter={[5, 5]}>
            <Col span={18}>
                <Form.Item name={'menuItem-' + index} rules={[{ required: true, message: 'Camp obligatoriu' }]}>
                    <Select
                        style={{ width: '100%' }}
                        showSearch
                        placeholder="Selecteaza grup auxiliar"
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        onChange={(value) => onChangeSelectMenuItem(value, index)}>
                        {menuItems &&
                            menuItems.map((d, index2) => {
                                return (
                                    <Option key={'menuItemOption-' + index + '-' + index2} value={d.uniqueIdentificationToken}>
                                        {d.groupName}
                                    </Option>
                                );
                            })}
                    </Select>
                </Form.Item>
            </Col>

            <Col span={5} offset={1}>
                <Popconfirm title="Sigur vrei sa stergi?" onConfirm={() => deleteMenuItem(index)}>
                    <a>șterge</a>
                </Popconfirm>
            </Col>
        </Row>
    );
};

export default MenuItem;
