import React, { useState, useEffect } from 'react';
import { Table, Input, Drawer, Button, Collapse, Skeleton } from 'antd';
import { UnorderedListOutlined, HourglassOutlined, CheckOutlined } from '@ant-design/icons';
import { ApiHelpers } from '../../../helpers';
import moment from 'moment';
import 'moment/locale/ro';
import { useRecoilState } from 'recoil';
import { activeRestaurantState } from '../../../recoil';

const { Column } = Table;
const { Panel } = Collapse;
const Search = Input.Search;

const RestaurantTablesOccupied = (props) => {
    const [restaurantTables, setRestaurantTables] = useState([]);
    const [restaurantTablesFilter, setRestaurantTablesFilter] = useState([]);
    const [newElement, setNewElement] = useState([]);
    const [orderDetails, setOrderDetails] = useState({});
    const [orderTotal, setOrderTotal] = useState(0);
    const [actionType, setActionType] = useState(props.actionType);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [activeRestaurant, setActiveRestaurant] = useRecoilState(activeRestaurantState);

    const isSelfService = activeRestaurant.sysRestaurantType === 'SelfService';

    const INITIAL_STATE_ELEMENT = {
        tableIndex: null,
        uniqueIdentificationToken: null,
        restaurantToken: props.restaurantId,
    };

    useEffect(() => {
        // Get Restaurant by Id
        ApiHelpers.getRestaurantTables(props.restaurantId).then(
            (response) => {
                setRestaurantTables(response.data.filter((table) => table.occupied === true));
                setRestaurantTablesFilter(response.data.filter((table) => table.occupied === true));
                setIsLoading(false);
            },
            (error) => {
                console.log(error);
            }
        );
    }, []);

    const onClose = () => {
        setDrawerVisible(false);
        onReset();
    };

    const onReset = () => {
        setNewElement([]);
    };

    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        if (value) {
            setRestaurantTables(
                restaurantTablesFilter.filter((item) => {
                    if (item.uniqueIdentificationToken.toLowerCase().indexOf(value) > -1) return true;
                })
            );
        } else setRestaurantTables(restaurantTablesFilter);
    };

    const onTableDetails = (record) => {
        setDrawerVisible(true);
        // Update state with the element being edited
        setNewElement({ ...record });
        ApiHelpers.getOrder(record.tableSessionToken, props.restaurantId).then(
            (response) => {
                setOrderDetails(response.data);
                setOrderTotal(response.data.items.length > 0 ? response.data.items.reduce((acc, item) => acc + item.price * item.quantity, 0) : 0);
            },
            (error) => {
                console.log(error);
            }
        );
    };

    return isLoading ? (
        <Skeleton active></Skeleton>
    ) : (
        <>
            <Search placeholder="Cauta" onChange={handleSearch} className="tableSearchField" />
            <Table
                dataSource={restaurantTables}
                pagination={false}
                rowKey={(record, index) => record.uniqueIdentificationToken + '-' + index}
                scroll={{ x: 240 }}>
                <Column title="Index" width="100px" dataIndex="tableIndex" />
                <Column
                    title="Actiuni"
                    key="actions"
                    render={(text, record) => (
                        <Button type="default" className="tableAction" icon={<UnorderedListOutlined />} onClick={() => onTableDetails(record)}>
                            Vezi detalii
                        </Button>
                    )}
                />
                <Column
                    title="Status"
                    dataIndex="occupied"
                    render={(text, record) =>
                        record.occupied === false ? (
                            <div>
                                <span className="tableIcon iconFree"></span>Liber
                            </div>
                        ) : (
                            <div>
                                <span className="tableIcon iconBusy"></span>Ocupat
                            </div>
                        )
                    }
                />
            </Table>
            <Drawer
                title={!isSelfService ? 'Masa ' : 'Comanda ' + newElement.tableIndex}
                width={window.innerWidth > 1000 ? 1000 : window.innerWidth}
                onClose={onClose}
                visible={drawerVisible}
                bodyStyle={{ paddingBottom: 80 }}
                footer={
                    <div className="modalFooter">
                        <div
                            style={{
                                textAlign: 'left',
                            }}></div>
                        <div
                            style={{
                                textAlign: 'right',
                            }}>
                            <Button size="large" onClick={onClose} style={{ marginRight: 8 }}>
                                Inchide
                            </Button>
                        </div>
                    </div>
                }>
                {orderDetails.sessionInfo ? (
                    <Collapse className="formCollapse" bordered={false} defaultActiveKey={['info', 'orderItems']}>
                        <Panel header="Informatii" key="info">
                            <p>Deschisa la: {moment(orderDetails.sessionInfo.sessionStartMoment).format('HH:mm DD.MM.YYYY')}</p>
                            <p>Ospatar: {orderDetails.sessionInfo.tableWaiterName}</p>
                        </Panel>
                        <Panel header="Comanda" key="orderItems">
                            {orderDetails.items && orderDetails.items.length > 0 ? (
                                <div>
                                    <Table dataSource={orderDetails.items} rowKey="uniqueIdentificationToken" pagination={false}>
                                        <Column title="Cantitate" dataIndex="quantity" key="quantity" width={100} />
                                        <Column
                                            title="Preparat"
                                            dataIndex="menuItemName"
                                            key="menuItemName"
                                            render={(text, record, index) => (
                                                <>
                                                    <strong>{record.menuItemName}</strong>

                                                    {record.auxiliaryItems.length > 0 ? (
                                                        <ul>
                                                            {record.auxiliaryItems.map((d) => {
                                                                return <li key={d.uniqueIdentificationToken}>{d.menuItemName}</li>;
                                                            })}
                                                        </ul>
                                                    ) : null}
                                                </>
                                            )}
                                        />
                                        <Column title="Pret" dataIndex="price" key="price" />
                                        <Column
                                            title="Status"
                                            dataIndex="sysOrderItemStateName"
                                            key="sysOrderItemStateName"
                                            render={(text, record) =>
                                                record.sysOrderItemStateName === 'Pending' ? <HourglassOutlined /> : <CheckOutlined />
                                            }
                                        />
                                        <Column
                                            title="Timp asteptare"
                                            dataIndex="requestDate"
                                            key="requestDate"
                                            render={(text, record) =>
                                                record.sysOrderItemStateName === 'Pending' ? moment(record.requestDate).fromNow(true) : null
                                            }
                                        />
                                    </Table>
                                    <p className="spacingTop">Total: {orderTotal.toFixed(2)} lei</p>
                                </div>
                            ) : null}
                        </Panel>
                    </Collapse>
                ) : null}
            </Drawer>
        </>
    );
};

export default RestaurantTablesOccupied;
