import React from 'react';
import Icon, {
    HomeOutlined
} from '@ant-design/icons';

const TableSvg = () => (
    <div className="iconBg">
        <svg width="30" height="30" fill="currentColor" viewBox="50 -100 380 500">
            <path d="m480 55.847656c-.023438-.925781-.207031-1.839844-.542969-2.703125-.097656-.222656-.203125-.445312-.320312-.65625-.132813-.316406-.28125-.628906-.449219-.929687l-32-48c-1.488281-2.234375-4-3.5703128-6.6875-3.558594h-400c-2.675781 0-5.171875 1.335938-6.65625 3.558594l-32 48c-.167969.300781-.316406.613281-.449219.929687-.117187.210938-.222656.433594-.320312.65625-.347657.859375-.542969 1.773438-.574219 2.703125v.152344 48c0 4.417969 3.582031 8 8 8h8v216c0 4.417969 3.582031 8 8 8h48c4.417969 0 8-3.582031 8-8v-184h320v184c0 4.417969 3.582031 8 8 8h48c4.417969 0 8-3.582031 8-8v-216h8c4.417969 0 8-3.582031 8-8v-48c0-.054688 0-.097656 0-.152344zm-435.71875-39.847656h391.4375l21.335938 32h-434.109376zm403.71875 304h-32v-184c0-4.417969-3.582031-8-8-8h-336c-4.417969 0-8 3.582031-8 8v184h-32v-208h416zm16-224h-448v-32h448zm0 0" />
        </svg>
    </div>
);

const TableIcon = props => <Icon component={TableSvg} {...props} />;


const OrderSvg = () => (
    <div className="iconBg">
        <svg width="30" height="30" fill="currentColor" viewBox="20 -80 380 500">
            <g>
                <g>
                    <path d="M364.845,11.045L364.845,11.045c-7.148-3.292-15.569-2.012-21.416,3.254l-27.754,24.575l-37.76-33.777
			c-7.586-6.796-19.071-6.796-26.657,0l-37.729,33.745L175.791,5.097c-7.588-6.796-19.074-6.796-26.662,0l-37.77,33.777L83.579,14.3
			c-8.197-7.286-20.749-6.547-28.035,1.65c-3.262,3.67-5.049,8.419-5.015,13.329v368.5c-0.065,10.983,8.786,19.939,19.769,20.004
			c4.911,0.029,9.66-1.761,13.331-5.024l27.754-24.575l37.76,33.777c7.586,6.796,19.071,6.796,26.657,0l37.729-33.749l37.735,33.745
			c7.588,6.795,19.074,6.795,26.662,0l37.77-33.776l27.78,24.574c8.196,7.288,20.748,6.552,28.035-1.644
			c3.265-3.671,5.052-8.423,5.018-13.336V29.279C376.628,21.412,372.033,14.242,364.845,11.045z M328.905,373.201
			c-7.587-6.721-19.007-6.691-26.558,0.071l-37.759,33.776l-37.73-33.745c-7.587-6.796-19.073-6.796-26.66,0l-37.734,33.745
			l-37.775-33.776c-7.522-6.761-18.922-6.792-26.481-0.072l-27.679,24.568v-53.305l-0.1-315.179l27.724,24.569
			c7.587,6.721,19.007,6.691,26.558-0.071l37.759-33.776l37.73,33.745c7.587,6.796,19.073,6.796,26.66,0l37.734-33.745
			l37.775,33.776c7.522,6.761,18.922,6.792,26.481,0.072l27.679-24.575v253.312l0.1,115.179L328.905,373.201z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M308.322,203.527H118.736c-5.523,0-10,4.477-10,10s4.477,10,10,10h189.586c5.523,0,10-4.477,10-10
			S313.844,203.527,308.322,203.527z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M218.322,143.527h-99.586c-5.523,0-10,4.477-10,10s4.477,10,10,10h99.586c5.523,0,10-4.477,10-10
			S223.844,143.527,218.322,143.527z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M308.322,263.527H118.736c-5.523,0-10,4.477-10,10c0,5.523,4.477,10,10,10h189.586c5.523,0,10-4.477,10-10
			C318.322,268.004,313.844,263.527,308.322,263.527z"/>
                </g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </svg>
    </div>
)

const OrderIcon = props => <Icon component={OrderSvg} {...props} />;


const FeedbackSvg = () => (
    <div className="iconBg">
        <svg width="30" height="30" fill="currentColor" viewBox="0 -80 450 600">
            <g>
                <g>
                    <g>
                        <path d="M117.333,149.333H352c5.896,0,10.667-4.771,10.667-10.667c0-5.896-4.771-10.667-10.667-10.667H117.333
				c-5.896,0-10.667,4.771-10.667,10.667C106.667,144.563,111.438,149.333,117.333,149.333z"/>
                        <path d="M245.333,256h-128c-5.896,0-10.667,4.771-10.667,10.667c0,5.896,4.771,10.667,10.667,10.667h128
				c5.896,0,10.667-4.771,10.667-10.667C256,260.771,251.229,256,245.333,256z"/>
                        <path d="M471.167,64c-0.618,0-1.217,0.155-1.833,0.184V64c0-23.531-19.146-42.667-42.667-42.667h-384
				C19.146,21.333,0,40.469,0,64v416c0,4.313,2.604,8.208,6.583,9.854c1.313,0.552,2.708,0.813,4.083,0.813
				c2.771,0,5.5-1.083,7.542-3.125L121.75,384h304.917c23.521,0,42.667-19.135,42.667-42.667V164.411l30.708-30.703c0,0,0,0,0-0.01
				c7.604-7.604,11.958-18.125,11.958-28.865C512,82.313,493.688,64,471.167,64z M448,341.333c0,11.76-9.563,21.333-21.333,21.333
				H117.333c-2.833,0-5.542,1.125-7.542,3.125L21.333,454.25V64c0-11.76,9.563-21.333,21.333-21.333h384
				C438.438,42.667,448,52.24,448,64v7.286c-2.025,1.392-3.962,2.923-5.708,4.672L326.232,192H117.333
				c-5.896,0-10.667,4.771-10.667,10.667c0,5.896,4.771,10.667,10.667,10.667h191.785l-10.243,51.24
				c-0.708,3.5,0.396,7.115,2.917,9.635c2.021,2.021,4.75,3.125,7.542,3.125c0.688,0,1.396-0.073,2.083-0.208l53.313-10.667
				c2.083-0.417,3.979-1.427,5.458-2.917L448,185.742V341.333z M357.396,246.177l-34.458,6.896l6.896-34.5l96.828-96.828
				l27.587,27.587L357.396,246.177z M484.958,118.625l-15.625,15.625l-27.589-27.589l15.63-15.63
				c3.625-3.615,8.646-5.698,13.792-5.698c10.75,0,19.5,8.75,19.5,19.5C490.667,109.958,488.583,114.99,484.958,118.625z"/>
                    </g>
                </g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </svg>

    </div>
);

const FeedbackIcon = props => <Icon component={FeedbackSvg} {...props} />;

const WaiterSvg = (props) => (
    <div className={ props.background === false ? null : "iconBg"}>
        <svg width="30" height="30" fill="currentColor" viewBox="160 -40 190 550">
            <g>
                <g>
                    <g>
                        <path d="M501.333,85.333H330.667h-85.333c-10.267,0-19.319,4.945-25.178,12.486C198.521,76.229,169.766,64,138.667,64
				c-18.156,0-36.219,7.438-48.302,19.906l-22.095,22.76H45.177c-17.302,0-31.375,14.354-31.375,32c0,5.094,1.177,9.917,3.26,14.198
				C6.938,158.177,0,168.948,0,181.333c0,12.385,6.938,23.156,17.063,28.469c-2.083,4.281-3.26,9.104-3.26,14.198
				c0,14.667,9.719,27.052,22.938,30.823c-1.438,3.667-2.229,7.667-2.229,11.844c0,17.646,14.073,32,31.375,32H160
				c20.319,0,40.034-6.625,56.405-18.539c5.103,10.913,16.107,18.539,28.928,18.539H320v138.667c0,5.896,4.771,10.667,10.667,10.667
				c23.854,0,36.385-6.26,47.438-11.792c10.24-5.125,19.083-9.542,37.896-9.542s27.656,4.417,37.896,9.542
				C464.948,441.74,477.479,448,501.333,448c5.896,0,10.667-4.771,10.667-10.667V96C512,90.104,507.229,85.333,501.333,85.333z
				 M213.333,254.37c-14.225,14.486-33.346,22.964-53.333,22.964H65.885c-5.542,0-10.042-4.781-10.042-10.667
				c0-5.885,4.5-10.667,10.042-10.667h8.781c5.896,0,10.667-4.771,10.667-10.667c0-5.896-4.771-10.667-10.667-10.667h-29.49
				c-5.542,0-10.042-4.781-10.042-10.667s4.5-10.667,10.042-10.667h8.156c5.896,0,10.667-4.771,10.667-10.667
				C64,196.771,59.229,192,53.333,192H31.375c-5.542,0-10.042-4.781-10.042-10.667c0-5.885,4.5-10.667,10.042-10.667h21.958
				C59.229,170.667,64,165.896,64,160s-4.771-10.667-10.667-10.667h-8.156c-5.542,0-10.042-4.781-10.042-10.667
				c0-5.885,4.5-10.667,10.042-10.667h69.021c5.896,0,10.667-4.771,10.667-10.667c0-5.896-4.771-10.667-10.667-10.667H98.003
				l7.674-7.906c8.031-8.281,20.677-13.427,32.99-13.427c29.499,0,56.474,13.457,74.667,36.85V254.37z M320,277.333h-74.667
				c-5.885,0-10.667-4.781-10.667-10.667V117.333c0-5.885,4.781-10.667,10.667-10.667H320V277.333z M490.667,426.115
				c-11.938-1.344-19.146-4.948-27.229-8.99c-11.052-5.531-23.583-11.792-47.438-11.792c-23.854,0-36.385,6.26-47.438,11.792
				c-8.083,4.042-15.292,7.646-27.229,8.99V106.667h149.333V426.115z"/>
                        <circle cx="277.333" cy="234.667" r="21.333" />
                    </g>
                </g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </svg>
    </div>
);

const WaiterIcon = props => <Icon component={WaiterSvg} {...props} />;


export { TableIcon, OrderIcon, FeedbackIcon, WaiterIcon, WaiterSvg };