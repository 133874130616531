import React, { useState, useEffect, useContext } from 'react';
import { withAuthorization, AuthUserContext } from '../Session';
import { Input, Form, Button, message, Checkbox } from 'antd';
import AdminLayout from '../Admin/AdminLayout';
import { ApiHelpers } from '../../helpers';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from '../../const/routes';

function Company() {
    // Get AuthUser ID from Firebase
    const firebaseAuthUser = useContext(AuthUserContext);
    const navigate = useNavigate();

    const INITIAL_STATE_COMPANY = {
        title: '',
        companyUniqueIdentifier: '',
        registrationNumber: '',
        registrationAddress: '',
        contactPhone: '',
        emailAddress: '',
        registrationCityId: 1,
        vatPayer: false,
        active: true,
        uuid: firebaseAuthUser.uid,
        preferences: {
            uuid: firebaseAuthUser.uid,
            analyticsSubscriber: false,
        },
    };

    const [formCompany] = Form.useForm();
    const [companyDetails, setCompanyDetails] = useState({ ...INITIAL_STATE_COMPANY });
    const [actionType, setActionType] = useState('new');

    const tailLayout = {
        layout: 'horizontal',
        labelCol: { span: 3 },
        wrapperCol: { span: 9 },
        size: 'large',
        labelAlign: 'left',
    };

    /* Company */
    const onSaveCompany = () => {
        // Validate form
        formCompany
            .validateFields()
            .then(() => {
                if (actionType === 'new') {
                    // Insert Company details
                    ApiHelpers.postCompanyDetails(companyDetails).then(
                        (response) => {
                            message.success('Date salvate!');
                            navigate(ROUTES.ADMIN);
                        },
                        (error) => {
                            console.log(error);
                            message.error('Eroare!');
                        }
                    );
                } else if (actionType === 'edit') {
                    // Update Company details
                    ApiHelpers.updateCompanyDetails(companyDetails, firebaseAuthUser.uid).then(
                        (response) => {
                            message.success('Date actualizate!');
                        },
                        (error) => {
                            console.log(error);
                            message.error('Eroare!');
                        }
                    );
                }
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    };
    const onChangeCompany = (event) => {
        setCompanyDetails({
            ...companyDetails,
            [event.target.name]: event.target.value,
        });
    };

    const onChangeCheckboxAnalytics = (event) => {
        setCompanyDetails({
            ...companyDetails,
            preferences: { ...companyDetails.preferences, analyticsSubscriber: event.target.checked },
        });
    };

    useEffect(() => {
        // Get Company details
        ApiHelpers.getCompanyDetails(firebaseAuthUser.uid).then(
            (response) => {
                if (!response.data.message) {
                    setActionType('edit');
                    setCompanyDetails({ ...response.data });
                    // Set form fields values
                    formCompany.setFieldsValue({
                        ...response.data,
                    });
                } else {
                }
            },
            (error) => {
                console.log(error);
                formCompany.setFieldsValue({
                    emailAddress: firebaseAuthUser.email,
                });
            }
        );
    }, []);

    return (
        <AdminLayout authUser={firebaseAuthUser} pageTitle="Setari">
            <h2>Date companie</h2>
            <Form layout="horizontal" form={formCompany} {...tailLayout} size="large">
                <Form.Item name="title" label="Denumire" rules={[{ required: true, message: 'Câmp obligatoriu' }]}>
                    <Input placeholder="" onBlur={onChangeCompany} name="title" value={companyDetails.title} />
                </Form.Item>
                <Form.Item name="companyUniqueIdentifier" label="CUI" rules={[{ required: true, message: 'Câmp obligatoriu' }]}>
                    <Input placeholder="" onBlur={onChangeCompany} name="companyUniqueIdentifier" value={companyDetails.companyUniqueIdentifier} />
                </Form.Item>
                <Form.Item name="registrationNumber" label="Reg Com" rules={[{ required: true, message: 'Câmp obligatoriu' }]}>
                    <Input placeholder="" onBlur={onChangeCompany} name="registrationNumber" value={companyDetails.registrationNumber} />
                </Form.Item>
                <Form.Item name="registrationAddress" label="Adresa" rules={[{ required: true, message: 'Câmp obligatoriu' }]}>
                    <Input placeholder="" onBlur={onChangeCompany} name="registrationAddress" value={companyDetails.registrationAddress} />
                </Form.Item>
                <Form.Item
                    name="emailAddress"
                    label="Contact email"
                    rules={[{ required: true, type: 'email', message: 'Câmp obligatoriu, tip email' }]}>
                    <Input placeholder="" onBlur={onChangeCompany} name="emailAddress" value={companyDetails.emailAddress} />
                </Form.Item>
                <Form.Item name="contactPhone" label="Contact telefon" rules={[{ required: true, message: 'Câmp obligatoriu' }]}>
                    <Input placeholder="" onBlur={onChangeCompany} name="contactPhone" value={companyDetails.contactPhone} />
                </Form.Item>
                <Form.Item name="analyticsSubscriber">
                    <Checkbox checked={companyDetails.preferences.analyticsSubscriber} onChange={(event) => onChangeCheckboxAnalytics(event)}>
                        Abonare notificări - trimitem pe email statisticile restaurantului și alte noutăți
                    </Checkbox>
                </Form.Item>
                <Form.Item {...tailLayout}>
                    <Button type="primary" className="btnAdd" onClick={onSaveCompany}>
                        Salvează
                    </Button>
                </Form.Item>
            </Form>
        </AdminLayout>
    );
}

const condition = (authUser) => !!authUser;
export default withAuthorization(condition)(Company);
