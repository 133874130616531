import React from 'react';
import Header from './Header';
import { Layout } from 'antd';
import Footer from './Footer';

export default function MainLayout(props) {
    return (
        <Layout className="layout">
            <Header></Header>
            {props.children}
            <Footer />
        </Layout>
    );
}