import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Button, Row, Col, Popover, Tooltip } from 'antd';
import { DownloadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useRecoilState } from 'recoil';
import { activeRestaurantState } from '../../../../recoil';
import QRCode from 'qrcode.react';
import { appIcon } from '../../../../const';
import * as htmlToImage from 'html-to-image';
import downloadThumb from '../../../../assets/img/qrcode-tableIndex-thumbnail.png';
import { ApiHelpers } from '../../../../helpers';

function debugBase64(base64URL) {
    var win = window.open();
    win.document.write(
        '<iframe src="' +
            base64URL +
            '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
    );
}

const QRCodeTableIndex = (props) => {
    const { value, tableIndex } = props;
    const [generatedQR, setGeneratedQR] = useState('');
    const [isLoading, setIsloading] = useState(false);
    const [activeRestaurant, setActiveRestaurant] = useRecoilState(activeRestaurantState);
    const isSelfService = activeRestaurant.sysRestaurantType === 'SelfService';

    const ref = useRef(null);

    const QRCodeMarkup = (
        <QRCode
            value={value}
            size={500}
            level="Q"
            fgColor="#292333"
            renderAs="svg"
            imageSettings={{
                //src: activeRestaurant.logoPath ? ApiHelpers.getImageLink(activeRestaurant.logoPath) : appIcon,
                src: appIcon,
                width: 160,
                height: 160,
                excavate: true,
            }}
        />
    );

    const handleDownload = useCallback(
        (ref) => {
            if (ref.current === null) {
                return;
            }
            setIsloading(true);
            htmlToImage
                .toPng(ref.current, { cacheBust: true, height: 709, width: 500 })
                .then(function (dataUrl) {
                    var img = new Image();
                    img.src = dataUrl;
                    //document.body.appendChild(img);
                    setGeneratedQR(dataUrl);
                    const link = document.createElement('a');
                    link.download = 'qrcode-masa-' + tableIndex;
                    link.href = dataUrl;
                    link.click();
                    setIsloading(false);
                })
                .catch(function (error) {
                    console.error('oops, something went wrong!', error);
                });
        },
        [ref]
    );
    useEffect(() => {
        if (props.autoDownload) {
            setTimeout(() => {
                if (ref) {
                    console.log('sasa');
                    handleDownload(ref);
                }
            }, 2000);
        }
    }, [ref]);

    return (
        <div className="qrCodeTemplateContainer">
            <Row>
                <Col span={24}>
                    <h3>Cod QR cu index masa</h3>
                    <div className="qrCodeTableIndex" ref={ref}>
                        <div className="tableIndexContainer">{tableIndex}</div>
                        <div className="qrCodeContainer">{QRCodeMarkup}</div>
                    </div>
                    <p>
                        <a href="#" onClick={() => handleDownload(ref)}>
                            <img src={downloadThumb} className="placecardThumbnail" title="Placecard Cod QR cu index masa" />
                        </a>
                    </p>
                    <Button onClick={() => handleDownload(ref)} icon={<DownloadOutlined />}>
                        Descarca
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default QRCodeTableIndex;
