import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Card, Skeleton, Spin, Button, Tag, Timeline } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import * as ROUTES from '../../const/routes';

const Onboarding = (props) => {
    const { step } = props;

    return (
        <div className="onBoardingContainer">
            <Row>
                <Col
                    xs={{ span: 20, offset: 2 }}
                    md={{ span: 18, offset: 3 }}
                    lg={{ span: 18, offset: 3 }}
                    xl={{ span: 12, offset: 6 }}
                    xxl={{ span: 12, offset: 6 }}>
                    <div className="onBoardingTitle">
                        <div>Bun venit!</div>
                        <h2>Îți recomandăm următorii pași</h2>
                    </div>
                    <Timeline className="onBoardingTimeline">
                        <Timeline.Item color={step === 2 ? 'green' : 'blue'}>
                            <div className={'onBoardingItem ' + (step === 2 ? '' : 'active')}>
                                <span>Completează datele companiei</span>
                                {step === 2 ? null : (
                                    <Button href={ROUTES.ACCOUNT_COMPANY} type="primary" size="large">
                                        <EditOutlined /> Date companie
                                    </Button>
                                )}
                            </div>
                        </Timeline.Item>
                        <Timeline.Item color={step === 2 ? 'blue' : 'gray'}>
                            <div className={'onBoardingItem ' + (step === 2 ? 'active' : '')}>
                                <span>Adaugă restaurantul</span>
                                {step === 2 ? (
                                    <Button href={ROUTES.ADMIN_LOCATIONS_NEW} type="primary" size="large">
                                        <PlusOutlined />
                                        Adaugă
                                    </Button>
                                ) : null}
                            </div>
                        </Timeline.Item>
                        <Timeline.Item color="gray">Creează meniul, categoriile și preparatele</Timeline.Item>
                        <Timeline.Item color="gray">Adaugă mesele</Timeline.Item>
                        <Timeline.Item color="gray">Înrolează ospătarii</Timeline.Item>
                        <Timeline.Item color="gray">Adaugă evenimente</Timeline.Item>
                        <Timeline.Item color="gray">Adaugă oferte</Timeline.Item>
                    </Timeline>
                </Col>
            </Row>
        </div>
    );
};

export default Onboarding;
