const mockMenu = [
    {
        id: 1,
        category: "Supe",
        name: "Ciorba de fasole",
        description: "Fasole pura",
        size: "100g",
        show: true,
        price: 10
    },
    {
        id: 2,
        category: "Supe",
        name: "Supa de rosii",
        description: "Hamburger ribeye doner kielbasa picanha frankfurter andouille venison pork chop meatloaf ground round flank shoulder fatback porchetta.",
        size: "100g",
        show: true,
        price: 10
    },
    {
        id: 3,
        category: "Supe",
        name: "Ciorba de perisoare",
        description: "Hamburger ribeye doner kielbasa picanha frankfurter andouille venison pork chop meatloaf ground round flank shoulder fatback porchetta.",
        size: "100g",
        show: true,
        price: 10
    },
    {
        id: 4,
        category: "Supe",
        name: "Supa crema de legume",
        description: "Hamburger ribeye doner kielbasa picanha frankfurter andouille venison pork chop meatloaf ground round flank shoulder fatback porchetta.",
        size: "100g",
        chefSelection: true,
        show: true,
        price: 10
    },
    {
        id: 5,
        category: "Garnituri",
        name: "Legume la gratar",
        description: "Bune",
        show: true,
        price: 12
    },
    {
        id: 6,
        category: "Fel principal",
        name: "Carnati",
        description: "Hamburger ribeye doner kielbasa picanha frankfurter andouille venison pork chop meatloaf ground round flank shoulder fatback porchetta.",
        size: "100g",
        show: true,
        price: 10
    },
    {
        id: 7,
        category: "Fel principal",
        name: "Somon la gratar",
        description: "Hamburger ribeye doner kielbasa picanha frankfurter andouille venison pork chop meatloaf ground round flank shoulder fatback porchetta.",
        size: "100g",
        show: true,
        price: 10
    },
    {
        id: 8,
        category: "Fel principal",
        name: "Snitzel",
        description: "Hamburger ribeye doner kielbasa picanha frankfurter andouille venison pork chop meatloaf ground round flank shoulder fatback porchetta.",
        size: "100g",
        chefSelection: true,
        show: true,
        price: 10
    },
    {
        id: 9,
        category: "Pizza",
        name: "Diavola",
        description: "Hamburger ribeye doner kielbasa picanha frankfurter andouille venison pork chop meatloaf ground round flank shoulder fatback porchetta.",
        size: "100g",
        show: false,
        price: 10
    },
    {
        id: 10,
        category: "Pizza",
        name: "Tonno",
        description: "Hamburger ribeye doner kielbasa picanha frankfurter andouille venison pork chop meatloaf ground round flank shoulder fatback porchetta.",
        size: "100g",
        show: true,
        price: 10
    },
    {
        id: 11,
        category: "Pizza",
        name: "Bistro",
        description: "Hamburger ribeye doner kielbasa picanha frankfurter andouille venison pork chop meatloaf ground round flank shoulder fatback porchetta.",
        size: "100g",
        chefSelection: true,
        show: true,
        price: 10
    },
    {
        id: 12,
        category: "Desert",
        name: "Tiramisu",
        description: "Hamburger ribeye doner kielbasa picanha frankfurter andouille venison pork chop meatloaf ground round flank shoulder fatback porchetta.",
        size: "100g",
        show: true,
        price: 10
    },
    {
        id: 13,
        category: "Bauturi",
        name: "Whisky",
        description: "Single Malt",
        show: true,
        price: 25
    }
];

const mockMenuDay = [
    {
        id: 1,
        category: "Supe",
        name: "Supa crema de mazare",
        description: "Mazare si mazare",
        size: "100g",
        price: 10
    },
    {
        id: 3,
        category: "Pizza",
        name: "Tonno",
        description: "Hamburger ribeye doner kielbasa picanha frankfurter andouille venison pork chop meatloaf ground round flank shoulder fatback porchetta.",
        size: "100g",
        price: 10
    }
];

export {mockMenu, mockMenuDay};