import React, { useState, useContext } from 'react';
import { AuthUserContext, withAuthorization } from '../Session';
import AdminLayout from './AdminLayout';
import { Skeleton } from 'antd';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import MenuItemGroupForm from './Menu/MenuItemGroupForm';
import * as ROUTES from '../../const/routes';
import { useRestaurantById, useCompany } from '../../swr';

function MenuItemGroupHome(props) {
    // Get AuthUser ID from Firebase
    const firebaseAuthUser = useContext(AuthUserContext);
    let { menuId, restaurantId } = useParams();

    const [menuData, setMenuData] = useState([]);
    const [activeTabKey, setActiveTabKey] = useState();
    const [entryFound, setEntryFound] = useState(false);
    const [menusLoaded, setMenusLoaded] = useState(false);

    const { data: restaurantDetailsSWR } = useRestaurantById(restaurantId);
    let restaurantTitle;

    if (restaurantDetailsSWR) {
        restaurantTitle = restaurantDetailsSWR.title;
    }
    const { data: companyDetails } = useCompany('getDetails', firebaseAuthUser.uid);
    let companyIdentificationToken = null;

    if (companyDetails) {
        companyIdentificationToken = companyDetails.uniqueIdentificationToken;
    }

    const navigate = useNavigate();
    const routeLocation = useLocation();

    const breadcrumb = [{ label: restaurantTitle, link: ROUTES.ADMIN_LOCATIONS + restaurantId }];

    return (
        <AuthUserContext.Consumer>
            {(authUser) => (
                <AdminLayout breadcrumb={breadcrumb} authUser={authUser} pageTitle={menuData.menuName}>
                    {entryFound === true && menusLoaded === true ? (
                        <div>
                            <MenuItemGroupForm restaurantId={restaurantId} />
                        </div>
                    ) : entryFound === false ? (
                        'De negasit'
                    ) : (
                        <Skeleton active />
                    )}
                </AdminLayout>
            )}
        </AuthUserContext.Consumer>
    );
}

const condition = (authUser) => !!authUser;
export default withAuthorization(condition)(MenuItemGroupHome);
