import React, { useEffect, useState } from 'react';
import { Badge, Layout, Menu, Spin, Tag } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { withAuthorization } from '../Session';
import logo from '../../assets/img/poftigo-admin-logo-white-tr.png';
import {
    HomeOutlined,
    ShopOutlined,
    UnorderedListOutlined,
    BorderOutlined,
    CommentOutlined,
    SolutionOutlined,
    ContainerOutlined,
    CalendarOutlined,
    TagsOutlined,
    FundOutlined 
} from '@ant-design/icons';

import * as ROUTES from '../../const/routes';
import { useMenusByRestaurant } from '../../swr';
import { activeEnvironment } from '../../config';
import { getSelectedMenuKey } from '../../helpers';
import { useRecoilState } from 'recoil';
import { activeRestaurantState, companyDetailsState } from '../../recoil';

const { Sider } = Layout;
const { SubMenu } = Menu;

const AdminLayout = (props) => {
    const [selectedMenuKey, setSelectedMenuKey] = useState({});
    const [activeRestaurant, setActiveRestaurant] = useRecoilState(activeRestaurantState);
    const [companyDetails, setCompanyDetails] = useRecoilState(companyDetailsState);
    const isSelfService = activeRestaurant.sysRestaurantType === 'SelfService';

    const { data: menusData, isLoading: isLoadingMenusData } = useMenusByRestaurant(activeRestaurant.uniqueIdentificationToken);
    let location = useLocation();
    const onCollapse = (collapsed) => {
        console.log(collapsed);
        this.setState({ collapsed });
    };

    const handleSignOut = () => {
        localStorage.clear();
        props.firebase.doSignOut();
    };

    useEffect(() => {
        let mounted = true;
        if (mounted) setSelectedMenuKey({ ...getSelectedMenuKey(location) });
        return () => {
            mounted = false;
        };
    }, [location]);

    const onOpenChange = (keys) => {
        if (selectedMenuKey.subMenuKey) setSelectedMenuKey({ ...getSelectedMenuKey(location) });
        else setSelectedMenuKey({ ...getSelectedMenuKey(location), subMenuKey: 'menus' });
    };

    return activeRestaurant !== null ? (
        <Sider
            breakpoint="lg"
            collapsedWidth="0"
            className="leftSiderContainer"
            onBreakpoint={(broken) => {
                // console.log(broken);
            }}
            onCollapse={(collapsed, type) => {
                props.setSiderCollapsed(collapsed);
            }}
            style={{
                height: '100vh',
                position: 'fixed',
                left: 0,
            }}>
            <div className="logo">
                <Link to={ROUTES.ADMIN}>
                    <img src={logo} title="Poftigo Admin" alt="Poftigo Admin" />
                </Link>
            </div>
            <div className="leftSider">
                <Menu
                    theme="dark"
                    mode="inline"
                    selectedKeys={selectedMenuKey.key}
                    openKeys={!props.siderCollapsed ? [selectedMenuKey.subMenuKey] : ''}
                    onOpenChange={onOpenChange}>
                    <Menu.Item key="dashboard" icon={<HomeOutlined />}>
                        <Link to={ROUTES.ADMIN}>Dashboard</Link>
                    </Menu.Item>
                    <Menu.ItemGroup className="menu-group-label">Configurari</Menu.ItemGroup>
                    <Menu.Item key="restaurant" icon={<ShopOutlined />} disabled={!companyDetails.uniqueIdentificationToken ? true : false}>
                        <Link to={ROUTES.ADMIN_LOCATIONS + activeRestaurant.uniqueIdentificationToken}>Detalii restaurant</Link>
                    </Menu.Item>
                    <SubMenu key="menus" icon={<UnorderedListOutlined />} title="Meniuri" disabled={!activeRestaurant.uniqueIdentificationToken ? true : false}>
                        <Menu.Item key="menus#list">
                            <Link to={ROUTES.ADMIN_LOCATIONS + activeRestaurant.uniqueIdentificationToken + '/menus#list'}>Lista meniuri</Link>
                        </Menu.Item>
                        {menusData &&
                            menusData.map((d) => {
                                return (
                                    <Menu.Item key={'menus/' + d.uniqueIdentificationToken} className="menuItemIndent">
                                        <Link
                                            to={
                                                ROUTES.ADMIN_LOCATIONS +
                                                activeRestaurant.uniqueIdentificationToken +
                                                '/menus/' +
                                                d.uniqueIdentificationToken
                                            }>
                                            {d.menuName}
                                        </Link>
                                    </Menu.Item>
                                );
                            })}
                        <Menu.Item key="menuItemGroups">
                            <Link to={ROUTES.ADMIN_LOCATIONS + activeRestaurant.uniqueIdentificationToken + '/menus#menuItemGroups'}>
                                Grupuri auxiliare
                            </Link>
                        </Menu.Item>
                    </SubMenu>
                    <Menu.Item key="events" icon={<CalendarOutlined />} disabled={!activeRestaurant.uniqueIdentificationToken ? true : false}>
                        <Link to={ROUTES.ADMIN_LOCATIONS + activeRestaurant.uniqueIdentificationToken + ROUTES.ADMIN_RESTAURANT_EVENTS}>
                            Evenimente
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="offers" icon={<TagsOutlined />} disabled={!activeRestaurant.uniqueIdentificationToken ? true : false}>
                        <Link to={ROUTES.ADMIN_LOCATIONS + activeRestaurant.uniqueIdentificationToken + ROUTES.ADMIN_RESTAURANT_OFFERS}>Oferte</Link>
                    </Menu.Item>
                    <Menu.Item key="tables" icon={<BorderOutlined />} disabled={!activeRestaurant.uniqueIdentificationToken ? true : false}>
                        <Link to={ROUTES.ADMIN_LOCATIONS + activeRestaurant.uniqueIdentificationToken + ROUTES.ADMIN_RESTAURANT_TABLES}>
                            {!isSelfService ? 'Mese / Cod QR' : 'Cod QR'}
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="waiters" icon={<SolutionOutlined />} disabled={!activeRestaurant.uniqueIdentificationToken ? true : false}>
                        <Link to={ROUTES.ADMIN_LOCATIONS + activeRestaurant.uniqueIdentificationToken + ROUTES.ADMIN_RESTAURANT_WAITERS}>Staff</Link>
                    </Menu.Item>
                    <Menu.ItemGroup className="menu-group-label">Rapoarte</Menu.ItemGroup>        
                    <Menu.Item key="orders" icon={<ContainerOutlined />} disabled={!activeRestaurant.uniqueIdentificationToken ? true : false}>
                        <Link to={ROUTES.ADMIN_LOCATIONS + activeRestaurant.uniqueIdentificationToken + ROUTES.ADMIN_RESTAURANT_ORDERS}>
                            Note de plata
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="feedback" icon={<CommentOutlined />} disabled={!activeRestaurant.uniqueIdentificationToken ? true : false}>
                        <Link to={ROUTES.ADMIN_LOCATIONS + activeRestaurant.uniqueIdentificationToken + ROUTES.ADMIN_RESTAURANT_FEEDBACK}>
                            Feedback
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="stats" icon={<FundOutlined />} disabled={!activeRestaurant.uniqueIdentificationToken ? true : false}>
                        <Link to={ROUTES.ADMIN_LOCATIONS + activeRestaurant.uniqueIdentificationToken + ROUTES.ADMIN_RESTAURANT_ANALYTICS}>
                           Statistici <Tag color="#f50" className="sidebar-menu-tag">nou</Tag>
                        </Link>
                    </Menu.Item>
                </Menu>

                <div className="locationSelector">{props.dropdownRestaurants}</div>
            </div>
        </Sider>
    ) : (
        <Spin />
    );
};

const condition = (authUser) => !!authUser;
export default withAuthorization(condition)(AdminLayout);
