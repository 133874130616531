import React from 'react';
import { PDFViewer, PDFDownloadLink, Page, Text, View, Document, StyleSheet, Font, Image, usePDF } from '@react-pdf/renderer';
import { DownloadOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import {
    formatNutritionalValues,
    uniqueValuesinArray,
    formatFoodAdditives,
    sortByKey,
    nutritionalCalculation,
    nutritionalCalculationSumIngredients,
    formatAllergensAndAdditives,
} from '../../../../helpers';
// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4',
    },
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
        fontSize: 10,
        fontFamily: 'Nunito',
    },
    section: {
        margin: 8,
        padding: 8,
        flexGrow: 1,
    },
    title: {
        fontSize: 20,
        textAlign: 'center',
        fontFamily: 'Nunito',
        fontWeight: 700,
        color: '#292333',
        marginBottom: 25,
    },
    subtitle: {
        fontSize: 10,
        marginBottom: 10,
        fontFamily: 'Oswald',
        textAlign: 'center',
    },
    text: {
        fontFamily: 'Roboto',
        fontSize: 10,
    },
    section: {
        marginBottom: 25,
        paddingBottom: 10,
    },
    sectionInner: {
        marginBottom: 8,
    },
    menuItem: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        borderBottom: 1,
        borderBottomColor: '#efefef',
        paddingBottom: 8,
    },
    menuItemTitle: {
        fontWeight: 600,
        fontSize: 11,
        marginBottom: 3,
        fontFamily: 'Nunito',
    },
    menuCategoryTag: {
        fontSize: 10,
        textAlign: 'center',
        marginBottom: 70,
        fontFamily: 'Roboto',
        fontWeight: 300,
    },
    menuItemImage: {
        borderRadius: 10,
        objectFit: 'cover',
        width: 100,
        height: 60,
    },
    menuItemImageContainer: {
        width: 100,
        height: 60,
        borderRadius: 10,
        marginRight: 20,
    },
    menuItemPrice: {
        textAlign: 'right',
        flex: 1,
        fontSize: 10,
    },
    menuItemText: {
        flex: 3,
    },

    textHighlight: {
        fontWeight: 700,
    },
    nutritionalSectionTitle: {
        marginTop: 5,
        marginBottom: 3,
        fontSize: 9,
    },
    small: {
        fontSize: 7,
        marginTop: 3,
        fontWeight: 300,
    },
    verticalSpacingSmall: {
        marginTop: 3,
    },
});

export const nutritionalMenuShort = (nutritionalData, record, embed = false) => {
    const nutritionalDataSorted = sortByKey(nutritionalData, 'quantity', 0);

    let listOfIngredientsSorted = [];
    let nutritionalValues = [];
    let noOfAdditives = 0;
    let uniqueAdditives = [];
    let noOfUniqueAdditives = 0;
    let allergensInComponents = [];
    let foodAdditivesExtractE = [];
    nutritionalDataSorted.map((item, index) => {
        if (item.displayFoodAdditives) {
            uniqueAdditives = [...uniqueAdditives, ...formatFoodAdditives(item)];
            noOfAdditives += item.foodAdditives.split(',').length;
        }
        if (item.components && item.components.length > 0) {
            item.components.map((item2) => {
                if (item2.displayFoodAdditives) {
                    uniqueAdditives = [...uniqueAdditives, ...formatFoodAdditives(item2)];
                    noOfAdditives += item2.foodAdditives.split(',').length;
                }
            });
        }
    });
    uniqueAdditives = uniqueValuesinArray(uniqueAdditives);
    noOfUniqueAdditives = uniqueAdditives.filter(function (v, i) {
        return i == uniqueAdditives.lastIndexOf(v);
    }).length;
    nutritionalDataSorted.map((item, index) => {
        const unitOfMeasure = item.unitOfMeasure || 'g';
        listOfIngredientsSorted.push(
            <Text key={'ingredient-' + item.uniqueIdentificationToken + '-' + index}>
                <Text>{index > 0 && index < nutritionalDataSorted.length ? ', ' : ''}</Text>
                {item.allergens ? <Text style={styles.textHighlight}>{item.title}</Text> : item.title}
                <Text>{' ' + item.quantity + ' ' + unitOfMeasure}</Text>
                {item.components && item.components.length > 0
                    ? item.components.filter((d) => d.allergens).length > 0
                        ? (item.components.map((d) => (d.allergens ? allergensInComponents.push(d.allergens) : null)),
                          (
                              <Text>
                                  {' '}
                                  (alergeni: <Text>{uniqueValuesinArray(allergensInComponents).join(',')})</Text>
                              </Text>
                          ))
                        : null
                    : ''}
            </Text>
        );
    });
    nutritionalValues.push(
        <View>
            <Text style={styles.nutritionalSectionTitle}>Valori nutritionale / 100g</Text>
            <Text>
                Valoare energetica: {formatNutritionalValues(record.energy, true)} | Grasimi: {formatNutritionalValues(record.fat)} g | acizi grasi
                saturati: {formatNutritionalValues(record.saturates)} g | Glucide: {formatNutritionalValues(record.carbohydrate)} g | Zaharuri:{' '}
                {formatNutritionalValues(record.sugars)} g | Proteine: {formatNutritionalValues(record.proteins)} g | Sare:{' '}
                {formatNutritionalValues(record.salt)} g
            </Text>
        </View>
    );
    return (
        <View>
            <Text>
                <Text style={styles.menuItemTitle}>
                    {record.title} {noOfUniqueAdditives > 0 ? ' - ' + noOfUniqueAdditives + ' aditivi / ' + uniqueAdditives.join(',') : null}
                </Text>
            </Text>
            {embed ? (
                <Text>
                    {record.weight && record.weight != 0 ? record.weight : ''}
                    {record.itemDescription ? ' | ' + record.itemDescription : ''}
                </Text>
            ) : null}
            <View style={styles.verticalSpacingSmall}>
                <Text>{listOfIngredientsSorted}</Text>
            </View>
            <View>{nutritionalValues}</View>
            {!embed ? (
                <View style={styles.verticalSpacingSmall}>
                    <Text style={styles.small}>Ingredientele ingrosate contin sau pot contine alergeni. </Text>
                </View>
            ) : null}
        </View>
    );
};

export const nutritionalMenuShort2 = (nutritionalData, record, embed = false) => {
    const nutritionalDataSorted = sortByKey(nutritionalData, 'quantity', 0);

    let listOfIngredientsSorted = [];
    let nutritionalValues = [];
    let noOfAdditives = 0;
    let uniqueAdditives = [];
    let noOfUniqueAdditives = 0;
    let allergensInComponents = [];
    let foodAdditivesExtractE = [];
    nutritionalDataSorted.map((item, index) => {
        if (item.displayFoodAdditives) {
            uniqueAdditives = [...uniqueAdditives, ...formatFoodAdditives(item)];
            noOfAdditives += item.foodAdditives.split(',').length;
        }
        if (item.components && item.components.length > 0) {
            item.components.map((item2) => {
                if (item2.displayFoodAdditives) {
                    uniqueAdditives = [...uniqueAdditives, ...formatFoodAdditives(item2)];
                    noOfAdditives += item2.foodAdditives.split(',').length;
                }
            });
        }
        const unitOfMeasure = item.unitOfMeasure || 'g';
        listOfIngredientsSorted.push(renderMenuItemIngredientShort(item, index, nutritionalDataSorted.length, unitOfMeasure));
    });
    uniqueAdditives = uniqueValuesinArray(uniqueAdditives);
    noOfUniqueAdditives = uniqueAdditives.filter(function (v, i) {
        return i == uniqueAdditives.lastIndexOf(v);
    }).length;

    nutritionalValues.push(
        <View>
            <Text style={styles.nutritionalSectionTitle}>Valori nutritionale / 100g</Text>
            <Text>
                Valoare energetica: {formatNutritionalValues(record.energy, true)} | Grasimi: {formatNutritionalValues(record.fat)} g | acizi grasi
                saturati: {formatNutritionalValues(record.saturates)} g | Glucide: {formatNutritionalValues(record.carbohydrate)} g | Zaharuri:{' '}
                {formatNutritionalValues(record.sugars)} g | Proteine: {formatNutritionalValues(record.proteins)} g | Sare:{' '}
                {formatNutritionalValues(record.salt)} g
            </Text>
        </View>
    );
    return (
        <View>
            <Text>
                <Text style={styles.menuItemTitle}>{record.title}</Text>
            </Text>
            {embed ? (
                <Text>
                    <Text>
                        {record.weight && record.weight != 0 ? record.weight : ''}
                        {record.itemDescription ? ' | ' + record.itemDescription : ''}
                    </Text>
                </Text>
            ) : null}
            <View style={styles.verticalSpacingSmall}>
                <Text>
                    {listOfIngredientsSorted} {noOfUniqueAdditives ? ' - ' + noOfUniqueAdditives + 'E' : null}
                </Text>
            </View>
            <View>{nutritionalValues}</View>
            {record.freezedIngredientsUsed ? (
                <View style={styles.verticalSpacingSmall}>
                    <Text style={styles.small}>Poate proveni din produs congelat</Text>
                </View>
            ) : null}
            {!embed ? <Text style={styles.small}>Ingredientele îngroșate conțin sau pot conține alergeni. </Text> : null}
        </View>
    );
};

const renderMenuItemIngredient = (item, index, length, unitOfMeasure, isChild = 0, parentQuantity = null, parentSum, showIngredientQty = false) => {
    let componentsSumQuantity = 0;
    const hasAllergens = item.allergens || (item.components && item.components.filter((d) => d.allergens).length > 0);
    if (item.components && item.components.length > 0) item.components.map((d) => (componentsSumQuantity += d.quantity));
    return (
        <Text key={'ingredient-' + item.uniqueIdentificationToken}>
            {index > 0 && index < length && !isChild ? ', ' : isChild && index > 0 ? (hasAllergens ? '; ' : ', ') : ''}

            {item.allergens ? <Text style={styles.textHighlight}>{item.title}</Text> : item.title}
            {showIngredientQty ? ' ' + item.quantity + '' + item.unitOfMeasure : null}
            {item.allergens ? <Text> | alergen: {formatAllergensAndAdditives(item.allergens)}</Text> : null}
            {item.foodAdditives ? <Text> | aditivi: {formatAllergensAndAdditives(item.displayFoodAdditives)}</Text> : null}

            {item.components && item.components.length > 0 ? ' (' : ''}
            {item.components && item.components.length > 0
                ? sortByKey(item.components, 'quantity', 0).map((d, index) => {
                      return renderMenuItemIngredient(
                          d,
                          index,
                          item.components.length,
                          unitOfMeasure,
                          1,
                          item.quantity,
                          componentsSumQuantity,
                          showIngredientQty
                      );
                  })
                : null}
            {item.components && item.components.length > 0 ? ')' : ''}
        </Text>
    );
};

const renderMenuItemIngredientShort = (item, index, length, unitOfMeasure, isChild = 0, parentQuantity = null, parentSum) => {
    let componentsSumQuantity = 0;
    const hasAllergensOrAdditives = item.foodAdditives !== null || item.allergens ? true : false;
    const hasAllergens = item.allergens || (item.components && item.components.filter((d) => d.allergens).length > 0) ? true : false;
    if (item.components && item.components.length > 0) item.components.map((d) => (componentsSumQuantity += d.quantity));
    return (
        <Text key={'ingredient-' + index + '-' + item.uniqueIdentificationToken}>
            {index > 0 && index < length && !isChild ? ', ' : isChild && index > 0 && hasAllergens ? '; ' : ''}

            {!isChild ? hasAllergens ? <Text style={styles.textHighlight}>{item.title}</Text> : item.title : null}

            {item.allergens ? (
                <Text>
                    {!isChild ? ' | alergen:' : ''} {formatAllergensAndAdditives(item.allergens)}
                </Text>
            ) : null}

            {item.components && item.components.length > 0
                ? sortByKey(item.components, 'quantity', 0).map((d, index) => {
                      return renderMenuItemIngredientShort(d, index, item.components.length, unitOfMeasure, 1, item.quantity, componentsSumQuantity);
                  })
                : null}
        </Text>
    );
};

export const nutritionalMenuFullListOfIngredients = (nutritionalData, record, showIngredientQty) => {
    let listOfIngredientsSorted = [];
    const nutritionalDataSorted = sortByKey(nutritionalData, 'quantity', 0);
    nutritionalDataSorted.map((item, index) => {
        const unitOfMeasure = item.unitOfMeasure || 'g';
        listOfIngredientsSorted.push(renderMenuItemIngredient(item, index, nutritionalDataSorted.length, unitOfMeasure, 0, 0, 0, showIngredientQty));
    });
    return listOfIngredientsSorted;
};

export const nutritionalMenuFullValues = (nutritionalData, record) => {
    let nutritionalValues = [];

    let nutritionalCalculationSum = nutritionalCalculation(nutritionalData, 'full');
    nutritionalValues.push(
        <View>
            <Text style={styles.nutritionalSectionTitle}>Valori nutritionale / 100g</Text>
            <Text>
                Valoare energetica: {formatNutritionalValues(record.energy, true)} | Grasimi: {formatNutritionalValues(record.fat)} g | acizi grasi
                saturati: {formatNutritionalValues(record.saturates)} g | Glucide: {formatNutritionalValues(record.carbohydrate)} g | Zaharuri:{' '}
                {formatNutritionalValues(record.sugars)} g | Proteine: {formatNutritionalValues(record.proteins)} g | Sare:{' '}
                {formatNutritionalValues(record.salt)} g
            </Text>
        </View>
    );

    return nutritionalValues;
};

export const nutritionalMenuFull = (nutritionalData, record, embed = false, showIngredientQty = false) => {
    return (
        <View>
            <Text>
                <Text style={styles.menuItemTitle}>{record.title}</Text>
            </Text>
            {embed ? (
                <Text>
                    <Text>
                        {record.weight && record.weight != 0 ? record.weight : ''}
                        {record.itemDescription ? ' | ' + record.itemDescription : ''}
                    </Text>
                </Text>
            ) : null}
            <View style={styles.verticalSpacingSmall}>
                <Text>{nutritionalMenuFullListOfIngredients(nutritionalData, record, showIngredientQty)}</Text>
            </View>
            <View>{nutritionalMenuFullValues(nutritionalData, record)}</View>
            {record.freezedIngredientsUsed ? (
                <View>
                    <Text style={styles.small}>Poate proveni din produs congelat</Text>
                </View>
            ) : null}
            {!embed ? <Text style={styles.small}>Ingredientele ingroșate conțin sau pot conține alergeni.</Text> : null}
        </View>
    );
};

const MenuItemPrint = (props) => {
    const { content, title, nutritionalData, record, type } = props;
    let fileName = 'export-' + title + (type === 'short' ? '_rezumat' : '_complet') + '-A4';
    fileName = fileName.replace(/[^a-z0-9]/gi, '-').toLowerCase();
    const MyDoc = () => (
        <Document>
            <Page size="A4" style={styles.body} orientation="portrait">
                {type === 'short' ? nutritionalMenuShort2(nutritionalData, record) : nutritionalMenuFull(nutritionalData, record)}
            </Page>
        </Document>
    );
    return (
        <>
            <PDFDownloadLink document={<MyDoc />} fileName={fileName}>
                {({ blob, url, loading, error }) =>
                    loading ? (
                        <Button size="large" loading={true}>
                            Se incarca
                        </Button>
                    ) : (
                        <Button icon={<FilePdfOutlined />} size="middle">
                            Generează PDF
                        </Button>
                    )
                }
            </PDFDownloadLink>
        </>
    );
};

export default MenuItemPrint;
