import React from 'react';
import ImgCrop from 'antd-img-crop';
import {Button, message, Upload, Menu, Dropdown } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { ApiHelpers } from '../../../helpers';

export default function UploadCategoryImage(props) {

    const { menuCategoriesData, setMenuCategoriesData, getMenuCategories, newElement, setNewElement, restaurantIdentificationToken, menuId} = props;

    const onPreview = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };

    const uploadPropsImage = {

            name: 'file',
            action: ApiHelpers.uploadMenuCategoryImage(newElement.uniqueIdentificationToken, restaurantIdentificationToken),
            method: 'PUT',
            headers: {
                authorization: 'authorization-text',
            },
            onChange(info) {
                if (info.file.status !== 'uploading') {
                    //console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                    message.success(`${info.file.name} file uploaded successfully`);
                    
                    setNewElement({
                        ...newElement,
                        "categoryImagePath": info.file.response.categoryImagePath
                    });
                    getMenuCategories();
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
            onPreview: onPreview
    }

    const menuImg = (uniqueIdentificationToken = newElement.uniqueIdentificationToken) => {
        return (
            <Menu>
                <Menu.Item>
                    <a onClick={() => onDeleteMenuCategoryImage(uniqueIdentificationToken, restaurantIdentificationToken)} href="#">
                        Sterge
            </a>
                </Menu.Item>
                <Menu.Item>
                <ImgCrop rotate aspect={3 / 2} minZoom={0.2} cropperProps={{ restrictPosition: false }}>
                    <Upload {...uploadPropsImage}>
                        <a href="#">Modifica</a>
                    </Upload>
                </ImgCrop>
                </Menu.Item>
            </Menu>
        )
    }

    const onDeleteMenuCategoryImage = (uniqueIdentificationToken, restaurantIdentificationToken) => {
        ApiHelpers.deleteMenuCategoryImage(uniqueIdentificationToken, restaurantIdentificationToken)
            .then((response) => {
                setNewElement({
                    ...newElement,
                    "categoryImagePath": null
                });
                message.success('Imagine stearsa!');
                getMenuCategories();
            }, (error) => {
                console.log(error);
                message.error('Eroare!');
            });
    }

    return (
        <>
            {newElement.categoryImagePath ?
                <Dropdown overlay={menuImg}>
                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                        <img src={ApiHelpers.getImageLink(newElement.categoryImagePath)} className="uploadedImage" />
                    </a>
                </Dropdown> :
                <ImgCrop rotate aspect={2 / 2} minZoom={0.2} cropperProps={{ restrictPosition: false }}>
                    <Upload {...uploadPropsImage}>
                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>
                </ImgCrop>
            }
        </>
    )
}