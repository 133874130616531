import React, { useState, useEffect, useContext } from 'react';
import { AuthUserContext, withAuthorization } from '../Session';
import AdminLayout from './AdminLayout';
import RestaurantForm from './Restaurant/RestaurantForm';
import { Drawer, Button, Table, Form, message } from 'antd';
import { PlusOutlined, CheckOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { ApiHelpers } from '../../helpers';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from '../../const/routes';

import { useCompany } from '../../swr';

const { Column } = Table;

function Locations() {
    // Get AuthUser ID from Firebase
    const firebaseAuthUser = useContext(AuthUserContext);
    const [form] = Form.useForm();
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [actionType, setActionType] = useState(null);
    const { data: companyDetails } = useCompany('getDetails', firebaseAuthUser.uid);
    let companyIdentificationToken = null;

    if (companyDetails) {
        companyIdentificationToken = companyDetails.uniqueIdentificationToken;
    }

    const [scheduleList, setScheduleList] = useState([]);
    const navigate = useNavigate();

    const INITIAL_STATE_RESTAURANT = {
        title: '',
        appDescription: '',
        placeAddress: '',
        cityId: null,
        uniqueIdentificationToken: null,
        logo: null,
        coverImage: null,
        logoPath: null,
        coverImagePath: null,
        active: false,
        latitude: '',
        longitude: '',
        cuisineTypes: null,
        companyIdentificationToken: '',
        sysRestaurantType: '',
        subscriptionPlan: 'Free',
        implementationMode: true,
        defaultCategoryType:'food'
    };
    const [newElement, setNewElement] = useState({ ...INITIAL_STATE_RESTAURANT });
    const [restaurantData, setRestaurantData] = useState([]);

    const refreshData = () => {
        // Get Company Identifier and afterwards Restaurants
        ApiHelpers.getCompanyDetails(firebaseAuthUser.uid).then(
            (response) => {
                if (!response.data.message) {
                    setNewElement({ ...newElement, companyIdentificationToken: response.data.uniqueIdentificationToken });
                    // Get Restaurants from API
                    ApiHelpers.getRestaurantsInCompany(response.data.uniqueIdentificationToken).then(
                        (response) => {
                            setRestaurantData(response.data);
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                } else {
                    console.log('Nix');
                }
            },
            (error) => {
                console.log(error);
            }
        );
    };

    useEffect(() => {
        refreshData();
    }, []);

    const resetNewElement = () => {
        setNewElement({
            ...INITIAL_STATE_RESTAURANT,
            companyIdentificationToken: companyIdentificationToken,
        });
    };

    const onClose = () => {
        resetNewElement();
        onReset();
        setDrawerVisible(false);
    };

    const onAddElement = () => {
        navigate(ROUTES.ADMIN_LOCATIONS_NEW);
        // setActionType('new');
        // // Get generated UUID from API and store it in state
        // ApiHelpers.getUUID().then(
        //     (response) => {
        //         setNewElement({
        //             ...newElement,
        //             uniqueIdentificationToken: response.data.uuid,
        //         });
        //         setDrawerVisible(true);
        //     },
        //     (error) => {
        //         console.log(error);
        //     }
        // );
    };

    const onEditElement = (record) => {
        setActionType('edit');
        //const elementsIndex = restaurantData.findIndex(element => element.uniqueIdentificationToken === record.uniqueIdentificationToken);

        let restaurantCuisineTypes = [];
        if (record.cuisineTypes)
            record.cuisineTypes.map((element) => {
                restaurantCuisineTypes.push(element.uniqueIdentificationToken);
            });
        // Set form fields values
        form.setFieldsValue({
            ...record,
            companyIdentificationToken: companyIdentificationToken,
            cuisineType: restaurantCuisineTypes,
        });

        // Update state with the element being edited
        setNewElement({
            ...record,
            companyIdentificationToken: companyIdentificationToken,
        });
        setScheduleList({ ...record.schedule });
        setDrawerVisible(true);
    };

    const onSave = () => {
        form.validateFields()
            .then(() => {
                if (actionType === 'new') {
                    ApiHelpers.postRestaurant({
                        ...newElement,
                        schedule: { ...scheduleList },
                    }).then(
                        (response) => {
                            const newList = restaurantData.concat(response.data.dbRestaurant);
                            setRestaurantData(newList);
                            message.success('Element adaugat!');
                            navigate(ROUTES.ADMIN);
                        },
                        (error) => {
                            console.log(error);
                            message.error('Eroare!');
                        }
                    );
                } else if (actionType === 'edit') {
                    ApiHelpers.updateRestaurant({
                        ...newElement,
                        schedule: { ...scheduleList },
                    }).then(
                        (response) => {},
                        (error) => {
                            console.log(error);
                            message.error('Eroare!');
                        }
                    );

                    const elementsIndex = restaurantData.findIndex(
                        (element) => element.uniqueIdentificationToken === newElement.uniqueIdentificationToken
                    );
                    let newRestaurantData = [...restaurantData];
                    newRestaurantData[elementsIndex] = { ...newElement };
                    setRestaurantData(newRestaurantData);
                    message.success('Element modificat!');
                    refreshData();
                }
                resetNewElement();
                setDrawerVisible(false);
                onReset();
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    };

    const onReset = () => {
        resetNewElement();
        form.resetFields();

        form.setFieldsValue({
            cuisineTypes: [],
        });
    };

    const locationFormLayout = {
        layout: 'vertical',
        form: form,
        size: 'large',
    };

    return (
        <AuthUserContext.Consumer>
            {(authUser) => (
                <AdminLayout authUser={authUser} pageTitle="Locuri">
                    {companyIdentificationToken ? (
                        <div>
                            <Button type="primary" onClick={onAddElement} className="btnAdd">
                                <PlusOutlined /> Adauga
                            </Button>
                            <Drawer
                                title="Restaurant"
                                width={window.innerWidth > 1000 ? 1000 : window.innerWidth}
                                onClose={onClose}
                                visible={drawerVisible}
                                bodyStyle={{ paddingBottom: 80 }}
                                footer={
                                    <div
                                        style={{
                                            textAlign: 'right',
                                        }}>
                                        <Button size="large" onClick={onClose} style={{ marginRight: 8 }}>
                                            Anuleaza
                                        </Button>
                                        <Button size="large" onClick={onSave} type="primary">
                                            Salveaza
                                        </Button>
                                    </div>
                                }>
                                <RestaurantForm
                                    formLayout={locationFormLayout}
                                    restaurantId={newElement.uniqueIdentificationToken}
                                    restaurantDetails={newElement}
                                    setRestaurantDetails={setNewElement}
                                    isDrawer={true}
                                    setScheduleList={setScheduleList}
                                    scheduleList={scheduleList}
                                    drawerVisible={drawerVisible}
                                />
                            </Drawer>
                            <Table dataSource={restaurantData} pagination={false} rowKey="uniqueIdentificationToken" scroll={{ x: 240 }}>
                                <Column
                                    title="Imagine"
                                    dataIndex="itemImage"
                                    key="itemImage"
                                    width="150px"
                                    render={(text, record) =>
                                        record.coverImagePath !== '' && record.coverImagePath !== null ? (
                                            <img src={ApiHelpers.getImageLink(record.coverImagePath)} className="thumbnailList" />
                                        ) : (
                                            ''
                                        )
                                    }
                                />
                                <Column title="Nume" width="200px" dataIndex="title" />
                                <Column
                                    title="Actiuni"
                                    render={(text, record) => (
                                        <div>
                                            <Button
                                                type="default"
                                                className="tableAction"
                                                icon={<UnorderedListOutlined />}
                                                href={ROUTES.ADMIN_LOCATIONS + record.uniqueIdentificationToken}>
                                                Vezi detalii
                                            </Button>
                                        </div>
                                    )}
                                />
                                <Column title="Adresa" dataIndex="placeAddress" />
                                <Column
                                    title="Activ"
                                    dataIndex="active"
                                    render={(text, record) => (record.active === true ? <CheckOutlined /> : '')}
                                />
                            </Table>
                        </div>
                    ) : (
                        <div>
                            Datele companiei nu au fost inregistrate. <a href={ROUTES.ACCOUNT_SETTINGS}>Date companie</a>
                        </div>
                    )}
                </AdminLayout>
            )}
        </AuthUserContext.Consumer>
    );
}

const condition = (authUser) => !!authUser;
export default withAuthorization(condition)(Locations);
