import React, { useState, useEffect, useContext } from 'react';
import { withAuthorization, AuthUserContext } from '../Session';
import { Input, Form, Button, message } from 'antd';
import AdminLayout from '../Admin/AdminLayout';
import { ApiHelpers } from '../../helpers';


function Settings() {

  // Get AuthUser ID from Firebase
  const firebaseAuthUser = useContext(AuthUserContext);


  const INITIAL_STATE_USER_PROFILE = {
    uuid: firebaseAuthUser.uid,
    userType: "",
    active: true,
    name: "",
    emailAddress: ""
  }

  const [formUserProfile] = Form.useForm();
  const [userDetails, setUserDetails] = useState({ ...INITIAL_STATE_USER_PROFILE });
  const [actionType, setActionType] = useState("new");

  const tailLayout = {
    wrapperCol: { offset: 3, span: 8 },
  };

  /* User Profile */
  const onChangeUserProfile = event => {
    setUserDetails({
      ...userDetails,
      [event.target.name]: event.target.value
    });
  };

  const onSaveUserProfile = () => {
    // Update User Profile
    ApiHelpers.updateUser(userDetails)
      .then((response) => {
        message.success('Date actualizate!');
      }, (error) => {
        console.log(error);
        message.error('Eroare!');
      });
  }


  useEffect(() => {
    // Get User from API
    ApiHelpers.getUser(firebaseAuthUser.uid)
      .then((response) => {
        setUserDetails(response.data);
        // Set form fields values
        formUserProfile.setFieldsValue({
          ...response.data
        });
      }, (error) => {
        console.log(error);
      });

  }, [])

  return (
    <AdminLayout authUser={firebaseAuthUser} pageTitle="Setari">
      <h2>Date cont</h2>
      <Form layout="horizontal" form={formUserProfile} labelCol={{ span: 3 }}
        wrapperCol={{ span: 8 }} size="large">
        <Form.Item
          name="name"
          label="Nume"
          rules={[{ required: true, message: 'Camp obligatoriu' }]}
        >
          <Input placeholder="Please enter name" onBlur={onChangeUserProfile} name="name" value={userDetails.name} />
        </Form.Item>
        <Form.Item
          name="emailAddress"
          label="Email"
          rules={[{ required: true, message: 'Camp obligatoriu, tip email' }]}
        >
          <Input placeholder="Please enter name" onBlur={onChangeUserProfile} name="emailAddress" disabled="disabled" value={userDetails.emailAddress} />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button type="primary" className="btnAdd" onClick={onSaveUserProfile}>
            Salveaza
          </Button>
        </Form.Item>
      </Form>
    </AdminLayout>
  )
}

const condition = authUser => !!authUser;
export default withAuthorization(condition)(Settings);