import React, { useState, useEffect } from 'react';
import { Table, Input, Form, DatePicker, Button, Space, Skeleton, Collapse, Drawer, Alert } from 'antd';
import { useReportFeedbackHistory } from '../../../swr';
import { UnorderedListOutlined } from '@ant-design/icons';
import { sortByKey, splitAndIterate } from '../../../helpers';

import moment from 'moment';
const { Column } = Table;
const Search = Input.Search;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;

const RestaurantFeedback = (props) => {
    const [form] = Form.useForm();
    const [restaurantFeedback, setRestaurantFeedback] = useState([]);
    const [restaurantFeedbackFilter, setRestaurantFeedbackFilter] = useState([]);
    const [newElement, setNewElement] = useState([]);
    const INITIAL_DATE_RANGE = [moment().subtract(1, 'weeks').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
    const INITAL_DATE_RANGE_PICKER = [moment().subtract(1, 'weeks'), moment()];
    const [isLoading, setIsLoading] = useState(true);
    const [drawerVisible, setDrawerVisible] = useState(false);

    const [dateRange, setDateRange] = useState(INITIAL_DATE_RANGE);
    const { data: feedbackHistory, isLoading: isLoadingFeedbackHistory } = useReportFeedbackHistory(props.restaurantId, ...dateRange);

    useEffect(() => {
        if (!isLoadingFeedbackHistory && feedbackHistory) {
            setRestaurantFeedback(feedbackHistory);
            setRestaurantFeedbackFilter(feedbackHistory);
            setIsLoading(false);
        }
    }, [feedbackHistory]);

    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        if (value) {
            setRestaurantFeedback(
                restaurantFeedbackFilter.filter((item) => {
                    if (
                        item.waiter.toLowerCase().indexOf(value) > -1 ||
                        item.sessionStartMoment.toLowerCase().indexOf(value) > -1 ||
                        item.items.toLowerCase().indexOf(value) > -1
                    )
                        return true;
                })
            );
        } else setRestaurantFeedback(restaurantFeedbackFilter);
    };

    const onChange = (dates, dateStrings) => {
        if (dates && dateStrings) {
            setDateRange([moment(dateStrings[0], 'DD.MM.YYYY').format('YYYY-MM-DD'), moment(dateStrings[1], 'DD.MM.YYYY').format('YYYY-MM-DD')]);
        }
    };
    const handleFilter = () => {};

    const onClose = () => {
        setDrawerVisible(false);
    };

    const onReset = () => {
        form.resetFields();
        setNewElement([]);
    };

    const onOrderDetails = (record) => {
        setDrawerVisible(true);
        // Update state with the element being edited
        setNewElement({ ...record });
    };

    return isLoading ? (
        <Skeleton active></Skeleton>
    ) : (
        <>
            <Space direction="horizontal" size={12}>
                Perioada:
                <RangePicker
                    defaultValue={[...INITAL_DATE_RANGE_PICKER]}
                    format="DD.MM.YYYY"
                    ranges={{
                        Astazi: [moment(), moment()],
                        Ieri: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                        'Saptamana curenta': [moment().startOf('week'), moment().endOf('week')],
                        'Saptamana trecuta': [moment().subtract(1, 'weeks').startOf('week'), moment().subtract(1, 'weeks').endOf('week')],
                        'Luna curenta': [moment().startOf('month'), moment().endOf('month')],
                        'Luna trecuta': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                    }}
                    onChange={onChange}
                />
                <Button onClick={handleFilter} type="primary">
                    Filtreaza
                </Button>
            </Space>
            <Search placeholder="Dupa data, comanda sau chelner" onChange={handleSearch} className="tableSearchField" />
            <Table
                dataSource={restaurantFeedback}
                pagination={false}
                rowKey={(record, index) => record.uniqueIdentificationToken}
                scroll={{ x: 240 }}>
                <Column title="Data" width="100px" render={(record) => moment(record.sessionStartMoment).format('DD.MM.YYYY HH:mm')} />
                <Column
                    title="Actiuni"
                    key="actions"
                    render={(text, record) => (
                        <Button type="default" className="tableAction" icon={<UnorderedListOutlined />} onClick={() => onOrderDetails(record)}>
                            Vezi detalii
                        </Button>
                    )}
                />
                <Column title="Masa" align="right" dataIndex="tableIndex" />
                <Column title="Scor restaurant" align="right" dataIndex="score" />
                <Column title="Scor mancare" align="right" dataIndex="foodScore" />
                <Column title="Scor ospatar" align="right" dataIndex="waiterScore" />
                <Column title="Mesaj" width="300px" dataIndex="feedbackMessage" />
                <Column title="Ospatar" dataIndex="waiter" />
            </Table>
            <Drawer
                title="Detalii nota de plata"
                width={window.innerWidth > 1000 ? 1000 : window.innerWidth}
                onClose={onClose}
                visible={drawerVisible}
                bodyStyle={{ paddingBottom: 80 }}
                footer={
                    <div className="modalFooter">
                        <div
                            style={{
                                textAlign: 'left',
                            }}></div>
                        <div
                            style={{
                                textAlign: 'right',
                            }}>
                            <Button size="large" onClick={onClose} style={{ marginRight: 8 }}>
                                Inchide
                            </Button>
                        </div>
                    </div>
                }>
                {newElement ? (
                    <Collapse className="formCollapse" bordered={false} defaultActiveKey={['info', 'orderItems']}>
                        <Panel header="Informatii" key="info">
                            <p>
                                <strong>Data:</strong> {moment(newElement.sessionStartMoment).format('DD.MM.YYYY HH:mm')}
                            </p>
                            <p>
                                <strong>Masa:</strong> {newElement.tableIndex}
                            </p>
                            <p>
                                <strong>Scor restaurant:</strong> {newElement.score}{' '}
                            </p>
                            <p>
                                <strong>Scor mancare:</strong> {newElement.foodScore}
                            </p>
                            <p>
                                <strong>Scor ospatar:</strong> {newElement.waiterScore}{' '}
                            </p>
                            <p>
                                <strong>Mesaj:</strong> {newElement.feedbackMessage}
                            </p>
                            <p>
                                <strong>Ospatar:</strong> {newElement.waiter}
                            </p>
                        </Panel>
                        {props.activeRestaurant.subscriptionPlan === 'Full' ? (
                            newElement.waiter ? (
                                <Panel header="Continut" key="orderItems">
                                    {newElement.items ? (
                                        <div>
                                            {splitAndIterate(newElement.items).map((d, index) => {
                                                return (
                                                    <div className={'orderContent orderContentDepth-' + d.depth} key={'orderItem-' + index}>
                                                        {d.token}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ) : null}
                                </Panel>
                            ) : null
                        ) : (
                            <Panel header="Continut" key="orderItems">
                                <Alert
                                    type="info"
                                    message="Vrei sa vezi continutul comenzii? Modulul poate fi accesat prin achizitionarea unui plan tarifar superior."
                                    action={
                                        <Space>
                                            <Button
                                                type="primary"
                                                size="medium"
                                                onClick={() => (window.location.href = 'mailto:contact@poftigo.ro?subject=Upgrade abonament')}>
                                                Vreau detalii
                                            </Button>
                                        </Space>
                                    }></Alert>
                            </Panel>
                        )}
                    </Collapse>
                ) : null}
            </Drawer>
        </>
    );
};

export default RestaurantFeedback;
