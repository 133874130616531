import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { activeRestaurantState } from '../../../../recoil';
import { appIcon } from '../../../../const';
import { ApiHelpers } from '../../../../helpers';
import QRCode from 'qrcode.react';
import { Spin } from 'antd';

const QRCodeCustomPreview = (props) => {
    const { value, customization, tableIndex = '10', isStaticPreview } = props;
    const [generatedQR, setGeneratedQR] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [activeRestaurant, setActiveRestaurant] = useRecoilState(activeRestaurantState);
    const isSelfService = activeRestaurant.sysRestaurantType === 'SelfService';
    const QRCodeMarkup = (
        <QRCode
            value={value}
            size={isStaticPreview ? 190 : 780}
            level="Q"
            fgColor="#292333"
            renderAs="svg"
            imageSettings={{
                src:
                    activeRestaurant.subscriptionPlan !== 'Free' && activeRestaurant.logoPath
                        ? ApiHelpers.getImageLink(activeRestaurant.logoPath)
                        : appIcon,
                //src: appIcon,
                width: isStaticPreview ? 50 : 200,
                height: isStaticPreview ? 50 : 200,
                excavate: true,
            }}
        />
    );

    useEffect(() => {
        if (customization) setIsLoading(false);
    }, [customization]);

    return isLoading ? (
        <Spin />
    ) : (
        <div
            className={'customized-QR-container' + (isStaticPreview ? ' preview' : '')}
            style={{
                backgroundColor: customization.backgroundColour,
                backgroundImage: `url(${customization.qrImagePath ? ApiHelpers.getImageLink(customization.qrImagePath) : ''})`,
            }}>
            {customization.qrImagePath ? (
                <div className="customized-QR-bg-layer" style={{ backgroundColor: customization.backgroundColour + '60' }}></div>
            ) : null}

            <div className={'customized-QR-content' + (value ? ' qr-value' : '')}></div>
            {!isStaticPreview || value ? <div className="customized-QR-value">{QRCodeMarkup}</div> : null}
            <div className={'customized-QR-text text-title' + (!customization.showAllActions ? ' text-short' : '')}>
                vezi{' '}
                <span
                    className="customized-QR-text text-highlight"
                    style={{
                        color:
                            customization.backgroundColour !== '#FFFFFF' && customization.backgroundColour !== '#F6EFD6'
                                ? customization.backgroundColour
                                : '#C32E32',
                    }}>
                    meniul
                </span>{' '}
                {customization.showAllActions ? 'și cheamă ospătarul' : null}
            </div>
            <div className="customized-QR-logo">
                <img src={activeRestaurant.logoPath ? ApiHelpers.getImageLink(activeRestaurant.logoPath) : appIcon} className="customized-QR-logo" />
            </div>
            {customization.showAllActions ? <div className="customized-QR-text text-subTitle">cere nota | acordă feedback</div> : null}
            <div className="customized-QR-text text-bottom">
                <span className="customized-QR-text text-bottom-highlight">{tableIndex}</span> {activeRestaurant.title}
            </div>
        </div>
    );
};

export default QRCodeCustomPreview;
