import React, { useEffect, useState } from 'react';
import { Form, Tooltip, Spin, Row, Col, Popconfirm, Select, Space } from 'antd';

import { INITAL_STATE_SCHEDULEHELPER, INITAL_STATE_SCHEDULE_NEW_HELPER, INITIAL_SLOTS } from './';
import { DeliveredProcedureOutlined, PlusOutlined, DeleteOutlined, ExportOutlined } from '@ant-design/icons';
import { ApiHelpers } from './';
const { Option } = Select;
const FormSchedules = (props) => {
    const { setScheduleList, restaurantId, type, drawerVisible, schedulesNew, setSchedulesNew } = props;
    const INITIAL_SCHEDULE_FORMATTED = {
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
        sunday: [],
    };

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        console.log(schedulesNew);
        if (Object.keys(schedulesNew).length === 0) {
            setSchedulesNew({ ...INITIAL_SCHEDULE_FORMATTED });
        }
    }, [schedulesNew]);

    const onChangeSchedule = (key, index, startTime, endTime, uniqueIdentificationToken = null) => {
        const day = key.toLowerCase();
        //const tempSchedulesNew = { ...schedulesNew };
        const tempSchedulesNew = JSON.parse(JSON.stringify(schedulesNew));
        tempSchedulesNew[day][index].startTime = startTime;
        tempSchedulesNew[day][index].endTime = endTime;

        setSchedulesNew({ ...tempSchedulesNew });
    };

    const handleDisableTimePicker = (available) => {
        setSchedulesNew({ ...schedulesNew });
    };

    const triggerImportSchedule = (e) => {
        setIsLoading(true);
        e.preventDefault();
        ApiHelpers.getRestaurantSchedules(restaurantId).then(
            (response) => {
                if (!response.data.message) {
                    const { uniqueIdentificationToken, ...newSchedule } = response.data;
                    setSchedulesNew({ ...response.data });
                }
                setIsLoading(false);
            },
            (error) => {
                console.log(error);
                setIsLoading(false);
            }
        );
    };
    const handleAddSlot = (day) => {
        const tempSchedulesNew = { ...schedulesNew };
        tempSchedulesNew[day] = [...tempSchedulesNew[day], { startTime: '', endTime: '', uniqueIdentificationToken: null }];

        setSchedulesNew({ ...tempSchedulesNew });
    };

    const handleDeleteSlot = (day, index) => {
        const tempSchedulesNew = { ...schedulesNew };
        tempSchedulesNew[day] = tempSchedulesNew[day].filter((value, index2) => index2 !== index);
        setSchedulesNew({ ...tempSchedulesNew });
    };

    const renderTimeSlotSelector = (name, index = null, d = null) => {
        const uniqueIdentificationToken = index ? schedulesNew[name][index].uniqueIdentificationToken : null;
        const startTime = index !== null ? schedulesNew[name][index].startTime : null;
        const endTime = index !== null ? schedulesNew[name][index].endTime : null;
        return (
            <Row key={name + '-' + index} className="schedules-item">
                <Col>
                    <Row gutter={[10, 10]}>
                        <Col>
                            <Select
                                className="schedules-item-select"
                                name={name + '-startTime'}
                                onChange={(value) => onChangeSchedule(name, index, value, endTime, uniqueIdentificationToken)}
                                value={d && d.startTime ? d.startTime.substring(0, 5) : ''}>
                                {INITIAL_SLOTS.map((d) => {
                                    return <Option key={name + '-' + index + '-' + d} value={d} />;
                                })}
                            </Select>
                        </Col>
                        <Col>
                            <Select
                                className="schedules-item-select"
                                name={name + '-endTime'}
                                onChange={(value) => onChangeSchedule(name, index, startTime, value, uniqueIdentificationToken)}
                                value={d && d.endTime ? d.endTime.substring(0, 5) : ''}>
                                {INITIAL_SLOTS.map((d) => {
                                    return <Option key={name + '-' + index + '-' + d} value={d} />;
                                })}
                            </Select>
                        </Col>
                    </Row>
                </Col>
                <Col offset={1}>
                    <Popconfirm title="Sigur vrei sa stergi?" onConfirm={() => handleDeleteSlot(name, index)}>
                        <a title="Sterge">
                            <DeleteOutlined style={{ fontSize: 15 }} />
                        </a>
                    </Popconfirm>
                </Col>
            </Row>
        );
    };

    const copyInAllDays = (timeSlot) => {
        const tempTimeSlot = timeSlot.map((d) => {
            return { ...d, uniqueIdentificationToken: null };
        });
        const tempSchedulesNew = JSON.parse(JSON.stringify(schedulesNew));
        ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map((d) => {
            tempSchedulesNew[d] = [...tempTimeSlot];
        });
        setSchedulesNew({ ...tempSchedulesNew });
    };
    return schedulesNew !== null ? (
        <>
            {type === 'menu' ? (
                <div className="actionLinkIcon">
                    <DeliveredProcedureOutlined />
                    <Tooltip title="Apasa pentru a prelua programul restaurantului">
                        <a href="#" onClick={triggerImportSchedule}>
                            Preia programul restaurantului
                        </a>
                    </Tooltip>
                </div>
            ) : null}
            {isLoading ? (
                <Spin />
            ) : (
                INITAL_STATE_SCHEDULE_NEW_HELPER.map((d, index) => {
                    const { startTime, endTime, label, name, available } = d;
                    return (
                        <div key={'timePickerContainer-' + name} className="timePicker-container">
                            <Form.Item key={name + '' + index} name={name} label={label} rules={[{ required: false }]}>
                                {schedulesNew && schedulesNew[name]
                                    ? schedulesNew[name].length > 0
                                        ? schedulesNew[name].map((d, index) => renderTimeSlotSelector(name, index, d))
                                        : null
                                    : null}
                                {schedulesNew && schedulesNew[name] && schedulesNew[name].length === 0 ? (
                                    <a title="Adauga" onClick={() => handleAddSlot(name)}>
                                        <PlusOutlined /> Adaugă interval
                                    </a>
                                ) : null}
                            </Form.Item>
                            {schedulesNew && schedulesNew[name] && schedulesNew[name].length > 0 ? (
                                <Row>
                                    <Col
                                        xs={{ span: 24, offset: 0 }}
                                        md={{ span: 24, offset: 0 }}
                                        lg={{ span: 24, offset: 0 }}
                                        xl={{ span: 21, offset: 3 }}
                                        xxl={{ span: 21, offset: 3 }}>
                                        <Space size={25}>
                                            <a title="Adauga" onClick={() => handleAddSlot(name)}>
                                                <PlusOutlined /> Adaugă interval
                                            </a>
                                            <a title="Aplică pentru toate zilele" onClick={() => copyInAllDays(schedulesNew[name])}>
                                                <ExportOutlined /> Aplică pentru toate zilele
                                            </a>
                                        </Space>
                                    </Col>
                                </Row>
                            ) : null}
                            <div className="schedules-item-separator"></div>
                        </div>
                    );
                })
            )}
        </>
    ) : (
        <Spin />
    );
};

export default FormSchedules;
