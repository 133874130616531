import useSWR from 'swr';
import axios from 'axios';
import { bacchusAPIEndpoint } from '../config';

const fetcher = (url) => axios.get(url).then((res) => res.data);

function useCompany(key = '', id = null) {
    // Default is Get company details
    let endpoint = '';
    if (id) {
        endpoint = bacchusAPIEndpoint + '/admin/company/getByUuid/' + id;
        if (key === 'getCities') {
            endpoint = bacchusAPIEndpoint + '/admin/company/getCities/' + id;
        }
    }

    const { data, error } = useSWR(endpoint ? endpoint : null, fetcher, {
        onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
            // Only retry up to 10 times.
            if (retryCount >= 3) return;

            // Retry after 5 seconds.
            setTimeout(() => revalidate({ retryCount }), 5000);
        },
        shouldRetryOnError: false,
    });

    return {
        data: data,
        isLoading: !error && !data,
        isError: error,
    };
}

export default useCompany;
